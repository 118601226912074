import type { ComponentPropsWithoutRef, JSX } from "react";
import * as colors from "../../../colors";

type IPlusMinus = "plus" | "minus";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
    plusMinus?: IPlusMinus;
}

function MagnifyingGlassIcon({ active, plusMinus, ...props }: IProps): JSX.Element {
    const fillColor =
        plusMinus === "plus"
            ? colors.gradient.green
            : plusMinus === "minus"
              ? colors.gradient.red
              : active
                ? "#0076c2"
                : "#fff";

    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="square"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="59" cy="40" fill="#fff" r="27" />
            <path
                d="m79.2 19.7c-5.5-5.3-12.7-8.2-20.3-8.1s-14.8 3.2-20.1 8.6c-9.5 9.7-10.6 24.5-3.5 35.5l-21 21.5c-2.8 2.8-3 7.1-.4 9.6 1.1 1.1 2.6 1.6 4.2 1.6h.6c1.8-.2 3.5-1 4.8-2.3l20.9-21.5c4.4 2.7 9.6 4.2 14.9 4.2h.4c7.6-.1 14.8-3.2 20.1-8.6 5.3-5.5 8.2-12.7 8.1-20.3s-3.2-14.9-8.7-20.2zm-1.6 38.3c-4.8 4.9-11.2 7.6-18 7.7s-13.3-2.5-18.2-7.3c-10.1-9.9-10.3-26.1-.5-36.2 4.8-4.9 11.2-7.6 18-7.7h.3c6.7 0 13 2.6 17.9 7.3 4.9 4.8 7.6 11.2 7.7 18 .2 6.9-2.4 13.3-7.2 18.2z"
                fill="#222"
                fillRule="nonzero"
            />
            {plusMinus === "plus" && <path d="m59.248 25.526v29.474" fill="none" stroke="#000" strokeWidth="3" />}
            {!!plusMinus && <path d="m44.263 40h29.474" fill="none" stroke="#000" strokeWidth="3" />}
            <g fillRule="nonzero">
                <path
                    d="m21.3 84c-.8.8-1.8 1.3-2.9 1.4-1 .1-1.8-.2-2.4-.8-1.3-1.3-1.1-3.7.5-5.3l20.5-21.1c.7.9 1.5 1.7 2.3 2.5s1.7 1.5 2.5 2.2z"
                    fill={fillColor}
                />
                {!plusMinus && (
                    <path
                        d="m73.5 52.8c-.4 0-.8-.1-1-.4-.6-.6-.6-1.5 0-2.1 5.9-6 5.7-15.7-.3-21.6-.6-.6-.6-1.5 0-2.1s1.5-.6 2.1 0c7.2 7 7.4 18.6.3 25.8-.3.2-.7.4-1.1.4z"
                        fill="#222"
                    />
                )}
            </g>
        </svg>
    );
}

export default MagnifyingGlassIcon;
