import { createSlice } from "@reduxjs/toolkit";

interface IChatState {
    open: boolean;
}

const initialState: IChatState = {
    open: false,
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        openChat(state) {
            state.open = true;
        },
        closeChat(state) {
            state.open = false;
        },
    },
});

export const { openChat, closeChat } = chatSlice.actions;
export default chatSlice.reducer;
