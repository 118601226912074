import type { DebouncedFunc } from "lodash-es";
import throttle from "lodash-es/throttle";
import { type FormEvent, type JSX, type KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import Arrow from "./Arrow";

interface Props {
    sendChatMessage: (message: string) => void;
    sendIsTyping: (isTyping: boolean) => void;
}

function Input({ sendChatMessage, sendIsTyping }: Props): JSX.Element {
    const [value, setValue] = useState<string>("");

    const throttledSendIsTypingRef = useRef<DebouncedFunc<(isTyping: boolean) => void>>(undefined);
    const sendIsTypingRef = useRef(sendIsTyping);
    const sendChatMessageRef = useRef(sendChatMessage);

    useEffect(() => {
        sendIsTypingRef.current = sendIsTyping;
    }, [sendIsTyping]);

    useEffect(() => {
        sendChatMessageRef.current = sendChatMessage;
    }, [sendChatMessage]);

    useEffect(() => {
        throttledSendIsTypingRef.current = throttle((isTyping: boolean) => {
            sendIsTypingRef.current(isTyping);
        }, 300);

        return () => {
            throttledSendIsTypingRef.current?.cancel();
        };
    }, []);

    const handleChange = useCallback((event: FormEvent<HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value;
        throttledSendIsTypingRef.current?.(!!newValue);
        setValue(newValue);
    }, []);

    const handleSend = useCallback(() => {
        if (!value) {
            return;
        }
        throttledSendIsTypingRef.current?.cancel();
        sendChatMessageRef.current(value);
        setValue("");
        sendIsTypingRef.current(false);
    }, [value]);

    const handleKeyPress = useCallback(
        (event: KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                handleSend();
            }
        },
        [handleSend],
    );

    return (
        <div className="chat-input">
            <textarea value={value} onChange={handleChange} onKeyDown={handleKeyPress} />
            <button onClick={handleSend} type="button">
                <Arrow />
            </button>
        </div>
    );
}

export default Input;
