import { range } from "lodash-es";
import type { JSX } from "react";
import { useResizeDetector } from "react-resize-detector";
import styled from "styled-components";
import PersonIcon from "../../../shared/components/icons/PersonIcon";

interface IProps {
    numMembers: number;
    fillPortion: number;
}

const MIN_WIDTH = 30;
const MAX_WIDTH = 80;
const MAX_ROW = 10;

function calcWidth(width: number, numMembers: number): number {
    const numItems = Math.min(numMembers, MAX_ROW);
    for (let i = MAX_WIDTH, iMin = MIN_WIDTH; i > iMin; i = i - 1) {
        if (i * numItems <= width) {
            return i;
        }
    }
    return MIN_WIDTH;
}

interface IWrapperProps {
    $elemWidth: number;
}

const Wrapper = styled.div<IWrapperProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, ${(props) => props.$elemWidth}px);
    grid-auto-rows: ${(props) => props.$elemWidth}px;
    justify-content: start;

    > * {
        width: ${(props) => props.$elemWidth}px;
        height: ${(props) => props.$elemWidth}px;
    }
`;

function PeopleChart({ fillPortion, numMembers }: IProps): JSX.Element {
    const { width, ref } = useResizeDetector();
    const full = Math.floor(fillPortion);
    const partial = fillPortion % 1;
    const empty = numMembers - full - Math.ceil(partial);
    return (
        <div ref={ref}>
            <Wrapper $elemWidth={calcWidth(width, numMembers)}>
                {range(full).map((i) => (
                    <PersonIcon hightlight={1} key={i} />
                ))}
                {!!partial && <PersonIcon hightlight={partial} />}
                {!!empty && range(empty).map((i) => <PersonIcon hightlight={0} key={i} />)}
            </Wrapper>
        </div>
    );
}

export default PeopleChart;
