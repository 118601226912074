import type { ComponentPropsWithoutRef } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function ArrowCircleIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m70.9 21.1-17.9-10.4c-.5-.3-1-.3-1.5 0s-.8.8-.8 1.3v7.5c-.1 0-.1 0-.2 0-16.4 0-29.7 13.3-29.7 29.7 0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5c0-14.8 12-26.7 26.7-26.7h.2v10.3c0 .5.3 1 .8 1.3.2.1.5.2.8.2s.5-.1.8-.2l17.9-10.3c.5-.3.8-.8.8-1.3-.2-.7-.5-1.2-.9-1.4z"
                fill="#222"
            />
            <path d="m53.7 30.1v-15.5l13.4 7.8z" fill={active ? "#ff855d" : "white"} />
            <path
                d="m80.2 49.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5c0 14.7-11.9 26.6-26.5 26.7v-9.6c0-.5-.3-1-.8-1.3s-1-.3-1.5 0l-17.9 10.3c-.5.3-.8.8-.8 1.3s.3 1 .8 1.3l17.9 10.3c.2.1.5.2.8.2s.5-.1.8-.2c.5-.3.8-.8.8-1.3v-8.1c16.2 0 29.4-13.3 29.4-29.6z"
                fill="#222"
            />
            <path d="m47.7 84.4-13.4-7.8 13.4-7.7z" fill={active ? "#ff855d" : "white"} />
        </svg>
    );
}

export default ArrowCircleIcon;
