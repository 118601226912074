import type { JSX } from "react";
import styled from "styled-components";

interface IProps {
    person: {
        id: string;
        firstName: string;
        lastName: string;
        profilePicSrc: string;
    };
}

const Wrapper = styled.div`
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-gap: 10px;
    align-items: center;
`;

const ImgTainer = styled.div`
    width: 80px;
    img {
        width: 80px;
        height: 80px;
        object-fit: cover;
    }
`;

function Person({ person }: IProps): JSX.Element {
    return (
        <Wrapper>
            <ImgTainer>
                <img src={person.profilePicSrc || "/static/images/profile-silouette.png"} alt="Profile" />
            </ImgTainer>
            <div>
                {person.firstName} {person.lastName}
            </div>
        </Wrapper>
    );
}

export default Person;
