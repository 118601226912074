import { useState, type JSX } from "react";
import { type UseFieldConfig, useField } from "react-final-form";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import StarIcon from "../../shared/components/icons/StarIcon";

interface IOwnProps {
    name: string;
}

type IProps = IOwnProps & UseFieldConfig<number>;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    width: 265px;
    margin: 0 auto;
    @media (min-width: 425px) {
        width: 360px;
    }
`;

const ErrorLabel = styled.small`
    grid-column: span 5;
    background-color: ${colors.alert};
    color: ${colors.white};
    text-transform: capitalize;
`;

const Star = styled(StarIcon)`
    width: 45px;
    height: 45px;
    cursor: pointer;
    @media (min-width: 425px) {
        width: 60px;
        height: 60px;
    }
`;

const STAR_VALUES = [1, 2, 3, 4, 5];

function StarInput({ name, ...props }: IProps): JSX.Element {
    const [hoverValue, setHoverValue] = useState<number | null>(null);
    const {
        input,
        meta: { touched, error },
    } = useField(name, props);
    const clickStar = (index: number) => {
        input.onChange(index);
    };
    const yellow = colors.star;
    const grey = colors.lightBorder;
    return (
        <Wrapper>
            {STAR_VALUES.map((starValue) => {
                const active = hoverValue !== null ? hoverValue >= starValue : input.value >= starValue;
                const stroke = active ? yellow : grey;
                const fill = yellow;
                const onClick = () => clickStar(starValue);
                const onMouseEnter = () => setHoverValue(starValue);
                const onMouseLeave = () => setHoverValue(null);
                return (
                    <Star
                        key={starValue}
                        stroke={stroke}
                        active={active}
                        fill={fill}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    />
                );
            })}
            {touched && error && <ErrorLabel>{error}</ErrorLabel>}
        </Wrapper>
    );
}

export default StarInput;
