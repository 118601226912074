import { Route, Routes } from "react-router-dom";
import MyAnswer from "./MyAnswer";
import AllAnswers from "./allAnswers/AllAnswers";
import type { ISubmittedTask } from "./query";
import type { JSX } from "react";

interface IProps {
    task: ISubmittedTask;
}

function SubmittedTaskRoutes({ task }: IProps): JSX.Element {
    return (
        <Routes>
            {task.showSharedAnswers && (
                <Route path="/submitted-task/:taskId/all-answers" element={<AllAnswers task={task} />} />
            )}
            <Route path="/submitted-task/:taskId/*" element={<MyAnswer task={task} />} />
        </Routes>
    );
}

export default SubmittedTaskRoutes;
