import { useState, type JSX } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { useQuery } from "urql";
import Loader from "../../../shared/components/Loader";
import PlusMinus from "../../../shared/components/icons/PlusMinus";
import { formatYMD, parseDate } from "../../../shared/dateFns";
import useScrollIntoView from "../../../shared/hooks/useScrollIntoView";
import t from "../../../shared/translations";
import type { TeamScanStatusType } from "../../../shared/types";
import ShowError from "../../components/ShowError";
import { Center } from "../stylings";
import { Accordion, AccordionHead } from "../stylings/accordion";
import Intro from "./Intro";
import NowWhat from "./NowWhat";
import SL from "./SL";
import TLB from "./TLB";
import TPS from "./TPS";

const query = `
    query($id:String!) {
        teamScan(id:$id) {
            id
            status
            reportDatetime
            progress
            report {
                membersWhoAnswered
                tlb {
                    questionIds
                    membersMeans
                    questionMeans
                    questionStds
                    mean
                    std
                    strengths
                    weaknesses
                    actionPoints
                }
                tps {
                    questionIds
                    membersMeans
                    questionMeans
                    questionStds
                    mean
                    std
                    strengths
                    weaknesses
                    actionPoints
                }
                sna {
                    teamMemberAverageLeadership
                    teamLeaderLeadership
                    density
                    centralisation
                    nodes {
                        id
                        teamLeader
                        incoming
                    }
                    links {
                        source
                        target
                    }
                }
            }
        }
        docList(ids:[
            "player.team-scan.report-ap-tlb1",
            "player.team-scan.report-ap-tlb2",
            "player.team-scan.report-ap-tlb3",
            "player.team-scan.report-ap-tlb7",
            "player.team-scan.report-ap-tlb8",
            "player.team-scan.report-ap-tlb9",
            "player.team-scan.report-ap-tps1",
            "player.team-scan.report-ap-tps2",
            "player.team-scan.report-ap-tps3",
            "player.team-scan.report-ap-tps4",
            "player.team-scan.report-ap-tps5",
            "player.team-scan.report-ap-tps6",
            "player.team-scan.report-ap-tps7",
            "player.team-scan.report-intro",
            "player.team-scan.report-now-what",
            "player.team-scan.report-tps-intro",
            "player.team-scan.report-tlb-intro",
            "player.team-scan.report-sl-intro",
            "player.team-scan.report-sl-outro",
        ]) {
            id
            content
        }
    }
`;

export interface IReportVariable {
    questionIds: string[];
    membersMeans: number[];
    questionMeans: number[];
    questionStds: number[];
    mean: number;
    std: number;
    strengths: string[];
    weaknesses: string[];
    actionPoints: string[];
}

export interface INode {
    id: string;
    teamLeader: boolean;
    incoming: number;
}

export interface ILink {
    source: string;
    target: string;
}

export interface ISNAData {
    teamMemberAverageLeadership: number;
    teamLeaderLeadership: number;
    density: number;
    centralisation: number;
    nodes: INode[];
    links: ILink[];
}

export interface ITeamScan {
    id: string;
    status: TeamScanStatusType;
    reportDatetime: string;
    progress: [number, number];
    report: {
        membersWhoAnswered: number;
        tlb: IReportVariable;
        tps: IReportVariable;
        sna: ISNAData;
    };
}

export interface IDoc {
    id: string;
    content: string;
}

export interface IData {
    teamScan: ITeamScan;
    docList: IDoc[];
}

interface IArgs {
    id: string;
}

type IParams = "id";

type IAccordion = "Intro" | "TPS" | "TLB" | "SL" | "NW" | "";

interface IProps {
    linkHome?: boolean;
}

function Report({ linkHome }: IProps): JSX.Element {
    const { id } = useParams<IParams>();
    const [accordion, setAccordion] = useState<IAccordion>("");
    const introRef = useScrollIntoView<HTMLDivElement>(accordion === "Intro");
    const tpsRef = useScrollIntoView<HTMLDivElement>(accordion === "TPS");
    const tlbRef = useScrollIntoView<HTMLDivElement>(accordion === "TLB");
    const slRef = useScrollIntoView<HTMLDivElement>(accordion === "SL");
    const nwRef = useScrollIntoView<HTMLDivElement>(accordion === "NW");

    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id },
    });

    const toggle = (acc: IAccordion) => {
        setAccordion((currAcc) => (currAcc === acc ? "" : acc));
    };

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const { teamScan, docList } = result.data;
    if (teamScan.status !== "reporting") {
        return <Navigate to="../team-scans" />;
    }
    const complete = teamScan.progress[0];
    const total = teamScan.progress[1];
    return (
        <>
            <h1>{t("shared.team-scan-report.heading")}</h1>
            <p>{formatYMD(parseDate(teamScan.reportDatetime))}</p>
            <p>
                {t("shared.team-scan-report.info-results")}
                <br />
                {t("shared.team-scan-report.info-num-complete", { complete, total })}
                <br />
                {teamScan.progress[0] !== teamScan.progress[1] && (
                    <span>
                        {t("shared.team-scan-report.info-results-will-update")}{" "}
                        <Link to={`../progress/${teamScan.id}`}>{t("shared.team-scan-report.link-progress")}</Link>
                    </span>
                )}
            </p>
            <Accordion ref={introRef}>
                <AccordionHead onClick={() => toggle("Intro")}>
                    <h2>{t("shared.team-scan-report.heading-introduction")}</h2>
                    <PlusMinus open={accordion === "Intro"} />
                </AccordionHead>
                {accordion === "Intro" && <Intro docList={docList} />}
            </Accordion>
            <Accordion ref={tpsRef}>
                <AccordionHead onClick={() => toggle("TPS")}>
                    <h2>{t("shared.team-scan-report.heading-tps")}</h2>
                    <PlusMinus open={accordion === "TPS"} />
                </AccordionHead>
                {accordion === "TPS" && <TPS teamScan={teamScan} docList={docList} />}
            </Accordion>
            <Accordion ref={tlbRef}>
                <AccordionHead onClick={() => toggle("TLB")}>
                    <h2>{t("shared.team-scan-report.heading-tlb")}</h2>
                    <PlusMinus open={accordion === "TLB"} />
                </AccordionHead>
                {accordion === "TLB" && <TLB teamScan={teamScan} docList={docList} />}
            </Accordion>
            <Accordion ref={slRef}>
                <AccordionHead onClick={() => toggle("SL")}>
                    <h2>{t("shared.team-scan-report.heading-sl")}</h2>
                    <PlusMinus open={accordion === "SL"} />
                </AccordionHead>
                {accordion === "SL" && <SL teamScan={teamScan} showNetwork={true} docList={docList} />}
            </Accordion>
            <Accordion ref={nwRef}>
                <AccordionHead onClick={() => toggle("NW")}>
                    <h2>{t("shared.team-scan-report.heading-now-what")}</h2>
                    <PlusMinus open={accordion === "NW"} />
                </AccordionHead>
                {accordion === "NW" && <NowWhat docList={docList} />}
            </Accordion>
            <Center $top="2rem" $bottom="2rem">
                <Link className="button" to="share">
                    {t("shared.team-scan-report.link-share")}
                </Link>
                <br />
                <a className="button" href={`/pdf/download/team-scan-report/${id}`} target="_blank" rel="noreferrer">
                    {t("shared.team-scan-report.link-download")}
                </a>
                <br />
                {linkHome && <Link to="/team-scans">{t("shared.team-scan-report.link-home")}</Link>}
            </Center>
        </>
    );
}

export default Report;
