import { useMatch } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import LockedModal, { useLockedModal } from "../LockedModal";
import ShowError from "../components/ShowError";
import WithTasksSidebar from "../tasksSidebar";
import SubmittedTaskRoutes from "./SubmittedTaskRoutes";
import { type IArgs, type IData, query } from "./query";
import type { JSX } from "react";

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1440px;
    width: 100%;
`;

function SubmittedTask(): JSX.Element {
    const {
        params: { id },
    } = useMatch("/submitted-task/:id/*");
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id },
    });

    if (result.fetching) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        );
    }
    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }

    const task = result.data.submittedTask;
    const startTab = task.learningPathItem.type === "submitted-task-only" ? "custom" : "tasks";
    return (
        <Wrapper>
            <WithTasksSidebar
                openLockedModal={openLockedModal}
                path={task.learningPathItem.learningPath}
                activeItem={task.learningPathItem.id}
                startTab={startTab}
                mainLabel={t("player.submitted-task.tab-task")}
                big={true}
            >
                <SubmittedTaskRoutes task={task} />
            </WithTasksSidebar>
            <LockedModal {...lockedProps} />
        </Wrapper>
    );
}

export default SubmittedTask;
