import * as Sentry from "@sentry/browser";
import { useCallback } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import { useMutation } from "urql";
import Doc from "../../shared/components/Doc";
import RenderMark from "../../shared/components/RenderMark";
import t from "../../shared/translations";
import AcceptForm from "./AcceptForm";

interface IProps {
    customTerms: string | null;
}

const acceptMutation = `
    mutation {
        currentUserAcceptTerms {
            error
            redirectUrl
        }
    }
`;

interface IData {
    currentUserAcceptTerms: {
        error: string | null;
        redirectUrl: string;
    };
}

const Wrapper = styled.main`
    padding: 0 15px;
    display: grid;
    max-width: 600px;
    width: 100%;
    max-height: 100vh;
`;

function AcceptTerms({ customTerms }: IProps): JSX.Element {
    const [acceptResult, executeMutation] = useMutation<IData>(acceptMutation);

    const onSubmit = useCallback(async () => {
        if (acceptResult.fetching) {
            return;
        }
        const result = await executeMutation();
        if (result.error) {
            console.error(result.error);
            Sentry.captureMessage(result.error.toString());
        } else if (result.data.currentUserAcceptTerms?.error) {
            console.error(result.data.currentUserAcceptTerms.error);
            Sentry.captureMessage(result.data.currentUserAcceptTerms.error);
        } else {
            window.location.href = result.data.currentUserAcceptTerms.redirectUrl;
        }
    }, [acceptResult, executeMutation]);

    return (
        <Wrapper>
            <header>
                <h1 className="small-only-text-center">{t("player.onboarding.heading")}</h1>
            </header>
            <section className="terms-content">
                <RenderMark content={customTerms} />
                <Doc path="player/terms/terms" />
            </section>
            <section>
                <AcceptForm onSubmit={onSubmit} />
            </section>
        </Wrapper>
    );
}

export default AcceptTerms;
