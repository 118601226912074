import styled from "styled-components";
import { useQuery } from "urql";
import * as colors from "../colors";
import { loadJSData } from "../jsData";
import type { JSX } from "react";

interface IProps {
    notice: string;
}

const NoticeBox = styled.div`
    color: white;
    background-color: ${colors.primaryColor};
    max-width: 1440px;
    width: 100%;
    margin: 0 auto 10px auto;
    font-size: .8rem;
    padding: .875rem;
    position: relative;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.4);
    grid-area: notices;
`;

function GlobalNotice({ notice }: IProps): JSX.Element {
    return <NoticeBox>{notice}</NoticeBox>;
}

const query = `
    query {
        currentBannerNotice
    }
`;

interface IData {
    currentBannerNotice: string | null;
}

export function UrqlGlobalNotice(): JSX.Element {
    const [result] = useQuery<IData>({
        query,
    });
    if (result.fetching || result.error || !result.data.currentBannerNotice) {
        return null;
    }
    return <GlobalNotice notice={result.data.currentBannerNotice} />;
}

export function JsDataGlobalNotice(): JSX.Element {
    const data = loadJSData();
    if (!data.current_banner_notice) {
        return null;
    }
    return <GlobalNotice notice={data.current_banner_notice} />;
}
