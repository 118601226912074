import t from "../../shared/translations";
import type { AnswerType, TaskIcon, VariantType } from "../../shared/types";

interface IData {
    task: {
        icon: TaskIcon;
        shortTitle: string;
        taskVariants: {
            variantType: VariantType;
            variantTitle: string | null;
            description: string;
            hoursQuestions: string | null;
            eventStart: string | null;
            eventEnd: string | null;
            taskQuestions: {
                answerType: AnswerType;
                questionContent: string | null;
            }[];
        }[];
    };
    placeholders: {
        title: string;
        answer: string;
    };
    errors: {
        title: string;
        answer: string;
    };
}

export default function taskData(kind: string): IData {
    if (kind === "insight") {
        return {
            task: {
                icon: "bulb",
                shortTitle: t("player.customer-insight.short-title"),
                taskVariants: [
                    {
                        variantType: "basic",
                        variantTitle: null,
                        description: t("player.customer-insight.description"),
                        hoursQuestions: null,
                        eventStart: null,
                        eventEnd: null,
                        taskQuestions: [
                            {
                                answerType: "textbox",
                                questionContent: null,
                            },
                        ],
                    },
                ],
            },
            placeholders: {
                title: t("player.customer-insight.form-title-placeholder"),
                answer: t("player.customer-insight.form-answer-placeholder"),
            },
            errors: {
                title: t("player.customer-insight.form-title-error"),
                answer: t("player.customer-insight.form-answer-error"),
            },
        };
    }
    if (kind === "course") {
        return {
            task: {
                icon: "diploma",
                shortTitle: t("player.course.short-title"),
                taskVariants: [
                    {
                        variantType: "basic",
                        variantTitle: null,
                        description: t("player.course.description"),
                        hoursQuestions: null,
                        eventStart: null,
                        eventEnd: null,
                        taskQuestions: [
                            {
                                answerType: "textbox",
                                questionContent: null,
                            },
                        ],
                    },
                ],
            },
            placeholders: {
                title: t("player.course.form-title-placeholder"),
                answer: t("player.course.form-answer-placeholder"),
            },
            errors: {
                title: t("player.course.form-title-error"),
                answer: t("player.course.form-answer-error"),
            },
        };
    }
    return {
        task: {
            icon: "puzzle-piece",
            shortTitle: t("player.custom-task.short-title"),
            taskVariants: [
                {
                    variantType: "basic",
                    variantTitle: null,
                    description: t("player.custom-task.description"),
                    hoursQuestions: null,
                    eventStart: null,
                    eventEnd: null,
                    taskQuestions: [
                        {
                            answerType: "textbox",
                            questionContent: null,
                        },
                    ],
                },
            ],
        },
        placeholders: {
            title: t("player.custom-task.form-title-placeholder"),
            answer: t("player.custom-task.form-answer-placeholder"),
        },
        errors: {
            title: t("player.custom-task.form-title-error"),
            answer: t("player.custom-task.form-answer-error"),
        },
    };
}
