import styled from "styled-components";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";
import type { JSX } from "react";

interface IProps {
    pathId: string;
}

const ButtonAnchor = styled.a`
    display: inline-block;
    padding: 0.5rem 1rem 0.5325rem;
    margin-bottom: 0.5rem;
    background-color: ${colors.primaryColor};
    color: ${colors.white};

    &:hover, &:focus, &:active {
        color: ${colors.white};
    }
`;

function DownloadAnswers({ pathId }: IProps): JSX.Element {
    return (
        <ButtonAnchor target="_blank" href={`/pdf/download/my-answers/${pathId}/my-answers.pdf`}>
            {t("player.my-answers.button-download-answers")}
        </ButtonAnchor>
    );
}

export default DownloadAnswers;
