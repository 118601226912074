import { uniq } from "lodash-es";

interface IMemoData {
    data: string;
    result: string[];
}

const memoData: IMemoData = {
    data: "",
    result: [],
};

const EMAIL_REGEX = /[a-zA-Z0-9-_.+]+@[a-zA-Z0-9-_.+]+/gi;

export default function parseEmails(data: string): string[] {
    if (data !== memoData.data) {
        const emails = (data || "").match(EMAIL_REGEX);
        memoData.data = data;
        memoData.result = uniq(emails);
    }
    return memoData.result;
}
