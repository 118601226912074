import { useState, type JSX } from "react";
import Modal from "react-modal";
import PulseIcon from "../../shared/components/icons/taskIcons/PulseIcon";
import t from "../../shared/translations";
import type { IPulseData } from "./queries";
import { BigLink, Button, Center, QrCode, QrHeading } from "./stylings";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.45)";
Modal.defaultStyles.overlay.zIndex = 3;
Modal.defaultStyles.content.inset = "0px";

type IPulse = IPulseData["pulse"];

interface IProps {
    pulse: IPulse;
}

function QrButton({ pulse }: IProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const html = {
        __html: pulse.qrCode,
    };
    return (
        <div>
            <Button onClick={() => setOpen(true)}>{t("player.pulse-modal.button-show-qr-code")}</Button>
            <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
                <Center $gap={"0"}>
                    <QrHeading>
                        <PulseIcon /> {t("player.pulse-modal.heading")}
                    </QrHeading>
                    {/* biome-ignore lint/security/noDangerouslySetInnerHtml: QR code is raw html */}
                    <QrCode dangerouslySetInnerHTML={html} />
                    <BigLink>{pulse.link}</BigLink>
                    <Button onClick={() => setOpen(false)}>{t("player.pulse-modal.button-close-modal")}</Button>
                </Center>
            </Modal>
        </div>
    );
}

export default QrButton;
