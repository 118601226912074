import { type JSX, memo } from "react";
import styled from "styled-components";
import t from "../../shared/translations";

const BannerContainer = styled.div`
    perspective: 800px;
    margin-top: 1.5rem;
`;

const CheckpointBanner = styled.svg`
    width: 250px;
    transform: rotateY(-45deg) rotateX(-1deg) rotateZ(0deg);

    line {
        stroke: #020202;
        stroke-width: 3px;
    }
    rect {
        fill: #D4D6D5;
        stroke: transparent;
    }
    text {
        stroke: transparent;
        font-family: Arial;
        font-weight: bold;
        text-anchor: middle;
    }
`;

function Banner(): JSX.Element {
    return (
        <BannerContainer>
            <CheckpointBanner viewBox="0 0 150 100" xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="1" x2="1" y2="99" />
                <rect x="2" y="3" width="147" height="20" />
                <line x1="149" y1="1" x2="146" y2="99" />
                <text x="75" y="18">
                    {t("player.performance.label-checkpoint")}
                </text>
            </CheckpointBanner>
        </BannerContainer>
    );
}

export default memo(Banner);
