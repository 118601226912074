// Query messages list

export interface IArgs {
    filter: "messages" | "notifications" | "archived";
}

export const query = `
    query($filter:String) {
        messages(filter:$filter) {
            id
            datetime
            senderName
            read
            archived
            subject
            content
            canReply
        }
    }
`;

export interface IMessage {
    id: string;
    datetime: string;
    senderName: string;
    read: boolean;
    archived: boolean;
    subject: string;
    content: string;
    canReply: boolean;
}

export interface IData {
    messages: IMessage[];
}

// Query sent messages list

export const sentQuery = `
    query {
        learningCoachMessageList {
            id
            datetime
            subject
            content
        }
    }
`;

export interface ISentMessage {
    id: string;
    datetime: string;
    subject: string;
    content: string;
}

export interface ISentData {
    learningCoachMessageList: ISentMessage[];
}

// Toggle message read status mutation

export interface IToggleReadArgs {
    id: string;
    read: boolean;
}

export const toggleReadMutation = `
    mutation($id:String!,$read:Boolean!) {
        messageToggleRead(id:$id,read:$read) {
            error
            message {
                id
            }
        }
    }
`;

export interface IToggleReadData {
    messageToggleRead: {
        error: string | null;
        message: {
            id: string;
        } | null;
    };
}

// Toggle message archive status mutation

export interface IToggleArchiveArgs {
    id: string;
    archived: boolean;
}

export const toggleArchiveMutataion = `
    mutation($id:String!,$archived:Boolean!) {
        messageToggleArchived(id:$id,archived:$archived) {
            error
            message {
                id
            }
        }
    }
`;

export interface IToggleArchiveData {
    messageToggleArchived: {
        error: string | null;
        message: {
            id: string;
        } | null;
    };
}

// Message thread query

export interface IThreadArgs {
    id: string;
}

export const replyQuery = `
    query($id:String!) {
        message(id:$id) {
            id
            replyThread {
                id
                senderName
                subject
                content
            }
        }
    }
`;

export interface IThreadData {
    message: {
        id: string;
        replyThread: {
            id: string;
            senderName: string;
            subject: string;
            content: string;
        }[];
    };
}
