import styled from "styled-components";
import t from "../../shared/translations";
import type { JSX } from "react";

const P = styled.p`
    text-align: center;
`;

function Undecided(): JSX.Element {
    return <P>{t("player.task.info-status-undecided")}</P>;
}

export default Undecided;
