import type { JSX } from "react";
import styled from "styled-components";
import t from "../../shared/translations";
import type { ICurrentPath, IFinishedPath, IOtherLearningMiles, ISpentLearningMiles } from "./MyHistory";
import { SectionHeading } from "./stylings";

interface IProps {
    currentPaths: ICurrentPath[];
    finishedPaths: IFinishedPath[];
    otherLearningMiles: IOtherLearningMiles[];
    spentLearningMiles: ISpentLearningMiles[];
}

const MilesGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    width: 270px;
    gap: 0 6px;
`;

function calcTotalMiles(
    currentPaths: ICurrentPath[],
    finishedPaths: IFinishedPath[],
    otherLearningMiles: IOtherLearningMiles[],
): number {
    const currentMiles = currentPaths.reduce(
        (acc, path) => acc + path.acceptedItems.reduce((acc, item) => acc + item.earnedMiles, 0),
        0,
    );
    const finishedMiles = finishedPaths.reduce(
        (acc, path) => acc + path.acceptedItems.reduce((acc, item) => acc + item.earnedMiles, 0),
        0,
    );
    const otherMiles = otherLearningMiles.reduce((acc, sm) => acc + sm.miles, 0);
    return currentMiles + finishedMiles + otherMiles;
}

function calcSpentMiles(spentLearningMiles: ISpentLearningMiles[]): number {
    return spentLearningMiles.reduce((acc, sm) => acc + sm.miles, 0);
}

function MilesInfo({ currentPaths, finishedPaths, otherLearningMiles, spentLearningMiles }: IProps): JSX.Element {
    const totalMiles = calcTotalMiles(currentPaths, finishedPaths, otherLearningMiles);
    const spentMiles = calcSpentMiles(spentLearningMiles);
    return (
        <section>
            <SectionHeading>{t("player.my-history.heading-learning-miles")}</SectionHeading>
            <MilesGrid>
                <div>{t("player.my-history.label-total-learning-miles")}</div>
                <div>{totalMiles}</div>
                <div>{t("player.my-history.label-miles-avaialble-to-spend")}</div>
                <div>{totalMiles - spentMiles}</div>
            </MilesGrid>
        </section>
    );
}

export default MilesInfo;
