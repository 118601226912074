import type { ComponentPropsWithoutRef, JSX } from "react";
import * as colors from "../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
    fill?: string;
    stroke?: string;
}

function StarIcon({ active, fill = colors.primaryColor, stroke = colors.primaryColor, ...props }: IProps): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m50.5 12 8.644 26.603h27.972l-22.63 16.441 8.644 26.603-22.63-16.441-22.63 16.441 8.644-26.603-22.63-16.441h27.972z"
                fill={active ? fill : "none"}
                stroke={stroke}
                strokeWidth="4.6"
                transform="matrix(1.19481 0 0 1.19481 -10.3377 -10.3377)"
            />
        </svg>
    );
}

export default StarIcon;
