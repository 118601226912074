import styled from "styled-components";
import { useMutation } from "urql";
import t from "../../../shared/translations";
import type { ITask, ITaskVariant } from "../query";
import type { JSX } from "react";

interface IProps {
    task: ITask;
    variant: ITaskVariant;
}

const createMutation = `
    mutation($id:String!,$variantId:String!) {
        teamScanCreateForTask(id:$id,variantId:$variantId) {
            error
            teamScan {
                __typename
                submittedTaskVariant {
                    __typename
                    submittedTask {
                        __typename
                        task {
                            __typename
                        }
                    }
                }
            }
        }
    }
`;

interface IArgs {
    id: string;
    variantId: string;
}

const Wrapper = styled.div`
    text-align: center;
    padding: 0.6rem;
    @media (min-width: 600px) {
        padding: 1rem;
    }
    > * {
        margin-bottom: 0;
    }
`;

function Create({ task, variant }: IProps): JSX.Element {
    const [createResult, executeMutation] = useMutation<any, IArgs>(createMutation);

    const create = async () => {
        if (createResult.fetching) {
            return;
        }
        const result = await executeMutation({ id: task.id, variantId: variant.id });
        if (result.error) {
            console.error(result.error);
        } else if (result.data.teamScanCreateForTask?.error) {
            console.error(result.data.teamScanCreateForTask.error);
        }
    };

    return (
        <Wrapper>
            <button type="button" onClick={create} disabled={createResult.fetching}>
                {t("player.task.button-create-team-scan")}
            </button>
        </Wrapper>
    );
}

export default Create;
