import { random } from "lodash-es";
import { type ComponentPropsWithoutRef, type JSX, memo } from "react";
import * as colors from "../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    hightlight: number;
}

function PersonIcon({ hightlight, ...props }: IProps): JSX.Element {
    const id = random(100000);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
            <defs>
                <mask id={`person-icon-mask-${id}`}>
                    <rect x="0" y="0" width={100 * hightlight} height="100" fill="#fff" />
                </mask>
            </defs>
            <path
                d="M65.2,55c4.3-4.8,7.1-11.6,7.1-19.1c0-14.5-10-26.2-22.3-26.2c-12.3,0-22.4,11.8-22.4,26.2 c0,7.5,2.7,14.3,7.1,19.1c-14.8,4.7-24.5,15.7-24.5,28c0,0.6,0,1.2,0.1,1.9l0.1,1.4h79.2l0.1-1.4c0.1-0.7,0.1-1.3,0.1-1.9 C89.7,70.8,79.9,59.7,65.2,55z"
                fill={colors.secondaryColor}
            />
            <g mask={`url(#person-icon-mask-${id})`}>
                <path
                    d="M65.2,55c4.3-4.8,7.1-11.6,7.1-19.1c0-14.5-10-26.2-22.3-26.2c-12.3,0-22.4,11.8-22.4,26.2 c0,7.5,2.7,14.3,7.1,19.1c-14.8,4.7-24.5,15.7-24.5,28c0,0.6,0,1.2,0.1,1.9l0.1,1.4h79.2l0.1-1.4c0.1-0.7,0.1-1.3,0.1-1.9 C89.7,70.8,79.9,59.7,65.2,55z"
                    fill="#509dc5"
                />
            </g>
        </svg>
    );
}

export default memo(PersonIcon);
