import type { JSX } from "react";
import styled from "styled-components";
import t from "../translations";
import type { AnswerType } from "../types";

export interface ITaskQuestion {
    answerType: AnswerType;
    questionContent: string;
    checkboxOptions: (
        | {
              content: string;
          }
        | string
    )[];
    taskLikertStatements: {
        statement: string;
    }[];
    poll: {
        name: string;
        url: string;
    } | null;
    quiz: {
        name: string;
        url: string;
    } | null;
    impactTracker: {
        name: string;
    } | null;
    surveyGizmo: {
        title: string;
        url: string;
    } | null;
}

interface IProps {
    taskQuestions: ITaskQuestion[];
}

const QuestionSection = styled.section`
    padding: 0 1rem;
`;

const CheckboxItem = styled.li`
    list-style-type: "☐";
    padding-left: 2px;
`;

const LikertItem = styled.li`
    list-style-type: "○○○";
    padding-left: 2px;
`;

function PreviewAnswers({ taskQuestions }: IProps): JSX.Element {
    return (
        <QuestionSection>
            {taskQuestions.map((q, idx) => (
                <div key={idx}>
                    <h5>{q.questionContent}</h5>
                    <div>
                        {t("shared.task-description.label-answer-type")} {t(`shared.answer-types.${q.answerType}`)}
                    </div>
                    {q.answerType === "checkbox" && q.checkboxOptions && (
                        <ul>
                            {q.checkboxOptions.map((opt, idx2) => (
                                <CheckboxItem key={idx2}>
                                    {typeof opt === "string" && opt}
                                    {typeof opt === "object" && opt.content}
                                </CheckboxItem>
                            ))}
                        </ul>
                    )}
                    {q.answerType === "likert" && q.taskLikertStatements && (
                        <ul>
                            {q.taskLikertStatements.map((opt, idx3) => (
                                <LikertItem key={idx3}>{opt.statement}</LikertItem>
                            ))}
                        </ul>
                    )}
                    {q.answerType === "poll" && q.poll && (
                        <div>
                            {t("shared.task-description.label-poll")}
                            {": "}
                            <a href={q.poll.url} target="_blank" rel="noreferrer">
                                {q.poll.name}
                            </a>
                        </div>
                    )}
                    {q.answerType === "quiz" && q.quiz && (
                        <div>
                            {t("shared.task-description.label-quiz")}
                            {": "}
                            <a href={q.quiz.url} target="_blank" rel="noreferrer">
                                {q.quiz.name}
                            </a>
                        </div>
                    )}
                    {q.answerType === "impact-tracker" && q.impactTracker && (
                        <div>
                            {t("shared.task-description.label-impact-tracker")}
                            {": "}
                            {q.impactTracker.name}
                        </div>
                    )}
                    {q.answerType === "survey-gizmo" && q.surveyGizmo && (
                        <div>
                            {t("shared.task-description.label-survey-gizmo")}
                            {": "}
                            <a href={q.surveyGizmo.url} target="_blank" rel="noreferrer">
                                {q.surveyGizmo.title}
                            </a>
                        </div>
                    )}
                    {idx !== taskQuestions.length - 1 && <hr />}
                </div>
            ))}
        </QuestionSection>
    );
}

export default PreviewAnswers;
