import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function MountainIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m68.309 50.168-4.952 2.476-3.236-3.641c-.29-.326-.708-.51-1.145-.503s-.849.204-1.128.54l-3.824 4.588-2.853-3.565c-.251-.315-.619-.515-1.02-.555-.401-.041-.802.082-1.111.34l-4.787 3.989s-5.053-6.737-5.053-6.737c-.261-.348-.66-.565-1.094-.596-.433-.031-.859.128-1.167.435l-6.352 6.353-2.183-5.819c-.06-.16-.145-.305-.248-.43l8.983-17.968 11.586 18.715c.253.41.689.672 1.17.706s.949-.165 1.257-.536l8.714-10.456z"
                fill="#fff"
            />
            <path
                d="m26.375 50.605 2.221 5.922c.179.477.588.831 1.086.939.499.108 1.018-.045 1.379-.405l6.777-6.777 4.962 6.616c.247.329.618.542 1.026.59s.818-.074 1.134-.338l4.825-4.02s3.044 3.805 3.044 3.805c.281.352.706.558 1.156.563.45.004.879-.194 1.167-.54l3.885-4.661s2.842 3.198 2.842 3.198c.45.506 1.185.648 1.792.345l6.315-3.158 17.211 25.816h-74.77z"
                fill={active ? "#0076c2" : "#fff"}
            />
            <path d="m8.658 79.329 27-54c.244-.486.731-.803 1.274-.827.543-.025 1.057.246 1.343.708l11.905 19.231 8.668-10.401c.303-.364.759-.563 1.232-.538s.906.272 1.168.666l30 45c.307.46.336 1.052.075 1.54-.262.488-.77.792-1.323.792h-80c-.52 0-1.003-.269-1.276-.711-.273-.443-.298-.995-.066-1.46zm17.717-28.724-13.948 27.895h74.77l-17.211-25.816-6.315 3.158c-.607.303-1.342.161-1.792-.345 0 0-2.842-3.198-2.842-3.198l-3.885 4.661c-.288.346-.717.544-1.167.54-.45-.005-.875-.211-1.156-.563 0 0-3.044-3.805-3.044-3.805l-4.825 4.02c-.316.264-.726.386-1.134.338s-.779-.261-1.026-.59l-4.962-6.616-6.777 6.777c-.361.36-.88.513-1.379.405-.498-.108-.907-.462-1.086-.939zm41.934-.437-8.443-12.664-8.714 10.456c-.308.371-.776.57-1.257.536s-.917-.296-1.17-.706l-11.586-18.715-8.983 17.968c.103.125.188.27.248.43l2.183 5.819 6.352-6.353c.308-.307.734-.466 1.167-.435.434.031.833.248 1.094.596 0 0 5.053 6.737 5.053 6.737l4.787-3.989c.309-.258.71-.381 1.111-.34.401.04.769.24 1.02.555l2.853 3.565 3.824-4.588c.279-.336.691-.533 1.128-.54s.855.177 1.145.503l3.236 3.641z" />
        </svg>
    );
}

export default MountainIcon;
