import { type JSX, type MouseEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import MilesCircle from "../../shared/components/MilesCircle";
import GroupTaskIcon from "../../shared/components/icons/GroupTaskIcon";
import NoteIcon from "../../shared/components/icons/NoteIcon";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import { formatYMD, parseDate } from "../../shared/dateFns";
import useWindowSize from "../../shared/hooks/useWindowSize";
import t from "../../shared/translations";
import type { TaskIcon as TaskIconType } from "../../shared/types";
import LockedModal, { useLockedModal } from "../LockedModal";
import { LockIcon } from "../landing/stylings";
import { type ITask, taskFinished, taskNeedsGroup, taskSharingAnswers } from "./query";

interface IProps {
    icon: TaskIconType;
    url: string;
    tasks: ITask[];
    miles: number;
    earnedMiles: number;
    current: string;
}

const dashColor = colors.deadline;
const circleColor = colors.deadline;
const milesCircleColor = colors.altPrimaryColor;
const deadlineColor = colors.deadline;
const finishedColor = colors.altPrimaryColor;

const Nav = styled.nav`
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 80px;
    font-size: 0.85rem;
    justify-content: space-between;
    gap: 20px;
    overflow-x: auto;
    margin-bottom: 0.5rem;
    @media (min-width: 640px) {
        margin-bottom: 1rem;
    }
`;

const StyledTaskIcon = styled(TaskIcon)`
    margin-top: 10px;
    width: 60px;
    height: 60px;
    background-color: white;
`;

const A = styled.a`
    position: relative;
    display: grid;
    justify-items: center;
    text-align: center;
    color: ${colors.text};
    &:hover, &:focus, &:active {
        color: ${colors.text};
    }
`;

const MilesHolder = styled.div`
    display: grid;
    justify-items: center;
    text-align: center;
    svg {
        margin-top: 0px;
        width: 80px;
        height: 80px;
        background-color: white;
    }
`;

const CircleSvg = styled.svg`
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
`;

const DashLine = styled.div`
    z-index: -1;
    top: 40px;
    position: absolute;
    height: 0;
    left: 80px;
    right: 120px;
    border-top: 3px solid ${dashColor};
    border-top-style: dashed;
`;

interface IStatusBox {
    $finished?: boolean;
}

const MilesBox = styled.div`
    margin-top: 5px;
`;

const StatusBox = styled.div<IStatusBox>`
    padding: 4px;
    background-color: ${({ $finished }) => ($finished ? finishedColor : deadlineColor)};
    color: white;
`;

const GroupIcon = styled(GroupTaskIcon)`
    width: 22px;
    height: 22px;
    position: absolute;
    top: 58px;
    left: 60px;
`;

const AllAnswersIcon = styled(NoteIcon)`
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0px;
    left: 3px;
`;

function Circle(): JSX.Element {
    return (
        <CircleSvg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="46" stroke={circleColor} strokeWidth="3" fill="none" />
        </CircleSvg>
    );
}

function taskUrl(task: ITask): string {
    if (task?.submittedTask?.status === "accepted") {
        return task.submittedTask?.url;
    }
    return task.url;
}

function PipNav({ current, icon, url, tasks, miles, earnedMiles }: IProps): JSX.Element {
    const navRef = useRef<HTMLElement | null>(null);
    const currentRef = useRef<HTMLAnchorElement | null>(null);
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const size = useWindowSize();
    const [width, setWidth] = useState(0);
    useEffect(() => {
        if (currentRef.current) {
            const offset = currentRef.current.offsetLeft;
            const parent = currentRef.current.parentNode as HTMLDivElement;
            parent.scrollLeft = offset;
        }
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Set width when size changes
    useLayoutEffect(() => {
        if (navRef.current) {
            setWidth(navRef.current.scrollWidth - 160);
        }
    }, [size]);

    const fillPercent = miles > 0 ? earnedMiles / miles : 0;
    const onClick = (event: MouseEvent<HTMLAnchorElement>, task: ITask) => {
        if (task.locked) {
            event.preventDefault();
            openLockedModal(task.id, "", "");
        }
    };

    return (
        <Nav ref={navRef}>
            <DashLine style={{ width }} />
            <A href={url} ref={current === "" ? currentRef : undefined}>
                {current === "" && <Circle />}
                <StyledTaskIcon icon={icon} />
            </A>
            {tasks.map((task) => (
                <A
                    href={taskUrl(task)}
                    key={task.id}
                    ref={current === task.id ? currentRef : undefined}
                    onClick={(e) => onClick(e, task)}
                >
                    {(current === task.id || current === task.submittedTask?.id) && <Circle />}
                    <StyledTaskIcon icon={task.icon} active={taskFinished(task)} />
                    {task.locked && <LockIcon $size={80} $absolute={true} />}
                    <MilesBox>{task.miles}</MilesBox>
                    {taskFinished(task) ? (
                        <StatusBox $finished>{t("player.pip.label-finished")}</StatusBox>
                    ) : task.deadline ? (
                        <StatusBox>{formatYMD(parseDate(task.deadline))}</StatusBox>
                    ) : null}
                    {taskNeedsGroup(task) && <GroupIcon />}
                    {taskSharingAnswers(task) && <AllAnswersIcon />}
                </A>
            ))}
            <MilesHolder>
                <MilesCircle fillPercent={fillPercent} color={milesCircleColor} />
                <MilesBox>
                    {earnedMiles}/{miles}
                </MilesBox>
            </MilesHolder>
            <LockedModal {...lockedProps} />
        </Nav>
    );
}

export default PipNav;
