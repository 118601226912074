import type { ValidationErrors } from "final-form";
import { Form } from "react-final-form";
import t from "../../shared/translations";
import FinalCheckbox from "../components/FinalCheckbox";
import type { JSX } from "react";

interface IProps {
    onSubmit: (values: IFormValues) => Promise<void>;
}

export interface IFormValues {
    accept: boolean;
}

const defaultValues: IFormValues = {
    accept: false,
};

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.accept) {
        errors.accept = t("player.onboarding.terms-form-error");
    }
    return errors;
}

function AcceptForm({ onSubmit }: IProps): JSX.Element {
    return (
        <Form onSubmit={onSubmit} validate={validate} initialValues={defaultValues}>
            {({ handleSubmit, submitting, pristine }) => (
                <form onSubmit={handleSubmit} className="accept-form">
                    <FinalCheckbox name="accept" label={t("player.onboarding.terms-form-accept-label")} />
                    <button type="submit" disabled={submitting || pristine}>
                        {t("player.onboarding.terms-form-submit")}
                    </button>
                </form>
            )}
        </Form>
    );
}

export default AcceptForm;
