import { loadJSData } from "./shared/jsData";

const jsData = loadJSData();

export const config = {
    dsn: jsData.sentry_dsn,
    enabled: jsData.features.sentry,
    release: `lm_game@${jsData.version}`,
};

export const userConfig = jsData.sentry_data.id != null ? jsData.sentry_data : null;
