import type { ChangeEvent, Dispatch, JSX, SetStateAction } from "react";
import { type UseFieldArrayConfig, useFieldArray } from "react-final-form-arrays";
import LikertPreview from "../../../shared/components/LikertPreview";
import t from "../../../shared/translations";
import Checkbox from "../../components/FinalCheckbox";
import { FakeSelect, FakeTextarea, Hider, Input, QuestionBox, QuestionHeading, QuestionRow } from "../stylings";
import LikertLabelInput from "./LikertLabelInput";
import QuestionNum from "./QuestionNum";
import type { ISuggestedValue } from "./Setup";

interface IOwnProps {
    name: string;
    allowTranslate: boolean;
    open: number | null;
    setOpen: Dispatch<SetStateAction<number | null>>;
}

type IProps = IOwnProps & UseFieldArrayConfig<ISuggestedValue>;

function SuggestedArray({ name, allowTranslate, open, setOpen, ...props }: IProps): JSX.Element {
    const {
        fields,
        meta: { valid, touched },
    } = useFieldArray(name, props);
    const openOnChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        if (event.target.checked) {
            setOpen(index);
        }
    };
    return (
        <>
            {fields.map((question, index) => (
                <QuestionBox
                    key={index}
                    $bold={open === index}
                    $error={!valid && touched}
                    onClick={() => setOpen(index)}
                >
                    <QuestionHeading>
                        <QuestionNum>{index + 1}</QuestionNum>
                        <span>
                            {open === index
                                ? fields.value[index].content
                                : fields.value[index].translation || fields.value[index].content}
                        </span>
                    </QuestionHeading>
                    {allowTranslate && (
                        <Checkbox
                            name={`${question}.showTrans`}
                            label={t("player.collect-feedback-setup.form-label-show-translation")}
                            onChange={(e) => openOnChange(e, index)}
                        />
                    )}
                    <Hider $hide={index !== open || !fields.value[index].showTrans}>
                        <Input
                            name={`${question}.translation`}
                            placeholder={t("player.collect-feedback-setup.form-label-question-translation")}
                        />
                        <QuestionRow>
                            <FakeSelect>
                                {fields.value[index].type === "likert"
                                    ? t("player.collect-feedback-setup.form-option-likert")
                                    : t("player.collect-feedback-setup.form-option-text")}
                            </FakeSelect>
                            {fields.value[index].type === "likert" && (
                                <div>
                                    <LikertLabelInput
                                        name={`${question}.leftTranslation`}
                                        label={t("player.collect-feedback-setup.form-label-left-label")}
                                        secondaryLabel={fields.value[index].leftLabel}
                                        placeholder={t("player.collect-feedback-setup.form-label-label-translation")}
                                    />
                                    <LikertLabelInput
                                        name={`${question}.rightTranslation`}
                                        label={t("player.collect-feedback-setup.form-label-right-label")}
                                        secondaryLabel={fields.value[index].rightLabel}
                                        placeholder={t("player.collect-feedback-setup.form-label-label-translation")}
                                    />
                                </div>
                            )}
                        </QuestionRow>
                    </Hider>
                    <Hider $hide={index === open && fields.value[index].showTrans}>
                        {fields.value[index].type === "likert" ? (
                            <LikertPreview
                                leftLabel={fields.value[index].leftTranslation || fields.value[index].leftLabel}
                                rightLabel={fields.value[index].rightTranslation || fields.value[index].rightLabel}
                                selected={null}
                            />
                        ) : (
                            <FakeTextarea />
                        )}
                    </Hider>
                </QuestionBox>
            ))}
        </>
    );
}

export default SuggestedArray;
