import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import ProfileNav from "../ProfileNav";
import Landing from "./landing/Landing";
import Progress from "./progress/Progress";
import Resend from "./progress/Resend";
import SendReminderSuccess from "./progress/SendReminderSuccess";
import Report from "./report/Report";
import ShareReport from "./report/ShareReport";
import ShareReportSuccess from "./report/ShareReportSuccess";
import Setup from "./setup/Setup";
import Terms from "./Terms";
import Theory from "./theory/Theory";
import type { JSX } from "react";

const Main = styled.div`
    flex-grow: 1;
    padding: 0 10px;
    width: 100%;
    max-width: 1440px;
`;

function TeamScans(): JSX.Element {
    return (
        <Main>
            <Routes>
                <Route path="/team-scans/theory" element={<Theory />} />
                <Route path="/team-scans/profile/*" element={<ProfileNav />} />
                <Route path="/team-scans/setup/:id" element={<Setup />} />
                <Route path="/team-scans/progress/:id/resend" element={<Resend />} />
                <Route path="/team-scans/progress/:id/resend-success" element={<SendReminderSuccess />} />
                <Route path="/team-scans/progress/:id" element={<Progress />} />
                <Route path="/team-scans/report/:id/share" element={<ShareReport />} />
                <Route path="/team-scans/report/:id/share-success" element={<ShareReportSuccess />} />
                <Route path="/team-scans/report/:id" element={<Report linkHome />} />
                <Route path="/team-scans/terms" element={<Terms />} />
                <Route path="/team-scans" element={<Landing />} />
            </Routes>
        </Main>
    );
}

export default TeamScans;
