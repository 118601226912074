import { type JSX, memo } from "react";
import styled from "styled-components";

interface IProps {
    src?: string;
}

const Img = styled.img`
    height: 45px;
    width: 45px;
    object-fit: scale-down;
    margin-right: 0.3rem;
    @media (min-width: 600px) {
        height: 60px;
        width: 60px;
        margin-right: 0.8rem;
    }
`;

function ProfileImg({ src }: IProps): JSX.Element {
    src = src || "/static/images/profile-silouette.png";
    return <Img src={src} alt="profile image" />;
}

export default memo(ProfileImg);
