import RenderMark from "../../../shared/components/RenderMark";
import { Center, PageBreak } from "../stylings";
import type { IDoc } from "./Report";
import type { JSX } from "react";

interface IProps {
    printing?: boolean;
    docList: IDoc[];
}

function Intro({ printing, docList }: IProps): JSX.Element {
    return (
        <div>
            <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-intro").content} />
            <Center>
                <img
                    src="/static/images/team-scan-report/teamscan-big-picture-en.png"
                    alt="Relationship between Shared leardership, Psycological safety, and Team learning behaviour"
                />
            </Center>
            {printing && <PageBreak />}
        </div>
    );
}

export default Intro;
