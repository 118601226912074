import { type JSX, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useMutation } from "urql";
import PollResults from "../../../shared/components/PollResults";
import QuizResults from "../../../shared/components/QuizResults";
import t from "../../../shared/translations";
import Modal from "../../components/Modal";
import { type Dispatch, openModal } from "../../state";
import NavTabs from "../NavTabs";
import {
    type IMarkReadArgs,
    type IMarkReadData,
    type IPollAnswerSet,
    type IQuizAnswerSet,
    type ISubmittedTask,
    markReadMutation,
} from "../query";
import Download from "./Download";
import GroupedAnswers from "./GroupedAnswers";
import UngroupedAnswers from "./UngroupedAnswers";

interface IProps {
    task: ISubmittedTask;
}

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-items: start;
    @media (min-width: 768px) {
        grid-template-columns: auto auto;
        justify-content: space-between;
        align-items: center;
    }
`;

function AllAnswers({ task }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const [grouped, setGrouped] = useState(false);
    const [modalQuizResult, setModalQuizResullt] = useState<IQuizAnswerSet>(null);
    const [modalPollResult, setModalPollResullt] = useState<IPollAnswerSet>(null);
    const [_, executeMutation] = useMutation<IMarkReadData, IMarkReadArgs>(markReadMutation);

    useEffect(() => {
        const ids = task.sharedAnswers.filter((ans) => !ans.read).map((ans) => ans.taskId);
        if (ids.length === 0) {
            return;
        }
        void executeMutation({ ids });
    }, [executeMutation, task]);

    const openQuizResults = (quizResult: IQuizAnswerSet) => {
        setModalQuizResullt(quizResult);
        dispatch(openModal("SubmittedTask/QuizResults"));
    };

    const openPollResults = (pollResult: IPollAnswerSet) => {
        setModalPollResullt(pollResult);
        dispatch(openModal("SubmittedTask/PollResults"));
    };

    let main = null;
    if (grouped) {
        main = (
            <GroupedAnswers
                sharedAnswers={task.sharedAnswers}
                openQuizResults={openQuizResults}
                openPollResults={openPollResults}
            />
        );
    } else {
        main = (
            <UngroupedAnswers
                sharedAnswers={task.sharedAnswers}
                openQuizResults={openQuizResults}
                openPollResults={openPollResults}
            />
        );
    }

    const unread = task.sharedAnswers.filter((answer) => !answer.read).length;
    return (
        <>
            <h1>{task.shortTitle}</h1>
            <NavTabs task={task} unread={unread} />
            <Row>
                <label>
                    <input type="checkbox" checked={grouped} onChange={(e) => setGrouped(e.currentTarget.checked)} />{" "}
                    {t("player.submitted-task.label-all-answers-grouped")}
                </label>
                <Download grouped={grouped} taskId={task.id} />
            </Row>
            {main}
            <Modal modal="SubmittedTask/QuizResults">
                <h4>{t("player.submitted-task.heading-quiz-results")}</h4>
                <QuizResults results={modalQuizResult?.answers} />
            </Modal>
            <Modal modal="SubmittedTask/PollResults">
                <h4>{t("player.submitted-task.heading-poll-results")}</h4>
                <PollResults answers={modalPollResult?.answers} />
            </Modal>
        </>
    );
}

export default AllAnswers;
