import type { ValidationErrors } from "final-form";
import { useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import Loader from "../../../shared/components/Loader";
import t from "../../../shared/translations";
import ShowError from "../../components/ShowError";
import { Center, PaddedLeft } from "../stylings";
import { Input, Textarea } from "../stylings/form";

const query = `
    query($id:String!) {
        systemMessage(message:"team_scan_share_report") {
            id
            title
            body
        }
        teamScan(id:$id) {
            teamScanSurveys {
                id
                firstName
                lastName
                datetime
            }
        }
    }
`;

interface ISystemMessage {
    id: string;
    title: string;
    body: string;
}

interface ITeamScan {
    teamScanSurveys: {
        id: string;
        firstName: string;
        lastName: string;
        datetime: string | null;
    }[];
}

interface IData {
    systemMessage: ISystemMessage;
    teamScan: ITeamScan;
}

const shareMutation = `
    mutation($id:String!,$messageSubject:String!,$messageBody:String!) {
        teamScanSurveyShare(id:$id,messageSubject:$messageSubject,messageBody:$messageBody) {
            error
        }
    }
`;

interface ISharedArgs {
    id: string;
    messageSubject: string;
    messageBody: string;
}

type IParams = "id";

interface IFormValues {
    title: string;
    body: string;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.title) {
        errors.title = t("player.team-scan-share-report.form-error-title-required");
    }
    if (!values.body) {
        errors.body = t("player.team-scan-share-report.form-error-body-required");
    }
    return errors;
}

function ShareReport(): JSX.Element {
    const navigate = useNavigate();
    const { id } = useParams<IParams>();
    const [result] = useQuery<IData>({
        query,
        variables: { id },
    });
    const [shareResult, executeMutation] = useMutation<any, ISharedArgs>(shareMutation);

    const onSubmit = useCallback(
        async (values: IFormValues) => {
            const args: ISharedArgs = {
                id,
                messageBody: values.body,
                messageSubject: values.title,
            };
            const result = await executeMutation(args);
            if (result.error || result.data?.teamScanSurveyShare?.error) {
                console.error(result.error || result.data.teamScanSurveyShare?.error);
            } else {
                navigate("../share-success", { relative: "path" });
            }
        },
        [executeMutation, id, navigate],
    );

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const { systemMessage, teamScan } = result.data;
    const recipients = teamScan.teamScanSurveys.filter((tqs) => !!tqs.datetime);
    return (
        <>
            <h1>{t("player.team-scan-share-report.heading")}</h1>
            <p>{t("player.team-scan-share-report.info-share")}</p>
            <p>{t("player.team-scan-share-report.info-recipients")}</p>
            <PaddedLeft>
                <ul>
                    {recipients.map((rep) => (
                        <li key={rep.id}>
                            {rep.firstName} {rep.lastName}
                        </li>
                    ))}
                </ul>
            </PaddedLeft>
            <p>{t("player.team-scan-share-report.info-edit")}</p>
            <Form onSubmit={onSubmit} validate={validate} initialValues={systemMessage}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Input name="title" />
                        <Textarea name="body" />
                        <Center>
                            <button type="submit" disabled={submitting}>
                                {t("player.team-scan-share-report.form-button-submit")}
                            </button>
                            <br />
                            {shareResult.fetching && <span>{t("player.team-scan-share-report.info-sending")}</span>}
                        </Center>
                    </form>
                )}
            </Form>
            <Center $bottom="2rem">
                <Link to=".." relative="path">
                    {t("player.team-scan-share-report.link-back")}
                </Link>
            </Center>
        </>
    );
}

export default ShareReport;
