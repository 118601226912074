import { useCallback, useEffect, useState, type JSX } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import { intervalToDuration, parseDate } from "../../shared/dateFns";

interface IProps {
    taskTime: string;
}

const Wrapper = styled.div`
    background-color: ${colors.primaryColor};
    padding: 0.2rem 0;
    font-size: 1.4rem;
    color: white;
    text-align: center;
    width: 12rem;
    margin: 4rem auto;
`;

function PitstopTimer({ taskTime }: IProps): JSX.Element {
    const [timeText, setTimeText] = useState("");

    const updateTimeText = useCallback(() => {
        const duration = intervalToDuration({
            start: parseDate(taskTime),
            end: new Date(),
        });
        const days = duration.days ?? 0;
        const hours = duration.hours ?? 0;
        const minutes = duration.minutes ?? 0;
        const seconds = duration.seconds ?? 0;

        const timeText = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        setTimeText(timeText);
    }, [taskTime]);

    useEffect(() => {
        updateTimeText();
        const timer = setInterval(updateTimeText, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [updateTimeText]);

    return <Wrapper>{timeText}</Wrapper>;
}

export default PitstopTimer;
