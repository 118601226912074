import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function ArrowsInCircleSpeechBubbleIcon({ active, ...props }: IProps): JSX.Element {
    if (active) {
        return (
            <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m50 10.5c-21.2 0-38.5 17.3-38.5 38.5 0 8.6 2.9 17 8.2 23.7l-2.2 15.9c-.1.5.1 1.1.6 1.4.3.2.6.3.9.3.2 0 .4 0 .6-.1l14.9-6c4.9 2.2 10.2 3.3 15.6 3.3 21.2 0 38.5-17.3 38.5-38.5s-17.5-38.5-38.6-38.5z"
                    fill="#222"
                />
                <path
                    d="m50 84.4c-5.2 0-10.2-1.1-14.9-3.3-.4-.2-.8-.2-1.2 0l-13.1 5.3 2-13.9c.1-.4-.1-.8-.3-1.2-5.2-6.3-8-14.2-8-22.3 0-19.6 15.9-35.5 35.5-35.5s35.6 15.9 35.6 35.5-16 35.4-35.6 35.4z"
                    fill="#fff"
                />
                <path
                    d="m49.8 30.7v6.9c0 .5.3 1 .8 1.3.2.1.5.2.8.2s.5-.1.8-.2l12.8-7.4c.5-.3.8-.8.8-1.3s-.3-1-.8-1.3l-12.8-7.4c-.5-.3-1-.3-1.5 0s-.8.8-.8 1.3v4.9c-11.9.2-21.7 9.9-21.7 21.8 0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5c0-10.3 8.3-18.6 18.6-18.8z"
                    fill="#222"
                />
                <path d="m52.8 25.4 8.4 4.8-8.4 4.9z" fill="#ff855d" />
                <path
                    d="m70.4 48c-.8 0-1.5.7-1.5 1.5 0 10.1-8.1 18.4-18.2 18.7v-6.4c0-.5-.3-1-.8-1.3s-1-.3-1.5 0l-12.8 7.4c-.5.3-.8.8-.8 1.3s.3 1 .8 1.3l12.8 7.4c.2.1.5.2.8.2s.5-.1.8-.2c.5-.3.8-.8.8-1.3v-5.4c11.7-.3 21.2-9.9 21.2-21.7-.1-.8-.9-1.5-1.6-1.5z"
                    fill="#222"
                />
                <path d="m47.7 74-8.5-4.8 8.5-4.8z" fill="#ff855d" />
            </svg>
        );
    }
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m50 10.5c-21.2 0-38.5 17.3-38.5 38.5 0 8.6 2.9 17 8.2 23.7l-2.2 15.9c-.1.5.1 1.1.6 1.4.3.2.6.3.9.3.2 0 .4 0 .6-.1l14.9-6c4.9 2.2 10.2 3.3 15.6 3.3 21.2 0 38.5-17.3 38.5-38.5s-17.5-38.5-38.6-38.5z"
                fill="#222"
            />
            <path
                d="m50 84.4c-5.2 0-10.2-1.1-14.9-3.3-.4-.2-.8-.2-1.2 0l-13.1 5.3 2-13.9c.1-.4-.1-.8-.3-1.2-5.2-6.3-8-14.2-8-22.3 0-19.6 15.9-35.5 35.5-35.5s35.6 15.9 35.6 35.5-16 35.4-35.6 35.4z"
                fill="#fff"
            />
            <path
                d="m65 28.9-12.8-7.4c-.5-.3-1-.3-1.5 0s-.8.8-.8 1.3v4.9c-11.9.2-21.7 9.9-21.7 21.8 0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5c0-10.3 8.3-18.6 18.6-18.8v6.9c0 .5.3 1 .8 1.3.2.1.5.2.8.2s.5-.1.8-.2l12.8-7.4c.5-.3.8-.8.8-1.3s-.3-1-.8-1.3zm-12.2 6.2v-9.7l8.4 4.8z"
                fill="#222"
            />
            <path
                d="m70.4 48c-.8 0-1.5.7-1.5 1.5 0 10.1-8.1 18.4-18.2 18.7v-6.4c0-.5-.3-1-.8-1.3s-1-.3-1.5 0l-12.8 7.4c-.5.3-.8.8-.8 1.3s.3 1 .8 1.3l12.8 7.4c.2.1.5.2.8.2s.5-.1.8-.2c.5-.3.8-.8.8-1.3v-5.4c11.7-.3 21.2-9.9 21.2-21.7-.1-.8-.9-1.5-1.6-1.5zm-22.7 26-8.5-4.8 8.5-4.8z"
                fill="#222"
            />
        </svg>
    );
}

export default ArrowsInCircleSpeechBubbleIcon;
