import type { JSX } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useMutation } from "urql";
import type { IRenameArgs } from "../../admin/paths/taskGroups/query";
import t from "../../shared/translations";
import Modal from "../components/Modal";
import { type Dispatch, closeModal, openModal } from "../state";
import { type IRenameData, renameMutation } from "./queries";

interface IProps {
    pulse: {
        id: string;
        name: string | null;
    };
}

interface IFormValues {
    name: string;
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    > * {
        margin: 0;
    }

    button {
        font-size: 0.9rem;
        padding: 0.5rem 0.75rem;
    }
`;

function PulseName({ pulse }: IProps): JSX.Element {
    const [renameResult, executeRename] = useMutation<IRenameData, IRenameArgs>(renameMutation);
    const dispatch = useDispatch<Dispatch>();

    const open = () => {
        dispatch(openModal("Pulse/Rename"));
    };

    const onSubmit = async (values: IFormValues) => {
        if (renameResult.fetching) {
            return;
        }
        const args: IRenameArgs = {
            data: {
                id: pulse.id,
                name: values.name || null,
            },
        };
        const result = await executeRename(args);
        if (result.error || result.data.pulseRename?.error) {
            console.error(result.error || result.data.pulseRename?.error);
        } else {
            dispatch(closeModal());
        }
    };

    let main = null;
    if (!pulse.name) {
        main = (
            <button type="button" onClick={open}>
                {t("player.pulse-results.button-name")}
            </button>
        );
    } else {
        main = (
            <>
                <h3>{pulse.name}</h3>
                <button type="button" onClick={open}>
                    {t("player.pulse-results.button-rename")}
                </button>
            </>
        );
    }

    const initialValues = {
        name: pulse.name || "",
    };

    return (
        <>
            <Row>{main}</Row>
            <Modal modal="Pulse/Rename">
                <Form onSubmit={onSubmit} initialValues={initialValues}>
                    {({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit}>
                            <label>
                                {t("player.pulse-results.form-rename-label-name")}
                                <Field name="name" type="text" component="input" />
                            </label>
                            <button type="submit" disabled={submitting}>
                                {t("player.pulse-results.form-rename-submit")}
                            </button>
                        </form>
                    )}
                </Form>
            </Modal>
        </>
    );
}

export default PulseName;
