import styled from "styled-components";

const FlexVideo = styled.div`
    height: 0;
    overflow: hidden;
    padding-bottom: 56%;
    position: relative;
    width: 100%;

    & > * {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
    }
`;

export default FlexVideo;
