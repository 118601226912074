import { type ComponentPropsWithoutRef, memo } from "react";
import type { TaskIcon as TaskIconType } from "../../types";
import ArrowCircleIcon from "./taskIcons/ArrowCircleIcon";
import ArrowsInCircleSpeechBubbleIcon from "./taskIcons/ArrowsInCircleSpeechBubbleIcon";
import ArrowsInTriangleIcon from "./taskIcons/ArrowsInTriangleIcon";
import BackpackBlueIcon from "./taskIcons/BackpackBlueIcon";
import BackpackIcon from "./taskIcons/BackpackIcon";
import BinocularsIcon from "./taskIcons/BinocularsIcon";
import BirdsIcon from "./taskIcons/BirdsIcon";
import BoatIcon from "./taskIcons/BoatIcon";
import BulbIcon from "./taskIcons/BulbIcon";
import BushIcon from "./taskIcons/BushIcon";
import CalendarIcon from "./taskIcons/CalendarIcon";
import CampfireIcon from "./taskIcons/CampfireIcon";
import CheckmarkIcon from "./taskIcons/CheckmarkIcon";
import ClockIcon from "./taskIcons/ClockIcon";
import ComputerIcon from "./taskIcons/ComputerIcon";
import CrossroadIcon from "./taskIcons/CrossroadIcon";
import DiplomaIcon from "./taskIcons/DiplomaIcon";
import ForkIcon from "./taskIcons/ForkIcon";
import HammerIcon from "./taskIcons/HammerIcon";
import LadderIcon from "./taskIcons/LadderIcon";
import MagnifyingGlassIcon from "./taskIcons/MagnifyingGlassIcon";
import MirrorIcon from "./taskIcons/MirrorIcon";
import MountainIcon from "./taskIcons/MountainIcon";
import NetworkOfPeopleIcon from "./taskIcons/NetworkOfPeopleIcon";
import NewspaperIcon from "./taskIcons/NewspaperIcon";
import PathSignIcon from "./taskIcons/PathSignIcon";
import PersonShadowIcon from "./taskIcons/PersonShadowIcon";
import PlantIcon from "./taskIcons/PlantIcon";
import PlayButtonIcon from "./taskIcons/PlayButtonIcon";
import PulseIcon from "./taskIcons/PulseIcon";
import PuzzlePieceIcon from "./taskIcons/PuzzlePieceIcon";
import QuestionSpeechBubbleIcon from "./taskIcons/QuestionSpeechBubbleIcon";
import RoadIcon from "./taskIcons/RoadIcon";
import RocketIcon from "./taskIcons/RocketIcon";
import SailingBoatIcon from "./taskIcons/SailingBoatIcon";
import ScissorsIcon from "./taskIcons/ScissorsIcon";
import ShovelIcon from "./taskIcons/ShovelIcon";
import SpeechBubbleIcon from "./taskIcons/SpeechBubbleIcon";
import SpiralIcon from "./taskIcons/SpiralIcon";
import StairsIcon from "./taskIcons/StairsIcon";
import StarTaskIcon from "./taskIcons/StarTaskIcon";
import StarsIcon from "./taskIcons/StarsIcon";
import SunflowerSeedIcon from "./taskIcons/SunflowerSeedIcon";
import WaveIcon from "./taskIcons/WaveIcon";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    icon: TaskIconType;
    active?: boolean;
}

function TaskIcon({ icon, ...props }: IProps) {
    switch (icon) {
        case "clock":
            return <ClockIcon {...props} />;
        case "path-sign":
            return <PathSignIcon {...props} />;
        case "puzzle-piece":
            return <PuzzlePieceIcon {...props} />;
        case "bulb":
            return <BulbIcon {...props} />;
        case "magnifying-glass":
            return <MagnifyingGlassIcon {...props} />;
        case "speech-bubble":
            return <SpeechBubbleIcon {...props} />;
        case "arrow-circle":
            return <ArrowCircleIcon {...props} />;
        case "play-button":
            return <PlayButtonIcon {...props} />;
        case "mirror":
            return <MirrorIcon {...props} />;
        case "question-speech-bubble":
            return <QuestionSpeechBubbleIcon {...props} />;
        case "newspaper":
            return <NewspaperIcon {...props} />;
        case "backpack":
            return <BackpackIcon {...props} />;
        case "person-shadow":
            return <PersonShadowIcon {...props} />;
        case "campfire":
            return <CampfireIcon {...props} />;
        case "computer":
            return <ComputerIcon {...props} />;
        case "checkmark":
            return <CheckmarkIcon {...props} />;
        case "scissors":
            return <ScissorsIcon {...props} />;
        case "plant":
            return <PlantIcon {...props} />;
        case "diploma":
            return <DiplomaIcon {...props} />;
        case "birds":
            return <BirdsIcon {...props} />;
        case "backpack-blue":
            return <BackpackBlueIcon {...props} />;
        case "arrows-in-circle-speech-bubble":
            return <ArrowsInCircleSpeechBubbleIcon {...props} />;
        case "binoculars":
            return <BinocularsIcon {...props} />;
        case "network-of-people":
            return <NetworkOfPeopleIcon {...props} />;
        case "calendar":
            return <CalendarIcon {...props} />;
        case "arrows-in-triangle":
            return <ArrowsInTriangleIcon {...props} />;
        case "rocket":
            return <RocketIcon {...props} />;
        case "boat":
            return <BoatIcon {...props} />;
        case "wave":
            return <WaveIcon {...props} />;
        case "puls":
            return <PulseIcon {...props} />;
        case "star":
            return <StarTaskIcon {...props} />;
        case "stars":
            return <StarsIcon {...props} />;
        case "stairs":
            return <StairsIcon {...props} />;
        case "spiral":
            return <SpiralIcon {...props} />;
        case "shovel":
            return <ShovelIcon {...props} />;
        case "mountain":
            return <MountainIcon {...props} />;
        case "ladder":
            return <LadderIcon {...props} />;
        case "hammer":
            return <HammerIcon {...props} />;
        case "crossroad":
            return <CrossroadIcon {...props} />;
        case "sunflower-seed":
            return <SunflowerSeedIcon {...props} />;
        case "sailing-boat":
            return <SailingBoatIcon {...props} />;
        case "road":
            return <RoadIcon {...props} />;
        case "bush":
            return <BushIcon {...props} />;
        case "fork":
            return <ForkIcon {...props} />;
        default:
            throw new Error(`Unknown icon: ${icon}`);
    }
}

export default memo(TaskIcon);
