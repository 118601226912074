import type { Gender } from "../../shared/types";

// Query person

export const query = `
    query {
        currentPerson {
            id
            firstName
            lastName
            gender
            title
            tel
            birthYear
            avatar
            eMailNotifications
            user {
                email
            }
        }
    }
`;

export interface IQueryData {
    currentPerson: {
        id: string;
        firstName: string;
        lastName: string;
        gender: Gender;
        title: string | null;
        tel: string | null;
        birthYear: number | null;
        avatar: string;
        eMailNotifications: boolean;
        user: {
            email: string;
        };
    };
}

// First time login mutation

export interface IArgs {
    data: {
        firstName: string;
        lastName: string;
        email: string;
        gender: Gender;
        title: string | null;
        tel: string | null;
        birthYear: number | null;
        emailNotifications: boolean;
        password: string;
        agreedToTerms: boolean;
        avatar: string;
        profilePicture: string | null;
    };
}

export const mutation = `
    mutation($data:PlayerCurrentPersonFirstTimeData!) {
        currentPersonFirstTimeSetup(data:$data) {
            error
            currentPerson {
                id
            }
            redirectUrl
        }
    }
`;

export interface IMuatationData {
    currentPersonFirstTimeSetup: {
        error: string | null;
        currentPerson: {
            id: string;
        } | null;
        redirectUrl: string | null;
    };
}
