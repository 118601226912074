import { type FormEvent, type JSX, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import HomeIcon from "../../shared/components/icons/navIcons/HomeIcon";
import ProfileIcon from "../../shared/components/icons/navIcons/ProfileIcon";
import TheoryIcon from "../../shared/components/icons/navIcons/TheoryIcon";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import { useCurrentPerson } from "../components/CurrentPerson";

const HeaderWrapper = styled.header`
    padding: 0 10px 0 10px;
    max-width: 1440px;
    width: 100%;
`;

const MainNav = styled.nav`
    background-color: ${colors.altSecondaryColor};
    margin-bottom: 0.2rem;
    svg {
        width: 60px;
        height: 60px;
        fill: white;
        margin: 0.2rem 1rem;
    }
`;

const SecondaryNav = styled.nav`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    gap: 4px;
    form {
        min-width: 0;
    }
`;

const Select = styled.select`
    display: inline-block;
    width: auto;
    line-height: 22px;
    position: relative;
    border: 1px solid ${colors.unlight};
    padding: 0;
    margin: 0;
    display: inline-block;
    width: auto;
    background-color: transparent;
    border-radius: 0;
    color: ${colors.text};
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    padding-right: 1.2rem;
    height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus, &:active, &:visited {
        background-color: white;
        border: 1px solid ${colors.unlight};
    }
`;

const ButtonAnchor = styled.a`
    display: inline-block;
    background-color: transparent;
    border: 1px solid ${colors.text};
    border-radius: 0;
    color: ${colors.text};
    margin: 0;
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    line-height: 22px;

    &:hover, &:focus, &:active, &:visited {
        background-color: transparent;
        color: ${colors.text};
    }
`;

function Header(): JSX.Element {
    const person = useCurrentPerson();
    const showAdmin = person.user.role.name === "admin";
    const showLearningCoach =
        loadJSData().features.learning_coach &&
        (person.user.role.name === "admin" || person.user.role.name === "learning-coach");
    const showTracking =
        loadJSData().features.tracking &&
        (person.user.role.name === "admin" ||
            person.user.role.name === "organisation-manager" ||
            person.user.role.name === "team-manager");
    const showViewSwitch = showAdmin || showLearningCoach || showTracking;
    const showPathSwitch = person.ongoingPaths.length > 0 || person.user.pulseRights;
    const pathFormRef = useRef<HTMLFormElement>(null);

    const onViewSwitch = (event: FormEvent<HTMLSelectElement>) => {
        const path = event.currentTarget.value;
        if (path) {
            window.location.href = path;
        }
    };

    const onPathSwitch = (event: FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        if (!value) {
            return;
        }
        if (value === "/pulse") {
            window.location.href = value;
        } else if (event.currentTarget.value) {
            pathFormRef.current.submit();
        }
    };

    return (
        <HeaderWrapper>
            <MainNav>
                <Link to="/team-scans">
                    <HomeIcon />
                </Link>
                <Link to="/team-scans/theory">
                    <TheoryIcon />
                </Link>
                <Link to="/team-scans/profile">
                    <ProfileIcon />
                </Link>
            </MainNav>
            <SecondaryNav>
                {showPathSwitch && (
                    <form action="/select-path" method="POST" ref={pathFormRef} style={{ minWidth: "0" }}>
                        <input name="_csrf_token" type="hidden" value={__CSRF_TOKEN__} />
                        <Select name="path" onChange={onPathSwitch}>
                            <option value="">{t("player.header.secondary-nav-team-scan")}</option>
                            {person.ongoingPaths.map((path) => (
                                <option key={path.id} value={path.id}>
                                    {path.pathname}
                                </option>
                            ))}
                            {person.user.pulseRights && (
                                <option value="/pulse">{t("player.header.secondary-nav-pulse")}</option>
                            )}
                            {loadJSData().features.ai && person.standaloneAiChatbots.length > 0 && (
                                <option value="/ai-chatbots">{t("player.header.secondary-nav-pulse")}</option>
                            )}
                        </Select>
                    </form>
                )}
                {showViewSwitch && (
                    <Select onChange={onViewSwitch}>
                        <option value="">{t("player.header.secondary-nav-switch-view")}</option>
                        {showAdmin && <option value="/admin">{t("player.header.secondary-nav-admin")}</option>}
                        {showLearningCoach && (
                            <option value="/learning-coach">{t("player.header.secondary-nav-learning-coach")}</option>
                        )}
                        {showTracking && (
                            <option value={`/tracking/${person.organisation.id}`}>
                                {t("player.header.secondary-nav-tracking")}
                            </option>
                        )}
                    </Select>
                )}
                <ButtonAnchor href="/logout">{t("player.header.secondary-nav-log-out")}</ButtonAnchor>
            </SecondaryNav>
        </HeaderWrapper>
    );
}

export default Header;
