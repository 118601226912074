import { type JSX, memo } from "react";
import styled from "styled-components";
import { Runners } from "../../shared/components/Runners";
import t from "../../shared/translations";
import WithDocs from "../components/WithDocs";
import Odometer from "./Odometer";
import Speedometer from "./Speedometer";
import type { IData } from "./query";
import { Box, Frame } from "./stylings";

interface IProps {
    data: IData;
}

const Position = styled.div`
    font-size: 2rem;
`;

function MyPerf({ data }: IProps): JSX.Element {
    const pm = data.currentPathMembership;
    const path = pm.learningPath;
    return (
        <Frame>
            {path.fixed && (
                <>
                    <Box $align="left">
                        <WithDocs
                            modal="Performance/Info/Running"
                            docPath={"player/performance/running"}
                            component="h3"
                            style={{ gridColumn: "span 12", justifyContent: "flex-start" }}
                        >
                            {t("player.performance.heading-running")}
                        </WithDocs>
                    </Box>
                    <Box $span={[12, 9]}>
                        <Runners
                            goal={path.goal}
                            miles={pm.miles}
                            memberMiles={pm.memberMiles}
                            checkpoint={path.checkpoint}
                        />
                    </Box>
                    <Box $span={[12, 3]}>
                        <h5>{t("player.performance.heading-position")}</h5>
                        <Position>
                            {t("player.performance.info-rank", {
                                rank: pm.rank,
                                num_members: path.numMembers,
                            })}
                        </Position>
                    </Box>
                </>
            )}
            <>
                <Box $span={[12, 4]}>
                    <WithDocs
                        modal="Performance/Info/TotalMiles"
                        docPath={"player/performance/total-miles"}
                        component="h3"
                    >
                        {t("player.performance.heading-miles")}
                    </WithDocs>
                    <Odometer miles={pm.miles} />
                </Box>
                <Box $span={[12, 4]}>
                    <WithDocs modal="Performance/Info/MySpeed" docPath={"player/performance/my-speed"} component="h3">
                        {t("player.performance.heading-speedometer")}
                    </WithDocs>
                    <Speedometer miles={pm.mySpeed} />
                </Box>
                <Box $span={[12, 4]}>
                    <WithDocs modal="Performance/Info/OurSpeed" docPath={"player/performance/our-speed"} component="h3">
                        {t("player.performance.heading-group-speedometer")}
                    </WithDocs>
                    <Speedometer miles={pm.ourSpeed} />
                </Box>
            </>
        </Frame>
    );
}

export default memo(MyPerf);
