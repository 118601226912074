import Linkify from "linkify-react";
import { type JSX, memo } from "react";
import type { Person } from "../../api/player/Chat";
import HistoryProfile from "./HistoryProfile";

interface IProps {
    id: number;
    person: Person | null;
    current: boolean;
    content: string[];
    datetime: string;
}

function HistoryItem({ content, person, current, datetime }: IProps): JSX.Element {
    return (
        <div className={person && current ? "history-item own" : "history-item other"}>
            <HistoryProfile person={person} datetime={datetime} />
            {content.map((text, index) => (
                <Linkify key={index} options={{ nl2br: true }} tagName="p">
                    {text}
                </Linkify>
            ))}
        </div>
    );
}

export default memo(HistoryItem);
