import { type ComponentPropsWithoutRef, memo, type JSX } from "react";

type IProps = ComponentPropsWithoutRef<"svg">;

function TheoryIcon(props: IProps): JSX.Element {
    return (
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m90 29.7c-1.4-.8-2.8-1.2-4.2-1.5-.5-.1-1-.3-1.5-.4v-11c0-.7-.5-1.3-1.2-1.5-2.5-.5-5-.8-7.6-.8-9.5 0-18.5 3.6-25.4 10-6.9-6.5-15.9-10-25.4-10-2.6 0-5.1.3-7.6.8-.7.1-1.2.8-1.2 1.5v10.9c-.5.2-1 .3-1.5.4-1.4.3-2.8.7-4.2 1.5-.5.3-.8.8-.8 1.3l.8 53c0 .5.3 1 .8 1.3.2.1.5.2.7.2.3 0 .5-.1.7-.2 2.7-1.6 5.5-2.9 8.3-4 11.5-4.5 21.8-1.9 28.5 1.2.3.1.6.2.9.1.3 0 .6 0 .9-.1 6.6-3 16.9-5.7 28.5-1.2 2.8 1.1 5.6 2.5 8.3 4 .2.1.5.2.7.2.3 0 .5-.1.7-.2.5-.3.7-.8.8-1.3l.8-53c-.1-.5-.4-1-.8-1.2zm-70.4 48.7c-2.2.9-4.4 1.9-6.5 3l-.7-49.6c.8-.4 1.7-.6 2.7-.8.3-.1.5-.1.8-.2v40.4c0 .5.2.9.6 1.2s.8.4 1.3.3c2.3-.5 4.6-.7 7-.7 6.3 0 12.3 1.7 17.6 4.8-6.6-1.4-14.4-1.7-22.8 1.6zm28.6-.9c-6.6-5.5-14.9-8.5-23.6-8.5-1.9 0-3.9.2-5.8.4v-51.4c1.9-.3 3.9-.5 5.8-.5 8.9 0 17.3 3.4 23.7 9.5v50.5zm3.6-50.4c6.4-6.1 14.8-9.5 23.7-9.5 1.9 0 3.9.2 5.8.5v51.4c-1.9-.3-3.9-.4-5.8-.4-8.7 0-17 3-23.7 8.5zm35.2 54.3c-2.1-1.1-4.3-2.1-6.5-3-8.4-3.3-16.2-3.1-22.6-1.6 5.3-3.2 11.3-4.8 17.6-4.8 2.4 0 4.7.2 7 .7.4.1.9 0 1.3-.3s.6-.7.6-1.2v-40.3c.3.1.6.1.8.2.9.2 1.8.5 2.7.8z" />
            <path d="m23.7 32.3h1.2c6.8 0 13.4 2 19 5.8.3.2.5.3.8.3.5 0 1-.2 1.2-.7.5-.7.3-1.6-.4-2.1-6.1-4.1-13.3-6.3-20.7-6.3-.4 0-.9 0-1.3 0-.8 0-1.5.7-1.4 1.6.1.8.8 1.4 1.6 1.4z" />
            <path d="m23.6 38.2c6.8 0 13.4 2 19 5.8.3.2.5.3.8.3.5 0 1-.2 1.2-.7.5-.7.3-1.6-.4-2.1-6.1-4.1-13.3-6.3-20.7-6.3-.8 0-1.5.7-1.5 1.5s.8 1.5 1.6 1.5z" />
            <path d="m43.5 50.2c.5 0 1-.2 1.2-.7.5-.7.3-1.6-.4-2.1-6.1-4.1-13.3-6.3-20.7-6.3-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c6.8 0 13.4 2 19 5.8.3.2.6.3.9.3z" />
            <path d="m56 38c5.6-3.8 12.2-5.8 19-5.8h1.2c.8.1 1.5-.6 1.6-1.4 0-.8-.6-1.5-1.4-1.6-.4 0-.9 0-1.3 0-7.4 0-14.6 2.2-20.7 6.3-.7.5-.9 1.4-.4 2.1.3.4.8.7 1.2.7.3 0 .6-.1.8-.3z" />
            <path d="m56.5 44.3c.3 0 .6-.1.8-.3 5.6-3.8 12.2-5.8 19-5.8.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5c-7.4 0-14.6 2.2-20.7 6.3-.7.5-.9 1.4-.4 2.1.3.4.8.7 1.3.7z" />
            <path d="m77.9 42.7c0-.8-.7-1.5-1.5-1.5-7.4 0-14.6 2.2-20.7 6.3-.7.5-.9 1.4-.4 2.1.3.4.8.7 1.2.7.3 0 .6-.1.8-.3 5.6-3.8 12.2-5.8 19-5.8.9 0 1.6-.7 1.6-1.5z" />
        </svg>
    );
}

export default memo(TheoryIcon);
