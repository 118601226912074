import type { CSSProperties, JSX } from "react";
import { primaryColor } from "../../shared/colors";
import t from "../../shared/translations";

interface IProps {
    color?: string;
}

function OpenChatButton({ color }: IProps): JSX.Element {
    const style: CSSProperties = {
        backgroundColor: color ?? primaryColor,
    };
    const open = () => {
        const event = new Event("tm-open-chat");
        document.querySelector("body").dispatchEvent(event);
    };
    return (
        <button style={style} type="button" onClick={open}>
            {t("player.chat.button-open-chat")}
        </button>
    );
}

export default OpenChatButton;
