import type { JSX } from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import * as colors from "../../shared/colors";
import Loader from "../../shared/components/Loader";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import type { LearningPathItemType, TaskIcon as TaskIconType } from "../../shared/types";
import ShowError from "../components/ShowError";
import CurrentPaths from "./CurrentPaths";
import FinishedPaths from "./FinishedPaths";
import MilesInfo from "./MilesInfo";
import OtherLearningMiles from "./OtherLearningMiles";
import SpentLearningMiles from "./SpentLearningMiles";
import { Grid } from "./stylings";

const query = `
    query {
        currentPerson {
            currentPaths: learningPaths(filter:"ongoing") {
                id
                pathname
                goal
                startDate
                endDate
                acceptedItems: learningPathItems(filter:"completed") {
                    id
                    type
                    earnedMiles
                    title
                    url
                    icon
                    finishDatetime
                }
            }
            finishedPaths: learningPaths(filter:"finished") {
                id
                pathname
                startDate
                endDate
                acceptedItems: learningPathItems(filter:"completed") {
                    id
                    type
                    earnedMiles
                    title
                    url
                    icon
                    finishDatetime
                }
            }
            otherLearningMiles {
                id
                miles
                date
                title
                description
            }
            spentLearningMiles {
                id
                miles
                date
                title
                description
            }
        }
    }
`;

export interface ICurrentPath {
    id: string;
    pathname: string;
    goal: number;
    startDate: string;
    endDate: string;
    acceptedItems: {
        id: string;
        type: LearningPathItemType;
        earnedMiles: number;
        title: string;
        url: string;
        icon: TaskIconType;
        finishDatetime: string;
    }[];
}

export interface IFinishedPath {
    id: string;
    pathname: string;
    startDate: string;
    endDate: string;
    acceptedItems: {
        id: string;
        type: LearningPathItemType;
        earnedMiles: number;
        title: string;
        url: string;
        icon: TaskIconType;
        finishDatetime: string;
    }[];
}

export interface IOtherLearningMiles {
    id: string;
    miles: number;
    date: string;
    title: string;
    description: string | null;
}

export interface ISpentLearningMiles {
    id: string;
    miles: number;
    date: string;
    title: string;
    description: string | null;
}

interface IData {
    currentPerson: {
        currentPaths: ICurrentPath[];
        finishedPaths: IFinishedPath[];
        otherLearningMiles: IOtherLearningMiles[];
        spentLearningMiles: ISpentLearningMiles[];
    };
}

const Wrapper = styled.div`
    border: 1px solid lightgray;
    padding: 5px;
    min-height: 300px;
    container-type: inline-size;
    @media (min-width: 400px) {
        padding: 10px;
    }
    @media (min-width: 600px) {
        padding: 15px;
    }
`;

const H1 = styled.h1`
    margin: 0 0 0.5rem 0;
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    
`;

const ButtonAnchor = styled.a`
    display: block;
    padding: 0.5rem 1rem 0.5325rem;
    margin-bottom: 0.5rem;
    background-color: ${colors.primaryColor};
    color: ${colors.white};
    white-space: nowrap;

    &:hover, &:focus, &:active {
        color: ${colors.white};
    }
`;

function MyPaths(): JSX.Element {
    const [result] = useQuery<IData>({
        query,
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const { currentPaths, finishedPaths, otherLearningMiles, spentLearningMiles } = result.data.currentPerson;
    return (
        <Wrapper>
            <Header>
                <H1>{t("player.my-history.heading", { webapp_title: loadJSData().settings.title })}</H1>
                <ButtonAnchor target="_blank" href="/pdf/download/my-report.pdf">
                    {t("player.my-history.button-download-report")}
                </ButtonAnchor>
            </Header>
            <Grid>
                <MilesInfo
                    currentPaths={currentPaths}
                    finishedPaths={finishedPaths}
                    otherLearningMiles={otherLearningMiles}
                    spentLearningMiles={spentLearningMiles}
                />
                <CurrentPaths currentPaths={currentPaths} />
                <FinishedPaths finishedPaths={finishedPaths} />
                <OtherLearningMiles otherMiles={otherLearningMiles} />
                {loadJSData().features.spent_miles && <SpentLearningMiles spentMiles={spentLearningMiles} />}
            </Grid>
        </Wrapper>
    );
}

export default MyPaths;
