import { configureStore } from "@reduxjs/toolkit";
import calendarReducer from "./calendarSlice";
import chatReducer from "./chatSlice";
import modalReducer from "./modalSlice";
import taskReducer from "./taskSlice";
import teamScanReducer from "./teamScanSlice";

export const store = configureStore({
    reducer: {
        teamScan: teamScanReducer,
        task: taskReducer,
        chat: chatReducer,
        modal: modalReducer,
        calendar: calendarReducer,
    },
});

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;

export * from "./calendarSlice";
export * from "./chatSlice";
export * from "./modalSlice";
export * from "./taskSlice";
export * from "./teamScanSlice";
