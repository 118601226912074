import type { JSX } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import t from "../../shared/translations";
import Inbox from "./Inbox";
import NavBar from "./NavBar";
import SendButton from "./SendButton";
import Sent from "./Sent";

const Wrapper = styled.div`
    border: 1px solid lightgray;
    padding: 5px;
    min-height: 300px;
    container-type: inline-size;
    @media (min-width: 400px) {
        padding: 10px;
    }
    @media (min-width: 600px) {
        padding: 15px;
    }
`;

const Header = styled.header`
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    margin-bottom: 1rem;

    > * {
        margin: 0;
    }
    
    @container (min-width: 600px) {
        grid-template-columns: auto auto;
        justify-content: space-between;
    }
`;

const H1 = styled.h1`
    text-align: center;
    @container (min-width: 600px) {
        text-align: left;
    }
`;

function Messages(): JSX.Element {
    return (
        <Wrapper>
            <Header>
                <H1>{t("player.messages.heading")}</H1>
                <SendButton />
            </Header>
            <NavBar />
            <Routes>
                <Route path="inbox" element={<Inbox filter="messages" />} />
                <Route path="notifications" element={<Inbox filter="notifications" />} />
                <Route path="archived" element={<Inbox filter="archived" />} />
                <Route path="sent" element={<Sent />} />
                <Route path="/" element={<Navigate to="inbox" />} />
            </Routes>
        </Wrapper>
    );
}

export default Messages;
