import { memo, type JSX } from "react";
import { PathGroupMemberBox, ProfilePic } from "../answerSection/PathGroupPreview";
import type { IPerson, ITaskApproval } from "../query";
import ApprovCircle from "./ApprovCircle";

interface IProps {
    person: IPerson;
    approval: ITaskApproval;
}

const DEFAULT_SRC = "/static/images/profile-silouette.png";

function ApprovalItem({ person, approval }: IProps): JSX.Element {
    const approveStatus = approval === undefined ? "unapproved" : approval.status;
    return (
        <PathGroupMemberBox key={person.id}>
            <ApprovCircle status={approveStatus} />
            <br />
            <ProfilePic src={person.profilePicSrc || DEFAULT_SRC} />
            <br />
            <span>
                {person.firstName} {person.lastName}
            </span>
        </PathGroupMemberBox>
    );
}

export default memo(ApprovalItem);
