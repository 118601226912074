import type { JSX } from "react";
import { type UseFieldArrayConfig, useFieldArray } from "react-final-form-arrays";
import styled from "styled-components";
import * as colors from "../shared/colors";
import LikertInput from "../shared/components/LikertInput";
import Textarea from "./Textarea";
import type { IQuestion } from "./index";

interface IOwnProps {
    name: string;
    questions: IQuestion[];
}

type IProps = IOwnProps & UseFieldArrayConfig<string | number>;

const NumRow = styled.div`
    display: grid;
    grid-template-columns: 35px 1fr;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    align-items: center;

    > *:first-child {
        color: white;
        background-color: ${colors.primaryColor};
        border-radius: 50%;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        margin: 0;
    }
`;

const Container = styled.div`
    margin-bottom: 3rem;
`;

function QuestionsArray({ name, questions, ...props }: IProps): JSX.Element {
    const { fields } = useFieldArray(name, props);
    return (
        <>
            {fields.map((inputName, index) => {
                const question = questions[index];
                return (
                    <Container key={index}>
                        <NumRow>
                            <h4>{question.nr}</h4>
                            <div>{question.translation || question.question}</div>
                        </NumRow>
                        {question.type === "text" && <Textarea name={inputName} />}
                        {question.type === "likert" && (
                            <LikertInput
                                name={inputName}
                                leftLabel={question.leftTranslation || question.leftLabel}
                                rightLabel={question.rightTranslation || question.rightLabel}
                            />
                        )}
                    </Container>
                );
            })}
        </>
    );
}

export default QuestionsArray;
