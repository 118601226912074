import type { JSX } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import NoteIcon from "../../shared/components/icons/NoteIcon";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import type { ShareAnswersType, SubmittedTaskStatus, TaskIcon as TaskIconType } from "../../shared/types";
import { taskFinished, taskSharingAnswers } from "./query";

interface IProps {
    taskChoice: {
        id: string;
        icon: TaskIconType;
        name: string;
        url: string;
        complete: boolean;
    };
    tasks: {
        id: string;
        icon: TaskIconType;
        shortTitle: string;
        url: string;
        shareAnswers: ShareAnswersType;
        submittedTask: {
            id: string;
            url: string;
            status: SubmittedTaskStatus;
        } | null;
    }[];
    current: string;
}

const Nav = styled.nav`
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 80px;
    font-size: 0.85rem;
    justify-content: space-between;
    gap: 20px;
    overflow-x: auto;
    margin-bottom: 0.5rem;
    @media (min-width: 640px) {
        margin-bottom: 1rem;
    }
`;

interface IStyledTaskIcon {
    $current: boolean;
}

const StyledTaskIcon = styled(TaskIcon)<IStyledTaskIcon>`
    margin-top: 10px;
    width: 60px;
    height: 60px;
    background-color: white;
    border-bottom: 6px solid ${({ $current }) => ($current ? colors.primaryColor : "transparent")};
    padding-bottom: 4px;
    margin-bottom: 2px;
`;

const A = styled.a`
    position: relative;
    display: grid;
    justify-items: center;
    text-align: center;
    color: ${colors.text};
    &:hover, &:focus, &:active {
        color: ${colors.text};
    }
`;

const AllAnswersIcon = styled(NoteIcon)`
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0px;
    left: 3px;
`;

function taskUrl(task: IProps["tasks"][0]): string {
    if (task?.submittedTask?.status === "accepted") {
        return task.submittedTask?.url;
    }
    return task.url;
}

function TaskChoiceNav({ taskChoice, tasks, current }: IProps): JSX.Element {
    return (
        <Nav>
            <A href={taskChoice.url}>
                <StyledTaskIcon icon={taskChoice.icon} $current={false} active={taskChoice.complete} />
                <div>{taskChoice.name}</div>
            </A>
            {tasks.map((task) => (
                <A href={taskUrl(task)} key={task.id}>
                    <StyledTaskIcon icon={task.icon} active={taskFinished(task)} $current={current === task.id} />
                    <div>{task.shortTitle}</div>
                    {taskSharingAnswers(task) && <AllAnswersIcon />}
                </A>
            ))}
        </Nav>
    );
}

export default TaskChoiceNav;
