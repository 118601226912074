import t from "../../../shared/translations";
import { Box } from "../stylings";
import type { JSX } from "react";

interface IProps {
    redo: boolean;
}

function OutsideFeedbackInfo({ redo }: IProps): JSX.Element {
    return (
        <Box>
            <h3>{t("player.task.heading-outside-feedback-instructions")}</h3>
            <p>{t("player.task.info-outside-feedback-what-it-is")}</p>
            <ul>
                <li>{t("player.task.info-outside-feedback-write-answer")}</li>
                {!redo && <li>{t("player.task.info-outside-feedback-check-info")}</li>}
                {!redo && <li>{t("player.task.info-outside-feedback-preview")}</li>}
                <li>{t("player.task.info-outside-feedback-submit")}</li>
                <li>{t("player.task.info-outside-feedback-send-link")}</li>
            </ul>
        </Box>
    );
}

export default OutsideFeedbackInfo;
