import styled from "styled-components";
import MagnifyingGlassIcon from "../../../shared/components/icons/taskIcons/MagnifyingGlassIcon";
import t from "../../../shared/translations";
import type { JSX } from "react";

interface IProps {
    ids: string[];
    allIds: string[];
}

const H5 = styled.h5`
    display: flex;
    align-items: center;
    flex-direction: column;
    svg {
        width: 90px;
        height: 90px;
    }
`;

function Strengths({ ids, allIds }: IProps): JSX.Element {
    return (
        <div>
            <div>
                <H5>
                    <MagnifyingGlassIcon plusMinus="plus" />
                    <span>{t("shared.team-scan-report.heading-strengths")}</span>
                </H5>
                {ids.length > 0 && <h6>{t("shared.team-scan-report.info-strengths-statements")}</h6>}
            </div>
            <div>
                {ids.length === 0 ? (
                    <span>{t("shared.team-scan-report.info-no-strengths-statements")}</span>
                ) : (
                    <ol type="A">
                        {ids.map((id) => (
                            <li key={id} value={allIds.indexOf(id) + 1}>
                                {t(`shared.team-scan-report-question.${id}`)}
                            </li>
                        ))}
                    </ol>
                )}
            </div>
        </div>
    );
}

export default Strengths;
