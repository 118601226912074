import * as Sentry from "@sentry/browser";
import { useEffect, useState, type JSX } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import * as colors from "../../shared/colors";
import StarIcon from "../../shared/components/icons/StarIcon";
import t from "../../shared/translations";
import Modal from "../components/Modal";
import { type Dispatch, closeModal, openModal } from "../state";
import RatingForm, { type IFormValues } from "./RatingForm";

const query = `
query {
    currentPathMembership {
        id
        hideAppRating
    }
}
`;

interface IData {
    currentPathMembership: {
        id: string;
        hideAppRating: boolean;
    };
}

interface IRateArgs {
    data: {
        rating: number | null;
        feedback: string | null;
    };
}

const rateMutation = `
    mutation($data:CurrentPathMembershipRatePathData!) {
        currentPathMembershipRatePath(data:$data) {
            error
            currentPathMembership {
                id
            }
        }
    }
`;

interface IRateData {
    currentPathMembershipRatePath: {
        error: string | null;
        currentPathMembership: {
            id: string;
        } | null;
    };
}

const dismissMutation = `
    mutation {
        currentPathMembershipDismissRating {
            error
            currentPathMembership {
                id
            }
        }
    }
`;

interface IDismissData {
    currentPathMembershipDismissRating: {
        error: string | null;
        currentPathMembership: {
            id: string;
        } | null;
    };
}

interface IProps {
    popup: boolean;
    includeFeedback: boolean;
    style?: React.CSSProperties;
}

const Button = styled.button`
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid ${colors.primaryColor};
    background: ${colors.secondaryColor};
    color: ${colors.primaryColor};
    font-size: 0.85rem;
    padding: 0.5rem 1rem .5325rem 1rem;
    &:hover, &:focus, &:active {
        color: ${colors.primaryColor};
        background: ${colors.secondaryColor};
    }
    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin: -4px 0;
    }
`;

function RatingButton({ popup, includeFeedback, style }: IProps): JSX.Element {
    const [result] = useQuery<IData>({
        query,
    });
    const [_rateResult, executeRate] = useMutation<IRateData, IRateArgs>(rateMutation);
    const [dismissResult, executeDismiss] = useMutation<IDismissData>(dismissMutation);
    const dispatch = useDispatch<Dispatch>();
    const [dismissed, setDismissed] = useState(false);

    useEffect(() => {
        if (result.fetching) {
            return;
        }
        if (result.error) {
            return;
        }
        const hideAppRating = result.data.currentPathMembership.hideAppRating;
        if (hideAppRating) {
            return;
        }
        if (!popup) {
            return;
        }
        if (dismissed) {
            return;
        }
        dispatch(openModal("Landing/Rate"));
    }, [popup, result, dismissed]);

    const openRate = () => {
        dispatch(openModal("Landing/Rate"));
    };

    const dismiss = () => {
        setDismissed(true);
        if (dismissResult.fetching) {
            return;
        }
        if (popup) {
            void executeDismiss();
        }
    };

    const submit = async (values: IFormValues) => {
        const data = { rating: values.rating || null, feedback: values.feedback || null };
        const rateResult = await executeRate({ data });
        if (rateResult.error) {
            console.error(rateResult.error);
            Sentry.captureMessage(rateResult.error.toString());
        } else if (rateResult.data.currentPathMembershipRatePath?.error) {
            console.error(rateResult.data.currentPathMembershipRatePath.error);
            Sentry.captureMessage(rateResult.data.currentPathMembershipRatePath.error);
        } else {
            setDismissed(true);
            dispatch(closeModal());
        }
    };

    if (result.fetching) {
        return null;
    }
    if (result.error) {
        return null;
    }
    return (
        <>
            <Button onClick={openRate} type="button" style={style}>
                <StarIcon fill={colors.star} active stroke={colors.star} />
                {t("player.app-rating.button-rate-app")}
            </Button>
            <Modal modal="Landing/Rate" onClose={dismiss}>
                <RatingForm includeFeedback={includeFeedback} submit={submit} />
            </Modal>
        </>
    );
}

export default RatingButton;
