import { type JSX, memo } from "react";
import APIErrorIcon from "../../shared/components/icons/APIErrorIcon";
import t from "../../shared/translations";

interface IProps {
    error?: string;
}

function ShowError({ error }: IProps): JSX.Element {
    return (
        <div id="error-message">
            <div className="error-wrapper">
                <APIErrorIcon className="error-icon" />
                <div>{error || t("player.error.error")}</div>
            </div>
        </div>
    );
}

export default memo(ShowError);
