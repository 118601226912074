import { memo } from "react";
import type { JSX } from "react";
import type { Message, Person } from "../../api/player/Chat";
import { diffMinutes, parseDate } from "../../shared/dateFns";
import { listToIdDict } from "../../shared/idDict";
import HistoryItem from "./HistoryItem";

interface IProps {
    currentId: number;
    messages: Message[];
    members: Person[];
}

interface IContent {
    id: number;
    person: Person | null;
    current: boolean;
    content: string[];
    datetime: string;
}

function HistoryList({ messages, members, currentId }: IProps): JSX.Element {
    const people = listToIdDict(members);
    const mergedContent: IContent[] = [];

    for (const message of messages) {
        const lastContent = mergedContent.length > 0 ? mergedContent[mergedContent.length - 1] : null;
        const update =
            lastContent !== null &&
            (lastContent.person == null || lastContent.person.id === message.person) &&
            diffMinutes(parseDate(lastContent.datetime), parseDate(message.datetime)) < 30;

        if (update) {
            lastContent.content.push(message.message);
        } else {
            mergedContent.push({
                id: message.id,
                person: people[message.person],
                current: message.person === currentId,
                content: [message.message],
                datetime: message.datetime,
            });
        }
    }

    return (
        <>
            {mergedContent.map((props) => (
                <HistoryItem {...props} key={props.id} />
            ))}
        </>
    );
}

export default memo(HistoryList);
