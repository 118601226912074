import Linkify from "linkify-react";
import Box from "./Box";
import type { JSX } from "react";

interface IProps {
    description: string;
}

function Info({ description }: IProps): JSX.Element {
    return (
        <Box>
            <Linkify options={{ nl2br: true }} tagName="div">
                {description}
            </Linkify>
        </Box>
    );
}

export default Info;
