import { useCallback, type JSX } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import PulseInfo from "../../pulse/PulseInfo";
import type { ITask, ITaskVariant } from "../query";
import { Box } from "../stylings";
import Redirector from "./Redirector";
import Results from "./Results";
import Setup from "./Setup";

interface IProps {
    task: ITask;
    variant: ITaskVariant;
    refetch: VoidFunction;
}

const Boxer = styled(Box)`
    padding: 15px;
    @media (min-width: 600px) {
        padding: 1rem;
    }
`;

function Pulse({ task, variant, refetch }: IProps): JSX.Element {
    const pulse = task?.submittedTask?.submittedTaskVariant?.pulse;
    const wrappedRefetch = useCallback(() => {
        if ((task?.submittedTask?.submittedTaskVariant?.pulse?.pulseAnswers?.length ?? 0) === 0) {
            refetch();
        }
    }, [task, refetch]);
    return (
        <>
            <Boxer>
                <PulseInfo />
            </Boxer>
            <Boxer>
                <Routes>
                    <Route path="pulse/setup" element={<Setup task={task} variant={variant} pulse={pulse} />} />
                    <Route path="pulse/:id/results" element={<Results refetch={wrappedRefetch} />} />
                    <Route path="/" element={<Redirector pulse={pulse} taskId={task.id} />} />
                </Routes>
            </Boxer>
        </>
    );
}

export default Pulse;
