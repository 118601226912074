import { type JSX, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Modal from "../components/Modal";
import { type Dispatch, type ICalendarEvent, type State, calendarSelectEvent } from "../state";
import EventModal from "./EventModal";
import FeedbackTaskModal from "./FeedbackTaskModal";
import TaskChoiceModal from "./TaskChoiceModal";
import TaskModal from "./TaskModal";

const calendarEventSelector = createSelector(
    (state: State) => state.calendar.selectedEvent,
    (state: State) => state.calendar.events.dict,
    (id, dict): ICalendarEvent => dict[id],
);

function Modals(): JSX.Element {
    const event = useSelector(calendarEventSelector);
    const dispatch = useDispatch<Dispatch>();
    const close = () => {
        dispatch(calendarSelectEvent(null));
    };
    if (!event) {
        return null;
    }
    return (
        <Modal modal="Calendar/EventDetails" onClose={close}>
            {(event.type === "event" || event.type === "task-event") && <EventModal event={event.event} />}
            {(event.type === "task-unlock" ||
                event.type === "task-call-to-action" ||
                event.type === "task-deadline") && <TaskModal task={event.task} />}
            {(event.type === "feedback-task-call-to-action" || event.type === "feedback-task-deadline") && (
                <FeedbackTaskModal feedbackTask={event.feedbackTask} />
            )}
            {(event.type === "task-choice-unlock" ||
                event.type === "task-choice-call-to-action" ||
                event.type === "task-choice-deadline") && <TaskChoiceModal taskChoice={event.taskChoice} />}
        </Modal>
    );
}

export default memo(Modals);
