import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function ShovelIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m39.254 57.736-1.957 2.626c-1.502 2.016-1.286 4.724.484 6.042 1.769 1.319 4.426.752 5.928-1.265l1.935-2.596c.834 1.164 1.581 2.382 2.131 3.302.76 1.274 1.461 2.589 2.151 3.909 0 0-7.765 10.422-7.765 10.422-.855 1.148-2.731 1.804-4.865 2.308-4.458 1.055-10.09 1.132-12.395 1.114-.671-2.21-2.196-7.645-2.462-12.22-.128-2.188-.042-4.168.801-5.304 2.231-3.01 6.28-8.435 7.762-10.42 1.463.284 2.924.58 4.362.944 1.069.271 2.5.653 3.89 1.138z"
                fill={active ? "#00ba9e" : "#fff"}
            />
            <path
                d="m61.816 32.476c.247.219.505.43.775.63.27.201.545.389.826.562l-22.113 29.679c-.375.503-1.039.645-1.48.316-.442-.329-.496-1.005-.121-1.509z"
                fill="#fff"
            />
            <path d="m47.499 60.053c1.122 1.462 2.143 3.066 2.852 4.255.95 1.591 1.811 3.244 2.671 4.895.266.51.216 1.127-.127 1.589l-8.328 11.177c-1.179 1.582-3.639 2.739-6.58 3.435-6.089 1.441-14.243 1.173-14.243 1.173-.628-.021-1.176-.431-1.374-1.027 0 0-2.563-7.756-2.926-13.998-.176-3.016.224-5.698 1.386-7.265 2.768-3.734 8.331-11.181 8.331-11.181.344-.461.921-.684 1.485-.576 1.828.352 3.658.705 5.454 1.16 1.372.347 3.252.881 5.007 1.56l18.706-25.107c-2.532-3.913-2.515-9.15.431-13.105l3.227-4.331c.495-.664 1.435-.801 2.099-.306l16.01 11.928c.664.495.801 1.435.306 2.099l-3.227 4.331c-2.946 3.955-7.96 5.469-12.434 4.162zm-8.245-2.317c-1.39-.485-2.821-.867-3.89-1.138-1.438-.364-2.899-.66-4.362-.944-1.482 1.985-5.531 7.41-7.762 10.42-.843 1.136-.929 3.116-.801 5.304.266 4.575 1.791 10.01 2.462 12.22 2.305.018 7.937-.059 12.395-1.114 2.134-.504 4.01-1.16 4.865-2.308 0 0 7.765-10.422 7.765-10.422-.69-1.32-1.391-2.635-2.151-3.909-.55-.92-1.297-2.138-2.131-3.302l-1.935 2.596c-1.502 2.017-4.159 2.584-5.928 1.265-1.77-1.318-1.986-4.026-.484-6.042zm23.396-38.905c-2.797 3.754-2.021 9.073 1.734 11.87 3.754 2.797 9.072 2.02 11.87-1.734 0 0 2.331-3.128 2.331-3.128s-13.604-10.137-13.604-10.137zm-.834 13.645-22.113 29.678c-.375.504-.321 1.18.121 1.509.441.329 1.105.187 1.48-.316l22.113-29.679c-.281-.173-.556-.361-.826-.562-.27-.2-.528-.411-.775-.63z" />
        </svg>
    );
}

export default ShovelIcon;
