import { type Dispatch, type FormEvent, useEffect, useState, type JSX } from "react";
import { loadJSData } from "../../jsData";
import t from "../../translations";
import type { IAction, IState } from "./state";
import { ButtonRow, FileButton, FileInput, PictureGrid, PictureImg } from "./stylings";

interface IProps {
    state: IState;
    dispatch: Dispatch<IAction>;
}

export function useProfilePictureUrl(file: File | null): string {
    const [objUrl, setObjUrl] = useState<string>("");
    useEffect(() => {
        if (file) {
            const url: string = URL.createObjectURL(file);
            setObjUrl(url);
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [file]);
    return objUrl || "/static/images/profile-silouette.png";
}

function PictureForm({ state, dispatch }: IProps): JSX.Element {
    const [profilePicture, setProfilePicture] = useState(state.values.profilePicture);
    const onNext = () => {
        dispatch({ type: "NEXT-PICTURE", values: { profilePicture } });
    };
    const onBack = () => {
        dispatch({ type: "BACK-PICTURE" });
    };
    const onChange = (event: FormEvent<HTMLInputElement>) => {
        setProfilePicture(event.currentTarget.files[0]);
    };
    const src = useProfilePictureUrl(profilePicture);
    const accept = loadJSData().formats.image_formats.join(",");
    return (
        <div>
            <p>{t("shared.account-wizard.form-picture-info")}</p>
            <PictureGrid>
                <PictureImg src={src} />
                <FileButton>
                    <FileInput type="file" accept={accept} onChange={onChange} value={undefined} />
                    {t("shared.account-wizard.form-picture-label-picture")}
                </FileButton>
            </PictureGrid>
            <ButtonRow>
                <button type="button" onClick={onBack}>
                    {t("shared.account-wizard.form-picture-button-back")}
                </button>
                <button type="button" onClick={onNext}>
                    {t("shared.account-wizard.form-picture-button-next")}
                </button>
            </ButtonRow>
        </div>
    );
}

export default PictureForm;
