import type {
    AnswerType,
    ContentType,
    PollChartType,
    SubmittedFeedbackTaskStatus,
    TaskIcon,
    TaskThreadItemSubtype,
    TaskThreadItemType,
    TimeEstimateType,
    VariantType,
} from "../../shared/types";

// FeedbackTask query

export interface IArgs {
    id: string;
}

export const query = `
    query($id:String!) {
        feedbackTask(id:$id) {
            id
            icon
            miles
            shortTitle
            title
            deadline
            learningPathItem {
                id
                learningPath {
                    id
                    pathname
                }
            }
            submittedFeedbackTask {
                id
                icon
                miles
                shortTitle
                title
                instructions
                status
                finishDatetime
                selectedSubmittedTaskVariant {
                    id
                    submittedTask {
                        id
                        shortTitle
                        objectives
                        inspiration {
                            mediaType
                            src
                        }
                        people {
                            id
                            firstName
                            lastName
                        }
                        title
                        icon
                        miles
                        timeEstimate
                        finishDatetime
                        media {
                            id
                            mediaType
                            src
                            name
                            logo
                        }
                        submittedTaskVariant {
                            id
                            variantType
                            variantTitle
                            description
                            hoursQuestions
                            eventStart
                            eventEnd
                            taskQuestions {
                                id
                                answerType
                                questionContent
                            }
                        }
                        thread {
                            id
                            type
                            subtype
                            contentType
                            content
                            person {
                                id
                                firstName
                                lastName
                                profilePicSrc
                            }
                            taskAnswers {
                                id
                                answerType
                                questionContent
                                answerContent
                                checkboxAnswers {
                                    id
                                    content
                                    checked
                                }
                                taskLikertAnswers {
                                    id
                                    statement
                                    leftLabel
                                    rightLabel
                                    value
                                }
                                showPollResults
                                quizAnswerSet {
                                    id
                                    results
                                    answers {
                                        id
                                        answer
                                        answeredQuestion {
                                            id
                                            number
                                            levelNumber
                                            description
                                            correctAnswer
                                            explanation
                                            quizOptions {
                                                id
                                                letter
                                                content
                                            }
                                        }
                                    }
                                }
                                pollAnswerSet {
                                    id
                                    answers {
                                        id
                                        answers
                                        question {
                                            id
                                            number
                                            content
                                            chartType
                                            options {
                                                id
                                                number
                                                content
                                                answerCount
                                            }
                                        }
                                    }
                                }
                                taskAnswerFile {
                                    id
                                    logoSrc
                                    src
                                    name
                                }
                                impactTrackerAnswerSet {
                                    id
                                    impactLikertAnswers {
                                        id
                                        statement
                                        leftLabel
                                        rightLabel
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

interface IPerson {
    id: string;
    firstName: string;
    lastName: string;
    profilePicSrc: string;
}

interface IQuizAnswer {
    id: string;
    answer: string;
    answeredQuestion: {
        id: string;
        number: number;
        levelNumber: number;
        description: string;
        correctAnswer: string;
        explanation: string | null;
        quizOptions: {
            id: string;
            letter: string;
            content: string;
        }[];
    };
}

interface IQuizAnswerSet {
    id: string;
    results: [number, number];
    answers: IQuizAnswer[];
}

interface IPollAnswer {
    id: string;
    answers: number[];
    question: {
        id: string;
        number: number;
        content: string;
        chartType: PollChartType;
        options: {
            id: string;
            number: number;
            content: string;
            answerCount: number;
        }[];
    };
}

interface IPollAnswerSet {
    id: string;
    answers: IPollAnswer[];
}

interface ITaskAnswerFile {
    id: string;
    src: string;
    logoSrc: string;
    name: string;
}

interface ITaskAnswer {
    id: string;
    answerType: AnswerType;
    questionContent: string | null;
    answerContent: string | null;
    showPollResults: boolean;
    checkboxAnswers: {
        id: string;
        content: string;
        checked: boolean;
    }[];
    taskLikertAnswers: {
        id: string;
        statement: string;
        leftLabel: string;
        rightLabel: string;
        value: number;
    }[];
    quizAnswerSet: IQuizAnswerSet;
    pollAnswerSet: IPollAnswerSet;
    taskAnswerFile: ITaskAnswerFile | null;
    impactTrackerAnswerSet: {
        id: string;
        impactLikertAnswers: {
            id: string;
            statement: string;
            leftLabel: string;
            rightLabel: string;
            value: number;
        }[];
    } | null;
}

interface ITaskThreadItem {
    id: string;
    type: TaskThreadItemType;
    subtype: TaskThreadItemSubtype;
    contentType: ContentType;
    content: string | null;
    person: IPerson;
    taskAnswers: ITaskAnswer[];
}

interface IMedia {
    id: string | number;
    mediaType: string;
    src: string;
    name: string;
    logo: string | null;
}

interface ISubmittedTaskVariant {
    id: string;
    variantType: VariantType;
    variantTitle: string;
    description: string;
    hoursQuestions: string;
    eventStart: string | null;
    eventEnd: string | null;
    taskQuestions: {
        id: string;
        answerType: AnswerType;
        questionContent: string;
    }[];
}

interface IPerson {
    id: string;
    firstName: string;
    lastName: string;
}

export interface ISubmittedTask {
    id: string;
    shortTitle: string;
    objectives: string | null;
    inspiration: {
        mediaType: string;
        src: string;
    } | null;
    people: IPerson[];
    title: string | null;
    finishDatetime: string;
    icon: TaskIcon;
    miles: number;
    timeEstimate: TimeEstimateType | null;
    media: IMedia[];
    submittedTaskVariant: ISubmittedTaskVariant;
    thread: ITaskThreadItem[];
}

interface IBaseFeedbackTask {
    id: string;
    icon: TaskIcon;
    miles: number;
    shortTitle: string;
    title: string | null;
    instructions: string | null;
}

interface ISubmittedFeedbackTask extends IBaseFeedbackTask {
    id: string;
    status: SubmittedFeedbackTaskStatus;
    finishDatetime: string;
    selectedSubmittedTaskVariant: {
        id: string;
        submittedTask: ISubmittedTask;
    };
}

interface IFeedbackTask extends IBaseFeedbackTask {
    deadline: string | null;
    submittedFeedbackTask: ISubmittedFeedbackTask | null;
    learningPathItem: {
        id: string;
        learningPath: {
            id: string;
            pathname: string;
        };
    };
}

export interface IData {
    feedbackTask: IFeedbackTask;
}

// Assing answer mutation

export interface IAssignArgs {
    id: string;
}

export const assignMutation = `
    mutation($id:String!) {
        feedbackTaskAssign(id:$id) {
            error
            feedbackTask {
                id
                submittedFeedbackTask {
                    id
                }
            }
        }
    }
`;

export interface IAssignData {
    feedbackTaskAssign: {
        error: string | null;
        feedbackTask: {
            id: string;
            submittedFeedbackTask: {
                id: string;
            };
        } | null;
    };
}

// Provide feedback mutation

export interface IFeedbackArgs {
    id: string;
    content: string;
}

export const feedbackMutation = `
    mutation($id:String!, $content:String!) {
        provideFeedback(id:$id, content:$content) {
            error
            flipper
            submittedFeedbackTask {
                id
                selectedSubmittedTaskVariant {
                    id
                    submittedTask {
                        id
                        thread {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export interface IFeedbackData {
    provideFeedback: {
        error: string | null;
        flipper: boolean | null;
        submittedFeedbackTask: {
            id: string;
            selectedSubmittedTaskVariant: {
                submittedTask: {
                    id: string;
                    thread: {
                        id: string;
                    }[];
                };
            };
        } | null;
    };
}
