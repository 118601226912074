import type { JSX } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import RenderMark from "../../shared/components/RenderMark";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import type { TaskIcon as TaskIconType } from "../../shared/types";
import { type ITask, taskFinished } from "./query";

interface IProps<T extends ITask> {
    url: string;
    name: string;
    icon: TaskIconType;
    description: string;
    tasks: T[];
}

const Wrapper = styled.div`
    margin: 0 auto;
`;

const borderColor = colors.primaryColor;

const DescriptionSection = styled.section`
    border-radius: 1rem;
    border: 1px solid ${borderColor};
    padding: 0.6rem;
    margin-bottom: 0.5rem;
    @media (min-width: 640px) {
        margin-bottom: 1rem;
        padding: 1rem;
        order: 0;
    }
`;

const TaskBox = styled.a`
    display: grid;
    grid-template-columns: 90px 1fr;
    gap: 10px;
    margin-bottom: 0.5rem;
    color: ${colors.text};
    &:hover, &:active, &:focus, &:visited {
        color: ${colors.text}
    }
    > *:last-child {
        padding-top: 5px;
    }
    svg {
        width: 90px;
        height: 90px;
    }
`;

function taskUrl(task: ITask): string {
    if (task?.submittedTask?.status === "accepted") {
        return task.submittedTask?.url;
    }
    return task.url;
}

function PipDescription<T extends ITask>({ description, tasks }: IProps<T>): JSX.Element {
    return (
        <Wrapper>
            <DescriptionSection>
                {description && <RenderMark content={description} />}
                <div>
                    {tasks.map((task) => (
                        <TaskBox key={task.id} href={taskUrl(task)}>
                            <TaskIcon icon={task.icon} active={taskFinished(task)} />
                            <div>
                                <div>{task.shortTitle}</div>
                                <div>{task.miles}</div>
                                {taskFinished(task) ? (
                                    <div>{t("player.pip.label-finished")}</div>
                                ) : task.deadline ? (
                                    <div>{formatYMD(parseDate(task.deadline))}</div>
                                ) : null}
                            </div>
                        </TaskBox>
                    ))}
                </div>
            </DescriptionSection>
        </Wrapper>
    );
}

export default PipDescription;
