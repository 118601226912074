import { type JSX, memo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as API from "../../../api/player";
import * as colors from "../../../shared/colors";
import GroupTaskIcon from "../../../shared/components/icons/GroupTaskIcon";
import getInt from "../../../shared/getInt";
import { loadJSData } from "../../../shared/jsData";
import t from "../../../shared/translations";
import TaskThreadItemAnswer from "../../components/TaskThreadItemAnswer";
import { type Dispatch, editTaskAnswer } from "../../state";
import { PathGroupPreviewBox } from "../answerSection/PathGroupPreview";
import type { IPathGroup, ISubmittedTask } from "../query";
import { Box } from "../stylings";
import ApprovalItem from "./ApprovalItem";

interface IProps {
    task: ISubmittedTask;
    pathGroup: IPathGroup;
}

const ApprovalsBox = styled(PathGroupPreviewBox)`
    background-color: ${colors.secondaryColor};
    border-color: ${colors.primaryColor};
`;

const ApproveHeader = styled.h4`
    width: 100%;
    margin-top: 2rem;
    text-align: center;
`;

const ApproveButton = styled.div`
    width: 100%;
    margin-top: 1rem;
    text-transform: uppercase;
    text-align: center;

    button {
        margin-bottom: 0;
    }
`;

const H3 = styled.h3`
    display: grid;
    grid-template-columns: 26px 1fr;
    align-items: center;
    gap: 0.5rem;
    svg {
        width: 26px;
        height: 26px;
    }
`;

function ApprovalSection({ task, pathGroup }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const [approving, setApproving] = useState(false);

    const approve = async () => {
        try {
            setApproving(true);
            const result = await API.Task.approveTask(getInt(task.id));
            window.location.href = result.redirect;
        } catch {
            setApproving(false);
            return;
        }
    };

    const editAnswer = () => {
        const ttis = task.thread.filter((tti) => tti.type === "unapproved-answer");
        const tti = ttis[ttis.length - 1];
        if (!tti) {
            return;
        }
        dispatch(editTaskAnswer(tti));
    };

    const tti = task.thread.find((item) => item.type === "unapproved-answer");
    const approvers = task.people;
    const currentMembership = pathGroup.members.find((m) => m.person.id === loadJSData().current_person.id);
    const showApproval = !task.taskApprovals.some((appr) => currentMembership.id === appr.membership.id);

    return (
        <>
            <Box>
                <H3>
                    <GroupTaskIcon active />
                    {t("player.task.heading-path-group-task")}
                </H3>
                <p>{t("player.task.info-awaiting-path-group-approval")}</p>
            </Box>
            <ApprovalsBox>
                <TaskThreadItemAnswer tti={tti} boxStyle={{ width: "100%" }} />
                <ApproveHeader>{t("player.task.heading-approve")}</ApproveHeader>
                {approvers.map((person) => (
                    <ApprovalItem
                        key={person.id}
                        person={person}
                        approval={task.taskApprovals.find((appr) => appr.membership.person.id === person.id)}
                    />
                ))}
                {showApproval && (
                    <ApproveButton>
                        <button onClick={approve} disabled={approving} type="button">
                            {t("player.task.button-approve")}
                        </button>
                    </ApproveButton>
                )}
            </ApprovalsBox>
            <div className="text-center">
                <button onClick={editAnswer} type="button">
                    {t("player.task.button-edit")}
                </button>
            </div>
        </>
    );
}

export default memo(ApprovalSection);
