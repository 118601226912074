import { HtmlRenderer, Parser } from "commonmark";
import { type ComponentPropsWithoutRef, memo } from "react";
import type { JSX } from "react";

const parser = new Parser();
const renderer = new HtmlRenderer();

interface IOwnProps {
    content: string | null;
}

type IProps = IOwnProps & ComponentPropsWithoutRef<"div">;

function replacer(match: string): string {
    const lastChar = match[match.length - 1];
    if (lastChar !== "/") {
        return `<a target="_blank" href="${lastChar}`;
    }
    return match;
}

function RenderMark({ content, ...props }: IProps): JSX.Element {
    if (content) {
        let htmlStr = renderer.render(parser.parse(content));
        htmlStr = htmlStr.replace(/<a href=".{1}/g, replacer);
        const html = {
            __html: htmlStr,
        };
        // biome-ignore lint/security/noDangerouslySetInnerHtml: Rendered markdown is safe
        return <div dangerouslySetInnerHTML={html} {...props} />;
    }
    return <div {...props} />;
}

export default memo(RenderMark);
