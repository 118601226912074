import type { JSX } from "react";
import styled from "styled-components";
import ProfileImg from "../shared/components/ProfileImg";
import t from "../shared/translations";
import Box from "./Box";

interface IProps {
    firstName: string;
    lastName: string;
    profilePicSrc: string | null;
}

const ProfileBox = styled(Box)`
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    > *:first-child {
        font-size: 1.2rem;
        @media (min-width: 600px) {
            font-size: 1.5rem;
        }
    }
`;

function Sender({ firstName, lastName, profilePicSrc }: IProps): JSX.Element {
    const name = `${firstName} ${lastName}`;
    return (
        <ProfileBox>
            <div>{t("shared.collect-feedback.info-sender-name", { name })}</div>
            <div>
                <ProfileImg src={profilePicSrc} />
            </div>
        </ProfileBox>
    );
}

export default Sender;
