import RenderMark from "../../../shared/components/RenderMark";
import PlusMinus from "../../../shared/components/icons/PlusMinus";
import useScrollIntoView from "../../../shared/hooks/useScrollIntoView";
import t from "../../../shared/translations";
import { Accordion, AccordionHead } from "../stylings/accordion";
import type { IAccordion } from "./Theory";
import type { JSX } from "react";

interface IProps {
    content: string;
    open: boolean;
    toggle: (acc: IAccordion) => void;
}

function TPS({ content, open, toggle }: IProps): JSX.Element {
    const ref = useScrollIntoView<HTMLDivElement>(open);
    return (
        <Accordion ref={ref}>
            <AccordionHead onClick={() => toggle("TPS")}>
                <h2 id="tps">{t("player.team-scan-theory.heading-tps")}</h2>
                <PlusMinus open={open} />
            </AccordionHead>
            {open && <RenderMark content={content} />}
        </Accordion>
    );
}

export default TPS;
