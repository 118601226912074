import { type JSX, useEffect } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";

interface IProps {
    setCopied: (value: boolean) => void;
}

const Holder = styled.div`
    display: grid;
    place-items: center;
    color: ${colors.primaryColor};
`;

function CopiedText({ setCopied }: IProps): JSX.Element {
    useEffect(() => {
        const timer = setTimeout(() => setCopied(false), 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [setCopied]);

    return <Holder>{t("player.submitted-task.info-outside-feedback-link-copied")}</Holder>;
}

export default CopiedText;
