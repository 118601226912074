import { Navigate } from "react-router-dom";
import type { ITeamScan } from "../query";
import type { JSX } from "react";

interface IProps {
    teamScan: ITeamScan;
    taskId: string;
}

function Redirector({ teamScan }: IProps): JSX.Element {
    switch (teamScan.status) {
        case "setup-survey":
            return <Navigate to={`team-scans/setup/${teamScan.id}`} />;
        case "do-survey":
            return <Navigate to={`team-scans/do-survey/${teamScan.id}`} />;
        case "waiting-for-responses":
            return <Navigate to={`team-scans/progress/${teamScan.id}`} />;
        case "reporting":
            return <Navigate to={`team-scans/report/${teamScan.id}`} />;
    }
}

export default Redirector;
