import clamp from "lodash-es/clamp";
import padStart from "lodash-es/padStart";
import { loadJSData } from "./jsData";

function lightenColor(color: string, amount: number): string {
    // From https://css-tricks.com/snippets/javascript/lighten-darken-color/
    const colorHex = color.slice(1);
    const num = Number.parseInt(colorHex, 16);

    let r = ((num & 0xff0000) >> 16) + amount;
    r = clamp(r, 0, 255);

    let b = ((num & 0x00ff00) >> 8) + amount;
    b = clamp(b, 0, 255);

    let g = (num & 0x0000ff) + amount;
    g = clamp(g, 0, 255);

    let numStr = (g | (b << 8) | (r << 16)).toString(16);

    numStr = padStart(numStr, 6, "0");

    return `#${numStr}`;
}

export const primaryColor = loadJSData().settings.primary_color;
export const secondaryColor = loadJSData().settings.secondary_color;

export const altPrimaryColor = loadJSData().settings.alt_primary_color;
export const altSecondaryColor = loadJSData().settings.alt_secondary_color;

export const text = "rgb(34, 34, 34)";
export const altText = "white";

export const deadline = "#ce4a99";
export const rejected = "#ce4a99";
export const active = primaryColor;
export const finished = primaryColor;

export const primaryDarker = lightenColor(primaryColor, -15);
export const primaryLighter = lightenColor(primaryColor, 25);
export const unlight = "#5C5C5C";
export const alert = "#FF4F57";
export const success = "#1F9B1D";
export const alertDarker = lightenColor(alert, -15);

export const answer = primaryColor;
export const answerBackground = secondaryColor;
export const feedback = primaryColor;
export const response = answer;

export const star = "#f4c359";

export const status = {
    green: "#52FB52",
    yellow: "#FAFA29",
    red: "#FF0103",
    white: "#F0F0F0",
};

export const calendar = {
    callToAction: {
        background: "#AADEBD",
        border: "#26633D",
    },
    feedbackCallToAction: {
        background: "#AADEBD",
        border: "#26633D",
    },
    deadline: {
        background: "#FFE8E9",
        border: "#E8000B",
    },
    feedbackDeadline: {
        background: "#FFE8E9",
        border: "#E8000B",
    },
    taskNotification: {
        background: "#8CD2FF",
        border: "#00568C",
    },
    taskChoiceNotification: {
        background: "#8CD2FF",
        border: "#00568C",
    },
    pathNotification: {
        background: "#8CD2FF",
        border: "#00568C",
    },
    taskEvent: {
        background: "#d7dbe2",
        border: "#3d4655",
    },
    event: {
        background: "#d7dbe2",
        border: "#3d4655",
    },
    unlock: {
        background: "#f2d1e5",
        border: "#ce4a99",
    },
    publish: {
        background: "#ECD7FB",
        border: "#911AE5",
    },
};

export const runner = "#8c8c8c";

export const lightBorder = "#a2aaad";
export const tableHeader = "#e8e8e8"; // #fafafa #d6d6d6
export const tableHeaderFocus = lightenColor(tableHeader, -15);

export const white = "#ffffff";
export const black = "#000000";

export const checkmarkGreen = "#00bd00";
export const checkmarkGrey = "#929292";
export const checkmarkRed = "#ff0000";

export const talentmiles = {
    primaryBlue: "#0075be",
    otherBlue: "#009ad2",
    paleBlue: "#dffaff",
    pink: "#ce4a99",
    darkGrey: "#3d4655",
};

export const gradient = {
    red: "#f33232",
    yellow: "#fde543",
    green: "#3db626",
};

export const quiz = {
    selectedColor: "#00B8F1",
    currentLevelColor: "#0075BF",
    levelColor: "#33A8F2",
    rightAnswerColor: "#06AF06",
    wrongAnswerColor: "#FF0103",
};

export const builder = {
    miles: "#009AD2",
};

export const theme = loadJSData().task_data.theme_colors;

export const chartLines = {
    checkpoint: unlight,
    goal: rejected,
    deadline: alert,
};

export const chartColors = [
    "#CE4B99",
    "#00487C",
    "#2E725A",
    "#A6533D",
    "#84C54D",
    "#F17105",
    "#FB444B",
    "#0075BF",
    "#A57204",
    "#9465AB",
    "#FDC243",
    "#FFBBF6",
    "#6D164D",
    "#9E0031",
    "#86BBD8",
    "#52B79F",
    "#F08B66",
];

export function chart(i: number): string {
    return chartColors[i % chartColors.length];
}

// export const theme = {
//     'customer': '#CE4B99',
//     'leadership': '#0075BF',
//     'onboarding': '#00487C',
//     'self-leadership': '#FFBBF6',
//     'strategy': '#2E725A',
//     'teamwork': '#A6533D',
//     // 'customer-content': '#84C54D',
//     // 'feedback': '#F17105',
//     // 'learning-capacity': '#FB444B',
//     // 'networking': '#A57204',
//     // 'personal-insights': '#9465AB',
//     // 'problem-solving': '#FDC243',
//     // 'psychological-safety': '#6D164D',
//     // 'purpose': '#9E0031',
//     // 'storytelling': '#86BBD8',
//     // 'virtual-leadership': '#52B79F',
//     // 'well-being': '#F08B66',
// };
