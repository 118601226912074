import { type ComponentPropsWithoutRef, type JSX, memo } from "react";
import styled, { css, keyframes } from "styled-components";

const dotAnimation = keyframes`
     0% { transform: scale(1,.7); }
    20% { transform: scale(.7,1.2); }
    40% { transform: scale(1,1);}
    50% { bottom: 97px;}
    46% { transform: scale(1,1);}
    80% { transform: scale(.7,1.2);}
    90% { transform: scale(.7,1.2);}
    100% { transform: scale(1,0.7);}
`;

const stepAnimation = keyframes`
    0% {
        opacity: 0;
        top: 0;
        right: 0;
    }
    50% { opacity: 1; }
    100% {
        top: 88px;
        right: 88px;
        opacity: 0;
    }
`;

const OuterContainer = styled.div`
    position: relative;
    min-height: 140px;
`;

const InnerContainer = styled.div`
    margin: 44px -58px;
    width: 117px;
    height: 88px;
    position: absolute;
    left: 50%;
`;

const Dot = styled.div`
    background: rgb(0,0,0);
    border-radius: 50%;
    width: 29px;
    height: 29px;
    position: absolute;
    bottom: 29px;
    left: 26px;
    transform-origin: center bottom;
    animation: ${dotAnimation} 0.69s ease-in-out infinite;
`;

const step = css`
    position: absolute;
    width: 29px;
    height: 29px;
    border-top: 2px solid rgb(0,0,0);
    top: 0;
    right:0;
`;

const Step1 = styled.div`
    ${step}
    animation: ${stepAnimation} 2.07s linear infinite;
`;

const Step2 = styled.div`
    ${step}
    animation: ${stepAnimation} 2.07s linear infinite -0.69s;
`;

const Step3 = styled.div`
    ${step}
    animation: ${stepAnimation} 2.07s linear infinite -1.38s;
`;

function Loader(props: ComponentPropsWithoutRef<"div">): JSX.Element {
    return (
        <OuterContainer {...props}>
            <InnerContainer>
                <Dot />
                <Step1 />
                <Step2 />
                <Step3 />
            </InnerContainer>
        </OuterContainer>
    );
}

export default memo(Loader);
