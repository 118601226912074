import type { ComponentPropsWithoutRef, JSX } from "react";

function MarkUnreadIcon(props: ComponentPropsWithoutRef<"svg">): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g fill="none">
                <g stroke="#222" strokeWidth="3">
                    <path d="m27.335 66.309c-8.42-3.657-15.787-9.289-21.516-16.309 10.456-12.813 26.37-21 44.181-21 4.515 0 8.909.526 13.122 1.52m9.579 3.187c8.405 3.657 15.76 9.283 21.48 16.293-10.456 12.813-26.37 21-44.181 21-4.495 0-8.868-.521-13.064-1.507" />
                    <path d="m35.574 58.009c-1.321-2.372-2.074-5.103-2.074-8.009 0-9.107 7.393-16.5 16.5-16.5 2.94 0 5.702.771 8.093 2.121m6.36 6.419c1.305 2.36 2.047 5.074 2.047 7.96 0 9.107-7.393 16.5-16.5 16.5-2.931 0-5.684-.766-8.07-2.108" />
                    <path d="m20 80 60-60" />
                </g>
            </g>
        </svg>
    );
}

export default MarkUnreadIcon;
