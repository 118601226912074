import type { JSX } from "react";
import { type UseFieldArrayConfig, useFieldArray } from "react-final-form-arrays";
import Checkbox from "../../components/FinalCheckbox";

export interface ICheckboxAnswer {
    content: string;
    checked: boolean;
}

interface ICheckboxes {
    name: string;
}

type IProps = ICheckboxes & UseFieldArrayConfig<ICheckboxAnswer>;

function Checkboxes({ name, ...props }: IProps): JSX.Element {
    const {
        fields,
        meta: { error, invalid, touched },
    } = useFieldArray(name, props);
    return (
        <div>
            {fields.map((answer, index) => (
                <div key={index}>
                    <Checkbox name={`${answer}.checked`} label={fields.value[index].content} />
                </div>
            ))}
            {touched && invalid && typeof error === "string" && <div className="error">{error}</div>}
        </div>
    );
}

export default Checkboxes;
