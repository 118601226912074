import type { JSX } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import getLocale from "../../shared/getLocale";
import t from "../../shared/translations";
import ShowError from "../components/ShowError";
import PulseForm, { type IFormValues } from "./PulseForm";
import { type ICreateArgs, type ICreateData, type IQuestionsData, createMutation, questionsQuery } from "./queries";

function Setup(): JSX.Element {
    const [createResult, executeCreate] = useMutation<ICreateData, ICreateArgs>(createMutation);
    const navigate = useNavigate();
    const [result] = useQuery<IQuestionsData>({ query: questionsQuery });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const onSubmit = async (values: IFormValues) => {
        if (createResult.fetching) {
            return;
        }
        const args: ICreateArgs = {
            data: {
                name: values.name || null,
                locale: getLocale(),
                question1: values.question1,
                question2: values.question2,
            },
        };
        const result = await executeCreate(args);
        if (result.error || result.data.pulseCreate?.error) {
            console.error(result.error || result.data.pulseCreate?.error);
        } else {
            const id = result.data.pulseCreate.pulse.id;
            navigate(`../pulse/${id}/results`);
        }
    };

    return (
        <>
            <h1>{t("player.pulse-setup.heading")}</h1>
            <PulseForm onSubmit={onSubmit} pulseSuggestedQuestions={result.data.pulseSuggestedQuestions} />
        </>
    );
}

export default Setup;
