// Query all AI chatbots

import type { AiMessageRoleType } from "../../shared/types";

export const listQuery = `
    query {
        currentPerson {
            id
            standaloneAiChatbots {
                id
                name
                description
            }
        }
    }
`;

export interface IListData {
    currentPerson: {
        id: string;
        standaloneAiChatbots: {
            id: string;
            name: string;
            description: string;
        }[];
    };
}

// Start thread mutation

export interface IStartArgs {
    id: string;
}

export const startMutation = `
    mutation($id:String!) {
        standaloneAiThreadCreate(id:$id) {
            error
            standaloneAiThread {
                id
            }
        }
    }
`;

export interface IStartData {
    standaloneAiThreadCreate: {
        error: string | null;
        standaloneAiThread: {
            id: string;
        } | null;
    };
}

// Continue thread mutation

export interface IContinueArgs {
    id: string;
    content: string;
}

export const continueMutation = `
    mutation($id:String!,$content:String!) {
        standaloneAiThreadContinue(id:$id,content:$content) {
            error
            standaloneAiThread {
                id
                aiThreadItems {
                    id
                    role
                    content
                }
            }
        }
    }
`;

export interface IContinueData {
    standaloneAiThreadContinue: {
        error: string | null;
        standaloneAiThread: {
            id: string;
            aiThreadItems: {
                id: string;
                role: AiMessageRoleType;
                content: string;
            }[];
        } | null;
    };
}

// Query Ai Thread

export interface IThreadArgs {
    id: string;
}

export const threadQuery = `
    query($id:String!) {
        standaloneAiThread(id:$id) {
            id
            aiThreadItems {
                id
                role
                content
            }
            standaloneAiChatbot {
                id
                name
                description
            }
        }
    }
`;

export interface IThreadData {
    standaloneAiThread: {
        id: string;
        aiThreadItems: {
            id: string;
            role: AiMessageRoleType;
            content: string;
        }[];
        standaloneAiChatbot: {
            id: string;
            name: string;
            description: string;
        };
    };
}

// Query Ai Thread list

export const threadListQuery = `
    query {
        currentPerson {
            id
            standaloneAiThreads {
                id
                created
                aiThreadItems {
                    id
                    role
                    content
                }
                standaloneAiChatbot {
                    id
                    name
                }
            }
        }
    }
`;

export interface IThreadListData {
    currentPerson: {
        id: string;
        standaloneAiThreads: {
            id: string;
            created: string;
            aiThreadItems: {
                id: string;
                role: AiMessageRoleType;
                content: string;
            }[];
            standaloneAiChatbot: {
                id: string;
                name: string;
            };
        }[];
    };
}
