import { useFieldArray, type UseFieldArrayConfig } from "react-final-form-arrays";
import SliderInput from "../../../shared/components/SliderInput";
import { Box } from "../stylings";
import type { JSX } from "react";

export interface IImpactAnswer {
    statement: string;
    leftLabel: string;
    rightLabel: string;
    value: number | null;
}

interface IOwnProps {
    name: string;
}

type IProps = IOwnProps & UseFieldArrayConfig<IImpactAnswer>;

function ImpactAnswers({ name, ...props }: IProps): JSX.Element {
    const {
        fields,
        meta: { error, invalid, touched },
    } = useFieldArray(name, props);
    return (
        <Box>
            {fields.map((answer, index) => (
                <div key={fields.value[index].statement}>
                    <SliderInput
                        name={`${answer}.value`}
                        statement={fields.value[index].statement}
                        leftLabel={fields.value[index].leftLabel}
                        rightLabel={fields.value[index].rightLabel}
                        showValue
                    />
                </div>
            ))}
            {touched && invalid && typeof error === "string" && <div className="error">{error}</div>}
        </Box>
    );
}

export default ImpactAnswers;
