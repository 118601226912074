import { useEffect, useState, type JSX } from "react";
import styled from "styled-components";
import t from "../../translations";
import Loader from "../Loader";

const aiMessages = [
    t("shared.ai-loader.msg-1"),
    t("shared.ai-loader.msg-2"),
    t("shared.ai-loader.msg-3"),
    t("shared.ai-loader.msg-4"),
    t("shared.ai-loader.msg-5"),
    t("shared.ai-loader.msg-6"),
];

const Wrapper = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-rows: 150px auto;
    grid-template-columns: auto;
    align-items: center;
`;

const Msg = styled.div`
    font-size: 1.5rem;
    text-align: center;
`;

function AiLoader(): JSX.Element {
    const [loaderMsg, setLoaderMsg] = useState(aiMessages[0]);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoaderMsg(aiMessages[Math.floor(Math.random() * aiMessages.length)]);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Wrapper>
            <Loader />
            <Msg>{loaderMsg}</Msg>
        </Wrapper>
    );
}

export default AiLoader;
