import { type JSX, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import t from "../../../shared/translations";

const Wrapper = styled.div`
    display: grid;
    place-items: center;
`;

function SendReminderSuccess(): JSX.Element {
    const navigate = useNavigate();
    useEffect(() => {
        const timerId = setTimeout(() => {
            navigate("..", { relative: "path" });
        }, 5000);
        return () => {
            clearTimeout(timerId);
        };
    }, [navigate]);

    return (
        <Wrapper>
            <h1>{t("player.team-scan-resend-success.info-success")}</h1>
        </Wrapper>
    );
}

export default SendReminderSuccess;
