interface HasID {
    id: number;
}

export interface IdDict<T> {
    [index: number]: T;
}

export function listToIdDict<T extends HasID>(data: T[]): IdDict<T> {
    const dict: IdDict<T> = {};
    for (const d of data) {
        dict[d.id] = d;
    }
    return dict;
}
