import { useEffect, type JSX } from "react";
import { useMutation } from "urql";
import Inspiration from "./Inspiration";
import { type IData, type IMarkReadArgs, type IMarkReadData, markReadMutation } from "./query";

interface IProps {
    inspirations: IData["learningPath"]["inspirations"];
}

function InspList({ inspirations }: IProps): JSX.Element {
    const [_, executeMutation] = useMutation<IMarkReadData, IMarkReadArgs>(markReadMutation);

    useEffect(() => {
        const ids = inspirations.filter((insp) => !insp.read).map((insp) => insp.id);
        if (ids.length === 0) {
            return;
        }
        void executeMutation({ ids });
    }, [executeMutation, inspirations]);

    return (
        <>
            {inspirations.map((insp) => (
                <Inspiration inspiration={insp} key={insp.id} />
            ))}
        </>
    );
}

export default InspList;
