import { type ComponentPropsWithoutRef, memo, type JSX } from "react";

type IProps = ComponentPropsWithoutRef<"svg">;

function NavArrow(props: IProps): JSX.Element {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path d="M30 10 L25 16 L60 50 L25 84 L30 90 L70 50" />
        </svg>
    );
}

export default memo(NavArrow);
