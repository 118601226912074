import { useField, type UseFieldConfig } from "react-final-form";
import { ErrorBox, Label, Input as StyledInput } from "./stylings";
import type { JSX } from "react";

interface IOwnProps {
    label: string;
    name: string;
    required?: boolean;
}

type IProps<T> = IOwnProps & UseFieldConfig<T>;

function Input<T = string>({ name, label, required, ...props }: IProps<T>): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField<any, HTMLInputElement>(name, props);
    return (
        <div>
            <Label $error={error && touched}>
                {label}
                {required ? "*" : ""}:
                <StyledInput $error={!!error && touched} type="text" {...input} />
                {touched && error && <ErrorBox>{error}</ErrorBox>}
            </Label>
        </div>
    );
}

export default Input;
