import MessageFormat from "messageformat";
import getLocale from "./getLocale";

type Translator = (id: string, data?: any) => string;

function setupTranslations(): Translator {
    const data = JSON.parse(document.getElementById("translation-data").innerHTML);
    const mf = new MessageFormat(getLocale());
    const translations = mf.compile(data);
    return (id: string, data?: any): string => {
        const translation: (data: any) => string | undefined = translations[id];
        if (translation === undefined) {
            return id;
        }
        return translation(data);
    };
}

const t = setupTranslations();

export default t;
