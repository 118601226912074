import { type JSX, memo } from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import type { AppRatingType } from "../../shared/types";
import LockedModal, { useLockedModal } from "../LockedModal";
import ShowError from "../components/ShowError";
import SendButton from "../messages/SendButton";
import WithTasksSidebar, { type IPathWithItems, SMALL_MOBILE_CUTOFF, pathWithItemsFragment } from "../tasksSidebar";
import Info from "./Info";
import PathProgress from "./PathProgress";
import RatingButton from "./RatingButton";
import StreetPath from "./StreetPath";
import { Center } from "./stylings";

interface IArgs {
    id: string;
    hurray: boolean;
}

const query = `
    ${pathWithItemsFragment}
    query($id:String!,$hurray:Boolean!) {
        learningPath(id:$id) {
            id
            pathname
            info
            avatarSrc(hurray:$hurray)
            goal
            appRatingConfig
            showRatingAfterTasks
            showParticipants
            pathGroup {
                id
                name
                number
                members {
                    id
                    person {
                        id
                        firstName
                        lastName
                        profilePicSrc
                    }
                }
            }
            learningCoaches {
                id
                firstName
                lastName
                profilePicSrc
            }
            participants {
                id
                firstName
                lastName
                profilePicSrc
            }
            ...PathWithItems
        }
    }
`;

export interface IData {
    learningPath: {
        id: string;
        pathname: string;
        info: string;
        goal: number;
        avatarSrc: string;
        appRatingConfig: AppRatingType;
        showRatingAfterTasks: number | null;
        showParticipants: boolean;
        pathGroup: {
            id: string;
            name: string | null;
            number: number;
            members: {
                id: string;
                person: {
                    id: string;
                    firstName: string;
                    lastName: string;
                    profilePicSrc: string;
                };
            }[];
        } | null;
        learningCoaches: {
            id: string;
            firstName: string;
            lastName: string;
            profilePicSrc: string;
        }[];
        participants:
            | {
                  id: string;
                  firstName: string;
                  lastName: string;
                  profilePicSrc: string;
              }[]
            | null;
    } & IPathWithItems;
}

interface IProps {
    id: string;
    hurray: boolean;
}

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1440px;
    width: 100%;
`;

function Landing({ id, hurray }: IProps): JSX.Element {
    const { width } = useWindowSize();
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id, hurray },
    });

    if (result.fetching) {
        return (
            <Wrapper style={{ height: 200 }}>
                <Loader />
            </Wrapper>
        );
    }
    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }
    const { learningPath } = result.data;
    if (learningPath == null) {
        return <Wrapper />;
    }
    let miles = learningPath.learningPathItems
        .filter((item) => item.complete)
        .reduce((acc, item) => acc + item.earnedMiles, 0);
    miles =
        miles +
        learningPath.customItems.filter((item) => item.complete).reduce((acc, item) => acc + item.earnedMiles, 0);
    const docPath = ["player/landing/tasks"];
    if (loadJSData().features.custom_tasks) {
        docPath.push("player/landing/tasks-custom");
    }
    const showRating = learningPath.appRatingConfig !== "hide";
    const includeRatingFeedback = learningPath.appRatingConfig === "star_text";
    const ratingPopup =
        hurray &&
        learningPath.showRatingAfterTasks <= learningPath.learningPathItems.filter((item) => item.complete).length;
    let main = null;
    if (width < SMALL_MOBILE_CUTOFF) {
        main = (
            <Wrapper>
                <WithTasksSidebar
                    openLockedModal={openLockedModal}
                    path={learningPath}
                    mainLabel={t("player.landing.tab-path")}
                    big={false}
                >
                    <StreetPath
                        path={learningPath}
                        avatarSrc={learningPath.avatarSrc}
                        openLockedModal={openLockedModal}
                    />
                    <PathProgress miles={miles} milesGoal={learningPath.goal} />
                </WithTasksSidebar>
                <Center $direction="column">
                    <SendButton />
                    {showRating && <RatingButton includeFeedback={includeRatingFeedback} popup={ratingPopup} />}
                </Center>
                <Info path={learningPath} />
            </Wrapper>
        );
    } else {
        main = (
            <Wrapper>
                <WithTasksSidebar
                    openLockedModal={openLockedModal}
                    path={learningPath}
                    mainLabel={t("player.landing.tab-path")}
                    big={false}
                >
                    {showRating && (
                        <RatingButton
                            includeFeedback={includeRatingFeedback}
                            popup={ratingPopup}
                            style={{ position: "absolute", top: "1rem", right: 0, zIndex: 1 }}
                        />
                    )}
                    <StreetPath
                        path={learningPath}
                        avatarSrc={learningPath.avatarSrc}
                        openLockedModal={openLockedModal}
                    />
                    <PathProgress miles={miles} milesGoal={learningPath.goal} />
                    <Center $direction="row">
                        <SendButton padding="0.5rem 1rem .5325rem 1rem" />
                    </Center>
                    <Info path={learningPath} />
                </WithTasksSidebar>
            </Wrapper>
        );
    }
    return (
        <>
            {main}
            <LockedModal {...lockedProps} />
        </>
    );
}

export default memo(Landing);
