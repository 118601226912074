import type { ValidationErrors } from "final-form";
import { Form } from "react-final-form";
import t from "../../../shared/translations";
import Textarea from "../../components/FinalTextarea";
import { Button, NavButton, NavButtonRow, NextArrow, PrevArrow } from "../stylings";
import type { JSX } from "react";

export interface IFormValues {
    outsideFeedbackDescription: string;
}

interface IProps {
    onSubmit: (values: IFormValues) => Promise<void>;
    redo: boolean;
    initialValues: IFormValues;
    invert: boolean;
    back: (values: IFormValues) => void;
    preview?: (values: IFormValues) => void;
}

function validate(values: IFormValues, redo: boolean): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!redo && !values.outsideFeedbackDescription) {
        errors.outsideFeedbackDescription = t("player.task.error-outside-feedback-description-required");
    }
    return errors;
}

function OutsideFeedbackForm({ redo, onSubmit, initialValues, invert, back, preview }: IProps): JSX.Element {
    return (
        <Form<IFormValues>
            onSubmit={onSubmit}
            validate={(values) => validate(values, redo)}
            initialValues={initialValues}
        >
            {({ handleSubmit, submitting, invalid, values }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <h3>{t("player.task.heading-label-outside-feedback-description")}</h3>
                        <Textarea name="outsideFeedbackDescription" />
                        <NavButtonRow>
                            <NavButton $prev={true} $invert={invert} type="button" onClick={() => back(values)}>
                                {PrevArrow} {t("player.task.button-prev")}
                            </NavButton>
                            <Button type="submit" disabled={submitting || invalid} $invert={invert} $noMargin>
                                {t("player.task.button-submit")}
                            </Button>
                            {preview && (
                                <NavButton $prev={false} type="button" $invert={invert} onClick={() => preview(values)}>
                                    {t("player.task.button-preview")} {NextArrow}
                                </NavButton>
                            )}
                        </NavButtonRow>
                    </form>
                );
            }}
        </Form>
    );
}

export default OutsideFeedbackForm;
