import type { ReactNode, JSX } from "react";
import OrigianlModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { type Dispatch, type Modal as IModal, type State, closeModal } from "../state";

OrigianlModal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.45)";
OrigianlModal.defaultStyles.overlay.zIndex = 3;

interface IProps {
    modal: IModal;
    children: ReactNode;
    onClose?: VoidFunction;
}

function Modal({ modal, children, onClose, ...props }: IProps): JSX.Element {
    const currentModal = useSelector((state: State) => state.modal.open);
    const dispatch = useDispatch<Dispatch>();

    const close = () => {
        dispatch(closeModal());
        if (onClose) {
            onClose();
        }
    };

    return (
        <OrigianlModal
            isOpen={modal === currentModal}
            onRequestClose={close}
            contentLabel={currentModal}
            className="styled-modal"
            {...props}
        >
            <div className="content-wrapper">{modal === currentModal && children}</div>
            <span onClick={close} className="close-cross">
                ×
            </span>
        </OrigianlModal>
    );
}

export default Modal;
