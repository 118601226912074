import type { Dispatch, JSX } from "react";
import getInt from "../../getInt";
import t from "../../translations";
import type { IAction, IPage, IState } from "./state";
import { Nav, NavItem } from "./stylings";

interface IProps {
    state: IState;
    dispatch: Dispatch<IAction>;
}

function idxToPage(idx: number): IPage {
    switch (idx) {
        case 1:
            return "1-profile";
        case 2:
            return "2-password";
        case 3:
            return "3-terms";
        case 4:
            return "4-avatar";
        case 5:
            return "5-picture";
        case 6:
            return "6-review";
        default:
            throw new Error("Unknown page");
    }
}

function NavBar({ state, dispatch }: IProps): JSX.Element {
    const currNumber = getInt(state.page.split("-")[0]);
    const goTo = (idx: number) => {
        if (currNumber < idx) {
            return;
        }
        dispatch({ type: "GO-TO", page: idxToPage(idx) });
    };

    return (
        <Nav>
            <NavItem $disabled={currNumber < 1} onClick={() => goTo(1)}>
                {t("shared.account-wizard.nav-bar-1-profile")}
            </NavItem>
            <NavItem $disabled={currNumber < 2} onClick={() => goTo(2)}>
                {t("shared.account-wizard.nav-bar-2-password")}
            </NavItem>
            <NavItem $disabled={currNumber < 3} onClick={() => goTo(3)}>
                {t("shared.account-wizard.nav-bar-3-terms")}
            </NavItem>
            <NavItem $disabled={currNumber < 4} onClick={() => goTo(4)}>
                {t("shared.account-wizard.nav-bar-4-avatar")}
            </NavItem>
            <NavItem $disabled={currNumber < 5} onClick={() => goTo(5)}>
                {t("shared.account-wizard.nav-bar-5-picture")}
            </NavItem>
            <NavItem $disabled={currNumber < 6} onClick={() => goTo(6)}>
                {t("shared.account-wizard.nav-bar-6-review")}
            </NavItem>
        </Nav>
    );
}

export default NavBar;
