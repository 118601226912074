import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function ForkIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m12.497 55.159v-10.318h22.023c.068.001.136.002.204.002 4.051 0 7.542-2.414 9.114-5.88.104-.229.2-.463.287-.701 1.876-4.355 5.255-7.913 9.484-10.018 2.679-1.334 5.699-2.085 8.894-2.085h13.59l-4.09-6h8l7.5 11-7.5 11h-8l4.09-6h-13.59c-4.064 0-7.564 2.429-9.128 5.913-.093.207-.179.417-.258.631-1.211 2.826-3.055 5.318-5.353 7.297 2.298 1.979 4.142 4.471 5.353 7.297.079.214.165.424.258.631 1.564 3.484 5.064 5.913 9.128 5.913h13.59l-4.09-6h8l7.5 11-7.5 11h-8l4.09-6h-13.59c-3.195 0-6.215-.751-8.894-2.085-4.229-2.105-7.608-5.663-9.484-10.018-.087-.238-.183-.472-.287-.701-1.572-3.466-5.063-5.88-9.114-5.88-.068 0-.136.001-.204.002z"
                fill={active ? "#ff855d" : "#fff"}
                stroke="#222"
                strokeWidth="3"
            />
        </svg>
    );
}

export default ForkIcon;
