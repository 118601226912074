import type { ComponentPropsWithoutRef } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function BirdsIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m49.7 83.6 0 0c-.5 0-1-.2-1.2-.7-3.6-5.4-9.6-8.6-16.1-8.6-5.4 0-10.5 2.2-14.2 6.2-.5.5-1.3.6-1.9.3-.6-.4-.9-1.1-.7-1.8 2.6-8.2 10.2-13.7 18.9-13.7 5.9 0 11.5 2.6 15.2 7.1 3.7-4.5 9.3-7.1 15.2-7.1 8.6 0 16.2 5.5 18.9 13.7.2.7-.1 1.4-.7 1.8s-1.4.2-1.9-.3c-3.7-4-8.7-6.2-14.2-6.2-6.5 0-12.5 3.2-16.1 8.6-.2.4-.7.7-1.2.7z"
                fill="#222"
            />
            <path
                d="m32.4 71.3c6.8 0 13.1 3 17.4 8.2 4.2-5.2 10.6-8.2 17.4-8.2 3.6 0 7.2.9 10.4 2.6-3.1-3.5-7.6-5.6-12.6-5.6-5.6 0-10.8 2.8-14 7.4-.3.4-.7.7-1.2.7l0 0c-.5 0-1-.2-1.2-.7-3.1-4.7-8.3-7.4-14-7.4-4.9 0-9.4 2.1-12.6 5.6 3.1-1.7 6.7-2.6 10.4-2.6z"
                fill={active ? "#0075bf" : "#fff"}
            />
            <g fill="#222">
                <path d="m89 53c-.6 0-1.2-.4-1.4-1-.9-2.8-3.5-4.6-6.4-4.6-2.2 0-4.3 1.1-5.6 3-.3.4-.7.7-1.2.7l0 0c-.5 0-1-.2-1.2-.7-1.2-1.9-3.3-3-5.6-3-2.9 0-5.5 1.9-6.4 4.6-.3.8-1.1 1.2-1.9 1-.8-.3-1.2-1.1-1-1.9 1.3-4 5-6.7 9.2-6.7 2.6 0 5 1 6.8 2.8 1.8-1.8 4.2-2.8 6.8-2.8 4.2 0 7.9 2.7 9.2 6.7.3.8-.2 1.6-1 1.9 0 0-.2 0-.3 0z" />
                <path d="m39.6 53c-.6 0-1.2-.4-1.4-1-.9-2.8-3.5-4.6-6.4-4.6-2.2 0-4.3 1.1-5.6 3-.6.8-1.9.8-2.5 0-1.2-1.9-3.3-3-5.6-3-2.9 0-5.5 1.9-6.4 4.6-.3.8-1.1 1.2-1.9 1-.8-.3-1.2-1.1-1-1.9 1.3-4 5-6.7 9.2-6.7 2.6 0 5 1 6.8 2.8 1.8-1.8 4.2-2.8 6.8-2.8 4.2 0 7.9 2.7 9.2 6.7.3.8-.2 1.6-1 1.9.2 0 0 0-.2 0z" />
                <path d="m78 26.4c-.2 0-.3 0-.5-.1-.8-.3-1.2-1.1-1-1.9.9-2.7 3.4-4.6 6.3-4.6 1.6 0 3 .6 4.2 1.5 1.2-1 2.7-1.5 4.2-1.5 2.9 0 5.4 1.8 6.3 4.6.3.8-.2 1.6-1 1.9s-1.6-.2-1.9-1c-.5-1.5-1.8-2.5-3.4-2.5-1.2 0-2.3.6-3 1.6-.6.8-1.9.8-2.5 0-.7-1-1.8-1.6-3-1.6-1.6 0-2.9 1-3.4 2.5 0 .7-.6 1.1-1.3 1.1z" />
                <path d="m22 26.4c-.6 0-1.2-.4-1.4-1-.5-1.5-1.8-2.5-3.4-2.5-1.2 0-2.3.6-3 1.6-.6.8-1.9.8-2.5 0-.7-1-1.8-1.6-3-1.6-1.6 0-2.9 1-3.4 2.5-.3.8-1.1 1.2-1.9 1-.8-.3-1.2-1.1-1-1.9.9-2.7 3.4-4.6 6.3-4.6 1.6 0 3.1.6 4.2 1.5 1.2-1 2.7-1.5 4.2-1.5 2.9 0 5.4 1.8 6.3 4.6.3.8-.2 1.6-1 1.9-.1-.1-.3 0-.4 0z" />
            </g>
        </svg>
    );
}

export default BirdsIcon;
