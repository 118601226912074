import TaskDescription from "../../shared/components/TaskDescription";
import Thread from "../task/Thread";
import FeedbackForm from "./FeedbackForm";
import Info from "./Info";
import type { ISubmittedTask } from "./queries";
import type { JSX } from "react";

interface IProps {
    submittedTask: ISubmittedTask;
    pathname: string;
    submittedFeedbackTaskId: string;
}

const EMPTY_LIST = [];

function Feedback({ submittedTask, pathname, submittedFeedbackTaskId }: IProps): JSX.Element {
    const submitter = submittedTask.people[0];
    const name = `${submitter.firstName} ${submitter.lastName}`;
    return (
        <>
            <Info info="feedback" pathname={pathname} name={name} />
            <TaskDescription
                objectives={submittedTask.objectives}
                inspiration={submittedTask.inspiration}
                title={submittedTask.title}
                taskVariants={EMPTY_LIST}
                submittedVariant={submittedTask.submittedTaskVariant}
                icon={submittedTask.icon}
                miles={submittedTask.miles}
                timeEstimate={submittedTask.timeEstimate}
                status={"finished"}
                finishDatetime={submittedTask.finishDatetime}
                media={submittedTask.media}
                invert={false}
            />
            <Thread thread={submittedTask.thread} />
            <FeedbackForm id={submittedFeedbackTaskId} />
        </>
    );
}

export default Feedback;
