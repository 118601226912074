import type { JSX } from "react";
import styled from "styled-components";
import PulseIcon from "../../shared/components/icons/taskIcons/PulseIcon";
import t from "../../shared/translations";

const H3 = styled.h3`
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 5px;
    svg {
        width: 2.5rem;
        height: 2.5rem;
    }
`;

function PulseInfo(): JSX.Element {
    return (
        <>
            <H3>
                <PulseIcon />
                {t("player.pulse-landing.heading-info")}
            </H3>
            <p>{t("player.pulse-landing.info-process-steps")}</p>
            <ol>
                <li>{t("player.pulse-landing.info-process-step-1")}</li>
                <li>{t("player.pulse-landing.info-process-step-2")}</li>
                <li>{t("player.pulse-landing.info-process-step-3")}</li>
            </ol>
        </>
    );
}

export default PulseInfo;
