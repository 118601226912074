import styled, { css } from "styled-components";
import GroupTaskIcon from "../../shared/components/icons/GroupTaskIcon";
import OrigLockIcon from "../../shared/components/icons/LockIcon";
import NoteIcon from "../../shared/components/icons/NoteIcon";
import TaskIcon from "../../shared/components/icons/TaskIcon";

interface ICenter {
    $direction?: "row" | "column";
}

export const Center = styled.div<ICenter>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-direction: ${({ $direction }) => $direction ?? "row"};
`;

interface ISizedTaskIcon {
    $size?: string;
}

export const SizedTaskIcon = styled(TaskIcon)<ISizedTaskIcon>`
    height: ${({ $size }) => $size ?? "90px"};
    width: ${({ $size }) => $size ?? "90px"};
`;

interface ILockIcon {
    $size: number;
    $absolute: boolean;
}

function lockPosition({ $absolute }: ILockIcon) {
    if ($absolute) {
        return css`
            position: absolute;
            top: 0px;
            left: 0px;
        `;
    }
    return "";
}

function lockSize({ $size, $absolute }: ILockIcon): number {
    return $absolute ? 0.5 * $size : $size - 10;
}

function lockMargin({ $size, $absolute }: ILockIcon): number {
    return $absolute ? 0.25 * $size : 5;
}

export const LockIcon = styled(OrigLockIcon)<ILockIcon>`
    flex-shrink: 0;
    ${lockPosition}
    height: ${lockSize}px;
    width: ${lockSize}px;
    margin: ${lockMargin}px;
`;

interface IGroupTaskLabel {
    $scale?: number;
}

export const GroupTaskLabel = styled(GroupTaskIcon)<IGroupTaskLabel>`
    width: ${({ $scale }) => ($scale ?? 1) * 22}px;
    height: ${({ $scale }) => ($scale ?? 1) * 22}px;
    position: absolute;
    bottom: 0px;
    right: ${({ $scale }) => ($scale ?? 1) * -3}px;
`;

interface IAllAnswersLabel {
    $scale?: number;
}

export const AllAnswersLabel = styled(NoteIcon)<IAllAnswersLabel>`
    width: ${({ $scale }) => ($scale ?? 1) * 22}px;
    height: ${({ $scale }) => ($scale ?? 1) * 22}px;
    position: absolute;
    top: 0px;
    left: ${({ $scale }) => ($scale ?? 1) * 3}px;
`;
