import type { ShareAnswersType, SubmittedTaskStatus, TaskIcon } from "../../shared/types";
import { type IPathWithItems, pathWithItemsFragment } from "../tasksSidebar/query";

export interface IArgs {
    id: string;
}

export const query = `
    ${pathWithItemsFragment}
    query($id:String!) {
        taskChoice(id:$id) {
            id
            icon
            name
            url
            description
            callToAction
            deadline
            complete
            learningPathItem {
                id
                learningPath {
                    id
                    ...PathWithItems
                }
                tasks {
                    id
                    icon
                    miles
                    shortTitle
                    url
                    shareAnswers
                    submittedTask {
                        id
                        url
                        status
                    }
                }
            }
        }
    }
`;

export interface ITask {
    id: string;
    icon: TaskIcon;
    shortTitle: string;
    miles: number;
    url: string;
    shareAnswers: ShareAnswersType;
    submittedTask: {
        id: string;
        url: string;
        status: SubmittedTaskStatus;
    } | null;
}

export interface ITaskChoice {
    id: string;
    icon: TaskIcon;
    name: string;
    url: string;
    description: string;
    callToAction: string;
    deadline: string;
    complete: boolean;
    learningPathItem: {
        id: string;
        miles: number;
        tasks: ITask[];
        learningPath: {
            id: string;
        } & IPathWithItems;
    };
}

export interface IData {
    taskChoice: ITaskChoice;
}

export function taskFinished(task: { submittedTask: { status: SubmittedTaskStatus } }): boolean {
    return task.submittedTask?.status === "accepted";
}

export function taskUndecied(task: { submittedTask: { status: SubmittedTaskStatus } }): boolean {
    return task.submittedTask?.status === "undecided";
}

export function taskRedo(task: { submittedTask: { status: SubmittedTaskStatus } }): boolean {
    return task.submittedTask?.status === "rejected";
}

export function taskSharingAnswers(task: { shareAnswers: ShareAnswersType }): boolean {
    return task.shareAnswers !== "no_one";
}
