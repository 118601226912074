import { type Dispatch, useState, type JSX } from "react";
import t from "../../translations";
import { avatarSrc } from "./AvatarForm";
import { useProfilePictureUrl } from "./PictureForm";
import type { IAction, IState, IValues } from "./state";
import { ButtonRow, PictureImg, ReviewAvatar, ReviewBox, ReviewGrid } from "./stylings";

interface IProps {
    state: IState;
    dispatch: Dispatch<IAction>;
    onComplete: (values: IValues) => Promise<void>;
}

function ReviewPage({ state, dispatch, onComplete }: IProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    const onBack = () => {
        dispatch({ type: "BACK-REVIEW" });
    };
    const onSubmit = async () => {
        setLoading(true);
        try {
            await onComplete(state.values);
        } finally {
            setLoading(false);
        }
    };
    const src = useProfilePictureUrl(state.values.profilePicture);
    return (
        <div>
            <p>{t("shared.account-wizard.review-page-info")}</p>
            <ReviewGrid>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-email")}</label>
                    <span>{state.values.email}</span>
                </ReviewBox>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-notifications")}</label>
                    <input type="checkbox" checked={state.values.emailNotifications} readOnly />{" "}
                    <span>{t("shared.account-wizard.review-page-label-email-notifications")}</span>
                </ReviewBox>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-first-name")}</label>
                    <span>{state.values.firstName}</span>
                </ReviewBox>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-last-name")}</label>
                    <span>{state.values.lastName}</span>
                </ReviewBox>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-gender")}</label>
                    <span>{t(`shared.gender.${state.values.gender}`)}</span>
                </ReviewBox>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-title")}</label>
                    <span>{state.values.title || "\u00A0"}</span>
                </ReviewBox>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-tel")}</label>
                    <span>{state.values.tel || "\u00A0"}</span>
                </ReviewBox>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-birth-year")}</label>
                    <span>{state.values.birthYear || "\u00A0"}</span>
                </ReviewBox>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-avatar")}</label>
                    <ReviewAvatar src={avatarSrc(state.values.avatar)} />
                </ReviewBox>
                <ReviewBox>
                    <label>{t("shared.account-wizard.review-page-label-profile-picture")}</label>
                    <PictureImg src={src} />
                </ReviewBox>
            </ReviewGrid>
            <ButtonRow>
                <button type="button" onClick={onBack} disabled={loading}>
                    {t("shared.account-wizard.review-page-button-back")}
                </button>
                <button type="button" onClick={onSubmit} disabled={loading}>
                    {t("shared.account-wizard.review-page-button-submit")}
                </button>
            </ButtonRow>
        </div>
    );
}

export default ReviewPage;
