export const breakpoints = {
    sm: "420px",
    md: "640px",
    lg: "900px",
};

export const fontSizes = {
    xs: "0.7rem",
    sm: "0.73rem",
    md: "0.77rem",
    lg: "0.8rem",
};
