import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function SpiralIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m74.803 75.096-.007-.008c-.279.277-.662.447-1.085.447-.849 0-1.539-.689-1.539-1.539 0-.425.173-.811.453-1.089l-.001-.001.096-.096c12.573-12.573 12.573-32.957 0-45.53-11.974-11.973-31.386-11.973-43.36 0-11.374 11.374-11.374 29.815 0 41.189 10.775 10.775 28.244 10.775 39.019 0 10.175-10.175 10.175-26.673 0-36.848-9.576-9.576-25.102-9.576-34.678 0-8.977 8.977-8.977 23.531 0 32.507 8.377 8.378 21.96 8.378 30.337 0 7.778-7.778 7.778-20.388 0-28.166-7.179-7.179-18.817-7.179-25.996 0-6.579 6.579-6.579 17.246 0 23.826 5.98 5.98 15.675 5.98 21.655 0 5.381-5.381 5.381-14.105 0-19.485-4.781-4.781-12.533-4.781-17.314 0-4.182 4.182-4.182 10.962 0 15.144 3.582 3.582 9.391 3.582 12.973 0 2.983-2.983 2.983-7.82 0-10.803-2.384-2.384-6.248-2.384-8.632 0-1.785 1.784-1.785 4.677 0 6.462 1.185 1.185 3.106 1.185 4.291 0 .586-.586.586-1.536 0-2.121-.585-.586-1.535-.586-2.121 0-.013.013-.035.013-.049 0-.613-.613-.613-1.607 0-2.22 1.212-1.212 3.178-1.212 4.39 0 1.812 1.812 1.812 4.749 0 6.56-2.411 2.411-6.32 2.411-8.731 0-3.01-3.01-3.01-7.891 0-10.901 3.61-3.61 9.462-3.61 13.072 0 4.209 4.209 4.209 11.033 0 15.242-4.808 4.809-12.604 4.809-17.413 0-5.407-5.407-5.407-14.175 0-19.583 6.007-6.007 15.747-6.007 21.754 0 6.606 6.607 6.606 17.318 0 23.924-7.206 7.206-18.889 7.206-26.095 0-7.805-7.805-7.805-20.459 0-28.265 8.405-8.404 22.031-8.404 30.436 0 9.003 9.004 9.003 23.602 0 32.606-9.604 9.603-25.173 9.603-34.776 0-10.203-10.202-10.203-26.744 0-36.946 10.801-10.802 28.315-10.802 39.116 0 11.402 11.401 11.402 29.886 0 41.287-12 12-31.457 12-43.457 0-12.6-12.6-12.6-33.028 0-45.628 13.199-13.2 34.599-13.2 47.798 0 13.774 13.773 13.774 36.105 0 49.878-.049.05-.095.102-.136.157z"
                fill={active ? "#ff855d" : "#fff"}
            />
            <path
                d="m76 76c14.359-14.359 14.359-37.641 0-52-13.785-13.785-36.135-13.785-49.92 0-13.186 13.186-13.186 34.564 0 47.75 12.586 12.586 32.993 12.586 45.579 0 11.987-11.987 11.987-31.422 0-43.409-11.388-11.388-29.851-11.388-41.238 0-10.788 10.788-10.788 28.279 0 39.068 10.189 10.189 26.708 10.189 36.897 0 9.59-9.59 9.59-25.138 0-34.727-8.99-8.99-23.566-8.99-32.556 0-8.391 8.391-8.391 21.995 0 30.386 7.791 7.791 20.424 7.791 28.215 0 7.193-7.192 7.193-18.853 0-26.045-6.592-6.593-17.282-6.593-23.874 0-5.994 5.993-5.994 15.71 0 21.704 5.394 5.394 14.139 5.394 19.534 0 4.794-4.795 4.794-12.569 0-17.364-4.196-4.195-10.998-4.195-15.194 0-3.596 3.597-3.596 9.427 0 13.023 2.997 2.997 7.856 2.997 10.853 0 2.397-2.397 2.397-6.284 0-8.682-1.798-1.798-4.714-1.798-6.512 0-1.198 1.199-1.198 3.143 0 4.341.6.6 1.571.6 2.171 0"
                fill="none"
                stroke="#000"
                strokeWidth="3"
            />
        </svg>
    );
}

export default SpiralIcon;
