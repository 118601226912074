import { useField, type UseFieldConfig } from "react-final-form";
import { ErrorBox, Label } from "./stylings";
import type { JSX } from "react";

interface IOwnProps {
    label?: string;
    name: string;
    required?: boolean;
}

type IProps = IOwnProps & UseFieldConfig<boolean>;

function Checkbox({ name, label, required, ...props }: IProps): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField(name, { ...props, type: "checkbox" }) as any;
    return (
        <div>
            <Label $error={error && touched}>
                <input {...input} type="checkbox" /> {label}
                {required ? "*" : ""}
            </Label>
            {touched && error && <ErrorBox>{error}</ErrorBox>}
        </div>
    );
}

export default Checkbox;
