import t from "../../../shared/translations";
import type { ITask, ITaskVariant } from "../query";
import { Button } from "../stylings";
import type { JSX } from "react";

interface Props {
    task: ITask;
    variant: ITaskVariant;
    invert: boolean;
}

function StartPoll({ task, variant, invert }: Props): JSX.Element {
    const question = variant.taskQuestions.find((q) => q.pollId);
    if (!question) {
        return null;
    }
    const pollId = question.pollId;
    return (
        <form action={`/poll/${pollId}`} method="post">
            {question.questionContent && <h4>{question.questionContent}</h4>}
            <input type="hidden" name="task_id" value={task.id} />
            <input type="hidden" name="variant_id" value={variant.id} />
            <input id="csrf_token" name="csrf_token" type="hidden" value={__CSRF_TOKEN__} />
            <div className="text-center">
                <Button type="submit" $invert={invert}>
                    {t("player.task.button-start-poll")}
                </Button>
            </div>
        </form>
    );
}

export default StartPoll;
