import * as Sentry from "@sentry/browser";
import { type JSX, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useMutation } from "urql";
import t from "../../shared/translations";
import { type Dispatch, type State, calendarUpdatePerfs } from "../state";
import { type IPerfsArgs, type IPerfsData, perfsMutation } from "./query";

const perfsSelector = createSelector(
    (state: State) => state.calendar.calendarShowStartDate,
    (state: State) => state.calendar.calendarShowFinished,
    (calendarShowStartDate, calendarShowFinished) => {
        return { calendarShowStartDate, calendarShowFinished };
    },
);

function useUpdatePerfs() {
    const dispatch = useDispatch<Dispatch>();
    const [_, executeChange] = useMutation<IPerfsData, IPerfsArgs>(perfsMutation);

    const updatePerfs = async (calendarShowStartDate: boolean, calendarShowFinished: boolean) => {
        dispatch(calendarUpdatePerfs({ calendarShowStartDate, calendarShowFinished }));
        const result = await executeChange({ data: { calendarShowStartDate, calendarShowFinished } });
        if (result.error) {
            console.error(result.error);
            Sentry.captureMessage(result.error.toString());
        } else if (result.data.currentPersonUpdateCalendarPerfs?.error) {
            console.error(result.data.currentPersonUpdateCalendarPerfs.error);
            Sentry.captureMessage(result.data.currentPersonUpdateCalendarPerfs.error);
        }
    };

    return updatePerfs;
}

interface IProps {
    loading: boolean;
}

function PrefForm({ loading }: IProps): JSX.Element {
    const { calendarShowStartDate, calendarShowFinished } = useSelector(perfsSelector);
    const updatePerfs = useUpdatePerfs();

    const onChangeStarDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (loading) {
            return;
        }
        const { checked } = e.target;
        void updatePerfs(checked, calendarShowFinished);
    };

    const onChangeFinished = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (loading) {
            return;
        }
        const { checked } = e.target;
        void updatePerfs(calendarShowStartDate, checked);
    };

    return (
        <div>
            <label>
                <input
                    type="checkbox"
                    name="calendarShowStartDate"
                    checked={calendarShowStartDate}
                    onChange={onChangeStarDate}
                    disabled={loading}
                />{" "}
                {t("player.calendar.label-show-start-date")}
            </label>
            <label>
                <input
                    type="checkbox"
                    name="calendarShowFinished"
                    checked={calendarShowFinished}
                    onChange={onChangeFinished}
                    disabled={loading}
                />{" "}
                {t("player.calendar.label-show-finished")}
            </label>
        </div>
    );
}

export default memo(PrefForm);
