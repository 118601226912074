import Linkify from "linkify-react";
import styled from "styled-components";
import LikertPreview from "../../../shared/components/LikertPreview";
import { formatYMD, parseDate } from "../../../shared/dateFns";
import t from "../../../shared/translations";
import type { ICollectFeedbackAnswerSet } from "../query";
import { Box, QuestionBox, QuestionHeading } from "../stylings";
import QuestionNum from "./QuestionNum";
import type { JSX } from "react";

interface IProps {
    answerSet: ICollectFeedbackAnswerSet;
    anonymous: boolean;
}

const RowBox = styled(Box)`
    display: flex;
    justify-content: space-around;
`;

function ResponseModal({ answerSet, anonymous }: IProps): JSX.Element {
    if (!answerSet) {
        return null;
    }
    const name = anonymous ? "" : `${answerSet.firstName} ${answerSet.lastName}`;
    const date = formatYMD(parseDate(answerSet.submitted));
    return (
        <>
            <h3>{t("player.task.heading-collect-feedback-response-received")}</h3>
            <RowBox>
                {!anonymous && <div>{t("player.task.info-collect-feedback-response-giver", { name })}</div>}
                <div>{t("player.task.info-collect-feedback-response-date", { date })}</div>
            </RowBox>
            {answerSet.collectFeedbackAnswers.map((ans) => (
                <QuestionBox key={ans.id}>
                    <QuestionHeading>
                        <QuestionNum>{ans.nr}</QuestionNum>
                        <span>{ans.translation || ans.question}</span>
                    </QuestionHeading>
                    {ans.type === "likert" ? (
                        <LikertPreview
                            leftLabel={ans.leftTranslation || ans.leftLabel}
                            rightLabel={ans.rightTranslation || ans.rightLabel}
                            selected={ans.likertAnswer}
                        />
                    ) : (
                        <Linkify options={{ nl2br: true }} tagName="p">
                            {ans.textAnswer}
                        </Linkify>
                    )}
                </QuestionBox>
            ))}
        </>
    );
}

export default ResponseModal;
