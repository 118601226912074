import { type CSSProperties, type MouseEvent, ReactNode, type JSX } from "react";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import * as colors from "../../shared/colors";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import LockedModal, { useLockedModal } from "../LockedModal";
import ShowError from "../components/ShowError";
import WithTasksSidebar from "../tasksSidebar";
import PipDescription from "./PipDescription";
import PipNav from "./PipNav";
import YourGroup from "./YourGroup";
import { type IArgs, type IData, query } from "./query";

const EMPTY_LIST = [];

const Center = styled.div`
    text-align: center;
`;

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1440px;
    width: 100%;
`;

function PathInPath(): JSX.Element {
    const {
        params: { id },
    } = useMatch({ path: "/path-in-path/:id/" });
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id },
    });

    if (result.fetching) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        );
    }

    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }

    const pip = result.data.pathInPath;
    const tasks = pip.learningPathItem.tasks;
    const showGroup = pip.showGroup && pip.learningPathItem.learningPath.pathGroup != null;
    const nextTask = tasks.find((task) => task.submittedTask?.status !== "accepted");
    const members = showGroup ? pip.learningPathItem.learningPath.pathGroup.members : EMPTY_LIST;
    const style: CSSProperties = {
        backgroundColor: colors.altPrimaryColor,
    };
    const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
        if (nextTask.locked) {
            event.preventDefault();
            openLockedModal(nextTask.id, "", "");
        }
    };

    return (
        <Wrapper>
            <WithTasksSidebar
                openLockedModal={openLockedModal}
                path={pip.learningPathItem.learningPath}
                activeItem={pip.learningPathItem.id}
                mainLabel={t("player.pip.tab-path-in-path")}
                big={true}
            >
                <h1>{pip.name}</h1>
                <PipNav
                    miles={pip.learningPathItem.miles}
                    earnedMiles={pip.learningPathItem.earnedMiles}
                    url={pip.url}
                    tasks={tasks}
                    icon={pip.icon}
                    current=""
                />
                {showGroup && <YourGroup members={members} />}
                <PipDescription
                    url={pip.url}
                    name={pip.name}
                    icon={pip.icon}
                    description={pip.description}
                    tasks={tasks}
                />
                <Center>
                    {nextTask && (
                        <a className="button" href={nextTask.url} style={style} onClick={onClick}>
                            {t("player.pip.button-next-task")}
                        </a>
                    )}
                </Center>
            </WithTasksSidebar>
            <LockedModal {...lockedProps} />
        </Wrapper>
    );
}

export default PathInPath;
