import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider as URQLProvider, cacheExchange, createClient, fetchExchange } from "urql";
import { UrqlGlobalNotice } from "../shared/components/GlobalNotice";
import sentryExchange from "../shared/sentryExchange";
import Footer from "./Footer";
import Header from "./Header";
import ProfileNav from "./ProfileNav";
import { CurrentPersonProvider } from "./components/CurrentPerson";
import { store } from "./state";

export const client = createClient({
    exchanges: [cacheExchange, sentryExchange, fetchExchange],
    fetchOptions: {
        credentials: "same-origin",
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
    },
    requestPolicy: "cache-and-network",
    url: "/graphql/player",
});

export default function init() {
    const container = document.getElementById("profile-nav-page");
    Modal.setAppElement(container);
    const root = createRoot(container);
    root.render(
        <URQLProvider value={client}>
            <ReduxProvider store={store}>
                <BrowserRouter>
                    <CurrentPersonProvider>
                        <UrqlGlobalNotice />
                        <Header />
                        <Routes>
                            <Route path="/*" element={<ProfileNav />} />
                        </Routes>
                        <Footer />
                    </CurrentPersonProvider>
                </BrowserRouter>
            </ReduxProvider>
        </URQLProvider>,
    );
}
