import { type JSX, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import Modal from "../components/Modal";
import ShowError from "../components/ShowError";
import { type Dispatch, openModal } from "../state";
import Message from "./Message";
import Send from "./Send";
import { type IArgs, type IData, type IMessage, query } from "./query";

interface IProps {
    filter: "messages" | "notifications" | "archived";
}

function Inbox({ filter }: IProps): JSX.Element {
    const [open, setOpen] = useState<string>("");
    const [replyMessage, setReplyMessage] = useState<IMessage | null>(null);
    const dispatch = useDispatch<Dispatch>();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { filter },
    });

    const toggleOpen = useCallback((id: string) => {
        setOpen((open) => (open === id ? "" : id));
    }, []);

    const openReply = useCallback((message: IMessage) => {
        setReplyMessage(message);
        dispatch(openModal("Messages/Reply"));
    }, []);

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    return (
        <section>
            {result.data.messages.map((message) => (
                <Message
                    key={message.id}
                    message={message}
                    open={open === message.id}
                    toggleOpen={toggleOpen}
                    openReply={openReply}
                />
            ))}
            <Modal modal="Messages/Reply">
                <Send message={replyMessage} />
            </Modal>
        </section>
    );
}

export default Inbox;
