import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import ProfileNav from "../ProfileNav";
import Terms from "../teamScans/Terms";
import Landing from "./Landing";
import ThreadLoader from "./ThreadLoader";
import type { JSX } from "react";

const Main = styled.div`
    flex-grow: 1;
    padding: 0 10px;
    width: 100%;
    max-width: 1440px;
`;

function AiChatbotRoutes(): JSX.Element {
    return (
        <Main>
            <Routes>
                <Route path="/ai-chatbots/profile/*" element={<ProfileNav />} />
                <Route path="/ai-chatbots/terms" element={<Terms />} />
                <Route path="/ai-chatbots/:id" element={<ThreadLoader />} />
                <Route path="/ai-chatbots" element={<Landing />} />
            </Routes>
        </Main>
    );
}

export default AiChatbotRoutes;
