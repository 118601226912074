import { memo, useEffect, useRef, type JSX } from "react";
import type { Message, Person } from "../../api/player/Chat";
import t from "../../shared/translations";
import HistoryList from "./HistoryList";

interface IProps {
    messages: Message[] | null;
    members: Person[];
    currentId: number;
    typing: { [index: number]: boolean };
}

function History({ messages, members, currentId, typing }: IProps): JSX.Element {
    const bottom = useRef<HTMLDivElement>(null);
    const typers = members.filter((p) => typing[p.id]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Scroll whenever messages change
    useEffect(() => {
        bottom.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <div className="chat-history">
            <HistoryList messages={messages} members={members} currentId={currentId} />
            {typers.map((person) => {
                const name = `${person.first_name} ${person.last_name}`;
                return (
                    <div className="typing" key={person.id}>
                        {t("player.chat.info-is-typing", { name })}
                    </div>
                );
            })}
            <div ref={bottom} />
        </div>
    );
}

export default memo(History);
