import { createRoot } from "react-dom/client";
import { Provider as URQLProvider, cacheExchange, createClient, fetchExchange } from "urql";
import { UrqlGlobalNotice } from "../../shared/components/GlobalNotice";
import sentryExchange from "../../shared/sentryExchange";
import Footer from "../Footer";
import Header from "../Header";
import { CurrentPersonProvider } from "../components/CurrentPerson";
import Inspirations from "./Inspirations";

const client = createClient({
    exchanges: [cacheExchange, sentryExchange, fetchExchange],
    fetchOptions: {
        credentials: "same-origin",
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
    },
    requestPolicy: "cache-and-network",
    url: "/graphql/player",
});

interface IData {
    id: string;
}

export default function init() {
    const elem = document.getElementById("insp-data");
    const data = JSON.parse(elem.innerHTML) as IData;

    const container = document.getElementById("inspiration-page");
    const root = createRoot(container);
    root.render(
        <URQLProvider value={client}>
            <CurrentPersonProvider>
                <UrqlGlobalNotice />
                <Header />
                <Inspirations id={data.id} />
                <Footer />
            </CurrentPersonProvider>
        </URQLProvider>,
    );
}
