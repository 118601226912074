import type { Language } from "../../shared/types";

// Pulse list query

export const listQuery = `
    query {
        pulseList {
            id
            name
            createdDatetime
            question1
            question2
        }
    }
`;

export interface IListData {
    pulseList: {
        id: string;
        name: string | null;
        createdDatetime: string;
        question1: string;
        question2: string | null;
    }[];
}

// Pulse query

export interface IPulseArgs {
    id: string;
}

export const pulseQuery = `
    query($id:String!) {
        pulse(id:$id) {
            id
            name
            createdDatetime
            question1
            question2
            link
            qrCode
            pulseAnswers {
                id
                createdDatetime
                answer1
                answer2
            }
        }
    }
`;

export interface IPulseData {
    pulse: {
        id: string;
        name: string | null;
        createdDatetime: string;
        question1: string;
        question2: string | null;
        link: string;
        qrCode: string;
        pulseAnswers: {
            id: string;
            createdDatetime: string;
            answer1: string;
            answer2: string | null;
        }[];
    };
}

// Create pulse mutation

export interface ICreateArgs {
    data: {
        name: string | null;
        question1: string;
        question2: string | null;
        locale: Language;
    };
}

export const createMutation = `
    mutation($data:PlayerPulseCreateData!) {
        pulseCreate(data:$data) {
            error
            pulse {
                id
            }
        }
    }
`;

export interface ICreateData {
    pulseCreate: {
        error: string | null;
        pulse: {
            id: string;
        } | null;
    };
}

// Suggested pulse questions query

export const questionsQuery = `
    query {
        pulseSuggestedQuestions {
            id
            content
        }
    }
`;

export interface IQuestionsData {
    pulseSuggestedQuestions: {
        id: string;
        content: string;
    }[];
}

// Rename pulse mutation

export interface IReanmeArgs {
    data: {
        id: string;
        name: string | null;
    };
}

export const renameMutation = `
    mutation($data:PlayerPulseRenameData!) {
        pulseRename(data:$data) {
            error
            pulse {
                id
            }
        }
    }
`;

export interface IRenameData {
    pulseRename: {
        error: string | null;
        pulse: {
            id: string;
        } | null;
    };
}
