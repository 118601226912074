import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { Box } from "../task/stylings";
import Progress from "../teamScans/progress/Progress";
import Resend from "../teamScans/progress/Resend";
import SendReminderSuccess from "../teamScans/progress/SendReminderSuccess";
import Report from "../teamScans/report/Report";
import ShareReport from "../teamScans/report/ShareReport";
import ShareReportSuccess from "../teamScans/report/ShareReportSuccess";
import type { ISubmittedTask } from "./query";
import type { JSX } from "react";

interface IProps {
    task: ISubmittedTask;
}

const Boxer = styled(Box)`
    padding: 15px;
    @media (min-width: 600px) {
        padding: 1rem;
    }
`;

function TeamScan({ task }: IProps): JSX.Element {
    if (!task.submittedTaskVariant.teamScan) {
        return null;
    }
    return (
        <Boxer>
            <Routes>
                <Route path="team-scans/progress/:id/resend" element={<Resend />} />
                <Route path="team-scans/progress/:id/resend-success" element={<SendReminderSuccess />} />
                <Route path="team-scans/progress/:id" element={<Progress />} />
                <Route path="team-scans/report/:id/share" element={<ShareReport />} />
                <Route path="team-scans/report/:id/share-success" element={<ShareReportSuccess />} />
                <Route path="team-scans/report/:id" element={<Report />} />
                <Route
                    path="/"
                    element={<Navigate to={`team-scans/report/${task.submittedTaskVariant.teamScan.id}`} />}
                />
            </Routes>
        </Boxer>
    );
}

export default TeamScan;
