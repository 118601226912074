import { memo } from "react";
import type { JSX } from "react";
import * as colors from "../../../colors";

interface IProps {
    unread?: number;
}

function ProfileIcon({ unread }: IProps): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="nav-icon">
            <path d="M65.2,55c4.3-4.8,7.1-11.6,7.1-19.1c0-14.5-10-26.2-22.3-26.2c-12.3,0-22.4,11.8-22.4,26.2 c0,7.5,2.7,14.3,7.1,19.1c-14.8,4.7-24.5,15.7-24.5,28c0,0.6,0,1.2,0.1,1.9l0.1,1.4h79.2l0.1-1.4c0.1-0.7,0.1-1.3,0.1-1.9 C89.7,70.8,79.9,59.7,65.2,55z M30.6,35.9c0-12.8,8.6-23.2,19.3-23.2s19.3,10.4,19.3,23.2c0,12.8-8.6,23.2-19.3,23.2 S30.6,48.7,30.6,35.9z M86.7,83.3H13.1c0-0.1,0-0.2,0-0.3c0-11.4,9.6-21.7,24-25.6c3.6,3,8,4.7,12.8,4.7c4.8,0,9.2-1.8,12.8-4.8 c0.6,0.2,1,0.3,1,0.3c13.8,4.2,23,14.4,23,25.4C86.7,83.1,86.7,83.2,86.7,83.3z" />
            {unread && (
                <>
                    <circle cx="20" cy="30" r="20" fill={colors.deadline} stroke="#FFF" strokeWidth="2" />
                    <text x="20" y="38">
                        {unread}
                    </text>
                </>
            )}
        </svg>
    );
}

export default memo(ProfileIcon);
