import { type JSX, memo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import { format, isSameDay, parseDate } from "../../shared/dateFns";
import type { ICalendarEvent, State } from "../state";
import CalendarEvent from "./CalendarEvent";
import { breakpoints, fontSizes } from "./breakpoints";

interface IProps {
    day: Date;
}

const Wrapper = styled.div`
    padding: 0 2px;
    min-height: 3.5rem;
    font-size: ${fontSizes.xs};
    min-width: 0;
    border-bottom: 1px solid ${colors.text};
    border-right: 1px solid ${colors.text};
    position: relative;
    display: grid;
    gap: 1px;
    padding-bottom: 1px;
    align-items: start;
    align-content: start;

    &:nth-child(7n + 7) {
        border-right: none;
    }

    &:nth-last-child(-n+7) {
        border-bottom: none;
    }

    @container (min-width: ${breakpoints.sm}) {
        font-size: ${fontSizes.sm};
        gap: 2px;
        padding-bottom: 2px;
        min-height: 4rem;
    }
    @container (min-width: ${breakpoints.md}) {
        font-size: ${fontSizes.md};
        min-height: 5rem;
    }
    @container (min-width: ${breakpoints.lg}) {
        font-size: ${fontSizes.lg};
        gap: 3px;
        padding-bottom: 3px;
        min-height: 6rem;
    }
`;

interface IDateNumber {
    $isToday: boolean;
}

const DateNumber = styled.div<IDateNumber>`
    z-index: 1;
    color: ${({ $isToday }) => ($isToday ? colors.white : colors.text)};
    text-align: center;
    position: relative;
`;

const TodayHighlight = styled.div`
    position: absolute;
    background-color: ${colors.primaryColor};
    border-radius: 50%;
    top: 0;
    left: calc(50% - 0.5rem);
    width: 1rem;
    height: 1rem;

    @container (min-width: ${breakpoints.sm}) {
        left: calc(50% - 0.55rem);
        width: 1.1rem;
        height: 1.1rem;
    }
    @container (min-width: ${breakpoints.md}) {
        left: calc(50% - 0.6rem);
        width: 1.2rem;
        height: 1.2rem;
    }
    @container (min-width: ${breakpoints.lg}) {
        left: calc(50% - 0.65rem);
        width: 1.3rem;
        height: 1.3rem;
    }
`;

const calendarEventSelector = createSelector(
    (state: State, _: Date) => state.calendar.events.ids,
    (state: State, _: Date) => state.calendar.events.dict,
    (state: State, _: Date) => state.calendar.calendarShowStartDate,
    (state: State, _: Date) => state.calendar.calendarShowFinished,
    (_: State, day: Date) => day,
    (ids, dict, showStartDate, showFinished, day): ICalendarEvent[] => {
        let events = ids.map((id) => dict[id]).filter((event) => isSameDay(parseDate(event.date), day));
        if (!showFinished) {
            events = events.filter((event) => !event.complete);
        }
        if (!showStartDate) {
            events = events.filter((event) => !event.type.endsWith("call-to-action"));
        }
        return events;
    },
);

function Day({ day }: IProps): JSX.Element {
    const events = useSelector((state: State) => calendarEventSelector(state, day));
    const isToday = isSameDay(day, new Date());

    return (
        <Wrapper>
            {isToday && <TodayHighlight />}
            <DateNumber $isToday={isToday}>{format(day, "d")}</DateNumber>
            {events.map((event) => (
                <CalendarEvent key={event.id} event={event} />
            ))}
        </Wrapper>
    );
}

export default memo(Day);
