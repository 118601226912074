import type { Dispatch, JSX, SetStateAction } from "react";
import { type UseFieldArrayConfig, useFieldArray } from "react-final-form-arrays";
import styled from "styled-components";
import LikertPreview from "../../../shared/components/LikertPreview";
import CrossIcon from "../../../shared/components/icons/CrossIcon";
import t from "../../../shared/translations";
import { Button, FakeTextarea, Hider, Input, QuestionBox, QuestionHeading, QuestionRow, Select } from "../stylings";
import LikertLabelInput from "./LikertLabelInput";
import QuestionNum from "./QuestionNum";
import type { ICustomValue } from "./Setup";

interface IOwnProps {
    name: string;
    invert: boolean;
    numStart: number;
    open: number | null;
    setOpen: Dispatch<SetStateAction<number | null>>;
}

type IProps = IOwnProps & UseFieldArrayConfig<ICustomValue>;

const Close = styled(CrossIcon)`
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
`;

const LikertLabels = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
`;

function newCustomQuestions(): ICustomValue {
    return {
        content: "",
        type: "text",
        leftLabel: "",
        rightLabel: "",
    };
}

function CustomArray({ name, invert, numStart, open, setOpen, ...props }: IProps): JSX.Element {
    const {
        fields,
        meta: { error, invalid },
    } = useFieldArray(name, props);
    const add = () => {
        fields.push(newCustomQuestions());
        setOpen(fields.value.length + numStart);
    };
    const remove = (index: number) => {
        fields.remove(index);
        setOpen(null);
    };
    return (
        <>
            {fields.map((question, index) => (
                <QuestionBox
                    key={index}
                    $bold={open === numStart + index}
                    onClick={() => setOpen(numStart + index)}
                    $error={invalid && error[index] && Object.keys(error[index]).length > 0}
                    $padRight
                >
                    {open === index && <Close onClick={() => remove(index)} />}
                    <Hider $hide={open !== numStart + index}>
                        <QuestionHeading>
                            <QuestionNum>{numStart + index + 1}</QuestionNum>
                            <Input
                                name={`${question}.content`}
                                placeholder={t("player.collect-feedback-setup.form-label-question")}
                            />
                        </QuestionHeading>
                        <QuestionRow>
                            <Select name={`${question}.type`}>
                                <option value="text">{t("player.collect-feedback-setup.form-option-text")}</option>
                                <option value="likert">{t("player.collect-feedback-setup.form-option-likert")}</option>
                            </Select>
                            {fields.value[index].type === "likert" && (
                                <LikertLabels>
                                    <LikertLabelInput
                                        name={`${question}.leftLabel`}
                                        label={t("player.collect-feedback-setup.form-label-left-label")}
                                    />
                                    <LikertLabelInput
                                        name={`${question}.rightLabel`}
                                        label={t("player.collect-feedback-setup.form-label-right-label")}
                                    />
                                </LikertLabels>
                            )}
                        </QuestionRow>
                    </Hider>
                    <Hider $hide={open === numStart + index}>
                        <QuestionHeading>
                            <QuestionNum>{numStart + index + 1}</QuestionNum>
                            <span>{fields.value[index].content}</span>
                        </QuestionHeading>
                        {fields.value[index].type === "likert" ? (
                            <LikertPreview
                                leftLabel={fields.value[index].leftLabel}
                                rightLabel={fields.value[index].rightLabel}
                                selected={null}
                            />
                        ) : (
                            <FakeTextarea />
                        )}
                    </Hider>
                </QuestionBox>
            ))}
            {invalid && typeof error === "string" && <small className="error">{error}</small>}
            <Button type="button" onClick={add} $invert={invert}>
                {t("player.collect-feedback-setup.form-button-add")}
            </Button>
        </>
    );
}

export default CustomArray;
