import styled from "styled-components";
import * as colors from "../../../shared/colors";

type IBox = {
    $read: boolean;
};

export const Box = styled.div<IBox>`
    border: ${({ $read }) => ($read ? 1 : 2)}px solid ${colors.primaryColor};
    border-radius: 1rem;
    margin-bottom: 0.8rem;
    padding: 0.6rem;
    @media (min-width: 640px) {
        margin-bottom: 1rem;
        padding: 1rem;
    }
`;

export const TextboxAnswer = styled.div`
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

interface ICheckboxAnswer {
    $checked: boolean;
}

export const CheckboxAnswer = styled.label<ICheckboxAnswer>`
    color: ${colors.text};
    font-size: 1rem;
    cursor: default;
    font-weight: ${({ $checked }) => ($checked ? "bold" : "normal")};
`;

export const FileLogo = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;
