// Query performance of current path membership

export const query = `
query {
    currentPathMembership {
        id
        person {
            id
        }
        learningPath {
            id
            fixed
            numMembers
            membersPastCheckpoint
            membersPastGoal
            checkpoint
            goal
        }
        pathGroup {
            id
        }
        startDate
        endDate
        taskTime
        mySpeed
        ourSpeed
        miles
        pathGroupMemberMiles
        pathGroupMiles
        pathGroupMilesAvg
        allPathGroupMiles
        pathGroupSpeed
        memberMiles
        rank
    }
}
`;

export interface IData {
    currentPathMembership: {
        id: string;
        person: {
            id: string;
        };
        learningPath: {
            id: string;
            fixed: boolean;
            numMembers: number;
            membersPastCheckpoint: number;
            membersPastGoal: number;
            checkpoint: number;
            goal: number;
        };
        pathGroup: {
            id: string;
        } | null;
        startDate: string;
        endDate: string;
        taskTime: string | null;
        mySpeed: number;
        ourSpeed: number;
        miles: number;
        pathGroupMemberMiles: number[];
        pathGroupMiles: number | null;
        pathGroupMilesAvg: number | null;
        allPathGroupMiles: number[] | null;
        pathGroupSpeed: number | null;
        memberMiles: number[] | null;
        rank: number | null;
    };
}
