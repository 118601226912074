import { type JSX, memo } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import { parseDate } from "../../shared/dateFns";
import eventDateStrs from "../../shared/eventDateStrs";
import t from "../../shared/translations";
import type { IEvent } from "../state";
import { ButtonAnchor, DateBox, Label, TwoCol, Wrapper } from "./TaskModal";

interface IProps {
    event: IEvent;
}

const EventBox = styled(DateBox)`
    border-color: ${colors.primaryColor};
    background-color: ${colors.primaryColor};
    color: ${colors.white};
`;

function EventModal({ event }: IProps): JSX.Element {
    let eventStr: string = null;
    if (event.start) {
        const { startStr, endStr } = eventDateStrs(parseDate(event.start), event.end ? parseDate(event.end) : null);
        eventStr = startStr;
        if (endStr) {
            eventStr = `${eventStr} - ${endStr}`;
        }
    }

    return (
        <Wrapper>
            <h2>
                {t("player.calendar.modal-heading-event")}: {event.shortTitle}
            </h2>
            <h4>{event.pathname}</h4>
            <TwoCol>
                <Label>{t("player.calendar.modal-label-event-time")}</Label>
                <EventBox>{eventStr}</EventBox>
                {event.url && <ButtonAnchor href={event.url}>{t("player.calendar.button-go-to-event")}</ButtonAnchor>}
            </TwoCol>
        </Wrapper>
    );
}

export default memo(EventModal);
