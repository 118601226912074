import { postFile } from "../base";

type FileType = "profile-picture" | "task-answer";

export async function uploadFile(file: File, file_type: FileType): Promise<string> {
    const url = "/api/player/file/upload";
    const data = new FormData();
    data.append("file_type", file_type);
    data.append("file", file);
    return postFile(url, data) as Promise<string>;
}
