import type { ComponentPropsWithoutRef } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function PathSignIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <rect x="46.5" y="11" width="7" height="76.5" fill="#fff" rx="4" ry="4" />
            <path
                d="m90.5 57-12.5-9.9c-.3-.2-.6-.3-.9-.3h-22.1v-5.5h20.5c.8 0 1.5-.7 1.5-1.5v-19.8c0-.8-.7-1.5-1.5-1.5h-20.5v-4.6c0-2.8-2.2-5-5-5s-5 2.2-5 5v4.6h-22.1c-.3 0-.7.1-.9.3l-12.5 10c-.3.3-.6.7-.5 1.2s.2.9.6 1.2l12.4 9.7c.3.2.6.3.9.3h22.1v5.5h-21.8c-.8 0-1.5.7-1.5 1.5v19.8c0 .8.7 1.5 1.5 1.5h21.8v14.6c0 2.8 2.2 5 5 5s5-2.2 5-5v-14.6h22.1c.3 0 .7-.1.9-.3l12.5-9.8c.4-.3.6-.7.6-1.2 0-.4-.3-.9-.6-1.2zm-38.5 27.2c0 1.1-.9 2-2 2s-2-.9-2-2v-14.7h4zm0-37.4h-4v-5.5h4zm0-28.3h-4v-4.6c0-1.1.9-2 2-2s2 .9 2 2z"
                fill="#222"
            />
            <path d="m12.9 29.9 10.6-8.4h50.5v16.8h-50.5z" fill={active ? "#ff4e57" : " #fff"} />
            <path d="m76.5 66.5h-51.8v-16.7h51.8l10.6 8.4z" fill={active ? "#ff4e57" : " #fff"} />
        </svg>
    );
}

export default PathSignIcon;
