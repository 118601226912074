import type { ComponentPropsWithoutRef } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function BulbIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m54.8 73.3h-9.1c-2.4 0-4.3-1.9-4.3-4.3v-2.4c0-1.1-.8-2.8-1.6-3.6-.4-.3-8.6-7.6-8.6-15.2 0-10.5 8.6-19.1 19.1-19.1s19.1 8.6 19.1 19.1c0 7.7-8.2 14.9-8.6 15.2-.8.7-1.6 2.4-1.6 3.6v2.4c-.1 2.4-2.1 4.3-4.4 4.3z"
                fill="#222"
            />
            <path
                d="m50.2 31.8c-8.9 0-16.1 7.2-16.1 16.1 0 6.3 7.5 12.9 7.6 13 1.5 1.3 2.7 3.8 2.7 5.8v2.4c0 .7.6 1.3 1.3 1.3h9.1c.7 0 1.3-.6 1.3-1.3v-2.4c0-2 1.1-4.5 2.7-5.8.1-.1 7.6-6.7 7.6-13-.1-8.9-7.3-16.1-16.2-16.1z"
                fill={active ? "#ffc232" : "#fff"}
            />
            <g fill="#222">
                <path d="m55.9 78.8h-11.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h11.2c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
                <path d="m54.7 83.8h-8.8c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h8.8c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
                <path d="m53.2 88.8h-5.7c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h5.7c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
                <path d="m50.3 23 0 0c-1 0-1.8-.8-1.8-1.8v-8.3c0-1 .8-1.8 1.8-1.8l0 0c1 0 1.8.8 1.8 1.8v8.3c0 .9-.8 1.8-1.8 1.8z" />
                <path d="m33.3 30.1 0 0c-.7.7-1.8.7-2.5 0l-5.9-5.9c-.7-.7-.7-1.8 0-2.5l0 0c .7-.7 1.8-.7 2.5 0l5.9 5.9c.7.7.7 1.8 0 2.5z" />
                <path d="m25.7 47.5 0 0c0 1-.8 1.8-1.8 1.8h-8.3c-1 0-1.8-.8-1.8-1.8l0 0c0-1 .8-1.8 1.8-1.8h8.3c1 0 1.8.8 1.8 1.8z" />
                <path d="m33.3 64.8 0 0c .7.7.7 1.8 0 2.5l-5.9 5.9c-.7.7-1.8.7-2.5 0l0 0c-.7-.7-.7-1.8 0-2.5l5.9-5.9c.6-.6 1.8-.6 2.5 0z" />
                <path d="m86.6 47.5 0 0c0 1-.8 1.8-1.8 1.8h-8.3c-1 0-1.8-.8-1.8-1.8l0 0c0-1 .8-1.8 1.8-1.8h8.3c1 0 1.8.8 1.8 1.8z" />
                <path d="m76 21.7 0 0c .7.7.7 1.8 0 2.5l-5.9 5.9c-.7.7-1.8.7-2.5 0l0 0c-.7-.7-.7-1.8 0-2.5l5.9-5.9c.6-.7 1.8-.7 2.5 0z" />
                <path d="m76 73.2 0 0c-.7.7-1.8.7-2.5 0l-5.9-5.9c-.7-.7-.7-1.8 0-2.5l0 0c .7-.7 1.8-.7 2.5 0l5.9 5.9c.7.7.7 1.8 0 2.5z" />
            </g>
        </svg>
    );
}

export default BulbIcon;
