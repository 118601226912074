import type { JSX } from "react";
import styled from "styled-components";
import t from "../../../shared/translations";
import WithDocs from "../../components/WithDocs";
import { H3, StyledRenderMark } from "../stylings";
import ActionPoints from "./ActionPoints";
import MeanChart from "./MeanChart";
import QuestionChart from "./QuestionChart";
import type { IDoc, ITeamScan } from "./Report";
import SpiderChart from "./SpiderChart";
import Strengths from "./Strengths";
import Weaknesses from "./Weaknesses";

interface IProps {
    teamScan: ITeamScan;
    docList: IDoc[];
    printing?: boolean;
}

const ChartRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 600px) {
        grid-template-columns: 4fr 6fr;
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
`;

const PageBreak = styled.div`
    page-break-after: always;
`;

function TLB({
    teamScan: {
        report: { tlb },
    },
    printing,
    docList,
}: IProps): JSX.Element {
    return (
        <div>
            {printing ? (
                <StyledRenderMark
                    content={docList.find((doc) => doc.id === "player.team-scan.report-tlb-intro-print").content}
                />
            ) : (
                <StyledRenderMark
                    content={docList.find((doc) => doc.id === "player.team-scan.report-tlb-intro").content}
                />
            )}
            {printing && <PageBreak />}
            <H3>{t("shared.team-scan-report.heading-results")}</H3>
            {printing ? (
                <h4>{t("shared.team-scan-report.heading-overview")}</h4>
            ) : (
                <WithDocs
                    component="h4"
                    modal="TeamScan/Info/ReportTLBOverview"
                    docPath="player/team-scan/report-tlb-overview"
                >
                    {t("shared.team-scan-report.heading-overview")}
                </WithDocs>
            )}
            <MeanChart
                mean={tlb.mean}
                memberMeans={tlb.membersMeans}
                labelText={t("shared.team-scan-report.label-team-learning-behaviour")}
            />
            {printing ? (
                <h4>{t("shared.team-scan-report.heading-detailed-view")}</h4>
            ) : (
                <WithDocs
                    component="h4"
                    modal="TeamScan/Info/ReportTLBDetails"
                    docPath="player/team-scan/report-tlb-details"
                >
                    {t("shared.team-scan-report.heading-detailed-view")}
                </WithDocs>
            )}
            <h5>{t("shared.team-scan-report.heading-statements")}</h5>
            <ol type="A">
                {tlb.questionIds.map((id) => (
                    <li key={id}>{t(`shared.team-scan-report-question.${id}`)}</li>
                ))}
            </ol>
            <ChartRow>
                <div>
                    <SpiderChart values={tlb.questionMeans} />
                </div>
                <div>
                    <QuestionChart values={tlb.questionMeans} />
                </div>
            </ChartRow>
            {printing && <PageBreak />}
            <h4>{t("shared.team-scan-report.heading-key-takeaways")}</h4>
            <Row>
                <Strengths ids={tlb.strengths} allIds={tlb.questionIds} />
                <Weaknesses ids={tlb.weaknesses} allIds={tlb.questionIds} />
            </Row>
            <ActionPoints ids={tlb.actionPoints} allIds={tlb.questionIds} docList={docList} />
            {printing && <PageBreak />}
        </div>
    );
}

export default TLB;
