import styled from "styled-components";

export const Frame = styled.section`
    border: 1px solid black;
    padding: 1rem;
    gap: 1rem;
    max-width: 900px;
    margin: 1rem auto;
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 1rem;
    @media (min-width: 641px) {
        grid-template-columns: repeat(12, 1fr);
        margin: 2rem auto;
    }
`;

interface IBox {
    $align?: "center" | "left";
    $span?: number | [number, number];
}

function getSpan(cols: number | [number, number] | undefined, media: "small" | "large"): string {
    if (cols === undefined) {
        return "span 12";
    }
    if (typeof cols === "number") {
        return `span ${cols}`;
    }
    if (media === "small") {
        return `span ${cols[0]}`;
    }
    return `span ${cols[1]}`;
}

export const Box = styled.div<IBox>`
    text-align: ${({ $align }) => $align ?? "center"};
    grid-column: ${({ $span }) => getSpan($span, "small")};
    @media (min-width: 641px) {
        grid-column: ${({ $span }) => getSpan($span, "large")};
    }
`;
