import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function BushIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m10 72h2.585m71.142 0h6.273" fill="none" stroke="#000" strokeWidth="3" />
            <path
                d="m13.838 70.5c-.222-1.405-.338-2.86-.338-4.35 0-11.636 7.049-21.25 16-21.25.075 0 .151.001.226.002 3.437.064 6.6 1.581 9.17 4.07.405.392.995.524 1.528.342.982-.335 2.013-.514 3.076-.514 2.42 0 4.671.931 6.57 2.51 1.738 1.445 3.182 3.428 4.211 5.76.953 2.16 1.548 4.619 1.688 7.241.02.393.031.789.031 1.189 0 1.74-.201 3.419-.574 5z"
                fill="#fff"
            />
            <path
                d="m12.585 72c-.382-1.867-.585-3.828-.585-5.85 0-12.556 7.841-22.75 17.5-22.75.085 0 .169.001.253.002 3.814.071 7.334 1.731 10.187 4.493 1.137-.388 2.33-.595 3.56-.595 2.771 0 5.354 1.049 7.529 2.857 1.905 1.584 3.496 3.751 4.624 6.308 1.023 2.317 1.664 4.954 1.813 7.766.023.419.034.842.034 1.269 0 2.29-.326 4.481-.921 6.5z"
                fill="none"
                stroke="#000"
                strokeWidth="3"
            />
            <path
                d="m32.303 42.161c3.272-4.679 7.956-7.661 13.197-7.661 4.066 0 7.804 1.803 10.801 4.794.488.488 1.247.579 1.837.221 1.174-.712 2.482-1.115 3.862-1.115 4.599 0 8.302 4.336 9.26 9.941.089.523.448.96.944 1.149 6.018 2.294 10.296 9.804 10.296 18.61 0 .812-.037 1.613-.108 2.4h-23.894c.327-1.595.502-3.271.502-5 0-.453-.012-.903-.036-1.349-.159-3.002-.847-5.817-1.938-8.292-1.227-2.78-2.966-5.132-5.038-6.856-2.45-2.037-5.367-3.203-8.488-3.203-1.086 0-2.147.141-3.172.411-2.351-2.083-5.082-3.496-8.025-4.05z"
                fill={active ? "#00ba9e" : "#fff"}
            />
            <g fill="none" stroke="#000" strokeWidth="3">
                <path d="m29.753 43.402c3.549-6.304 9.283-10.402 15.747-10.402 4.461 0 8.573 1.951 11.86 5.232 1.41-.854 2.983-1.332 4.64-1.332 5.249 0 9.645 4.79 10.738 11.189 6.498 2.476 11.262 10.503 11.262 20.011 0 1.333-.094 2.637-.273 3.9h-27.148c.595-2.019.921-4.21.921-6.5 0-.427-.011-.85-.034-1.269-.149-2.812-.79-5.449-1.813-7.766-1.128-2.557-2.719-4.724-4.624-6.308-2.175-1.808-4.758-2.857-7.529-2.857-1.23 0-2.423.207-3.56.595-2.853-2.762-6.373-4.422-10.187-4.493z" />
                <path d="m38 72 6-11" />
                <path d="m28 72-1-10-5-4.3 5 4.3 5-8" />
                <path d="m61 47-6 5.5" />
                <path d="m66 72 5-7 3-4-3 4-4-8" />
                <path d="m46 42 1 5" />
            </g>
        </svg>
    );
}

export default BushIcon;
