import { type MutableRefObject, useCallback, useEffect, useRef } from "react";

function useScrollIntoView<T extends HTMLElement>(
    open: boolean,
    behavior: ScrollBehavior = "smooth",
    block: ScrollLogicalPosition = "start",
    inline: ScrollLogicalPosition = "nearest",
): MutableRefObject<T> {
    const ref = useRef<T>(undefined);
    const scroll = useCallback(() => {
        ref.current?.scrollIntoView({
            behavior,
            block,
            inline,
        });
    }, [behavior, block, inline]);
    useEffect(() => {
        if (open) {
            scroll();
        }
    }, [open, scroll]);

    return ref;
}

export default useScrollIntoView;
