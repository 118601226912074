import { type IPathWithItems, pathWithItemsFragment } from "../tasksSidebar";

// Submit custom task mutation

import type {
    AnswerType,
    ContentType,
    PollChartType,
    TaskIcon,
    TaskThreadItemSubtype,
    TaskThreadItemType,
    VariantType,
} from "../../shared/types";
// Path with items query

export interface IPathArgs {
    id: string;
}

export const pathQuery = `
    ${pathWithItemsFragment}
    query($id:String!) {
        learningPath(id:$id) {
            id
            ...PathWithItems
        }
    }
`;

export interface IPathData {
    learningPath: {
        id: string;
    } & IPathWithItems;
}

export interface ISubmitArgs {
    kind: string;
    title: string;
    answer: string;
}

export const submitMutation = `
mutation($kind:String!,$title:String!,$answer:String!) {
    submitCustomTask(kind:$kind,title:$title,answer:$answer) {
        error
        redirect
        submittedTask {
            id
            learningPathItem {
                id
                learningPath {
                    id
                }
            }
        }
    }
}
`;

export interface ISubmitData {
    submitCustomTask: {
        error: string | null;
        redirect: string | null;
        submittedTask: {
            id: string;
            learningPathItem: {
                id: string;
                learningPath: {
                    id: string;
                };
            };
        } | null;
    };
}

// Rejected task query

export interface IQueryArgs {
    id: string;
}

export const query = `
    ${pathWithItemsFragment}
    query($id:String!) {
        submittedTask(id:$id) {
            id
            kind
            shortTitle
            title
            icon
            submittedTaskVariant {
                id
                variantType
                variantTitle
                description
                hoursQuestions
                eventStart
                eventEnd
                taskQuestions {
                    id
                    answerType
                    questionContent
                }
            }
            learningPathItem {
                id
                learningPath {
                    id
                    ...PathWithItems
                }
            }
            thread {
                id
                type
                subtype
                contentType
                content
                person {
                    id
                    firstName
                    lastName
                    profilePicSrc
                }
                taskAnswers {
                    id
                    answerType
                    questionContent
                    answerContent
                    checkboxAnswers {
                        id
                        content
                        checked
                    }
                    taskLikertAnswers {
                        id
                        statement
                        leftLabel
                        rightLabel
                        value
                    }
                    showPollResults
                    quizAnswerSet {
                        id
                        results
                        answers {
                            id
                            answer
                            answeredQuestion {
                                id
                                number
                                levelNumber
                                description
                                correctAnswer
                                explanation
                                quizOptions {
                                    id
                                    letter
                                    content
                                }
                            }
                        }
                    }
                    pollAnswerSet {
                        id
                        answers {
                            id
                            answers
                            question {
                                id
                                number
                                content
                                chartType
                                options {
                                    id
                                    number
                                    content
                                    answerCount
                                }
                            }
                        }
                    }
                    taskAnswerFile {
                        id
                        logoSrc
                        src
                        name
                    }
                    impactTrackerAnswerSet {
                        id
                        impactLikertAnswers {
                            id
                            statement
                            leftLabel
                            rightLabel
                            value
                        }
                    }
                }
            }
        }
    }
`;

interface IPerson {
    id: string;
    firstName: string;
    lastName: string;
    profilePicSrc: string;
}

interface IQuizAnswer {
    id: string;
    answer: string;
    answeredQuestion: {
        id: string;
        number: number;
        levelNumber: number;
        description: string;
        correctAnswer: string;
        explanation: string | null;
        quizOptions: {
            id: string;
            letter: string;
            content: string;
        }[];
    };
}

interface IQuizAnswerSet {
    id: string;
    results: [number, number];
    answers: IQuizAnswer[];
}

interface IPollAnswer {
    id: string;
    answers: number[];
    question: {
        id: string;
        number: number;
        content: string;
        chartType: PollChartType;
        options: {
            id: string;
            number: number;
            content: string;
            answerCount: number;
        }[];
    };
}

interface IPollAnswerSet {
    id: string;
    answers: IPollAnswer[];
}

interface ITaskAnswerFile {
    id: string;
    src: string;
    logoSrc: string;
    name: string;
}

interface ITaskAnswer {
    id: string;
    answerType: AnswerType;
    questionContent: string | null;
    answerContent: string | null;
    checkboxAnswers: {
        id: string;
        content: string;
        checked: boolean;
    }[];
    taskLikertAnswers: {
        id: string;
        statement: string;
        leftLabel: string;
        rightLabel: string;
        value: number;
    }[];
    showPollResults: boolean;
    quizAnswerSet: IQuizAnswerSet;
    pollAnswerSet: IPollAnswerSet;
    taskAnswerFile: ITaskAnswerFile | null;
    impactTrackerAnswerSet: {
        id: string;
        impactLikertAnswers: {
            id: string;
            statement: string;
            leftLabel: string;
            rightLabel: string;
            value: number;
        }[];
    } | null;
}

interface ITaskThreadItem {
    id: string;
    type: TaskThreadItemType;
    subtype: TaskThreadItemSubtype;
    contentType: ContentType;
    content: string | null;
    person: IPerson;
    taskAnswers: ITaskAnswer[];
}

interface ITaskQuestion {
    id: string;
    answerType: AnswerType;
    questionContent: string;
}

interface ISubmittedTaskVariant {
    id: string;
    variantType: VariantType;
    variantTitle: string;
    description: string;
    hoursQuestions: string;
    eventStart: string | null;
    eventEnd: string | null;
    taskQuestions: ITaskQuestion[];
}

export interface ISubmittedTask {
    id: string;
    kind: string;
    shortTitle: string;
    title: string | null;
    icon: TaskIcon;
    miles: number;
    submittedTaskVariant: ISubmittedTaskVariant;
    thread: ITaskThreadItem[];
    learningPathItem: {
        id: string;
        learningPath: {
            id: string;
        } & IPathWithItems;
    };
}

export interface IQueryData {
    submittedTask: ISubmittedTask;
}

// Submit rejected task mutation

export interface IRejectedArgs {
    taskId: string;
    title: string;
    answer: string;
}

export const rejectedMutation = `
mutation($taskId:String!,$title:String!,$answer:String!) {
    submitCustomRejectedTask(taskId:$taskId,title:$title,answer:$answer) {
        error
        redirect
        submittedTask {
            id
            learningPathItem {
                id
                learningPath {
                    id
                }
            }
        }
    }
}
`;

export interface IRejectedData {
    submitCustomRejectedTask: {
        error: string | null;
        redirect: string | null;
        submittedTask: {
            id: string;
            learningPathItem: {
                id: string;
                learningPath: {
                    id: string;
                };
            };
        } | null;
    };
}
