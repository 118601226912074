import styled from "styled-components";
import t from "../../../shared/translations";
import { Center } from "../stylings";
import type { JSX } from "react";

interface IProps {
    myLink: string | null;
}

const Img = styled.img`
    width: 300px;
    height: 300px;
    max-width: 100%;
`;

function Step5({ myLink }: IProps): JSX.Element {
    return (
        <Center>
            <h1>{t("player.team-scan-setup-5.heading")}</h1>
            <Img src="/static/images/highfive.svg" />
            <h2>{t("player.team-scan-setup-5.heading-well-done")}</h2>
            <p>{t("player.team-scan-setup-5.info-take-team-scan")}</p>
            {myLink && (
                <a href={myLink} className="button">
                    {t("player.team-scan-setup-5.link-team-scan")}
                </a>
            )}
        </Center>
    );
}

export default Step5;
