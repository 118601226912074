import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    faded?: boolean;
}

function NoteIcon({ faded, ...props }: IProps): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 90 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m70 24.004c0-4.418-3.586-8.004-8.004-8.004h-36.992c-4.418 0-8.004 3.586-8.004 8.004v45.992c0 4.418 3.586 8.004 8.004 8.004h36.992c4.418 0 8.004-3.586 8.004-8.004z"
                fill="#fff"
            />
            <g fillRule="nonzero">
                <path
                    d="m84.6 8.9c-.2-1.1-.8-2.1-1.8-2.8l-6-4.2c-.9-.7-2.1-.9-3.2-.7s-2.1.8-2.8 1.8l-8.2 11.6h-37c-5.5 0-10 4.5-10 10v45.2c0 5.5 4.5 10 10 10h35.5c5.5 0 10-4.5 10-10v-39.6l12.8-18c.6-1 .9-2.1.7-3.3zm-16.5 60.8c0 3.9-3.1 7-7 7h-35.5c-3.9 0-7-3.1-7-7v-45.2c0-3.9 3.1-7 7-7h34.9l-15.5 21.8c-1.1 1.5-2 4.1-2.2 5.9l-.8 9.2c-.1 1.3.3 2.4 1.2 3 .4.2.8.4 1.3.4.6 0 1.3-.2 1.9-.6l7-4.4c1.5-.9 3.5-2.8 4.5-4.2l10-14.1zm-15.4-20c-.2.2-.5.4-.7.5l-6.9 4.3.8-9.1c0-.2 0-.3.1-.5z"
                    fill={faded ? "#aaa" : "#222"}
                />
                <path
                    d="m81.4 10.4-25.8 36.4c-.2.3-.4.5-.7.8l-8-5.7c.2-.4.4-.7.5-.9l25.8-36.4c.2-.3.5-.5.9-.5.4-.1.7 0 1 .2l6 4.2c.6.5.8 1.3.3 1.9z"
                    fill={faded ? "#fff" : "#ff855f"}
                />
            </g>
        </svg>
    );
}

export default NoteIcon;
