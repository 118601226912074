import { type ComponentPropsWithoutRef, memo, type JSX } from "react";

type IProps = ComponentPropsWithoutRef<"svg">;

function HomeIcon(props: IProps): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
            <path d="M95.1,48.5c-0.7-1.6-2.3-3.8-3.7-5L53.3,12.2c-1.8-1.5-4.5-1.5-6.3,0L8.8,43.5c-1.3,1.1-3,3-4,4.4 c-0.2,0.3-0.7,1.1-0.7,2.9c0,2.1,0.9,4,2.6,5.4c3.3,2.9,8.5,1.3,8.7,1.2c1.6-0.5,3.8-1.8,5.2-2.9v30c0,2.4,1.9,4.3,4.3,4.3h10.3 c2.4,0,4.3-1.9,4.3-4.3V66.5c0-0.7,0.6-1.3,1.3-1.3H60c0.7,0,1.3,0.6,1.3,1.3v18.1c0,2.4,1.9,4.3,4.3,4.3h9.8 c2.4,0,4.3-1.9,4.3-4.3v-30c1.3,1.1,3.3,2.5,4.7,3.3c0.2,0.1,1.2,0.6,3.2,0.6c2.2,0,4.3-0.8,5.9-2.2 C97.1,53.2,95.2,48.7,95.1,48.5z M75.4,85.9h-9.8c-0.7, 0-1.3-0.6-1.3-1.3V66.5c0-2.4-1.9-4.3-4.3-4.3H40.9c-2.4,0-4.3,1.9-4.3,4.3 v18.1c0,0.7-0.6,1.3-1.3,1.3H24.9c-0.7,0-1.3-0.6-1.3-1.3V52.4c0-0.1,0-0.3-0.1-0.4L49,29.8c0.6-0.5,1.7-0.5,2.3,0L76.7,52v32.6 C76.7,85.3,76.1,85.9,75.4,85.9z M91.6,54c-1,0.9-2.5,1.4-4,1.4c-1.2,0-1.7-0.2-1.7-0.2c0,0,0,0,0,0c-1.3-0.7-3.3-2.1-4.4-3.1 l-2-1.7c-0.2-0.3-0.5-0.6-0.8-0.7L53.3,27.5c-1.8-1.5-4.5-1.5-6.2,0L18.8,52.2c-1,0.9-3,2-4.3,2.5c0,0-3.7,1.2-5.8-0.6 c-1-0.9-1.5-2-1.5-3.2c0-0.8,0.1-1.1,0.1-1.2c0.9-1.2,2.5-2.9,3.5-3.8l38.1-31.4c0.6-0.5,1.8-0.5,2.5,0l38.1,31.3 c1,0.9,2.3,2.7,2.9,3.9C92.4,49.9,93.4,52.4,91.6,54z" />
        </svg>
    );
}

export default memo(HomeIcon);
