import Confetti from "react-confetti";
import styled, { keyframes } from "styled-components";
import useWindowSize from "../../shared/hooks/useWindowSize";
import t from "../../shared/translations";
import Banner from "../performance/Banner";
import Flag from "../performance/Flag";
import type { IData } from "./index";
import type { JSX } from "react";

interface IProps {
    data: IData;
}

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const P = styled.p`
    text-align: center;
    font-size: 1.8rem;
    margin-top: 1rem;
    animation: ${fadeIn} 1.5s ease 1 forwards;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    animation: ${fadeIn} 1.5s ease 1 forwards;
`;

function ConfettiAward({ data }: IProps): JSX.Element {
    const { width, height } = useWindowSize();
    return (
        <>
            <Confetti width={width} height={height} />
            <P>
                {data.passedGoal
                    ? t("player.flipper.heading-goal", { miles: data.milesGoal })
                    : t("player.flipper.heading-checkpoint", { miles: data.milesCheckpoint })}
            </P>
            <Center>{data.passedGoal ? <Flag /> : <Banner />}</Center>
        </>
    );
}

export default ConfettiAward;
