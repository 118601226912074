import type { ComponentPropsWithoutRef } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function BackpackIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m24.5 35 c10 20, 20 15, 22 18 q0 4, 4 4 q4 0, 4 -4 q16 -2, 22 -18 q-4 -14, -18 -16 q-8 -12, -16 0q-15 2, -18 16z"
                fill="#fff"
            />
            <path
                d="m58.8 17.6 0 0c-1.3-3.5-4.6-5.9-8.3-5.9-3.5 0-6.7 2.1-8.1 5.3-.1.2-.1.4-.1.6-11 .8-19.7 10-19.7 21.3v28.1c0 11.8 9.6 21.3 21.3 21.3h13.2c11.8 0 21.3-9.6 21.3-21.3v-28.1c.2-11.3-8.6-20.5-19.6-21.3zm-8.3-2.9c2 0 3.9 1.1 4.9 2.8h-9.8c1-1.7 2.9-2.8 4.9-2.8zm2.5 39.5c0 .7-.6 1.3-1.3 1.3h-2.1c-.7 0-1.3-.6-1.3-1.3v-6.6c0-.7.6-1.3 1.3-1.3h2c .7 0 1.3.6 1.3 1.3zm22.2-18.5c-3.5 8.1-10.8 13.9-19.2 15.5v-3.5c0-2.4-1.9-4.3-4.3-4.3h-2.1c-2.4 0-4.3 1.9-4.3 4.3v3.3c-8.6-1.9-15.9-8.1-19-16.5 2-8 9.2-14 17.8-14h13.2c8.9 0 16.4 6.5 17.9 15.2 0-.1 0-.1 0 0z"
                fill="#222"
            />
            <path
                d="m75.6 66.9c0 10.1-8.2 18.3-18.3 18.3h-13.3c-10.1.1-18.4-8.2-18.4-18.3v-26.7c4.3 7.2 11.4 12.2 19.6 13.8v.1c0 2.4 1.9 4.3 4.3 4.3h2.1c2.4 0 4.3-1.9 4.3-4.3 8-1.4 15.1-6.1 19.6-12.9z"
                fill={active ? "#ff855d" : "#fff"}
            />
        </svg>
    );
}

export default BackpackIcon;
