import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function HeartIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m86.6 40.65c-3.3 8.7-30.9 37.8-36.7 43.9-5.8-6.1-33.4-35.1-36.7-43.9-1.2-3.1-1.3-4.4-1.3-6.9 0-10.5 8.6-19.1 19.1-19.1 7.5 0 14.4 4.4 17.5 11.3.2.5.8.9 1.4.9s1.1-.3 1.4-.9c3.1-6.9 9.9-11.3 17.5-11.3 10.5 0 19.1 8.6 19.1 19.1-.1 2.6-.2 3.9-1.3 6.9z"
                fill={active ? "#ce4a99" : "white"}
            />
            <path
                d="m68.7 11.75c-7.7 0-14.8 4-18.8 10.5-4-6.5-11.1-10.5-18.8-10.5-12.2 0-22.1 9.9-22.1 22.1 0 2.7.1 4.4 1.5 7.9 3.9 10.3 37 44.5 38.4 46 .3.3.7.5 1.1.5s.8-.2 1.1-.5c1.4-1.5 34.5-35.7 38.4-46 1.3-3.5 1.5-5.2 1.5-7.9-.2-12.2-10.1-22.1-22.3-22.1zm17.9 28.9c-3.3 8.7-30.9 37.8-36.7 43.9-5.8-6.1-33.4-35.1-36.7-43.9-1.2-3.1-1.3-4.4-1.3-6.9 0-10.5 8.6-19.1 19.1-19.1 7.5 0 14.4 4.4 17.5 11.3.2.5.8.9 1.4.9s1.1-.3 1.4-.9c3.1-6.9 9.9-11.3 17.5-11.3 10.5 0 19.1 8.6 19.1 19.1-.1 2.6-.2 3.9-1.3 6.9z"
                fill="#222"
                fillRule="nonzero"
            />
        </svg>
    );
}

export default HeartIcon;
