import t from "./translations";

interface INamedGroup {
    name: string | null;
    number: number;
}

export default function fmtGroupName(group: INamedGroup): string {
    if (group.name) {
        return group.name;
    }
    return t("shared.fmt-group-name.label-group-name", { nr: group.number });
}
