import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function BoatIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m90.6 44.4c-.3-.4-.7-.6-1.2-.6h-11c-.4 0-.7.1-1 .4l-5.1 4.5v-7.7c0-.8-.7-1.5-1.5-1.5h-2.7v-3c0-3.2-2.6-5.8-5.8-5.8h-11.1c-3.2 0-5.8 2.6-5.8 5.8v3h-4.2v-17.1c0-.8-.7-1.5-1.5-1.5h-7.2c-.8 0-1.5.7-1.5 1.5v17.1h-5.5c-.8 0-1.5.7-1.5 1.5v8.2h-11c-.5 0-.9.2-1.2.6s-.4.9-.3 1.3l6.8 24.8c-2.8 0-5.2-1.7-6.2-4.3-.3-.8-1.2-1.1-1.9-.8-.8.3-1.2 1.2-.9 1.9 1.5 3.7 5 6.2 9 6.2.8 0 1.6-.1 2.3-.3 2-.5 3.9-1.7 5.2-3.3 1.8 2.4 4.6 3.8 7.7 3.8s5.9-1.4 7.7-3.8c1.8 2.3 4.6 3.6 7.6 3.6s5.7-1.4 7.6-3.6c1.8 2.4 4.6 3.8 7.7 3.8s5.9-1.4 7.7-3.8c1.8 2.3 4.6 3.6 7.6 3.6 3.8 0 7.2-2.2 8.8-5.6.3-.3.4-.8.2-1.2-.2-.8-1-1.3-1.8-1.1-.5.1-.9.4-1 .9-1 2.5-3.4 4.1-6.1 4.1l11.2-30.1c.2-.6.1-1.1-.1-1.5z"
                fill="#1e1e1c"
                fillRule="nonzero"
            />
            <path d="m27 42.6h42.2v6.5h-42.2z" fill="#fff" />
            <path d="m34 23.9h4.2v15.4h-4.2z" fill="#fff" />
            <g fillRule="nonzero">
                <path
                    d="m48.4 36.6c0-1.5 1.2-2.8 2.8-2.8h11.2c1.5 0 2.8 1.2 2.8 2.8v3h-16.8z"
                    fill={active ? "#1177bc" : "#fff"}
                />
                <path
                    d="m76.5 75.2c-1.5-.7-2.7-2-3.3-3.6-.2-.6-.9-1-1.5-.9-.7 0-1.2.5-1.4 1.1l-.1.2c-1.1 2.4-3.5 4-6.1 4-2.8 0-5.2-1.7-6.2-4.3-.3-.7-1.1-1.1-1.8-.9-.5.1-.9.4-1 .9-1.1 2.5-3.5 4.1-6.2 4.1-2.8 0-5.2-1.7-6.2-4.3-.2-.6-.8-1-1.5-.9-.7 0-1.2.5-1.4 1.1l-.1.2c-1.1 2.4-3.5 4-6.1 4-2.8 0-5.2-1.7-6.2-4.3-.3-.7-1.1-1.1-1.8-.9-.5.1-.9.4-1 .9-.6 1.5-1.8 2.7-3.2 3.4l-6.3-23h57.1c.4 0 .7-.1 1-.4l5.8-5.1h8.3z"
                    fill={active ? "#1177bc" : "#fff"}
                />
                <path d="m78.1 51.1c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2-1-2.2-2.2-2.2-2.2 1-2.2 2.2z" fill="#222" />
                <path d="m61.8 60.1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" fill="#222" />
                <path d="m70.1 60.1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" fill="#222" />
                <path d="m45.2 60.1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" fill="#222" />
                <path d="m53.5 60.1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" fill="#222" />
                <path d="m28.6 60.1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.8-2-2-2z" fill="#222" />
                <path d="m36.9 60.1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" fill="#222" />
            </g>
        </svg>
    );
}

export default BoatIcon;
