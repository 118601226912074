import { type JSX, memo } from "react";
import styled from "styled-components";
import * as colors from "../colors";
import { formatYMD, parseDate } from "../dateFns";
import eventDateStrs from "../eventDateStrs";
import t from "../translations";
import type { TaskIcon as TaskIconType, TimeEstimateType, VariantType } from "../types";
import FlexVideo from "./FlexVideo";
import TaskIcon from "./icons/TaskIcon";
import ClockIcon from "./icons/taskIcons/ClockIcon";

interface IMedia {
    id: string | number;
    mediaType: string;
    src: string;
    name: string;
    logo?: string | null;
}

const backgroupColor = colors.secondaryColor;
const invertBackgroundColor = colors.altSecondaryColor;
const borderColor = `1px solid ${colors.primaryColor}`;
const invertBorderColor = "none";
const textColor = colors.text;
const invertTextColor = colors.altText;
const deadlineColor = colors.deadline;
const finishedColor = colors.finished;
const invertFinishedColor = colors.altPrimaryColor;

type IStatus = "redo" | "deadline" | "finished" | null;

interface IProps {
    icon: TaskIconType;
    miles: number | null;
    status?: IStatus;
    deadline?: string;
    finishDatetime?: string;
    media: IMedia[];
    eventStart?: string;
    eventEnd?: string;
    invert?: boolean;
    timeEstimate?: TimeEstimateType | null;
    variant: {
        variantType: VariantType;
        eventStart: string | null;
        eventEnd: string | null;
    };
}

interface Invertible {
    $invert?: boolean;
}

const InfoBox = styled.section<Invertible>`
    grid-area: info;
    max-width: 100%;
    border-radius: 1rem;
    padding: 0.6rem;
    background-color: ${({ $invert }) => ($invert ? invertBackgroundColor : backgroupColor)};
    border: ${({ $invert }) => ($invert ? invertBorderColor : borderColor)};
    color: ${({ $invert }) => ($invert ? invertTextColor : textColor)};

    @container (min-width: 640px) {
        padding: 1rem;
    }

    h1, h2, h3, h4, h5, h6 {
        color: ${({ $invert }) => ($invert ? invertTextColor : textColor)};
    }

    a {
        color: ${({ $invert }) => ($invert ? invertTextColor : colors.primaryColor)};
        &:hover, &:focus, &:active, &:visited {
            color: ${({ $invert }) => ($invert ? invertTextColor : colors.primaryColor)};
        }
    }
`;

const TaskBadge = styled.div`
    display: grid;
    flex-direction: column;
    justify-items: center;
    & > svg {
        width: 8rem;
        height: 8rem;
        flex-shrink: 0;
    }
`;

const MilesBox = styled.div`
    display: grid;
    justify-items: center;
    margin-bottom: 0.5rem;
    & > div:first-child {
        font-size: 4rem;
        line-height: 1;
    }
    & > div:last-child {
        font-size: 1.5rem;
    }
`;

const Deadline = styled.span<Invertible>`
    background-color: ${({ $invert }) => ($invert ? "white" : deadlineColor)};
    color: ${({ $invert }) => ($invert ? deadlineColor : "white")};
    padding: 0.2rem 1.5rem;
    display: inline-block;
    border: ${({ $invert }) => ($invert ? `3px solid ${deadlineColor}` : "none")};
`;

const Finished = styled.span<Invertible>`
    background-color: ${({ $invert }) => ($invert ? invertFinishedColor : finishedColor)};
    color: white;
    padding: 0.2rem 1.5rem;
    display: inline-block;
`;

const FinishDate = styled.div`
    text-align: center;
    margin-top: 0.5rem;
`;

const TimeEstimate = styled.div`
    display: grid;
    grid-template-columns: 5rem 1fr;
    align-items: center;
    justify-content: center;
    gap: 0;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    svg {
        width: 5rem;
        height: 5rem;
    }
`;

const MediaSection = styled.div`
    margin-top: 4rem;
`;

const MediaList = styled.ul`
    list-style-type: none;
    margin: 0;
`;

const Audio = styled.audio`
    width: 100%;
`;

const FileList = styled.ul`
    list-style: none;
    padding-left: 1rem;
    & > li {
        position: relative;
        margin-bottom: 0.3rem;
        &:before {
            content: '';
            position: absolute;
            height: 1.5rem;
            width: 1.5rem;
            top: 0.2rem;
            left: -2rem;
            background-size: 1.5rem 1.5rem;
            background-repeat: no-repeat;
        }
    }
`;

interface FLProps {
    $logo: string;
}

function bgUrl(logo: string): string {
    if (logo) {
        return `/static/images/${logo}_logo.svg`;
    }
    return "";
}

const FileLI = styled.li<FLProps>`
    &:before {
        background-image: url(${(props) => bgUrl(props.$logo)});
    }
`;

const EventTime = styled.div`
    margin: 0.7rem 0;
    text-align: center;
`;

function TaskInfoCol({
    icon,
    miles,
    status,
    deadline,
    finishDatetime,
    media,
    eventStart,
    eventEnd,
    invert,
    variant,
    timeEstimate,
}: IProps): JSX.Element {
    const multiMedia = media.filter(
        (med) =>
            med.mediaType === "Audio" ||
            med.mediaType === "LegacyAudio" ||
            med.mediaType === "YouTube" ||
            med.mediaType === "Vimeo",
    );
    const files = media.filter((med) => med.mediaType === "File");
    let statusSection = null;
    if (status === "deadline") {
        statusSection = (
            <div>
                {deadline ? t("shared.task-description.label-deadline") : t("shared.task-description.label-status")}{" "}
                <Deadline $invert={invert}>{deadline || t("shared.task-description.status-not-done")}</Deadline>
            </div>
        );
    } else if (status === "redo") {
        statusSection = (
            <div>
                {t("shared.task-description.label-status")}{" "}
                <Deadline $invert={invert}>{t("shared.task-description.status-redo")}</Deadline>
            </div>
        );
    } else if (status === "finished") {
        statusSection = (
            <div>
                {t("shared.task-description.label-status")}{" "}
                <Finished $invert={invert}>{t("shared.task-description.status-finished")}</Finished>
            </div>
        );
    }
    eventStart = eventStart ?? variant?.eventStart;
    eventEnd = eventEnd ?? variant?.eventEnd;
    let eventStr: string = null;
    if (eventStart) {
        const { startStr, endStr } = eventDateStrs(parseDate(eventStart), eventEnd ? parseDate(eventEnd) : null);
        eventStr = startStr;
        if (endStr) {
            eventStr = `${eventStr} - ${endStr}`;
        }
    }
    return (
        <InfoBox $invert={invert}>
            <TaskBadge>
                <TaskIcon icon={icon} active={status === "finished"} />
                {miles != null && (
                    <MilesBox>
                        <div>{miles}</div>
                        <div>{t("shared.task-description.label-miles")}</div>
                    </MilesBox>
                )}
                {timeEstimate && (
                    <TimeEstimate>
                        <ClockIcon />
                        <span>{t(`shared.time-estimate.${timeEstimate}`)}</span>
                    </TimeEstimate>
                )}
                {statusSection}
            </TaskBadge>
            {eventStr != null && (
                <EventTime>
                    <span>{t("shared.task-description.label-event")}</span>
                    <br />
                    <Finished $invert={invert}>{eventStr}</Finished>
                </EventTime>
            )}
            {finishDatetime && (
                <FinishDate>
                    {t("shared.task-description.info-finish-date", {
                        finish_date: formatYMD(parseDate(finishDatetime)),
                    })}
                </FinishDate>
            )}

            {multiMedia.length > 0 && (
                <MediaSection>
                    <h4>{t("shared.task-description.heading-media")}</h4>
                    <MediaList>
                        {multiMedia.map((m) => (
                            <li key={m.id}>
                                {(m.mediaType === "YouTube" || m.mediaType === "Vimeo") && (
                                    <FlexVideo>
                                        <iframe
                                            title={`${m.mediaType} video`}
                                            width="270"
                                            height="152"
                                            src={m.src}
                                            frameBorder="0"
                                            allowFullScreen
                                        />
                                    </FlexVideo>
                                )}
                                {(m.mediaType === "Audio" || m.mediaType === "LegacyAudio") && (
                                    <div>
                                        <span>{m.name}</span>
                                        <Audio src={m.src} controls />
                                    </div>
                                )}
                            </li>
                        ))}
                    </MediaList>
                </MediaSection>
            )}
            {files.length > 0 && (
                <MediaSection>
                    <h4>{t("shared.task-description.heading-attachements")}</h4>
                    <FileList>
                        {files.map((f) => (
                            <FileLI key={f.id} $logo={f.logo}>
                                <a href={f.src} target="_blank" rel="noreferrer">
                                    {f.name}
                                </a>
                            </FileLI>
                        ))}
                    </FileList>
                </MediaSection>
            )}
        </InfoBox>
    );
}

export default memo(TaskInfoCol);
