import type { JSX } from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import ShowError from "../components/ShowError";
import MonthContainer from "./MonthContainer";
import PrefForm from "./PrefForm";
import { useCalendarData } from "./processData";
import { type IData, query } from "./query";

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1030px;
    width: 100%;
    margin-bottom: 2rem;
`;

function Calendar(): JSX.Element {
    const [result] = useQuery<IData>({
        query,
    });
    useCalendarData(result);
    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError />;
    } else {
        main = <MonthContainer />;
    }
    return (
        <Wrapper style={{ height: result.fetching ? 200 : "auto" }}>
            <h1>{t("player.calendar.heading")}</h1>
            <PrefForm loading={result.fetching} />
            {main}
        </Wrapper>
    );
}

export default Calendar;
