import Linkify from "linkify-react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "urql";
import t from "../../../shared/translations";
import { backToStep, type Dispatch, type State, toStep5 } from "../../state";
import { Center, PaddedLeft } from "../stylings";
import type { JSX } from "react";

const createSurveysMutation = `
    mutation($data:PlayerTeamScanCreateSurveysData!) {
        teamScanCreateSurveys(data:$data) {
            error
            teamScan {
                __typename
                myLink
            }
        }
    }
`;

interface IData {
    teamScanCreateSurveys: {
        error: string | null;
        teamScan: {
            myLink: string;
        } | null;
    };
}

interface ICreateSurveysArgs {
    data: {
        teamScanId: string;
        industry: string;
        organisationSize: string;
        recipients: {
            email: string;
            firstName: string;
            lastName: string;
            teamLeader: boolean;
            current: boolean;
        }[];
        messageSubject: string;
        messageBody: string;
    };
}

interface IProps {
    teamScanId: string;
}

function Step4({ teamScanId }: IProps): JSX.Element {
    const [sendResult, executeMutation] = useMutation<IData, ICreateSurveysArgs>(createSurveysMutation);
    const dispatch = useDispatch<Dispatch>();
    const state = useSelector((state: State) => state.teamScan[teamScanId]);

    const onNext = async () => {
        if (sendResult.fetching) {
            return;
        }
        const { industry, organisationSize, recipients, msg } = state;
        const args: ICreateSurveysArgs = {
            data: {
                industry,
                messageBody: msg.body,
                messageSubject: msg.title,
                organisationSize,
                recipients,
                teamScanId,
            },
        };
        const result = await executeMutation(args);
        if (result.error || result.data.teamScanCreateSurveys?.error) {
            console.error(result.error || result.data.teamScanCreateSurveys?.error);
        } else {
            dispatch(toStep5({ id: teamScanId, myLink: result.data.teamScanCreateSurveys.teamScan.myLink }));
        }
    };

    const back = () => {
        dispatch(backToStep({ id: teamScanId, step: "step3" }));
    };

    return (
        <>
            <h1>{t("player.team-scan-setup-4.heading")}</h1>
            <p>{t("player.team-scan-setup-4.info-send")}</p>
            <h3>{t("player.team-scan-setup-4.heading-participants")}</h3>
            <ul>
                {state.recipients.map((rep) => (
                    <li key={rep.email}>{rep.email}</li>
                ))}
            </ul>
            <h3>{t("player.team-scan-setup-4.heading-message")}</h3>
            <h4>{t("player.team-scan-setup-4.heading-subject")}</h4>
            <PaddedLeft>{state.msg.title}</PaddedLeft>
            <h4>{t("player.team-scan-setup-4.heading-body")}</h4>
            <PaddedLeft>
                <Linkify options={{ nl2br: true }}>{state.msg.body}</Linkify>
            </PaddedLeft>
            <Center $top="1rem">
                <button onClick={back} type="button">
                    {t("player.team-scan-setup-4.button-back")}
                </button>{" "}
                <button onClick={onNext} type="button">
                    {t("player.team-scan-setup-4.button-send")}
                </button>
            </Center>
        </>
    );
}

export default Step4;
