import { type JSX, memo } from "react";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import type { IFeedbackTask } from "../state";
import { ButtonAnchor, CallToActionBox, DeadlineBox, Label, MilesBox, TwoCol, Wrapper } from "./TaskModal";

interface IProps {
    feedbackTask: IFeedbackTask;
}

function FeedbackTaskModal({ feedbackTask }: IProps): JSX.Element {
    return (
        <Wrapper>
            <h2>
                {t("player.calendar.modal-heading-feedback-task")}: {feedbackTask.shortTitle}
            </h2>
            <h4>{feedbackTask.pathname}</h4>
            {feedbackTask.complete && <p>{t("player.calendar.info-feedback-task-complete")}</p>}
            <TwoCol>
                <Label>{t("player.calendar.modal-label-call-to-action")}:</Label>
                <CallToActionBox $complete={feedbackTask.complete}>
                    {formatYMD(parseDate(feedbackTask.callToAction))}
                </CallToActionBox>
                {feedbackTask.deadline && (
                    <>
                        <Label>{t("player.calendar.modal-label-deadline")}:</Label>
                        <DeadlineBox $complete={feedbackTask.complete}>
                            {formatYMD(parseDate(feedbackTask.deadline))}
                        </DeadlineBox>
                    </>
                )}
                <Label>{t("player.calendar.modal-label-miles")}:</Label>
                <MilesBox>{feedbackTask.miles}</MilesBox>
                {feedbackTask.url && (
                    <ButtonAnchor href={feedbackTask.url}>
                        {t("player.calendar.button-go-to-feedback-task")}
                    </ButtonAnchor>
                )}
            </TwoCol>
        </Wrapper>
    );
}

export default memo(FeedbackTaskModal);
