import { type ComponentPropsWithoutRef, memo, type JSX } from "react";

type IProps = ComponentPropsWithoutRef<"svg">;

function PerformanceIcon(props: IProps): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
            <path d="M93.1,10.4c-0.7-0.4-1.6-0.2-2.1,0.5l-9,14.7c-0.8-0.3-1.7-0.5-2.6-0.5c-3.8,0-6.9,3.1-6.9,7 c0,1.4,0.4,2.7,1.1,3.8l-9.3,8.8c-1.1-0.7-2.4-1.1-3.7-1.1c-3.8,0-6.9,3.1-6.9,7c0,0.5,0,1,0.1,1.4l-13.9,5.5c-1.3-1.3-3-2.1-5-2.1 c-3.8,0-6.9,3.1-6.9,7c0,1.4,0.4,2.8,1.2,3.9c-3.3,6.1-8.5,15.7-10.2,18.7v-73c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5v74.6 c0,0.4,0.2,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4c0,0,0,0,0,0l2.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l71.1-0.5 c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c0,0,0,0,0,0l-68.6,0.5c2.1-3.8,6.2-11.3,9.1-16.6c1,0.6,2.2,1,3.5,1 c3.8,0,6.9-3.1,6.9-7c0-0.8-0.1-1.5-0.4-2.2l13.5-5.4c1.3,1.7,3.3,2.8,5.5,2.8c3.8,0,6.9-3.1,6.9-7c0-1.4-0.4-2.6-1.1-3.7l9.3-8.8 c1.1,0.7,2.3,1,3.6,1c3.8,0,6.9-3.1,6.9-7c0-1.8-0.7-3.5-1.8-4.7l9.1-14.8C94.1,11.8,93.9,10.9,93.1,10.4z M35.1,66.1 c-2.1,0-3.9-1.8-3.9-4s1.7-4,3.9-4s3.9,1.8,3.9,4S37.3,66.1,35.1,66.1z M60.7,54.4c-2.1,0-3.9-1.8-3.9-4s1.7-4,3.9-4 c2.1,0,3.9,1.8,3.9,4S62.8,54.4,60.7,54.4z M79.4,36c-2.1,0-3.9-1.8-3.9-4s1.7-4,3.9-4s3.9,1.8,3.9,4S81.6,36,79.4,36z" />
        </svg>
    );
}

export default memo(PerformanceIcon);
