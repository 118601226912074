import type { AiMessageRoleType } from "../../../shared/types";

export interface IStartArgs {
    taskId: string;
    variantId: string;
}

export const startMutation = `
    mutation($taskId:String!,$variantId:String!) {
        taskAiThreadCreate(taskId:$taskId,variantId:$variantId) {
            error
            taskAiThread {
                id
                submittedTaskVariant {
                    id
                    submittedTask {
                        id
                        task {
                            id
                        }
                    }
                }
                aiThreadItems {
                    id
                    role
                    content
                }
            }
        }
    }
`;

export interface IStartData {
    taskAiThreadCreate: {
        error: string | null;
        taskAiThread: {
            id: string;
            submittedTaskVariant: {
                submittedTask: {
                    id: string;
                    task: {
                        id: string;
                    };
                };
            };
            aiThreadItems: {
                id: string;
                role: AiMessageRoleType;
                content: string;
            }[];
        };
    };
}

export interface IContinueArgs {
    threadId: string;
    input: string;
}

export const continueMutation = `
    mutation($threadId:String!,$input:String!) {
        taskAiThreadContinue(threadId:$threadId,input:$input) {
            error
            taskAiThread {
                id
                aiThreadItems {
                    id
                    role
                    content
                }
            }
        }
    }
`;

export interface IContinueData {
    taskAiThreadContinue: {
        error: string | null;
        taskAiThread: {
            id: string;
            aiThreadItems: {
                id: string;
                role: AiMessageRoleType;
                content: string;
            }[];
        };
    };
}
