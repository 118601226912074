import type { JSX } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import RenderMark from "../../shared/components/RenderMark";
import AiIcon from "../../shared/components/icons/AiIcon";
import { type IStartArgs, type IStartData, startMutation } from "./queries";
import { ChatbotButton, Column } from "./stylings";

interface IProps {
    chatbots: {
        id: string;
        name: string;
        description: string;
    }[];
}

function ChatbotList({ chatbots }: IProps): JSX.Element {
    const [startResult, executeStart] = useMutation<IStartData, IStartArgs>(startMutation);
    const navigate = useNavigate();

    const create = async (id: string) => {
        if (startResult.fetching) {
            return;
        }
        const result = await executeStart({ id });
        if (result.error || result.data.standaloneAiThreadCreate?.error) {
            console.error(result.error);
        } else {
            const id = result.data.standaloneAiThreadCreate.standaloneAiThread.id;
            navigate(`${id}`);
        }
    };

    return (
        <Column>
            {chatbots.map((chatbot) => (
                <ChatbotButton key={chatbot.id} onClick={() => create(chatbot.id)}>
                    <h3>
                        <AiIcon />
                        {chatbot.name}
                    </h3>
                    <RenderMark content={chatbot.description} />
                </ChatbotButton>
            ))}
        </Column>
    );
}

export default ChatbotList;
