import type { ComponentPropsWithoutRef } from "react";
import * as colors from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
    invert?: boolean;
    alert?: boolean;
}

function SpeechBubbleIcon({ active, invert, alert, ...props }: IProps) {
    const fillColor = invert ? "#FFF" : "#222";
    const backgroundFillColor = alert ? colors.deadline : active ? "#0076c2" : "#fff";
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m22.4 81.5c-.7 0-1.2-.2-1.6-.3-1-.5-2.8-1.8-2.8-5.7v-47c0-5.5 4.5-10 10-10h44.1c5.5 0 10 4.5 10 10v27.4c0 5.5-4.5 10-10 10.1l0 0-22.4.2c-4.3 0-10.7 2.5-13.9 5.3l-9 7.9c-1.7 1.6-3.2 2.1-4.4 2.1z"
                fill={fillColor}
            />
            <path
                d="m28.1 21.5c-3.9 0-7 3.1-7 7v46.9c0 1.9.5 2.8 1 3s1.5 0 2.9-1.2l9-7.9c3.7-3.3 10.9-6 15.9-6.1l22.2-.2c3.9 0 7-3.2 7-7.1v-27.4c0-3.9-3.1-7-7-7z"
                fill={backgroundFillColor}
            />
            <g fill={fillColor}>
                <circle cx="35.3" cy="51.4" r="4.1" />
                <circle cx="50.1" cy="51.4" r="4.1" />
                <circle cx="64.9" cy="51.4" r="4.1" />
            </g>
        </svg>
    );
}

export default SpeechBubbleIcon;
