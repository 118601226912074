import { useForm } from "react-final-form";
import styled from "styled-components";
import * as colors from "../colors";
import { CircleRow, Grid, Label, Statement } from "./LikertPreview";
import LikertRadio from "./LikertRadio";
import type { JSX } from "react";

interface IProps {
    name: string;
    statement?: string;
    leftLabel: string;
    rightLabel: string;
}

const ErrorLabel = styled.small`
    color: ${colors.white};
    background-color: ${colors.alert};
    padding: 0.5rem 1rem;
    border-radius: 8px;
    display: block;
    font-size: .75rem;
    font-style: italic;
`;

function LikertInput({ name, statement, leftLabel, rightLabel }: IProps): JSX.Element {
    const meta = useForm().getFieldState(name);
    return (
        <>
            <Grid>
                {statement && <Statement>{statement}</Statement>}
                <Label $area="leftLabel">{leftLabel}</Label>
                <CircleRow>
                    <LikertRadio name={name} value={1} size={20} />
                    <LikertRadio name={name} value={2} size={25} />
                    <LikertRadio name={name} value={3} size={30} />
                    <LikertRadio name={name} value={4} size={35} />
                    <LikertRadio name={name} value={5} size={40} />
                </CircleRow>
                <Label $area="rightLabel">{rightLabel}</Label>
            </Grid>
            {meta?.touched && meta?.error && <ErrorLabel>{meta.error}</ErrorLabel>}
        </>
    );
}

export default LikertInput;
