import * as Sentry from "@sentry/browser";
import Empty from "./app/Empty";
import ProfileNavPage from "./app/ProfileNavPage";
import AcceptTerms from "./app/acceptTerms";
import AiChatbots from "./app/aiChatbots";
import Calendar from "./app/calendar";
import Chat from "./app/chat/index";
import CustomTask from "./app/customTask/index";
import Event from "./app/event";
import FirstTimeLogin from "./app/firstTimeLogin";
import Flipper from "./app/flipper/index";
import Inspiration from "./app/inspiration";
import Landing from "./app/landing/index";
import Performance from "./app/performance/index";
import Pip from "./app/pip/index";
import ProvideFeedback from "./app/provideFeedback/index";
import Pulse from "./app/pulse";
import ReplacePassword from "./app/replacePassword";
import SubmittedTask from "./app/submittedTask/index";
import Task from "./app/task";
import TaskChoice from "./app/taskChoice/index";
import TeamScans from "./app/teamScans";
import Terms from "./app/terms/index";
import { config, userConfig } from "./sentry.config";
import { initDateLocale } from "./shared/dateFns";
import runOnLoad from "./shared/runOnLoad";

function init(): void {
    Sentry.init(config);
    if (userConfig) {
        Sentry.setUser(userConfig);
    }

    try {
        initDateLocale();

        if (document.getElementById("accept-terms-page")) {
            AcceptTerms();
        }
        if (document.getElementById("landing-page")) {
            Landing();
        }
        if (document.getElementById("flipper-page")) {
            Flipper();
        }
        if (document.getElementById("task-page")) {
            Task();
        }
        if (document.getElementById("pip-page")) {
            Pip();
        }
        if (document.getElementById("custom-task-page")) {
            CustomTask();
        }
        if (document.getElementById("submitted-task-page")) {
            SubmittedTask();
        }
        if (document.getElementById("inspiration-page")) {
            Inspiration();
        }
        if (document.getElementById("calendar-page")) {
            Calendar();
        }
        if (document.getElementById("profile-nav-page")) {
            ProfileNavPage();
        }
        if (document.getElementById("performance-page")) {
            Performance();
        }
        if (document.getElementById("chat-holder")) {
            Chat();
        }
        if (document.getElementById("replace-password-page")) {
            ReplacePassword();
        }
        if (document.getElementById("team-scans-page")) {
            TeamScans();
        }
        if (document.getElementById("pulse-page")) {
            Pulse();
        }
        if (document.getElementById("ai-chatbots-page")) {
            AiChatbots();
        }
        if (document.getElementById("terms-page")) {
            Terms();
        }
        if (document.getElementById("empty-page")) {
            Empty();
        }
        if (document.getElementById("event-page")) {
            Event();
        }
        if (document.getElementById("provide-feedback-page")) {
            ProvideFeedback();
        }
        if (document.getElementById("first-time-login-page")) {
            FirstTimeLogin();
        }
        if (document.getElementById("task-choice-page")) {
            TaskChoice();
        }
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
}

runOnLoad(init);
