import { type JSX, type MouseEvent, useState } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import PlusMinus from "../../shared/components/icons/PlusMinus";
import t from "../../shared/translations";
import type { OpenLockedModal } from "../LockedModal";
import { AllAnswersLabel, GroupTaskLabel, LockIcon, SizedTaskIcon } from "../landing/stylings";
import type { IPathWithItems } from "./query";
import { Current, IconHolder, SMALL_MOBILE_CUTOFF, TaskBox, TaskRow } from "./stylings";

type ILearningPathItem = IPathWithItems["learningPathItems"][0];

interface IProps {
    path: IPathWithItems;
    openLockedModal: OpenLockedModal;
    activeItem?: string;
}

const Wrapper = styled.div`
    overflow-x: hidden;
    max-height: calc(100% - 50px);
    margin-bottom: 1rem;
    @media (min-width: ${SMALL_MOBILE_CUTOFF}px) {
        overflow-y: auto;
    }
`;

const PipTasks = styled.div`
    width: 100%;
`;

const PipTask = styled.div`
    display: grid;
    align-items: center;
    padding-left: 20px;
    margin-top: 5px;
    font-size: 0.85rem;
    grid-template-columns: auto 1fr;
`;

const Input = styled.input`
    &[type=checkbox] {
        margin-bottom: 0px;
        margin-top: 12px;
    }
`;

type StatusReturn = [
    boolean, // item is complete
    string, // status text
    string, // status color
    string, // text color
    string, // border color
    number, // border radius
    boolean, // current item
];

function itemStatus(item: ILearningPathItem, activeItem?: string): StatusReturn {
    let complete = false;
    let statusText = "";
    let statusColor = colors.text;
    let textColor = colors.text;
    let borderColor = colors.unlight;
    let borderRadius = 0;
    const current = item.id === activeItem;
    if (item.complete) {
        complete = true;
        statusText = t("player.tasks-sidebar.status-accepted");
        statusColor = colors.primaryColor;
        textColor = colors.primaryColor;
        borderColor = colors.primaryColor;
        borderRadius = 10;
    } else if (item.redo) {
        statusText = t("player.tasks-sidebar.status-redo");
        statusColor = colors.deadline;
        borderColor = colors.deadline;
    } else if (item.active) {
        statusText = item.deadline ? item.deadline : "";
        borderColor = colors.primaryColor;
    } else if (item.deadline) {
        statusText = item.deadline;
    } else if (item.type === "event") {
        statusText = item.callToAction;
    }
    return [complete, statusText, statusColor, textColor, borderColor, borderRadius, current];
}

type IDateExtractors = "callToAction" | "deadline";

function makeSorter(extractor: IDateExtractors): (itemA: ILearningPathItem, itemB: ILearningPathItem) => number {
    return (itemA, itemB) => {
        const dateA = itemA[extractor] ?? "9999-99-99";
        const dateB = itemB[extractor] ?? "9999-99-99";
        return dateA.localeCompare(dateB);
    };
}

function fmtMiles(item: ILearningPathItem): string {
    if (!item.miles?.[0]) {
        return "";
    }
    if (item.miles[0] === item.miles[1]) {
        return item.miles[0].toString();
    }
    return `${item.miles[0]}-${item.miles[1]}`;
}

function AllTasks({ path, openLockedModal, activeItem }: IProps): JSX.Element {
    const [checked, setChecked] = useState<boolean>(false);
    const all = [...path.learningPathItems];
    all.sort(makeSorter(checked ? "deadline" : "callToAction"));
    return (
        <>
            <label>
                <Input type="checkbox" checked={checked} onChange={(e) => setChecked(e.currentTarget.checked)} />{" "}
                {t("player.tasks-sidebar.label-sort-deadlines")}
            </label>
            <Wrapper>
                {all.map((item) => {
                    const [open, setOpen] = useState(false);
                    const toggleOpen = (event: MouseEvent) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setOpen((o) => !o);
                    };
                    const requiresGroup = item.tasks.some(
                        (task) =>
                            task.taskVariants.some((v) => v.variantType === "peer-feedback") ||
                            task.doInGroup !== "none",
                    );
                    const [complete, statusText, statusColor, color, borderColor, radius, current] = itemStatus(
                        item,
                        activeItem,
                    );
                    const locked = item.locked && status !== "complete";
                    const showLock = item.lockedType !== "none";
                    const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
                        if (item.locked) {
                            event.preventDefault();
                            openLockedModal(
                                item.taskChoice ? null : item.tasks[0]?.id,
                                item.feedbackTask?.id,
                                item.taskChoice?.id,
                            );
                            event.stopPropagation();
                        }
                    };
                    return (
                        <TaskBox
                            href={item.url}
                            key={item.id}
                            $color={color}
                            $borderColor={borderColor}
                            $borderRadius={radius}
                            onClick={onClick}
                        >
                            <TaskRow>
                                <IconHolder>
                                    <SizedTaskIcon icon={item.icon} active={complete} $size="60px" />
                                    {requiresGroup && <GroupTaskLabel active={item.active} />}
                                    {item.shareAnswers && <AllAnswersLabel />}
                                </IconHolder>
                                <div>
                                    <div>{item.title}</div>
                                    <div style={{ color: statusColor }}>
                                        {statusText}
                                        {item.active && (
                                            <span style={{ color: colors.primaryColor }}>
                                                {" "}
                                                {t("player.landing.active")}
                                            </span>
                                        )}
                                        {item.undecided && (
                                            <span style={{ color: colors.primaryColor }}>
                                                {" "}
                                                {t("player.landing.undecided")}
                                            </span>
                                        )}
                                    </div>
                                    {item.miles?.[0] != null && (
                                        <div>{t("player.tasks-sidebar.label-miles", { miles: fmtMiles(item) })}</div>
                                    )}
                                </div>
                                {showLock && <LockIcon $size={50} $absolute={false} locked={locked} />}
                                {(item.type === "path-in-path" || item.type === "task-choice") && (
                                    <PlusMinus open={open} onClick={toggleOpen} />
                                )}
                                {current && <Current $borderRadius={radius} />}
                            </TaskRow>
                            {(item.type === "path-in-path" || item.type === "task-choice") && open && (
                                <PipTasks>
                                    {item.tasks.map((task) => (
                                        <PipTask key={task.id}>
                                            <SizedTaskIcon
                                                icon={task.icon}
                                                active={task.submittedTask?.status === "accepted"}
                                                $size="44px"
                                            />
                                            <div>
                                                <div>{task.shortTitle}</div>
                                                <div>
                                                    {t("player.tasks-sidebar.label-miles", { miles: task.miles })}
                                                </div>
                                            </div>
                                        </PipTask>
                                    ))}
                                </PipTasks>
                            )}
                        </TaskBox>
                    );
                })}
            </Wrapper>
        </>
    );
}

export default AllTasks;
