import { ARRAY_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import { type JSX, useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "urql";
import { useConfirm } from "../../../shared/components/ConfirmDialogue";
import t from "../../../shared/translations";
import type { CollectFeedbackQuestionType } from "../../../shared/types";
import InfoButton from "../../components/InfoButton";
import Modal from "../../components/Modal";
import { type Dispatch, type State, clearCollectFeedbackForm, openModal, saveCollectFeedbackForm } from "../../state";
import type { ITask } from "../query";
import { Box, Button, ButtonLink, ButtonRow, Input, Textarea } from "../stylings";
import CustomArray from "./CustomArray";
import Info from "./Info";
import Preview from "./Preview";
import SuggestedArray from "./SuggestedArray";

interface IArgs {
    data: {
        taskId: string;
        variantId: string;
        heading: string;
        description: string | null;
        questions: {
            nr: number;
            type: CollectFeedbackQuestionType;
            content: string;
            translation: string | null;
            leftLabel: string | null;
            rightLabel: string | null;
            leftTranslation: string | null;
            rightTranslation: string | null;
        }[];
    };
}

const createCollectFeedbackMutation = `
    mutation($data:PlayerCollectFeedbackCreateData!) {
        collectFeedbackCreate(data:$data) {
            error
            collectFeedback {
                __typename
                submittedTask {
                    __typename
                    task {
                        __typename
                    }
                }
            }
        }
    }
`;

interface IData {
    collectFeedbackCreate: {
        error: string | null;
    };
}

interface IProps {
    task: ITask;
}

export interface ICustomValue {
    content: string;
    type: CollectFeedbackQuestionType;
    leftLabel: string | null;
    rightLabel: string | null;
}

export interface ISuggestedValue {
    content: string;
    type: CollectFeedbackQuestionType;
    showTrans: boolean;
    translation: string;
    leftLabel: string | null;
    rightLabel: string | null;
    leftTranslation: string | null;
    rightTranslation: string | null;
}

export interface IFormValues {
    heading: string;
    description: string;
    suggestedQuestions: ISuggestedValue[];
    customQuestions: ICustomValue[];
}

type IParams = "id" | "variantId";

function validate(values: IFormValues): any {
    const errors: any = {};
    if (!values.heading) {
        errors.heading = t("player.collect-feedback-setup.form-error-heading-required");
    }
    if (values.suggestedQuestions.length + values.customQuestions.length === 0) {
        errors.customQuestions = [];
        errors.customQuestions[ARRAY_ERROR] = t("player.collect-feedback-setup.form-error-no-questions");
    }
    if (values.customQuestions.length > 0) {
        errors.customQuestions = new Array(values.customQuestions.length);
        values.customQuestions.forEach((value, index) => {
            errors.customQuestions[index] = {};
            if (!value.content) {
                errors.customQuestions[index].content = t("player.collect-feedback-setup.form-error-empty-question");
            }
            if (value.type === "likert") {
                if (!value.leftLabel) {
                    errors.customQuestions[index].leftLabel = t(
                        "player.collect-feedback-setup.form-error-empty-left-label",
                    );
                }
                if (!value.rightLabel) {
                    errors.customQuestions[index].rightLabel = t(
                        "player.collect-feedback-setup.form-error-empty-right-label",
                    );
                }
            }
        });
    }
    return errors;
}

const H3 = styled.h3`
    display: flex;
    align-items: center;
    gap: 0.3rem;
`;

function SetupSurvey({ task }: IProps): JSX.Element {
    const { variantId } = useParams<IParams>();
    const variant = task.taskVariants.find((v) => v.id === variantId);
    if (variant.variantType !== "collect-feedback") {
        return <Navigate to="../../" relative="path" />;
    }
    const invert = task.learningPathItem.pathInPath != null;
    const dispatch = useDispatch<Dispatch>();
    const navigate = useNavigate();
    const savedValues = useSelector((state: State) => state.task.savedCollectFeedbackForm);
    const [_, executeMutation] = useMutation<IData, IArgs>(createCollectFeedbackMutation);
    const [open, setOpen] = useState<number | null>(null);
    const [showSubmitError, setShowSubmitError] = useState(false);
    const showConfirm = useConfirm();
    const initialValues = useMemo((): IFormValues => {
        if (savedValues?.id === task.id) {
            return {
                ...savedValues,
            };
        }
        const values: IFormValues = {
            customQuestions: [],
            description: variant.collectFeedbackDescription || "",
            heading: variant.collectFeedbackTitle || "",
            suggestedQuestions: variant.suggestedQuestions.map((sq) => ({
                content: sq.content,
                type: sq.type,
                leftLabel: sq.leftLabel,
                rightLabel: sq.rightLabel,
                translation: "",
                leftTranslation: "",
                rightTranslation: "",
                showTrans: false,
            })),
        };
        return values;
    }, [task, savedValues, variant]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onSubmit = useCallback(
        async (values: IFormValues) => {
            const cont = await showConfirm(t("player.collect-feedback-setup.confirm-complete-setup"));
            if (!cont) {
                return cont;
            }
            let nr = 1;
            let questions = values.suggestedQuestions.map((q) => ({
                nr: nr++,
                type: q.type,
                content: q.content,
                leftLabel: q.leftLabel,
                rightLabel: q.rightLabel,
                translation: q.showTrans ? q.translation : null,
                leftTranslation: q.showTrans && q.type === "likert" ? q.leftTranslation : null,
                rightTranslation: q.showTrans && q.type === "likert" ? q.rightTranslation : null,
            }));
            if (variant.allowAddQuestions) {
                questions = questions.concat(
                    values.customQuestions.map((q) => ({
                        nr: nr++,
                        type: q.type,
                        content: q.content,
                        translation: null,
                        leftLabel: q.type === "likert" ? q.leftLabel : null,
                        rightLabel: q.type === "likert" ? q.rightLabel : null,
                        leftTranslation: null,
                        rightTranslation: null,
                    })),
                );
            }
            const args: IArgs = {
                data: {
                    description: values.description,
                    variantId,
                    taskId: task.id,
                    heading: values.heading,
                    questions,
                },
            };
            const result = await executeMutation(args);
            if (result.error) {
                setShowSubmitError(true);
                return;
            }
            if (result.data.collectFeedbackCreate?.error) {
                setShowSubmitError(true);
                console.error(result.data.collectFeedbackCreate.error);
            } else {
                dispatch(clearCollectFeedbackForm());
                navigate("../..", { relative: "path" });
            }
        },
        [executeMutation, task, navigate, variant.allowAddQuestions, variantId],
    );

    if (task.submittedTask?.collectFeedback) {
        return <Navigate to="../.." relative="path" />;
    }

    const numSuggested = variant.suggestedQuestions.length;
    const questionDocs = ["player/task/collect-feedback-questions"];
    if (numSuggested > 0 && variant.allowTranslateCollectedFeedback) {
        questionDocs.push("player/task/collect-feedback-translate");
    }
    if (variant.allowAddQuestions) {
        questionDocs.push("player/task/collect-feedback-questions-add");
    }
    return (
        <>
            <h1>{task.shortTitle}</h1>
            <Info variant={variant} />
            <Form<IFormValues>
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                mutators={{ ...arrayMutators }}
            >
                {({ handleSubmit, submitting, values }) => (
                    <form onSubmit={handleSubmit}>
                        <H3>
                            {t("player.collect-feedback-setup.form-label-heading-description")}
                            <InfoButton
                                docPath="player/task/collect-feedback-heading"
                                modal="Task/Info/CollectFeedbackHeading"
                            />
                        </H3>
                        <Box>
                            <Input name="heading" />
                            <Textarea name="description" />
                        </Box>
                        <H3>
                            {t("player.collect-feedback-setup.form-label-questions")}
                            <InfoButton docPath={questionDocs} modal="Task/Info/CollectFeedbackQuestions" />
                        </H3>
                        <SuggestedArray
                            name="suggestedQuestions"
                            open={open}
                            setOpen={setOpen}
                            allowTranslate={variant.allowTranslateCollectedFeedback}
                        />
                        {variant.allowAddQuestions && (
                            <CustomArray
                                name="customQuestions"
                                invert={invert}
                                numStart={numSuggested}
                                open={open}
                                setOpen={setOpen}
                            />
                        )}
                        <ButtonRow>
                            <Button
                                type="button"
                                $invert={invert}
                                onClick={() => dispatch(openModal("Task/CollectFeedback/Preview"))}
                            >
                                {t("player.collect-feedback-setup.form-button-preivew")}
                            </Button>
                        </ButtonRow>
                        <ButtonRow>
                            <ButtonLink
                                to="../../"
                                relative="path"
                                onClick={() => dispatch(saveCollectFeedbackForm({ values, id: task.id }))}
                            >
                                {t("player.collect-feedback-setup.form-link-back")}
                            </ButtonLink>
                            <Button type="submit" $invert={invert} disabled={submitting}>
                                {t("player.collect-feedback-setup.form-button-submit")}
                            </Button>
                        </ButtonRow>
                        <p style={{ textAlign: "center" }}>
                            {t("player.collect-feedback-setup.info-no-edit-after-submit")}
                        </p>
                        {showSubmitError && <div>{t("player.collect-feedback-setup.error-submit-survey")}</div>}
                        <Modal modal="Task/CollectFeedback/Preview">
                            <Preview values={values} anonymous={variant.anonymousCollectedFeedback} />
                        </Modal>
                    </form>
                )}
            </Form>
        </>
    );
}

export default SetupSurvey;
