import type { AnswerType } from "../../shared/types";
import { postJson } from "../base";

interface SubmitTaskData {
    task_answers: {
        answer_type: AnswerType;
        question_content: string | null;
        answer_content: string | null;
        checkbox_answers: {
            content: string;
            checked: boolean;
        }[];
        task_likert_answers: {
            statement: string;
            left_label: string;
            right_label: string;
            value: number;
        }[];
        filename: string | null;
        name: string | null;
        survey_gizmo_resp_id: string | null;
        impact_values: number[];
    }[];
    task_id: number;
    variant_id: number;
    task_thread_item_id: number | null;
    ouside_feedback_description: string | null;
}

export interface TaskApproval {
    id: number;
    status: "approved" | "submitted";
    membership: number;
    submitted_task: number;
}

interface SubmitTaskResultSubmitted {
    status: "submitted";
    redirect: string;
}

interface SubmitTaskResultAwaitingApproval {
    status: "awaiting-approval";
    approvals: TaskApproval[];
    redirect: string;
}

type SubmitTaskResult = SubmitTaskResultSubmitted | SubmitTaskResultAwaitingApproval;

export async function submitTask(data: SubmitTaskData): Promise<SubmitTaskResult> {
    const url = "/api/player/submit-task";
    return postJson(url, data) as Promise<SubmitTaskResult>;
}

interface SaveIncompleteAnswerData {
    variant_id: number | null;
    submitted_task_id: number | null;
    answers: {
        question_content: string | null;
        part: number;
        answer_content: string | null;
    }[];
}

export async function saveIncompleteAnswer(data: SaveIncompleteAnswerData): Promise<void> {
    const url = "/api/player/save-incomplete-answer";
    return postJson(url, data) as Promise<void>;
}

export async function approveTask(submitted_task_id: number): Promise<SubmitTaskResult> {
    const url = "/api/player/approve-task";
    const data = { submitted_task_id };
    return postJson(url, data) as Promise<SubmitTaskResult>;
}

interface RespondFeedbackData {
    submitted_task_id: number;
    content: string;
}

export async function respondFeedback(data: RespondFeedbackData): Promise<void> {
    const url = "/api/player/respond-feedback";
    return postJson(url, data) as Promise<void>;
}
