import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import getLocale from "../../../shared/getLocale";
import type { Language } from "../../../shared/types";
import PulseForm, { type IFormValues } from "../../pulse/PulseForm";
import type { IPulse, ITask, ITaskVariant } from "../query";
import type { JSX } from "react";

interface ICreateArgs {
    data: {
        question1: string;
        question2: string | null;
        locale: Language;
        id: string;
        variantId: string;
    };
}

export const createMutation = `
    mutation($data:PlayerPulseCreateForTaskData!) {
        pulseCreateForTask(data:$data) {
            error
            pulse {
                id
                submittedTaskVariant {
                    id
                    submittedTask {
                        id
                        task {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export interface ICreateData {
    pulseCreate: {
        error: string | null;
        pulse: {
            id: string;
            submittedTaskVariant: {
                id: string;
                submittedTask: {
                    id: string;
                    task: {
                        id: string;
                    };
                };
            };
        } | null;
    };
}

interface IProps {
    task: ITask;
    variant: ITaskVariant;
    pulse: IPulse | null;
}

function Setup({ task, variant, pulse }: IProps): JSX.Element {
    const [createResult, executeCreate] = useMutation<ICreateData, ICreateArgs>(createMutation);
    const navigate = useNavigate();

    if (pulse) {
        return <Navigate to={`../pulse/${pulse.id}/results`} />;
    }

    const onSubmit = async (values: IFormValues) => {
        if (createResult.fetching) {
            return;
        }
        const args: ICreateArgs = {
            data: {
                locale: getLocale(),
                question1: values.question1,
                question2: values.question2,
                id: task.id,
                variantId: variant.id,
            },
        };
        const result = await executeCreate(args);
        if (result.error || result.data.pulseCreate?.error) {
            console.error(result.error || result.data.pulseCreate?.error);
        } else {
            const id = result.data.pulseCreate.pulse.id;
            navigate(`../pulse/${id}/results`);
        }
    };

    return <PulseForm onSubmit={onSubmit} pulseSuggestedQuestions={variant.pulseSuggestedQuestions} />;
}

export default Setup;
