import { loadJSData } from "../../shared/jsData";
import type { OpenLockedModal } from "../LockedModal";
import AllTasks from "./AllTasks";
import CustomTasks from "./CustomTasks";
import type { ITab } from "./Tabs";
import type { IPathWithItems } from "./query";
import type { JSX } from "react";

interface IProps {
    path: IPathWithItems;
    tab: ITab;
    openLockedModal: OpenLockedModal;
    activeItem?: string;
}

function TasksSidebar({ path, tab, openLockedModal, activeItem }: IProps): JSX.Element {
    return (
        <>
            {tab === "tasks" && <AllTasks path={path} openLockedModal={openLockedModal} activeItem={activeItem} />}
            {loadJSData().features.custom_tasks && tab === "custom" && (
                <CustomTasks path={path} activeItem={activeItem} />
            )}
        </>
    );
}

export default TasksSidebar;
