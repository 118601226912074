import { type JSX, memo } from "react";
import styled from "styled-components";
import t from "../../shared/translations";
import WithDocs from "../components/WithDocs";
import Banner from "./Banner";
import Flag from "./Flag";
import PitstopTimer from "./PitstopTimer";
import type { IData } from "./query";
import { Box, Frame } from "./stylings";

interface IProps {
    data: IData;
}

const MemberCounter = styled.div`
    font-size: 1.1rem;
`;

function Goals({ data }: IProps): JSX.Element {
    const pm = data.currentPathMembership;
    const path = pm.learningPath;
    return (
        <Frame>
            <Box $span={[12, 4]}>
                <WithDocs modal="Performance/Info/Activity" docPath={"player/performance/activity"} component="h3">
                    {t("player.performance.heading-activity")}
                </WithDocs>
                <PitstopTimer taskTime={pm.taskTime || pm.startDate} />
            </Box>
            <Box $span={[12, 4]}>
                <WithDocs modal="Performance/Info/Checkpoint" docPath={"player/performance/checkpoint"} component="h3">
                    {t("player.performance.heading-checkpoint")}
                </WithDocs>
                <h4>
                    {t("player.performance.heading-checkpoint-miles", {
                        miles: path.checkpoint,
                    })}
                </h4>
                <Banner />
                <MemberCounter>
                    {t("player.performance.info-past-checkpoint", {
                        count: path.membersPastCheckpoint,
                    })}
                </MemberCounter>
            </Box>
            <Box $span={[12, 4]}>
                <WithDocs modal="Performance/Info/Goal" docPath={"player/performance/goal"} component="h3">
                    {t("player.performance.heading-goal")}
                </WithDocs>
                <h4>
                    {t("player.performance.heading-goal-miles", { miles: path.goal })}
                    <br />
                </h4>
                <Flag />
                <MemberCounter>
                    {t("player.performance.info-reached-goal", { count: path.membersPastGoal })}
                    <br />
                    {t("player.performance.info-learning-path-end", { date: pm.endDate })}
                </MemberCounter>
            </Box>
        </Frame>
    );
}

export default memo(Goals);
