import { memo } from "react";
import type { JSX } from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import * as colors from "../../../shared/colors";

interface IProps {
    values: number[];
}

const chars = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

function QuestionChart({ values }: IProps): JSX.Element {
    const data = values.map((value, i) => ({ name: chars[i], value: value * 100 }));
    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 20,
                    left: -15,
                    bottom: 5,
                }}
                layout="vertical"
                barGap={5}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis dataKey="name" type="category" interval={0} />
                <XAxis
                    type="number"
                    domain={[0, 100]}
                    interval="preserveStartEnd"
                    tickFormatter={(val: number) => `${val.toFixed(0)}%`}
                />
                <Bar
                    dataKey="value"
                    fill={colors.primaryColor}
                    isAnimationActive={false}
                    stroke={colors.primaryColor}
                    fillOpacity={0.6}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default memo(QuestionChart);
