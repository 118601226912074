import type { JSX } from "react";
import styled from "styled-components";
import RenderMark from "../../../shared/components/RenderMark";
import AiIcon from "../../../shared/components/icons/AiIcon";
import t from "../../../shared/translations";
import type { ITaskVariant } from "../query";
import { Box } from "../stylings";

interface IProps {
    variant: ITaskVariant;
}

const H3 = styled.h3`
    display: grid;
    grid-template-columns: 26px 1fr;
    align-items: center;
    gap: 5px;
    svg {
        width: 26px;
        height: 26px;
    }
`;

function Info({ variant }: IProps): JSX.Element {
    return (
        <Box>
            <H3>
                <AiIcon />
                {t("player.task.heading-ai-chat")}
            </H3>
            <p>{t("player.task.info-ai-chat")}</p>
            {variant.playerAiInstructions && <RenderMark content={variant.playerAiInstructions} />}
        </Box>
    );
}

export default Info;
