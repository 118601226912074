import type { ValidationErrors } from "final-form";
import { useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import Loader from "../../../shared/components/Loader";
import t from "../../../shared/translations";
import ShowError from "../../components/ShowError";
import { type Dispatch, type State, setResendRecipients } from "../../state";
import { Center, PaddedLeft } from "../stylings";
import { Input, Textarea } from "../stylings/form";

const query = `
    query {
        systemMessage(message:"team_scan_survey") {
            id
            title
            body
        }
    }
`;

interface ISystemMessage {
    id: string;
    title: string;
    body: string;
}

interface IData {
    systemMessage: ISystemMessage;
}

const resendMutation = `
    mutation($ids:[String]!,$messageSubject:String!,$messageBody:String!) {
        teamScanSurveyResend(ids:$ids,messageSubject:$messageSubject,messageBody:$messageBody) {
            error
        }
    }
`;

interface IResendArgs {
    ids: string[];
    messageSubject: string;
    messageBody: string;
}

interface IFormValues {
    title: string;
    body: string;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.title) {
        errors.title = "Required";
    }
    if (!values.body) {
        errors.body = "Required";
    } else if (!values.body.includes("{{link}}")) {
        errors.body = "Message must include {{link}}";
    }
    return errors;
}

function Resend(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch<Dispatch>();
    const { id } = useParams();
    const recipients = useSelector((state: State) => (state.teamScan[id] ? state.teamScan[id].resendRecipients : []));
    const [result] = useQuery<IData>({
        query,
    });
    const [_, executeMutation] = useMutation<any, IResendArgs>(resendMutation);

    const onSubmit = useCallback(
        async (values: IFormValues) => {
            const args: IResendArgs = {
                ids: recipients.map((r) => r.id),
                messageBody: values.body,
                messageSubject: values.title,
            };
            const result = await executeMutation(args);
            if (result.error || result.data.teamScanSurveyResend?.error) {
                console.error(result.error || result.data.teamScanSurveyResend?.error);
            } else {
                dispatch(setResendRecipients({ id, resendRecipients: [] }));
                navigate("../resend-success", { relative: "path" });
            }
        },
        [recipients, id, executeMutation, navigate],
    );

    if (recipients.length === 0) {
        return <Navigate to=".." relative="path" />;
    }

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const { systemMessage } = result.data;

    return (
        <>
            <h1>{t("player.team-scan-resend.heading")}</h1>
            <p>{t("player.team-scan-resend.info-recipients")}</p>
            <PaddedLeft>
                <ul>
                    {recipients.map((rep) => (
                        <li key={rep.id}>{rep.email}</li>
                    ))}
                </ul>
            </PaddedLeft>
            <p>{t("player.team-scan-resend.info-email-content")}</p>
            <Form onSubmit={onSubmit} validate={validate} initialValues={systemMessage}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Input name="title" label={t("player.team-scan-resend.form-label-subject")} />
                        <Textarea name="body" label={t("player.team-scan-resend.form-label-message")} />
                        <Center>
                            <button type="submit" disabled={submitting}>
                                {t("player.team-scan-resend.form-button-submit")}
                            </button>
                        </Center>
                    </form>
                )}
            </Form>
            <p>
                <Link to=".." relative="path">
                    {t("player.team-scan-resend.link-back")}
                </Link>
            </p>
        </>
    );
}

export default Resend;
