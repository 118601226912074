import { memo } from "react";
import type { JSX } from "react";
import type { Person } from "../../api/player/Chat";

interface IProps {
    members: Person[];
    online: { [index: number]: boolean };
    currentId: number;
}

function Members({ members, online, currentId }: IProps): JSX.Element {
    const filteredMembers = members.filter((m) => m.id !== currentId);
    return (
        <div className="chat-members">
            {filteredMembers.map((member) => (
                <span key={member.id} className="chat-member">
                    {member.first_name} {member.last_name}{" "}
                    {online[member.id] ? <span className="online" /> : <span className="offline" />}
                </span>
            ))}
        </div>
    );
}

export default memo(Members);
