import { useField, type UseFieldConfig } from "react-final-form";
import { ErrorBox, Label, Select as StyledSelect } from "./stylings";
import type { JSX } from "react";

interface IOwnProps {
    label: string;
    name: string;
    required?: boolean;
    options: { label: string; value: string }[];
}

type IProps<T> = IOwnProps & UseFieldConfig<T>;

function Select<T = string>({ name, label, required, options, ...props }: IProps<T>): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField<any, HTMLSelectElement>(name, props);
    return (
        <div>
            <Label $error={error && touched}>
                {label}
                {required ? "*" : ""}:
                <StyledSelect $error={!!error && touched} {...input}>
                    {options.map((opt) => (
                        <option value={opt.value} key={opt.value}>
                            {opt.label}
                        </option>
                    ))}
                </StyledSelect>
                {touched && error && <ErrorBox>{error}</ErrorBox>}
            </Label>
        </div>
    );
}

export default Select;
