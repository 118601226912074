import { produce } from "immer";
import { useDispatch, useSelector } from "react-redux";
import { useImmerReducer } from "use-immer";
import * as UploadAPI from "../../../api/Upload";
import * as API from "../../../api/player";
import getInt from "../../../shared/getInt";
import t from "../../../shared/translations";
import { type ICurrentPerson, useCurrentPerson } from "../../components/CurrentPerson";
import { type Dispatch, type State, cancelTaskEditAnswer } from "../../state";
import type { IIncompleteAnswer, ISubmittedTask, ITask, ITaskQuestion, ITaskThreadItem, ITaskVariant } from "../query";
import AnswerForm, { type IFormValues as ITaskFormValues } from "./AnswerForm";
import OutsideFeedbackForm, { type IFormValues as IFeedbackFormValues } from "./OutsideFeedbackForm";
import OutsideFeedbackInfo from "./OutsideFeedbackInfo";
import OutsideFeedbackPreview from "./OutsideFeedbackPreview";
import type { JSX } from "react";

interface IProps {
    task: ITask;
    shownTask: ISubmittedTask | ITask;
    redo: boolean;
    variant: ITaskVariant;
    invert: boolean;
}

type IPage = "answers" | "outsideFeedbackForm" | "outsideFeedbackPreview";

interface IState {
    page: IPage;
    ansIdx: number;
    numAnswers: number;
    taskValues: ITaskFormValues[];
    feedbackValue: IFeedbackFormValues;
    showSubmitError: boolean;
}

function withSavedAnswer(question: ITaskQuestion, index: number, incompleteAnswers: IIncompleteAnswer[]): string {
    const saved = incompleteAnswers.find(
        (answer) => answer.questionContent === question.questionContent && index === answer.part,
    );
    return saved?.answerContent ?? "";
}

function createInitialState([variant, current, editAnswer]: [
    ITaskVariant,
    ICurrentPerson,
    ITaskThreadItem | null,
]): IState {
    let outsideFeedbackDescription = "";
    if (variant.variantType === "outside-feedback") {
        const first_name = current.firstName;
        const last_name = current.lastName;
        const path_name = current.currentPath?.pathname ?? "";
        outsideFeedbackDescription = t("player.task.default-outside-feedback", { first_name, last_name, path_name });
    }
    let taskAnswers: ITaskFormValues[] = null;
    if (editAnswer) {
        taskAnswers = editAnswer.taskAnswers.map((answer, i) => ({
            answerType: answer.answerType,
            questionContent: answer.questionContent,
            answerContent: answer.answerContent,
            file: null,
            checkboxAnswers: answer.checkboxAnswers.map((ans) => ({ ...ans })),
            taskLikertAnswers: answer.taskLikertAnswers.map((ans) => ({
                statement: ans.statement,
                leftLabel: ans.leftLabel,
                rightLabel: ans.rightLabel,
                value: ans.value,
            })),
            requireAllCheckmarks: variant.taskQuestions[i].requireAllCheckmarks,
            showPollResults: true,
            impactAnswers:
                answer.impactTrackerAnswerSet?.impactLikertAnswers?.map((ans) => ({
                    statement: ans.statement,
                    leftLabel: ans.leftLabel,
                    rightLabel: ans.rightLabel,
                    value: ans.value,
                })) ?? [],
        }));
    } else {
        taskAnswers = variant.taskQuestions.map((question, index) => ({
            answerType: question.answerType,
            questionContent: question.questionContent,
            answerContent: withSavedAnswer(question, index, variant.incompleteAnswers),
            file: null,
            checkboxAnswers: question.checkboxOptions.map((opt) => ({
                content: opt.content,
                checked: false,
            })),
            taskLikertAnswers: question.taskLikertStatements.map((stmnt) => ({
                statement: stmnt.statement,
                leftLabel: stmnt.leftLabel,
                rightLabel: stmnt.rightLabel,
                value: null,
            })),
            requireAllCheckmarks: question.requireAllCheckmarks,
            showPollResults: true,
            impactAnswers:
                question.impactTracker?.impactLikertStatements?.map((stmnt) => ({
                    statement: stmnt.statement,
                    leftLabel: stmnt.leftLabel,
                    rightLabel: stmnt.rightLabel,
                    value: 5,
                })) ?? [],
        }));
    }
    return {
        page: "answers",
        ansIdx: 0,
        numAnswers: taskAnswers.length,
        taskValues: taskAnswers,
        feedbackValue: { outsideFeedbackDescription },
        showSubmitError: false,
    };
}

type IAction =
    | { type: "GO-TO-ANSWER"; values: ITaskFormValues; index: number; direction: "forward" | "back" }
    | { type: "START-SUBMIT"; taskValues: ITaskFormValues[]; feedbackValue: IFeedbackFormValues }
    | { type: "SET-ERROR"; to: boolean }
    | { type: "GO-TO-OUTSIDE-FEEDBACK-FORM"; values: ITaskFormValues; index: number }
    | { type: "BACK-TO-ANSWERS"; value: IFeedbackFormValues }
    | { type: "GO-TO-OUTSIDE-FEEDBACK-PREVIEW"; value: IFeedbackFormValues }
    | { type: "BACK-TO-OUTSIDE-FEEDBACK-FORM" };

function reducer(draft: IState, action: IAction): void {
    switch (action.type) {
        case "GO-TO-ANSWER": {
            draft.taskValues[action.index] = action.values;
            const nextIdx = action.direction === "forward" ? action.index + 1 : action.index - 1;
            draft.ansIdx = nextIdx;
            return;
        }
        case "START-SUBMIT":
            draft.taskValues = action.taskValues;
            draft.feedbackValue = action.feedbackValue;
            draft.showSubmitError = false;
            return;
        case "SET-ERROR":
            draft.showSubmitError = action.to;
            return;
        case "GO-TO-OUTSIDE-FEEDBACK-FORM":
            draft.page = "outsideFeedbackForm";
            draft.taskValues[action.index] = action.values;
            return;
        case "BACK-TO-OUTSIDE-FEEDBACK-FORM":
            draft.page = "outsideFeedbackForm";
            return;
        case "BACK-TO-ANSWERS":
            draft.page = "answers";
            draft.feedbackValue = action.value;
            return;
        case "GO-TO-OUTSIDE-FEEDBACK-PREVIEW":
            draft.page = "outsideFeedbackPreview";
            draft.feedbackValue = action.value;
            return;
        default:
            throw new Error("Unknown action");
    }
}

function AnswerWizard({ task, shownTask, redo, variant, invert }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const current = useCurrentPerson();
    const editAnswer = useSelector((state: State) => state.task.editAnswer);
    const [state, localDispatch] = useImmerReducer(reducer, [variant, current, editAnswer], createInitialState);

    const submit = async (taskValues: ITaskFormValues[], feedbackValue: IFeedbackFormValues): Promise<void> => {
        const task_id = getInt(task.id);
        const variant_id = getInt(variant.id);
        const task_thread_item_id = getInt(editAnswer?.id);
        const ouside_feedback_description = feedbackValue.outsideFeedbackDescription || null;
        const filenames = new Array<string>(taskValues.length);
        localDispatch({ type: "START-SUBMIT", taskValues, feedbackValue });

        for (let i = 0, iMax = taskValues.length; i < iMax; i++) {
            const answer = taskValues[i];
            if (answer.answerType === "file") {
                try {
                    const filename = await UploadAPI.uploadFile(answer.file, "task-answer");
                    filenames[i] = filename;
                } catch (err) {
                    console.error(err);
                    localDispatch({ type: "SET-ERROR", to: true });
                    return;
                }
            }
        }
        const task_answers = taskValues.map((ans, i) => ({
            answer_type: ans.answerType,
            question_content: ans.questionContent,
            answer_content: ans.answerContent,
            filename: filenames[i] ?? null,
            name: ans.file?.name ?? null,
            checkbox_answers: ans.checkboxAnswers,
            show_poll_results: false,
            survey_gizmo_resp_id: null,
            task_likert_answers: ans.taskLikertAnswers.map((lkrt) => ({
                statement: lkrt.statement,
                left_label: lkrt.leftLabel,
                right_label: lkrt.rightLabel,
                value: lkrt.value,
            })),
            impact_values: ans.impactAnswers.map((impct) => impct.value),
        }));
        try {
            // biome-ignore lint/style/noVar: Use var to host type inference outside the block.
            // biome-ignore lint/correctness/noInnerDeclarations: Use var to host type inference outside the block.
            var result = await API.Task.submitTask({
                task_answers,
                task_id,
                variant_id,
                task_thread_item_id,
                ouside_feedback_description,
            });
        } catch (err) {
            console.error(err);
            localDispatch({ type: "SET-ERROR", to: true });
            return;
        }
        window.location.href = result.redirect;
    };

    const saveIncomplete = async (values: ITaskFormValues[]): Promise<void> => {
        await API.Task.saveIncompleteAnswer({
            submitted_task_id: shownTask.discriminant === "submitted_task" ? getInt(shownTask.id) : null,
            variant_id: shownTask.discriminant === "task" ? getInt(variant.id) : null,
            answers: values
                .map((ans, idx) => ({ idx, ...ans }))
                .filter((ans) => ans.answerType === "textbox" && !!ans.answerContent)
                .map((ans) => ({
                    question_content: ans.questionContent,
                    part: ans.idx,
                    answer_content: ans.answerContent,
                })),
        });
    };

    const answerNext = async (values: ITaskFormValues, index: number): Promise<void> => {
        const isFinalAnswer = index === state.numAnswers - 1;
        if (isFinalAnswer) {
            if (variant.variantType === "outside-feedback") {
                localDispatch({ type: "GO-TO-OUTSIDE-FEEDBACK-FORM", index, values });
            } else {
                const taskValues = produce(state.taskValues, (draft) => {
                    draft[index] = values;
                });
                await submit(taskValues, state.feedbackValue);
            }
        } else {
            localDispatch({ type: "GO-TO-ANSWER", index, values, direction: "forward" });
        }
    };

    const answerBack = (values: ITaskFormValues, index: number): void => {
        localDispatch({ type: "GO-TO-ANSWER", index, values, direction: "back" });
    };

    const cancel = () => {
        dispatch(cancelTaskEditAnswer());
    };

    const backToAnswers = (value: IFeedbackFormValues): void => {
        localDispatch({ type: "BACK-TO-ANSWERS", value });
    };

    const toOutsideFeedbackPreview = (value: IFeedbackFormValues): void => {
        localDispatch({ type: "GO-TO-OUTSIDE-FEEDBACK-PREVIEW", value });
    };

    const feedbackFormSubmit = async (value: IFeedbackFormValues): Promise<void> => {
        await submit(state.taskValues, value);
    };

    const feedbackPreviewSubmit = async (): Promise<void> => {
        await submit(state.taskValues, state.feedbackValue);
    };

    const backToOutsideFeedbackForm = () => {
        localDispatch({ type: "BACK-TO-OUTSIDE-FEEDBACK-FORM" });
    };

    const showOutsideFeedbackPreview = variant.variantType === "outside-feedback" && !redo;
    const final =
        state.ansIdx === state.numAnswers - 1 &&
        (variant.variantType !== "outside-feedback" ||
            (!showOutsideFeedbackPreview && variant.variantType === "outside-feedback"));

    if (state.page === "answers") {
        return (
            <>
                {variant.variantType === "outside-feedback" && <OutsideFeedbackInfo redo={redo} />}
                <AnswerForm
                    index={state.ansIdx}
                    key={state.ansIdx}
                    invert={invert}
                    editAnswer={editAnswer}
                    initialValues={state.taskValues[state.ansIdx]}
                    onSubmit={answerNext}
                    goBack={state.ansIdx === 0 ? undefined : answerBack}
                    cancel={cancel}
                    final={final}
                    allValues={state.taskValues}
                    saveIncomplete={saveIncomplete}
                />
                {state.showSubmitError && <div>{t("player.task.error-submit-answer")}</div>}
                {variant.variantType === "collect-feedback" && !redo && (
                    <p style={{ textAlign: "center" }}>{t("player.task.info-collect-feedback-survey-close")}</p>
                )}
            </>
        );
    }
    if (state.page === "outsideFeedbackForm") {
        return (
            <>
                <OutsideFeedbackForm
                    redo={redo}
                    invert={invert}
                    initialValues={state.feedbackValue}
                    back={backToAnswers}
                    preview={showOutsideFeedbackPreview ? toOutsideFeedbackPreview : undefined}
                    onSubmit={feedbackFormSubmit}
                />
                {state.showSubmitError && <div>{t("player.task.error-submit-answer")}</div>}
            </>
        );
    }
    if (state.page === "outsideFeedbackPreview") {
        return (
            <>
                <OutsideFeedbackPreview
                    taskValues={state.taskValues}
                    feedbackValue={state.feedbackValue}
                    shownTask={shownTask}
                    variant={variant}
                    back={backToOutsideFeedbackForm}
                    invert={invert}
                    submit={feedbackPreviewSubmit}
                />
                {state.showSubmitError && <div>{t("player.task.error-submit-answer")}</div>}
            </>
        );
    }
}

export default AnswerWizard;
