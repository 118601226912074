import type { JSX } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import RenderMark from "../../shared/components/RenderMark";
import t from "../../shared/translations";
import { type Dispatch, openModal } from "../state";
import type { ICurrentPerson } from "./Profile";
import { Button } from "./stylins";

interface IProps {
    person: ICurrentPerson;
}

const Box = styled.div`
    display: grid;
    grid-gap: 1rem;
    justify-items: center;
    button {
        margin-bottom: 0;
    }
`;

const Pic = styled.img`
    width: 150px;
    height: 160px;
    object-fit: cover;
`;

const Avatar = styled.img`
    width: 130px;
    height: 160px;
    object-fit: scale-down;
`;

const Note = styled(RenderMark)`
    width: 100%;
    grid-column: 1/-1;
`;

function PictureSection({ person }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();

    const openPicture = () => {
        dispatch(openModal("Profile/ChangeProfilePicture"));
    };

    const openAvatar = () => {
        dispatch(openModal("Profile/ChangeAvatar"));
    };

    return (
        <>
            <Box>
                <Pic src={person.profilePicSrc || "/static/images/profile-silouette.png"} />
                <Button onClick={openPicture}>{t("player.profile.button-change-profile-picture")}</Button>
            </Box>
            <Box>
                <Avatar src={person.avatarUrl} alt="avatar" />
                <Button onClick={openAvatar}>{t("player.profile.button-change-avatar")}</Button>
            </Box>
            {person.noteForPlayer && <Note content={person.noteForPlayer} />}
        </>
    );
}

export default PictureSection;
