import type { ChangeEvent, ChangeEventHandler, JSX } from "react";
import { type UseFieldConfig, useField } from "react-final-form";

interface IOwnProps {
    label?: string;
    name: string;
    className?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

type IProps = IOwnProps & UseFieldConfig<boolean>;

function Checkbox({ name, label, className, onChange, ...props }: IProps): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField(name, { ...props, type: "checkbox" });
    const combinedOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        input.onChange(event);
        if (onChange) {
            onChange(event);
        }
    };
    return (
        <div className={touched && error ? `error ${className}` : className || ""}>
            <label>
                <input {...input} onChange={combinedOnChange} type="checkbox" />
                {label && ` ${label}`}
            </label>
            {touched && error && <small className="error">{error}</small>}
        </div>
    );
}

export default Checkbox;
