import { type JSX, useEffect, useState } from "react";
import styled from "styled-components";
import ConfettiAward from "./ConfettiAward";
import TaskAward from "./TaskAward";
import UnlockAward from "./UnlockAward";
import type { AnimationType, IData } from "./index";

interface IProps {
    data: IData;
}

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1030px;
    width: 100%;
`;

const ANIMATION_TIMINGS = {
    task: 5000,
    unlock: 4000,
    milestone: 6000,
};

function Flipper({ data }: IProps): JSX.Element {
    const [shownAnimation, setShowAnimation] = useState<AnimationType>(data.animations[0]);

    useEffect(() => {
        const timeoutIds = [];
        let timeout = ANIMATION_TIMINGS[data.animations[0]];
        for (const animation of data.animations.slice(1)) {
            const timeoutId = window.setTimeout(() => {
                if (animation === "redirect") {
                    window.location.href = data.redirectUrl;
                } else {
                    setShowAnimation(animation);
                }
            }, timeout);
            timeout += ANIMATION_TIMINGS[animation] ?? 0;
            timeoutIds.push(timeoutId);
        }
        return () => {
            for (const timeoutId of timeoutIds) {
                window.clearTimeout(timeoutId);
            }
        };
    }, [data.redirectUrl, data.animations]);

    let main = null;
    if (shownAnimation === "milestone") {
        main = <ConfettiAward data={data} />;
    } else if (shownAnimation === "task") {
        main = <TaskAward data={data} />;
    } else if (shownAnimation === "unlock") {
        main = <UnlockAward data={data} />;
    }

    return <Wrapper>{main}</Wrapper>;
}

export default Flipper;
