import { type ComponentPropsWithoutRef, memo, type JSX } from "react";
import * as colors from "../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    fillPercent: number;
    color?: string;
    thin?: boolean;
}

function MilesCircle({ fillPercent, color, thin, ...props }: IProps): JSX.Element {
    const outerRadius = 47;
    const innerRadius = thin ? 39 : 35;
    const strokeWidth = thin ? 2 : 3;
    const radius = (outerRadius + innerRadius) / 2;
    const thickStroke = outerRadius - innerRadius;
    const circumference = 2 * Math.PI * radius;

    return (
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g transform="rotate(-90,50,50)">
                <circle
                    cx="50"
                    cy="50"
                    r={radius}
                    stroke={color ?? colors.primaryColor}
                    strokeWidth={thickStroke}
                    strokeDasharray={circumference}
                    strokeDashoffset={(1.0 - fillPercent) * circumference}
                    fill="none"
                />
            </g>
            <circle
                cx="50"
                cy="50"
                r={outerRadius}
                stroke={color ?? colors.primaryColor}
                strokeWidth={strokeWidth}
                fill="none"
            />
            <circle
                cx="50"
                cy="50"
                r={innerRadius}
                stroke={color ?? colors.primaryColor}
                strokeWidth={strokeWidth}
                fill="none"
            />
        </svg>
    );
}

export default memo(MilesCircle);
