import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function QuestionSpeechBubbleIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m19 89.4c-.3 0-.7-.1-.9-.3-.4-.3-.6-.9-.6-1.4l2.2-15.9c-5.3-6.7-8.2-15.1-8.2-23.7 0-21.2 17.3-38.5 38.5-38.5s38.5 17.3 38.5 38.5-17.3 38.4-38.5 38.4c-5.4 0-10.6-1.1-15.6-3.3l-14.9 6c-.2.1-.3.2-.5.2z"
                fill="#222"
            />
            <path
                d="m50 12.6c-19.6 0-35.5 15.9-35.5 35.5 0 8.1 2.8 16 7.9 22.3.3.3.4.7.3 1.2l-2 13.9 13.1-5.3c.4-.2.8-.1 1.2 0 4.7 2.2 9.7 3.3 14.9 3.3 19.6 0 35.5-15.9 35.5-35.5s-15.8-35.4-35.4-35.4z"
                fill={active ? "#00ba9e" : "#fff"}
            />
            <path
                d="m50.1 61.9c-3.5 0-6 3.2-6 6.1 0 3.5 2.5 6.1 6 6.1s6.1-2.6 6.1-6.1c-.1-3.6-2.6-6.1-6.1-6.1z"
                fill="#222"
            />
            <path
                d="m50.1 71.1c-1.8 0-3-1.2-3-3.1 0-1.2 1.2-3.1 3-3.1 1.9 0 3 1.2 3.1 3.1 0 1.8-1.3 3.1-3.1 3.1z"
                fill="#fff"
            />
            <path
                d="m64.1 29.1c-2.1-4.6-6.1-7.6-10.8-8.2-5.6-.6-11.3 1.3-15.4 7.4l-3.5 5 10 7 3.4-5c1.5-2.2 2.9-2.4 3.8-1.8.7.5 1 1.7.4 2.5-.7 1.1-7.8 11.4-7.9 11.5l-.1.2v10.4h12.8v-8.9c.3-.4.8-.9 1.3-1.5 1-1.1 2.3-2.5 3.7-4.5 2.7-3.4 4.4-9.4 2.3-14.1z"
                fill="#222"
            />
            <path
                d="m59.5 41.5c-1.4 1.9-2.6 3.2-3.5 4.2-1.1 1.2-1.7 1.8-2 2.5l-.1.3v6.6h-6.8v-6.9c.3-.7 6.6-9.2 7.4-10.5 1.4-2.1.8-5.1-1.2-6.6-1.8-1.3-5-1.8-7.9 2.3l-1.8 2.7-5.1-3.6 1.7-2.5c3.9-5.3 8.1-6.7 12.7-6.2 3.7.4 6.7 2.7 8.4 6.4 1.6 3.6.3 8.4-1.8 11.3z"
                fill="#fff"
            />
        </svg>
    );
}

export default QuestionSpeechBubbleIcon;
