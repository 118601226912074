import type { JSX } from "react";
import { type UseFieldConfig, useField } from "react-final-form";
import styled from "styled-components";
import * as colors from "../../../shared/colors";

interface IOwnProps {
    label: string;
    secondaryLabel?: string;
    placeholder?: string;
    name: string;
    className?: string;
}

type IProps = IOwnProps & UseFieldConfig<string>;

const Label = styled.label`
    display: grid;
    align-items: center;
    justify-items: end;
`;

interface IWrapper {
    $error?: boolean;
}

const Wrapper = styled.div<IWrapper>`
    display: grid;
    grid-template-columns: subgrid;
    gap: 0 0.5rem;
    grid-column: 1 / -1;
    input {
        border: 1px solid ${colors.text};
        color: ${colors.text};
        padding: 0.5rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: ${({ $error }) => ($error ? 0 : 0.5)}rem;
        border-bottom-right-radius: ${({ $error }) => ($error ? 0 : 0.5)}rem;
        background-color: 'white';
        height: auto;
        min-height: 2.3125rem;
        font-size: 1rem;
        flex-grow: 1;
        margin-bottom: 0;

        &:focus {
            border-color: ${colors.text};
        }
    }
`;

const ErrorMsg = styled.small`
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 1px solid ${colors.text};
    display: block;
    font-size: .75rem;
    font-style: italic;
    font-weight: normal;
    margin-top: -1px;
    padding: .375rem .5625rem .5625rem;
    background: ${colors.alert};
    color: ${colors.white};
    text-transform: capitalize;
    grid-column: 2/3;
`;

const Strong = styled.strong`
    white-space: nowrap;
`;

function LikertLabelInput({ name, label, secondaryLabel, placeholder, className, ...props }: IProps): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField<string, HTMLInputElement>(name, props);
    return (
        <Wrapper className={className} $error={touched && !!error}>
            <Label htmlFor={name}>
                <Strong>{label}:</Strong>
                {secondaryLabel && ` ${secondaryLabel}`}
            </Label>
            <input {...input} id={name} placeholder={placeholder} />
            {touched && error && <ErrorMsg>{error}</ErrorMsg>}
        </Wrapper>
    );
}

export default LikertLabelInput;
