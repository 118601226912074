import type { FormApi, ValidationErrors } from "final-form";
import { useCallback, useState, type JSX } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import { useMutation } from "urql";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";
import Textarea from "../components/FinalTextarea";
import { type IFeedbackArgs, type IFeedbackData, feedbackMutation } from "./queries";
import { ErrorBox } from "./stylings";

interface IProps {
    id: string;
}

interface IFormValues {
    content: string;
}

const defaultValues: IFormValues = {
    content: "",
};

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};

    if (!values.content) {
        errors.content = t("player.provide-feedback.form-error-content-required");
    }

    return errors;
}

const StyledTextarea = styled(Textarea)`
    textarea {
        border-radius: 1rem;
        border: 1px solid ${colors.text};
    }
    &.error textarea {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    &.error small.error {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border: 1px solid #222;
        border-top: none;
    }
`;

function timeout(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function FeedbackForm({ id }: IProps): JSX.Element {
    const [_, executeMutation] = useMutation<IFeedbackData, IFeedbackArgs>(feedbackMutation);
    const [error, setError] = useState<string>("");

    const onSubmit = useCallback(
        async (values: IFormValues, form: FormApi<IFormValues>) => {
            const result = await executeMutation({ id, content: values.content });
            if (result.error) {
                console.error(result.error);
                setError(t("player.error.error"));
            } else if (result.data.provideFeedback) {
                const { error, flipper, submittedFeedbackTask } = result.data.provideFeedback;
                if (error) {
                    console.error(error);
                    setError(t(error));
                } else {
                    setError("");
                    form.restart();
                    if (flipper) {
                        await timeout(300);
                        window.location.href = `/feedback-flipper/${submittedFeedbackTask.id}`;
                    }
                }
            }
        },
        [id, executeMutation],
    );

    return (
        <>
            <Form onSubmit={onSubmit} validate={validate} initialValues={defaultValues}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <StyledTextarea
                            name="content"
                            placeholder={t("player.provide-feedback.form-placeholder-content")}
                        />
                        <div style={{ textAlign: "center" }}>
                            <button type="submit" disabled={submitting}>
                                {t("player.provide-feedback.form-submit")}
                            </button>
                        </div>
                    </form>
                )}
            </Form>
            {error && <ErrorBox>{error}</ErrorBox>}
        </>
    );
}

export default FeedbackForm;
