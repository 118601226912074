import { memo, type JSX } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { formatYMD, parseDate } from "../../../shared/dateFns";
import t from "../../../shared/translations";
import { type Dispatch, openModal } from "../../state";
import type { ICollectFeedbackAnswerSet } from "../query";

interface IProps {
    answerSet: ICollectFeedbackAnswerSet;
    selectResponse: (response: ICollectFeedbackAnswerSet) => void;
    anonymous: boolean;
}

const Button = styled.button`
    padding: 0.3rem 0.8rem;
    margin-bottom: 0;
`;

function ResponseRow({ answerSet, selectResponse, anonymous }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();

    const open = () => {
        dispatch(openModal("Task/CollectFeedback/IndividualResponse"));
        selectResponse(answerSet);
    };

    return (
        <tr>
            {!anonymous && (
                <td>
                    {answerSet.firstName} {answerSet.lastName}
                </td>
            )}
            <td>{formatYMD(parseDate(answerSet.submitted))}</td>
            <td>
                <Button onClick={open}>{t("player.task.button-collect-feedback-view-response")}</Button>
            </td>
        </tr>
    );
}

export default memo(ResponseRow);
