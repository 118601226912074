import Header from "../../../collectFeedback/Header";
import Info from "../../../collectFeedback/Info";
import QuestionsForm from "../../../collectFeedback/QuestionsForm";
import Sender from "../../../collectFeedback/Sender";
import { useCurrentPerson } from "../../components/CurrentPerson";
import type { IFormValues } from "./Setup";
import type { JSX } from "react";

interface IProps {
    values: IFormValues;
    anonymous: boolean;
}

function Preview({ values, anonymous }: IProps): JSX.Element {
    const current = useCurrentPerson();
    let nr = 1;
    let questions = values.suggestedQuestions.map((q) => ({
        id: nr.toString(),
        nr: nr++,
        type: q.type,
        question: q.content,
        leftLabel: q.leftLabel,
        rightLabel: q.rightLabel,
        translation: q.showTrans ? q.translation : null,
        leftTranslation: q.showTrans && q.type === "likert" ? q.leftTranslation : null,
        rightTranslation: q.showTrans && q.type === "likert" ? q.rightTranslation : null,
    }));
    questions = questions.concat(
        values.customQuestions.map((q) => ({
            id: nr.toString(),
            nr: nr++,
            type: q.type,
            question: q.content,
            translation: null,
            leftLabel: q.type === "likert" ? q.leftLabel : null,
            rightLabel: q.type === "likert" ? q.rightLabel : null,
            leftTranslation: null,
            rightTranslation: null,
        })),
    );
    return (
        <>
            <Header heading={values.heading} />
            <Sender firstName={current.firstName} lastName={current.lastName} profilePicSrc={current.profilePicSrc} />
            {values.description && <Info description={values.description} />}
            <QuestionsForm anonymous={anonymous} id={""} setFinished={() => {}} preview questions={questions} />
        </>
    );
}

export default Preview;
