import styled from "styled-components";

export const Accordion = styled.div`
    border: 1px solid black;
    margin-bottom: 0.4rem;
    border-radius: 0.5rem;
    overflow: hidden;
    > * {
        padding: 0.5rem;
    }
`;

export const AccordionHead = styled.div`
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: auto auto;

    > h2 {
        margin: 0;
        font-size: 1.9rem;
    }
`;
