import type { FormEvent, JSX } from "react";
import { useMutation } from "urql";
import t from "../../../shared/translations";
import type { ITask } from "../query";

interface IArgs {
    id: string;
    on: boolean;
}

const mutation = `
    mutation($id:String!, $on:Boolean!) {
        collectFeedbackToggleNotification(id:$id, on:$on) {
            error
            collectFeedback {
                id
            }
        }
    }
`;

interface IData {
    collectFeedbackToggleNotification: {
        error: string | null;
        collectFeedback: {
            id: string;
        } | null;
    };
}

interface IProps {
    task: ITask;
}

function NotifyCheckbox({ task }: IProps): JSX.Element {
    const [mutationResult, executeMutation] = useMutation<IData, IArgs>(mutation);
    const { sendNotification, id } = task.submittedTask.collectFeedback;

    const onChange = async (event: FormEvent<HTMLInputElement>) => {
        if (mutationResult.fetching) {
            return;
        }
        const result = await executeMutation({ id, on: event.currentTarget.checked });
        if (result.error) {
            return;
        }
        if (result.data.collectFeedbackToggleNotification?.error) {
            console.error(result.data.collectFeedbackToggleNotification.error);
        }
    };

    return (
        <label>
            <input type="checkbox" checked={sendNotification} onChange={onChange} />{" "}
            {t("player.task.label-send-notification")}
        </label>
    );
}

export default NotifyCheckbox;
