import { type ComponentPropsWithoutRef, memo } from "react";
import type { JSX } from "react";

type IProps = ComponentPropsWithoutRef<"svg">;

function HamburgerIcon(props: IProps): JSX.Element {
    return (
        <svg viewBox="0 0 100 80" width="40" height="40" {...props}>
            <rect width="100" height="20" fill="currentColor" />
            <rect y="30" width="100" height="20" fill="currentColor" />
            <rect y="60" width="100" height="20" fill="currentColor" />
        </svg>
    );
}

export default memo(HamburgerIcon);
