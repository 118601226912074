import { type UseFieldConfig, useField } from "react-final-form";
import styled from "styled-components";
import * as colors from "../colors";
import getInt from "../getInt";
import type { JSX } from "react";

interface IOwnProps {
    name: string;
    size: number;
}

type IProps<T> = IOwnProps & UseFieldConfig<T>;

const LabelInput = styled.label`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40px;
    height: 40px;

    input {
        display: none;
    }
`;

interface IFakeInput {
    $checked?: boolean;
    $size: number;
}

const FakeInput = styled.div<IFakeInput>`
    position: relative;
    border: 1px solid black;
    border-radius: 100%;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    &:after {
        content: "";
        position: absolute;
        display: ${({ $checked }) => ($checked ? "block" : "none")};
        top: 3px;
        left: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 100%;
        background: ${colors.primaryColor};
    }
`;

function LikertRadio<T = string>({ name, size, ...props }: IProps<T>): JSX.Element {
    const { input } = useField<any, HTMLInputElement>(name, { ...props, type: "radio", parse: getInt });
    return (
        <LabelInput>
            <input {...input} type="radio" />
            <FakeInput $size={size} $checked={input.checked} />
        </LabelInput>
    );
}

export default LikertRadio;
