import { type JSX, memo } from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import ShowError from "../components/ShowError";
import Goals from "./Goals";
import MyPerf from "./MyPerf";
import PathGroupPerf from "./PathGroupPerf";
import { type IData, query } from "./query";

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1030px;
    width: 100%;
`;

function Performance(): JSX.Element {
    const [result] = useQuery<IData>({
        query,
    });
    if (result.fetching) {
        return (
            <Wrapper style={{ height: 200 }}>
                <Loader />
            </Wrapper>
        );
    }
    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }
    const data = result.data;
    const hasPathGroup = !!data.currentPathMembership.pathGroup; // Corrected the variable name typo
    return (
        <Wrapper>
            <MyPerf data={data} />
            <Goals data={data} />
            {hasPathGroup && <PathGroupPerf data={data} />}
        </Wrapper>
    );
}

export default memo(Performance);
