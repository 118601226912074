import { useState, type JSX } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import * as API from "../../api/Upload";
import AccountWizard, { type IValues } from "../../shared/components/AccountWizard";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import ShowError from "../components/ShowError";
import type { IData } from "./index";
import { type IArgs, type IMuatationData, type IQueryData, mutation, query } from "./queries";

interface IProps {
    data: IData;
}

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1030px;
    width: 100%;
`;

function FirstTimeLogin({ data }: IProps): JSX.Element {
    const [result] = useQuery<IQueryData>({ query });
    const [_, executeMutation] = useMutation<IMuatationData, IArgs>(mutation);
    const [error, setError] = useState("");

    const onComplete = async (values: IValues) => {
        let filename: string | null = null;
        if (values.profilePicture) {
            try {
                filename = await API.uploadFile(values.profilePicture, "image");
            } catch (err) {
                console.error(err);
                setError(t("player.first-time-login.error"));
                return;
            }
        }
        const result = await executeMutation({
            data: {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender,
                title: values.title,
                tel: values.tel,
                password: values.password,
                agreedToTerms: values.agreedToTerms,
                avatar: values.avatar,
                birthYear: values.birthYear,
                emailNotifications: values.emailNotifications,
                profilePicture: filename,
            },
        });
        if (result.error) {
            console.error(result.error);
            setError(t("player.first-time-login.error"));
        } else {
            if (result.data.currentPersonFirstTimeSetup.error) {
                console.error(result.data.currentPersonFirstTimeSetup.error);
                setError(result.data.currentPersonFirstTimeSetup.error);
            } else {
                window.location.href = result.data.currentPersonFirstTimeSetup.redirectUrl;
            }
        }
    };

    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError />;
    } else {
        const person = result.data.currentPerson;
        const initialValues = {
            email: person.user.email,
            firstName: person.firstName,
            lastName: person.lastName,
            gender: person.gender,
            title: person.title,
            tel: person.tel,
            avatar: person.avatar,
            birthYear: person.birthYear,
            emailNotifications: person.eMailNotifications,
        };
        main = (
            <AccountWizard
                terms={data.terms}
                avatarOptions={data.avatars}
                onComplete={onComplete}
                initialValues={initialValues}
            />
        );
    }
    return (
        <Wrapper>
            <header>
                <h1>{t("player.first-time-login.header")}</h1>
            </header>
            <p>{t("player.first-time-login.info", { webapp_title: data.webappTitle })}</p>
            {main}
            {error && <div>{error}</div>}
        </Wrapper>
    );
}

export default FirstTimeLogin;
