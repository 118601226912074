import type { AiMessageRoleType } from "../../../shared/types";
import type { ISubmittedTaskVariant, ITaskVariant } from "../query";

type IMessage = {
    id: string;
    role: AiMessageRoleType;
    content: string;
};

export interface IState {
    status: "notStarted" | "chatting" | "finished";
    aiLoading: boolean;
    messages: IMessage[];
    error: string | null;
    threadId: string | null;
}

export function createInitialState(variant: ITaskVariant | ISubmittedTaskVariant): IState {
    const messages = "taskAiThread" in variant ? variant.taskAiThread.aiThreadItems : [];
    const threadId = "taskAiThread" in variant ? variant.taskAiThread.id : null;
    return {
        status: messages.length === 0 ? "notStarted" : "chatting",
        aiLoading: false,
        messages,
        error: null,
        threadId,
    };
}

export type IAction =
    | { type: "TOGGLE-AI-LOADING"; to: boolean }
    | { type: "RECORD-ERROR"; error: string }
    | { type: "SET-CHAT"; messages: IMessage[]; threadId: string }
    | { type: "FINISH" };

export function reducer(draft: IState, action: IAction): void {
    switch (action.type) {
        case "TOGGLE-AI-LOADING":
            draft.aiLoading = action.to;
            return;
        case "FINISH":
            draft.status = "finished";
            return;
        case "RECORD-ERROR":
            draft.aiLoading = false;
            draft.error = action.error;
            return;
        case "SET-CHAT":
            draft.status = "chatting";
            draft.aiLoading = false;
            draft.error = null;
            draft.messages = action.messages;
            draft.threadId = action.threadId;
            return;
        default:
            throw new Error("Unknown action");
    }
}
