import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Progress from "../../teamScans/progress/Progress";
import Resend from "../../teamScans/progress/Resend";
import SendReminderSuccess from "../../teamScans/progress/SendReminderSuccess";
import Report from "../../teamScans/report/Report";
import ShareReport from "../../teamScans/report/ShareReport";
import ShareReportSuccess from "../../teamScans/report/ShareReportSuccess";
import Setup from "../../teamScans/setup/Setup";
import type { ITask, ITaskVariant } from "../query";
import { Box } from "../stylings";
import Create from "./Create";
import DoSurvey from "./DoSurvey";
import Redirector from "./Redirector";
import type { JSX } from "react";

interface IProps {
    task: ITask;
    variant: ITaskVariant;
}

const Boxer = styled(Box)`
    padding: 15px;
    @media (min-width: 600px) {
        padding: 1rem;
    }
`;

function TeamScan({ task, variant }: IProps): JSX.Element {
    if (!task.submittedTask?.submittedTaskVariant?.teamScan) {
        return (
            <Boxer>
                <Create task={task} variant={variant} />
            </Boxer>
        );
    }
    const teamScan = task.submittedTask.submittedTaskVariant.teamScan;
    return (
        <Boxer>
            <Routes>
                <Route path="team-scans/setup/:id" element={<Setup />} />
                <Route path="team-scans/do-survey/:id" element={<DoSurvey teamScan={teamScan} />} />
                <Route path="team-scans/progress/:id/resend" element={<Resend />} />
                <Route path="team-scans/progress/:id/resend-success" element={<SendReminderSuccess />} />
                <Route path="team-scans/progress/:id" element={<Progress />} />
                <Route path="team-scans/report/:id/share" element={<ShareReport />} />
                <Route path="team-scans/report/:id/share-success" element={<ShareReportSuccess />} />
                <Route path="team-scans/report/:id" element={<Report />} />
                <Route path="/" element={<Redirector teamScan={teamScan} taskId={task.id} />} />
            </Routes>
        </Boxer>
    );
}

export default TeamScan;
