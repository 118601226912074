import { memo } from "react";
import type { ComponentPropsWithoutRef, JSX } from "react";

function CrossIcon(props: ComponentPropsWithoutRef<"svg">): JSX.Element {
    return (
        <svg strokeLinecap="butt" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" stroke="currentColor" strokeWidth="20">
                <path d="m10 10 80 80" />
                <path d="m90 10-80 80" />
            </g>
        </svg>
    );
}

export default memo(CrossIcon);
