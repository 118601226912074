import t from "../../shared/translations";
import { Box } from "./stylings";
import type { JSX } from "react";

interface IProps {
    info: "request" | "feedback";
    name?: string;
    pathname: string;
}

function Info({ info, name, pathname }: IProps): JSX.Element {
    return (
        <Box>
            <h3>{t("player.provide-feedback.heading-peer-feedback")}</h3>
            {info === "request" && <p>{t("player.provide-feedback.info-request", { pathname })}</p>}
            {info === "feedback" && (
                <>
                    <p>{t("player.provide-feedback.info-feedback", { name })}</p>
                    <p>{t("player.provide-feedback.info-name-shown")}</p>
                </>
            )}
        </Box>
    );
}

export default Info;
