import { type FormEvent, type JSX, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import ProfileIcon from "../../shared/components/icons/navIcons/ProfileIcon";
import PulseIcon from "../../shared/components/icons/taskIcons/PulseIcon";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import { useCurrentPerson } from "../components/CurrentPerson";
import { NavButtonAnchor, NavSelect } from "./stylings";

const HeaderWrapper = styled.header`
    padding: 0 10px 0 10px;
    max-width: 1440px;
    width: 100%;
`;

const MainNav = styled.nav`
    background-color: ${colors.altSecondaryColor};
    margin-bottom: 0.2rem;
    svg {
        width: 60px;
        height: 60px;
        fill: white;
        margin: 0.2rem 1rem;
    }
`;

const SecondaryNav = styled.nav`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    form {
        min-width: 0;
    }
`;

function Header(): JSX.Element {
    const person = useCurrentPerson();
    const showAdmin = person.user.role.name === "admin";
    const showLearningCoach =
        loadJSData().features.learning_coach &&
        (person.user.role.name === "admin" || person.user.role.name === "learning-coach");
    const showTracking =
        loadJSData().features.tracking &&
        (person.user.role.name === "admin" ||
            person.user.role.name === "organisation-manager" ||
            person.user.role.name === "team-manager");
    const showViewSwitch = showAdmin || showLearningCoach || showTracking;
    const showPathSwitch = person.ongoingPaths.length > 0 || person.teamScans.length > 0;
    const pathFormRef = useRef<HTMLFormElement>(null);

    const onViewSwitch = (event: FormEvent<HTMLSelectElement>) => {
        const path = event.currentTarget.value;
        if (path) {
            window.location.href = path;
        }
    };

    const onPathSwitch = (event: FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        if (!value) {
            return;
        }
        if (value === "/team-scans" || value === "/ai-chatbots") {
            window.location.href = value;
        } else {
            pathFormRef.current?.submit();
        }
    };

    return (
        <HeaderWrapper>
            <MainNav>
                <Link to="/pulse">
                    <PulseIcon white />
                </Link>
                <Link to="/pulse/profile">
                    <ProfileIcon />
                </Link>
            </MainNav>
            <SecondaryNav>
                {showPathSwitch && (
                    <form action="/select-path" method="POST" ref={pathFormRef} style={{ minWidth: "0" }}>
                        <input name="_csrf_token" type="hidden" value={__CSRF_TOKEN__} />
                        <NavSelect name="path" onChange={onPathSwitch}>
                            <option value="">{t("player.header.secondary-nav-pulse")}</option>
                            {person.ongoingPaths.map((path) => (
                                <option key={path.id} value={path.id}>
                                    {path.pathname}
                                </option>
                            ))}
                            {person.teamScans.length > 0 && (
                                <option value="/team-scans">{t("player.header.secondary-nav-team-scan")}</option>
                            )}
                            {loadJSData().features.ai && person.standaloneAiChatbots.length > 0 && (
                                <option value="/ai-chatbots">{t("player.header.secondary-nav-ai-chatbots")}</option>
                            )}
                        </NavSelect>
                    </form>
                )}
                {showViewSwitch && (
                    <NavSelect onChange={onViewSwitch}>
                        <option value="">{t("player.header.secondary-nav-switch-view")}</option>
                        {showAdmin && <option value="/admin">{t("player.header.secondary-nav-admin")}</option>}
                        {showLearningCoach && (
                            <option value="/learning-coach">{t("player.header.secondary-nav-learning-coach")}</option>
                        )}
                        {showTracking && (
                            <option value={`/tracking/${person.organisation.id}`}>
                                {t("player.header.secondary-nav-tracking")}
                            </option>
                        )}
                    </NavSelect>
                )}
                <NavButtonAnchor href="/logout">{t("player.header.secondary-nav-log-out")}</NavButtonAnchor>
            </SecondaryNav>
        </HeaderWrapper>
    );
}

export default Header;
