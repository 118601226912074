import { type ComponentPropsWithoutRef, type JSX, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import { addMonths, format, formatDatetime, parseDate, subMonths } from "../../shared/dateFns";
import { type Dispatch, type State, calendarSelectMonth } from "../state";
import { breakpoints } from "./breakpoints";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 5px 2.7vw;
    align-items: center;
    border: 1px solid ${colors.text};
    border-radius: 8px;
    justify-content: center;
    gap: 1rem;

    & > svg {
        cursor: pointer;
        width: 1.2rem;
        height: 1.2rem;
        fill: none;
        stroke: ${colors.text};
        stroke-width: 4.5;
        user-select: none;
    }

    @container (min-width: ${breakpoints.sm}) {
        padding: 6px 2.7vw;
        gap: 2rem;
        & > svg {
            width: 1.3rem;
            height: 1.3rem;
        }
    }
    @container (min-width: ${breakpoints.md}) {
        padding: 8px 2.7vw;
        gap: 3rem;
        & > svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    @container (min-width: ${breakpoints.lg}) {
        padding: 10px 2.7vw;
        gap: 4rem;
        & > svg {
            width: 1.7rem;
            height: 1.7rem;
        }
    }
`;

interface ArrowProps extends ComponentPropsWithoutRef<"svg"> {
    flip?: boolean;
}

const Arrow = ({ flip, ...props }: ArrowProps) => (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Arrow</title>
        <circle cx="50" cy="50" r="47" />
        <path d={`M${flip ? "40" : "60"} 15 L${flip ? "70" : "30"} 50 L${flip ? "40" : "60"} 85`} />
    </svg>
);

const MonthHeading = styled.span`
    font-size: 1rem;
    text-align: center;
    flex-grow: 1;
    

    @container (min-width: ${breakpoints.sm}) {
        flex-grow: 0;
        font-size: 1.1rem;
    }
    @container (min-width: ${breakpoints.md}) {
        font-size: 1.3rem;
    }
    @container (min-width: ${breakpoints.lg}) {
        font-size: 1.6rem;
    }
`;

function MonthHeader(): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const month = parseDate(useSelector((state: State) => state.calendar.selectedMonth));

    const prev = () => {
        const newMonth = subMonths(month, 1);
        dispatch(calendarSelectMonth(formatDatetime(newMonth)));
    };
    const next = () => {
        const newMonth = addMonths(month, 1);
        dispatch(calendarSelectMonth(formatDatetime(newMonth)));
    };
    return (
        <Wrapper>
            <Arrow onClick={prev} />
            <MonthHeading>{format(month, "yyyy-MM")}</MonthHeading>
            <Arrow flip onClick={next} />
        </Wrapper>
    );
}

export default memo(MonthHeader);
