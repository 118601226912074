import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import RenderMark from "../../shared/components/RenderMark";
import ShowError from "../components/ShowError";
import type { JSX } from "react";

const query = `
    query {
        doc(id:"player.terms.terms") {
            id
            content
        }
    }
`;

interface IDoc {
    id: string;
    content: string;
}

interface IData {
    doc: IDoc;
}

function Terms(): JSX.Element {
    const [result] = useQuery<IData>({ query });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }
    return <RenderMark content={result.data.doc.content} />;
}

export default Terms;
