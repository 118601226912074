import Linkify from "linkify-react";
import { Fragment, useMemo } from "react";
import LikertChart from "../../../shared/components/LikertChart";
import type { ICollectFeedbackAnswerSet } from "../query";
import { QuestionBox, QuestionHeading } from "../stylings";
import QuestionNum from "./QuestionNum";

interface IProps {
    answerSets: ICollectFeedbackAnswerSet[];
}

type ICollectedAnswer =
    | {
          nr: number;
          type: "likert";
          question: string;
          translation: string | null;
          leftLabel: string;
          rightLabel: string;
          leftTranslation: string | null;
          rightTranslation: string | null;
          likertAnswer: [number, number, number, number, number];
      }
    | {
          nr: number;
          type: "text";
          question: string;
          translation: string | null;
          textAnswers: string[];
      };

function collectAnswers(answerSets: ICollectFeedbackAnswerSet[]): ICollectedAnswer[] {
    if (answerSets.length === 0) {
        return [];
    }
    const answers: ICollectedAnswer[] = answerSets[0].collectFeedbackAnswers.map((ans) => {
        if (ans.type === "text") {
            return {
                nr: ans.nr,
                type: "text",
                question: ans.question,
                translation: ans.translation,
                textAnswers: [],
            };
        }
        return {
            nr: ans.nr,
            type: "likert",
            question: ans.question,
            translation: ans.translation,
            leftLabel: ans.leftLabel,
            rightLabel: ans.rightLabel,
            leftTranslation: ans.leftTranslation,
            rightTranslation: ans.rightTranslation,
            likertAnswer: [0, 0, 0, 0, 0],
        };
    });
    for (const answerSet of answerSets) {
        for (let i = 0, iMax = answerSet.collectFeedbackAnswers.length; i < iMax; i++) {
            const answer = answerSet.collectFeedbackAnswers[i];
            const targetAnswer = answers[i];
            if (targetAnswer.type === "likert") {
                targetAnswer.likertAnswer[answer.likertAnswer - 1] += 1;
            } else if (targetAnswer.type === "text") {
                targetAnswer.textAnswers.push(answer.textAnswer);
            }
        }
    }
    return answers;
}

function AllResponsesModal({ answerSets }: IProps) {
    const collectedAnswers = useMemo(() => collectAnswers(answerSets), [answerSets]);

    return (
        <>
            {collectedAnswers.map((ans) => (
                <QuestionBox key={ans.nr}>
                    <QuestionHeading>
                        <QuestionNum>{ans.nr}</QuestionNum>
                        <span>{ans.translation || ans.question}</span>
                    </QuestionHeading>
                    {ans.type === "text" &&
                        ans.textAnswers.map((textAns, idx) => (
                            <Fragment key={idx}>
                                <Linkify options={{ nl2br: true }} tagName="p">
                                    {textAns}
                                </Linkify>
                                {idx < ans.textAnswers.length - 1 && <hr />}
                            </Fragment>
                        ))}
                    {ans.type === "likert" && (
                        <LikertChart
                            leftLabel={ans.leftTranslation || ans.leftLabel}
                            rightLabel={ans.rightTranslation || ans.rightLabel}
                            answers={ans.likertAnswer}
                        />
                    )}
                </QuestionBox>
            ))}
        </>
    );
}

export default AllResponsesModal;
