import type { ValidationErrors } from "final-form";
import type { Dispatch, JSX } from "react";
import { Form } from "react-final-form";
import { loadJSData } from "../../jsData";
import t from "../../translations";
import Input from "./Input";
import type { IAction, IState } from "./state";
import { ButtonRow, FormGrid } from "./stylings";

interface IFormValues {
    password: string;
    confirmPassword: string;
}

interface IProps {
    state: IState;
    dispatch: Dispatch<IAction>;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.password) {
        errors.password = t("shared.account-wizard.form-password-error-password-required");
    } else if (values.password.length < loadJSData().min_password_length) {
        errors.password = t("shared.account-wizard.form-password-error-password-too-short");
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = t("shared.account-wizard.form-password-error-confirm-password-required");
    }
    if (values.confirmPassword !== values.password) {
        errors.confirmPassword = t("shared.account-wizard.form-password-error-confirm-password-not-match");
    }
    return errors;
}

function PasswordForm({ state, dispatch }: IProps): JSX.Element {
    const initialValues: IFormValues = {
        password: state.values.password,
        confirmPassword: state.values.confirmPassword,
    };
    const onSubmit = (values: IFormValues) => {
        dispatch({ type: "NEXT-PASSWORD", values });
    };
    const onBack = () => {
        dispatch({ type: "BACK-PASSWORD" });
    };

    return (
        <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <p>
                        {t("shared.account-wizard.form-password-info", {
                            min_length: loadJSData().min_password_length,
                        })}
                    </p>
                    <FormGrid>
                        <Input
                            name="password"
                            type="password"
                            label={t("shared.account-wizard.form-password-label-password")}
                            required
                        />
                        <Input
                            name="confirmPassword"
                            type="password"
                            label={t("shared.account-wizard.form-password-label-confirm-password")}
                            required
                        />
                    </FormGrid>
                    <ButtonRow>
                        <button type="button" onClick={onBack}>
                            {t("shared.account-wizard.form-password-button-back")}
                        </button>
                        <button type="submit">{t("shared.account-wizard.form-password-button-next")}</button>
                    </ButtonRow>
                </form>
            )}
        </Form>
    );
}

export default PasswordForm;
