import styled from "styled-components";
import * as colors from "../../../shared/colors";
import t from "../../../shared/translations";
import type { JSX } from "react";

interface IProps {
    taskId: string;
    grouped: boolean;
}

const ButtonAnchor = styled.a`
    display: inline-block;
    padding: 0.5rem 1rem 0.5325rem;
    margin-bottom: 0.5rem;
    background-color: ${colors.primaryColor};
    color: ${colors.white};

    &:hover, &:focus, &:active {
        color: ${colors.white};
    }
`;

function DownloadAnswers({ taskId, grouped }: IProps): JSX.Element {
    let url = `/pdf/download/all-answers/${taskId}/all-answers.pdf`;
    if (grouped) {
        url += "?grouped=true";
    }
    return (
        <ButtonAnchor target="_blank" href={url}>
            {t("player.submitted-task.button-download-all-answers")}
        </ButtonAnchor>
    );
}

export default DownloadAnswers;
