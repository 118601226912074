import type { JSX } from "react";
import styled, { keyframes } from "styled-components";
import UnlockAnimation from "../../shared/components/UnlockAnimation";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import t from "../../shared/translations";
import type { IData } from "./index";

interface IProps {
    data: IData;
}

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const P = styled.p`
    text-align: center;
    font-size: 1.5rem;
    animation: ${fadeIn} 1s ease 0s 1 forwards,
               ${fadeOut} 1s ease 3s 1 forwards;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 120px);
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    animation: ${fadeIn} 1s ease 0s 1 forwards,
               ${fadeOut} 1s ease 3s 1 forwards;
`;

const TaskBox = styled.div`
    position: relative;
    display: grid;
    justify-items: center;
    width: 120px;
    text-align: center;
`;

const StyledUnlockAnimation = styled(UnlockAnimation)`
    position: absolute;
    top: 10px;
    left: 20px;
    width: 80px;
    height: 80px;
`;

function UnlockAward({ data }: IProps): JSX.Element {
    return (
        <>
            <P>{t("player.flipper.heading-unlock-tasks", { count: data.lockedTasks.length })}</P>
            <Row>
                {data.lockedTasks.map((task, i) => (
                    <TaskBox key={task.id}>
                        <TaskIcon icon={task.icon} active={false} />
                        <span>{task.shortTitle}</span>
                        <StyledUnlockAnimation delay={i / 5} />
                    </TaskBox>
                ))}
            </Row>
        </>
    );
}

export default UnlockAward;
