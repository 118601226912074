import { postFile } from "./base";

type FileType = "image" | "audio" | "file" | "task-answer";

export async function uploadFile(file: File, fileType: FileType, publicId?: string): Promise<string> {
    const url = "/upload/file";
    const data = new FormData();
    data.append("file_type", fileType);
    if (publicId) {
        data.append("public_id", publicId);
    }
    data.append("file", file);
    return postFile(url, data) as Promise<string>;
}
