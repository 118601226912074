import type { ValidationErrors } from "final-form";
import { useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as colors from "../../../shared/colors";
import parseEmails from "../../../shared/parseEmails";
import t from "../../../shared/translations";
import { type Dispatch, type IRecipient, type State, toStep2 } from "../../state";
import { Center, OList, PaddedLeft } from "../stylings";
import { Select, Textarea } from "../stylings/form";
import type { ICurrentPerson } from "./Setup";
import { industryOptions, sizeOptions } from "./options";

interface IProps {
    id: string;
    current: ICurrentPerson;
    teamSizeLimit: number;
    requiredFraction: number;
}

interface IFormValues {
    industry: string;
    organisationSize: string;
    emails: string;
}

function parseRecipients(data: string): IRecipient[] {
    const emails = parseEmails(data);
    const recipients: IRecipient[] = [];
    for (const email of emails) {
        const start = email.split("@")[0];
        let [firstName, lastName] = start.split(".");
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
        lastName = lastName || "";
        if (lastName) {
            lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
        }
        recipients.push({
            current: false,
            email,
            firstName,
            lastName,
            teamLeader: false,
        });
    }
    return recipients;
}

function validate(values: IFormValues, teamSizeLimit: number, email: string): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.industry) {
        errors.industry = t("player.team-scan-setup-1.form-error-industry-required");
    }
    if (!values.organisationSize) {
        errors.organisationSize = t("player.team-scan-setup-1.form-error-org-size-required");
    }
    if (!values.emails) {
        errors.emails = t("player.team-scan-setup-1.form-error-emails-required");
    } else if (!values.emails.includes("@")) {
        errors.emails = t("player.team-scan-setup-1.form-error-emails-required");
    } else {
        const emails = parseEmails(values.emails);
        if (typeof teamSizeLimit === "number" && emails.length >= teamSizeLimit) {
            errors.emails = t("player.team-scan-setup-1.form-error-emails-too-many");
        } else if (emails.length < 3) {
            errors.emails = t("player.team-scan-setup-1.form-error-emails-too-few");
        } else if (emails.includes(email)) {
            errors.emails = t("player.team-scan-setup-1.form-error-emails-not-your-own");
        }
    }
    return errors;
}

const ErrorSpan = styled.span`
    display: inline-block;
    padding: 3px;
    background-color: ${colors.alert};
    color: white;
`;

function Step1({ current, teamSizeLimit, requiredFraction, id }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const { industry, organisationSize, emails } = useSelector((state: State) => state.teamScan[id]);
    const initialValues: IFormValues = {
        emails,
        industry,
        organisationSize,
    };

    const onSubmit = useCallback(
        (values: IFormValues) => {
            const recipients = parseRecipients(values.emails).filter((rep) => rep.email !== current.user.email);
            recipients.unshift({
                current: true,
                email: current.user.email,
                firstName: current.firstName,
                lastName: current.lastName,
                teamLeader: true,
            });
            dispatch(
                toStep2({
                    id,
                    emails: values.emails,
                    industry: values.industry,
                    organisationSize: values.organisationSize,
                    recipients,
                }),
            );
        },
        [current, id],
    );

    const requiredPercent = (requiredFraction * 100).toFixed(0);
    return (
        <>
            <h1>{t("player.team-scan-setup-1.heading")}</h1>
            <PaddedLeft>
                <OList>
                    <li>{t("player.team-scan-setup-1.info-point-1")}</li>
                    <li>
                        {t("player.team-scan-setup-1.info-point-2-add-emails")}
                        <br />
                        <strong>{t("player.team-scan-setup-1.info-point-2-not-your-email")}</strong>
                        <br />
                        {t("player.team-scan-setup-1.info-point-2-redirect-to-survey")}
                    </li>
                </OList>
            </PaddedLeft>
            <h2>{t("player.team-scan-setup-1.heading-requirements")}</h2>
            <PaddedLeft>
                <p>
                    {t("player.team-scan-setup-1.info-minimum-needed", { requiredPercent })}
                    <br />
                    {t("player.team-scan-setup-1.info-all-fill")}
                    <br />
                    {typeof teamSizeLimit === "number" && teamSizeLimit < 4 ? (
                        <ErrorSpan>{t("player.team-scan-setup-1.error-too-small-team")}</ErrorSpan>
                    ) : teamSizeLimit ? (
                        <span>{t("player.team-scan-setup-1.info-max-team-size", { teamSizeLimit })}</span>
                    ) : null}
                </p>
            </PaddedLeft>
            <Form
                onSubmit={onSubmit}
                validate={(values) => validate(values, teamSizeLimit, current.user.email)}
                initialValues={initialValues}
            >
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Select name="industry" label={t("player.team-scan-setup-1.form-label-industry")}>
                            <option value="">{t("player.team-scan-setup-1.form-option-industry-none")}</option>
                            {industryOptions.en.map((opt) => (
                                <option key={opt} value={opt}>
                                    {opt}
                                </option>
                            ))}
                        </Select>
                        <Select name="organisationSize" label={t("player.team-scan-setup-1.form-label-org-size")}>
                            <option value="">{t("player.team-scan-setup-1.form-option-org-size-none")}</option>
                            {sizeOptions.map((opt) => (
                                <option key={opt} value={opt}>
                                    {opt}
                                </option>
                            ))}
                        </Select>
                        <Textarea name="emails" label={t("player.team-scan-setup-1.form-label-emails")} />
                        <div>{t("player.team-scan-setup-1.info-email-per-line")}</div>
                        <Center>
                            <button type="submit" disabled={submitting}>
                                {t("player.team-scan-setup-1.form-button-submit")}
                            </button>
                        </Center>
                    </form>
                )}
            </Form>
        </>
    );
}

export default Step1;
