import { animated, to, useSpring } from "@react-spring/web";
import { type ComponentPropsWithoutRef, memo, type JSX } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    miles: number;
}

function miles2degrees(miles: number): number {
    const cappedMiles = Math.min(miles, 500);
    const proportion = cappedMiles / 500;
    const degrees = proportion * 224;
    return degrees;
}

const Svg = styled.svg`
    max-width: 100%;
    width: 200px;
    height: auto;
    stroke: ${colors.text};
    text-anchor: middle;
    stroke: none;
`;

const SpeedLabel = styled.text`
    font-size: 6px;
    text-anchor: start;
    stroke: none;
`;

const MilesLabel = styled.text`
    text-anchor: middle;
    font-variant: small-caps;
    font-size: 7px;
`;

function Speedometer({ miles, className, ...props }: IProps): JSX.Element {
    const degrees = miles2degrees(miles);
    const { rotation } = useSpring({
        from: { rotation: 0 },
        to: { rotation: degrees },
        delay: 1000,
        config: {
            tension: 120,
            friction: 90,
            easing: (t) => (1 - Math.cos(Math.PI * t)) / 2,
        },
    });

    return (
        <Svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props} className={className}>
            <g stroke="black" fill="none" strokeWidth="2">
                <circle cx="50" cy="50" r="47" />
                <circle cx="50" cy="50" r="42" />
                <path d="M16.5 65 A36.8 36.8 0 1 1 83.5 65" />
                <line x1="16.5" y1="63.9" x2="21" y2="61.7" />
                <line x1="13" y1="50" x2="19" y2="50" />
                <line x1="16.5" y1="36.1" x2="21" y2="38.3" />
                <line x1="23" y1="25" x2="27" y2="28.7" />
                <line x1="35" y1="17" x2="37.2" y2="21.5" />
                <line x1="50" y1="13" x2="50" y2="19" />
                <line x1="65" y1="17" x2="62.6" y2="21.5" />
                <line x1="77" y1="25" x2="72.3" y2="28.7" />
                <line x1="83.5" y1="36.1" x2="79" y2="38.3" />
                <line x1="87" y1="50" x2="81" y2="50" />
                <line x1="83.5" y1="63.9" x2="79" y2="61.7" />
            </g>
            <SpeedLabel x="23" y="62">
                0
            </SpeedLabel>
            <SpeedLabel x="22" y="41">
                100
            </SpeedLabel>
            <SpeedLabel x="35" y="27">
                200
            </SpeedLabel>
            <SpeedLabel x="57" y="27">
                300
            </SpeedLabel>
            <SpeedLabel x="67" y="41">
                400
            </SpeedLabel>
            <SpeedLabel x="67" y="62">
                500
            </SpeedLabel>
            <MilesLabel x="50" y="73">
                {t("player.performance.label-speedometer-row1")}
            </MilesLabel>
            <MilesLabel x="50" y="80">
                {t("player.performance.label-speedometer-row2")}
            </MilesLabel>
            <animated.g
                stroke="black"
                strokeWidth="2"
                fill="#ff5057"
                strokeLinejoin="round"
                transform={to([rotation], (r) => `rotate(${r}, 50, 50)`)}
            >
                <path d="M23 61 L60 43 L62.2 48Z" />
                <circle cx="50" cy="50" r="4.5" />
            </animated.g>
        </Svg>
    );
}

export default memo(Speedometer);
