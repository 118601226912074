import { type JSX, memo } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import OrigLockIcon from "../../shared/components/icons/LockIcon";
import OrigClockIcon from "../../shared/components/icons/taskIcons/ClockIcon";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import type { ITask } from "../state";
import { breakpoints } from "./breakpoints";

interface IProps {
    task: ITask;
}

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    justify-items: start;
    > * {
        margin: 0;
    }
    @media (min-width: 800px) {
        gap: 1rem 0.5rem;
    }
`;

export const TwoCol = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
    align-items: center;
    justify-items: start;

    > * {
        margin: 0;
    }

    @media (min-width: ${breakpoints.sm}) {
        gap: 1rem 0.5rem;
    }
`;

export const ButtonAnchor = styled.a`
    display: block;
    padding: 0.5rem 1rem 0.5325rem;
    margin-bottom: 0.5rem;
    background-color: ${colors.primaryColor};
    color: ${colors.white};
    grid-column: 2/3;

    &:hover, &:focus, &:active {
        color: ${colors.white};
    }
`;

export const DateBox = styled.div`
    padding: 0.3rem 0.6rem;
    font-size: 1rem;
    border-width: 1px;
    border-style: solid;
    justify-self: start;
    @media (min-width: 800px) {
        padding: 0.5rem 1rem;
    }
`;

export const Label = styled.div`
    justify-self: end;
`;

export const UnlockBox = styled(DateBox)`
    border-color: ${colors.primaryColor};
    color: ${colors.primaryColor};
    display: flex;
    align-items: center;
    gap: 5px;
`;

interface IComplete {
    $complete: boolean;
}

export const CallToActionBox = styled(DateBox)<IComplete>`
    border-color: ${colors.calendar.callToAction.border};
    background-color: ${({ $complete }) => ($complete ? colors.white : colors.calendar.callToAction.border)};
    color: ${({ $complete }) => ($complete ? colors.calendar.callToAction.border : colors.white)};
`;

export const DeadlineBox = styled(DateBox)<IComplete>`
    border-color: ${colors.deadline};
    background-color: ${({ $complete }) => ($complete ? colors.white : colors.deadline)};
    color: ${({ $complete }) => ($complete ? colors.deadline : colors.white)};
`;

export const MilesBox = styled.div`
    font-size: 2rem;
    line-height: 1;
`;

export const LockIcon = styled(OrigLockIcon)`
    height: 1rem;
    width: 1rem;
`;

const ClockIcon = styled(OrigClockIcon)`
    width: 5rem;
    height: 5rem;
    justify-self: end;
`;

const TimeLabel = styled.div`
    font-size: 1.2rem;
`;

function TaskModal({ task }: IProps): JSX.Element {
    return (
        <Wrapper>
            <h2>
                {t("player.calendar.modal-heading-task")}: {task.shortTitle}
            </h2>
            <h4>{task.pathname}</h4>
            {task.complete && <p>{t("player.calendar.info-task-complete")}</p>}
            <TwoCol>
                {task.unlockDate && (
                    <>
                        <Label>{t("player.calendar.modal-label-unlock-date")}:</Label>
                        <UnlockBox>
                            <LockIcon /> {formatYMD(parseDate(task.unlockDate))}
                        </UnlockBox>
                    </>
                )}
                <Label>{t("player.calendar.modal-label-call-to-action")}:</Label>
                <CallToActionBox $complete={task.complete}>{formatYMD(parseDate(task.callToAction))}</CallToActionBox>
                {task.deadline && (
                    <>
                        <Label>{t("player.calendar.modal-label-deadline")}:</Label>
                        <DeadlineBox $complete={task.complete}>{formatYMD(parseDate(task.deadline))}</DeadlineBox>
                    </>
                )}
                <Label>{t("player.calendar.modal-label-miles")}:</Label>
                <MilesBox>{task.miles}</MilesBox>
                {task.timeEstimate && (
                    <>
                        <ClockIcon />
                        <TimeLabel>{t(`shared.time-estimate.${task.timeEstimate}`)}</TimeLabel>
                    </>
                )}
                {task.url && <ButtonAnchor href={task.url}>{t("player.calendar.button-go-to-task")}</ButtonAnchor>}
            </TwoCol>
        </Wrapper>
    );
}

export default memo(TaskModal);
