import * as Sentry from "@sentry/browser";
import type { ValidationErrors } from "final-form";
import Linkify from "linkify-react";
import { useCallback, useState, type JSX } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useMutation } from "urql";
import t from "../../shared/translations";
import Input from "../components/FinalInput";
import Textarea from "../components/FinalTextarea";
import { type Dispatch, closeModal } from "../state";

interface IFormValues {
    subject: string;
    content: string;
}

const defaultValues: IFormValues = {
    content: "",
    subject: "",
};

const sendMutation = `
    mutation($subject:String!,$content:String!,$replyToId:String) {
        messageSend(subject:$subject,content:$content,replyToId:$replyToId) {
            error
            learningCoachMessage {
                __typename
            }
        }
    }
`;

interface IArgs {
    subject: string;
    content: string;
    replyToId: string | null;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.subject) {
        errors.subject = t("player.messages.form-message-error-subject");
    }
    if (!values.content) {
        errors.content = t("player.messages.form-message-error-content");
    }
    return errors;
}

interface IProps {
    message?: {
        id: string;
        datetime: string;
        senderName: string;
        subject: string;
        content: string;
    };
}

const H3 = styled.h3`
    font-size: 1.2rem;
`;

function Send({ message }: IProps): JSX.Element {
    const [_, executeMutation] = useMutation<any, IArgs>(sendMutation);
    const dispatch = useDispatch<Dispatch>();
    const [showError, setShowError] = useState(false);

    const onSubmit = useCallback(
        async (values: IFormValues) => {
            const result = await executeMutation({
                replyToId: message?.id || null,
                ...values,
            });
            if (result.error) {
                console.error(result.error);
                Sentry.captureMessage(result.error.toString());
                setShowError(true);
            } else if (result.data.sendMessage?.error) {
                console.error(result.data.sendMessage.error);
                Sentry.captureMessage(result.data.sendMessage.error);
                setShowError(true);
            } else {
                dispatch(closeModal());
            }
        },
        [message?.id, executeMutation],
    );

    const initialValues = message ? { content: "", subject: `Re: ${message.subject}` } : defaultValues;

    return (
        <>
            <h2>{t("player.messages.heading-send-message")}</h2>
            {!!message && (
                <div>
                    <hr />
                    <H3>{t("player.messages.heading-replying-to", { sender: message.senderName })}</H3>
                    <div>{message.subject}</div>
                    <hr />
                    <Linkify options={{ nl2br: true }}>{message.content}</Linkify>
                    <hr />
                </div>
            )}
            <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
                {({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <Input name="subject" label={t("player.messages.form-message-label-subject")} />
                        <Textarea name="content" />
                        <button type="submit" disabled={submitting || pristine}>
                            {t("player.messages.form-message-submit")}
                        </button>
                    </form>
                )}
            </Form>
            {showError && <p>{t("player.messages.error-failed-to-send-message")}</p>}
        </>
    );
}

export default Send;
