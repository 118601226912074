import type { ValidationErrors } from "final-form";
import type { JSX } from "react";
import { Form } from "react-final-form";
import CrossIcon from "../../shared/components/icons/CrossIcon";
import t from "../../shared/translations";
import { Center, Input, InputRow, Maw, SmallButton, SuggestedList } from "./stylings";

export interface IFormValues {
    name: string;
    question1: string;
    question2: string;
}

const defaultValues: IFormValues = {
    name: "",
    question1: "",
    question2: "",
};

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.question1) {
        errors.question1 = t("player.pulse-setup.form-error-question1-required");
    } else if (values.question1 === values.question2) {
        errors.question2 = t("player.pulse-setup.form-error-question2-duplicate");
    }
    return errors;
}

interface IProps {
    onSubmit: (values: IFormValues) => Promise<void>;
    pulseSuggestedQuestions: {
        content: string;
    }[];
}

function PulseForm({ onSubmit, pulseSuggestedQuestions }: IProps): JSX.Element {
    return (
        <Maw>
            <p>{t("player.pulse-setup.info-setup")}</p>
            <Form onSubmit={onSubmit} validate={validate} initialValues={defaultValues}>
                {({ handleSubmit, submitting, form, values }) => {
                    const handleAdd = (content: string) => {
                        if (!values.question1) {
                            form.change("question1", content);
                        } else if (!values.question2) {
                            form.change("question2", content);
                        }
                    };
                    const handleClear = (name: "question1" | "question2") => {
                        form.change(name, "");
                    };
                    return (
                        <form onSubmit={handleSubmit}>
                            <Input name="name" label={t("player.pulse-setup.form-label-name")} />
                            <h4>{t("player.pulse-setup.heading-suggested-pulse-questions")}</h4>
                            <SuggestedList>
                                {pulseSuggestedQuestions.map((q, idx) => (
                                    <li onClick={() => handleAdd(q.content)} key={idx}>
                                        <span>{q.content}</span>{" "}
                                        <SmallButton type="button">{t("player.pulse-setup.button-select")}</SmallButton>
                                    </li>
                                ))}
                            </SuggestedList>
                            <InputRow>
                                <Input name="question1" label={t("player.pulse-setup.form-label-question1")} />
                                <CrossIcon onClick={() => handleClear("question1")} />
                            </InputRow>
                            <InputRow>
                                <Input name="question2" label={t("player.pulse-setup.form-label-question2")} />
                                <CrossIcon onClick={() => handleClear("question2")} />
                            </InputRow>
                            <Center>
                                <button type="submit" disabled={submitting}>
                                    {t("player.pulse-setup.form-button-submit")}
                                </button>
                            </Center>
                        </form>
                    );
                }}
            </Form>
        </Maw>
    );
}

export default PulseForm;
