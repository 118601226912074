import t from "../../../shared/translations";
import type { ITask, ITaskVariant } from "../query";
import { ButtonLink, Center } from "../stylings";
import type { JSX } from "react";

interface IProps {
    task: ITask;
    invert: boolean;
    variant: ITaskVariant;
}

function CreateButton({ invert, variant }: IProps): JSX.Element {
    return (
        <Center>
            <ButtonLink to={`${variant.id}/collect-feedback-setup`} $invert={invert}>
                {t("player.task.button-collect-feedback-create-survey")}
            </ButtonLink>
        </Center>
    );
}

export default CreateButton;
