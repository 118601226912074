import { type ComponentPropsWithoutRef, memo } from "react";
import type { JSX } from "react";

type IProps = ComponentPropsWithoutRef<"svg">;
function Arrow(props: IProps): JSX.Element {
    return (
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>Arrow</title>
            <path d="M0 0 L100 50 L0 100 L20 50Z" fill="white" />
        </svg>
    );
}

export default memo(Arrow);
