import type { ComponentPropsWithoutRef } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function NewspaperIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m73.1 88.9h-46.2c-5.5 0-10-4.5-10-10v-57.8c0-5.5 4.5-10 10-10h46.1c5.5 0 10 4.5 10 10v57.8c.1 5.5-4.4 10-9.9 10z"
                fill="#222"
            />
            <path
                d="m26.9 14.1c-3.9 0-7 3.1-7 7v57.8c0 3.9 3.1 7 7 7h46.1c3.9 0 7-3.1 7-7v-57.8c0-3.9-3.1-7-7-7z"
                fill={active ? "#00ba9e" : "#fff"}
            />
            <g fill="#222">
                <path d="m69.6 25.8h-22.4c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h22.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.6 1.5z" />
                <path d="m69.6 34.4h-22.4c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h22.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.6 1.5z" />
                <path d="m69.6 42.9h-39.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h39.3c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.6 1.5z" />
                <path d="m69.6 51.5h-39.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h39.3c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.6 1.5z" />
                <path d="m42.6 60.1h-12.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h12.3c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.6 1.5z" />
                <path d="m42.6 68.6h-12.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h12.3c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.6 1.5z" />
                <path d="m42.6 77.2h-12.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h12.3c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.6 1.5z" />
                <path d="m66.9 56.5h-13.3c-2.4 0-4.3 1.9-4.3 4.3v13.3c0 2.4 1.9 4.3 4.3 4.3h13.3c2.4 0 4.3-1.9 4.3-4.3v-13.3c0-2.4-1.9-4.3-4.3-4.3zm1.3 17.6c0 .7-.6 1.3-1.3 1.3h-13.3c-.7 0-1.3-.6-1.3-1.3v-13.3c0-.7.6-1.3 1.3-1.3h13.3c.7 0 1.3.6 1.3 1.3z" />
                <path d="m37.2 34.9c-.5 0-.9-.2-1.2-.6l-5.4-7.4v6.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5v-11c0-.6.4-1.2 1-1.4s1.3 0 1.7.5l5.4 7.4v-6.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v11c0 .6-.4 1.2-1 1.4-.2 0-.4.1-.5.1z" />
            </g>
        </svg>
    );
}

export default NewspaperIcon;
