import { type JSX, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";
import CopiedText from "./CopiedText";
import type { IFeedbackRequest } from "./query";

interface IProps {
    feedbackRequest: IFeedbackRequest;
}

export const Box = styled.div`
    border-radius: 1rem;
    padding: 0.6rem;
    border: 1px solid ${colors.primaryColor};
    margin-bottom: 0.5rem;

    @media (min-width: 600px) {
        padding: 1rem;
        margin-bottom: 1rem;
    }

    > *:last-child {
        margin-bottom: 0;
    }
`;

const CopyRow = styled.div`
    display: grid;
    margin-bottom: 1.5rem;
    align-items: stretch;
    grid-template-columns: 1fr auto;
    grid-auto-flow: column;
    gap: 0.5rem;
    input {
        margin-bottom: 0;
    }
    button {
        padding: 0.5rem 0.9rem;
        margin-bottom: 0;
    }
`;

function FeedbackLink({ feedbackRequest }: IProps): JSX.Element {
    const [copied, setCopied] = useState(false);

    const showCopied = () => {
        setCopied(true);
    };

    return (
        <Box>
            <h3>{t("player.submitted-task.heading-outside-feedback-link")}</h3>
            <p>{t("player.submitted-task.info-outside-feedback-link")}</p>
            <CopyRow>
                <input value={feedbackRequest.url} readOnly />
                <CopyToClipboard text={feedbackRequest.url} onCopy={showCopied}>
                    <button type="button">{t("player.submitted-task.button-outside-feedback-copy-link")}</button>
                </CopyToClipboard>
                {copied && <CopiedText setCopied={setCopied} />}
            </CopyRow>
        </Box>
    );
}

export default FeedbackLink;
