import type { JSX } from "react";
import styled from "styled-components";
import * as colors from "../shared/colors";
import { loadJSData } from "../shared/jsData";
import t from "../shared/translations";

const FOOTER_HEIGHT = 40;

const FooterWrapper = styled.footer`
    border-top: 1px solid ${colors.unlight};
    padding: 5px 15px;
    width: 100%;
    font-size: 0.85rem;
    height: ${FOOTER_HEIGHT};
    line-height: ${FOOTER_HEIGHT - 10}px;
    text-align: center;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1440px;
    margin: 0 auto;
    @media (min-width: 700px) {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
    }
`;

const Claimer = styled.div`
    line-height: 1.5;
    font-size: 0.9em;
    padding-top: 3px;
    max-width: 1440px;
    margin: 0 auto;
`;

const LogoImg = styled.img`
    max-height: ${FOOTER_HEIGHT - 10}px;
    max-width: 100%;
    object-fit: scale-down;
`;

function Footer(): JSX.Element {
    return (
        <FooterWrapper>
            <Row>
                <div>
                    <LogoImg src={loadJSData().player_logo_src} alt="logo" />
                </div>
                {loadJSData().faq_link && (
                    <div>
                        <a href={loadJSData().faq_link} target="_blank" rel="noreferrer">
                            {t("shared.footer.faq")}
                        </a>
                    </div>
                )}
                <div>
                    <a href="/terms">{t("shared.footer.terms")}</a>
                </div>
                <div>{t("shared.footer.copyright")}</div>
            </Row>
            <Claimer>{t("shared.footer.disclaimer")}</Claimer>
        </FooterWrapper>
    );
}

export default Footer;
