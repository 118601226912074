import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import PulseResponse from "../pulse/PulseResponse";
import { Box } from "../task/stylings";
import type { ISubmittedTask } from "./query";
import type { JSX } from "react";

interface IProps {
    task: ISubmittedTask;
}
const Boxer = styled(Box)`
    padding: 15px;
    @media (min-width: 600px) {
        padding: 1rem;
    }
`;

function Pulse({ task }: IProps): JSX.Element {
    const pulse = task.submittedTaskVariant?.pulse;
    if (!pulse) {
        return null;
    }
    return (
        <Boxer>
            <Routes>
                <Route path="pulse/:id/results" element={<PulseResponse />} />
                <Route path="/" element={<Navigate to={`pulse/${pulse.id}/results`} />} />
            </Routes>
        </Boxer>
    );
}

export default Pulse;
