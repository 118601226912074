import PulseResponse from "../../pulse/PulseResponse";
import type { JSX } from "react";

interface IProps {
    refetch: VoidFunction;
}

function Results({ refetch }: IProps): JSX.Element {
    return <PulseResponse refetch={refetch} />;
}

export default Results;
