import { forceCenter, forceLink, forceManyBody, forceSimulation } from "d3-force";
import { type Dispatch, type JSX, type MouseEvent, type SetStateAction, useMemo, useState } from "react";
import * as colors from "../../../shared/colors";
import t from "../../../shared/translations";
import type { ILink, INode } from "./Report";

const linkColor = "#A5A5A5";

// Adjust node size based on the number of incoming edges.
function size(incoming: number): number {
    return 4 + incoming * 15;
}

interface INodeProps {
    node: IDataNode;
    selected: string;
    setSelected: Dispatch<SetStateAction<string>>;
}

function Node({ node, setSelected }: INodeProps): JSX.Element {
    const radius = size(node.incoming);
    const onClick = (event: MouseEvent<SVGCircleElement | SVGRectElement>): boolean => {
        event.stopPropagation();
        setSelected((id) => (id === node.id ? "" : node.id));
        return false;
    };
    return (
        <g transform={`translate(${node.x} ${node.y})`} onClick={onClick} style={{ cursor: "pointer" }}>
            {node.teamLeader ? (
                <rect x={-radius} y={-radius} width={2 * radius} height={2 * radius} fill={colors.primaryColor} />
            ) : (
                <circle r={radius} fill={colors.primaryColor} />
            )}
        </g>
    );
}

// Shift target coordinates 'radius' pixels away from target towards the source so that the marker doesn't
// end up under the node. This assumes the target node is a circle with given radius.
function shiftTarget(x1: number, y1: number, x2: number, y2: number, radius: number): [number, number] {
    // source S = (x1, y1)
    // target T = (x2, y2)
    // new target T' = T + radius * ( (S - T) / (|| S - T ||) )
    const dX = x1 - x2;
    const dY = y1 - y2;
    const norm = Math.sqrt(dX * dX + dY * dY);
    const newX2 = x2 + radius * (dX / norm);
    const newY2 = y2 + radius * (dY / norm);
    return [newX2, newY2];
}

interface ILinkProps {
    link: IDataLink;
    selected: boolean;
}

function Link({ link: { source, target }, selected }: ILinkProps): JSX.Element {
    const [x2, y2] = shiftTarget(source.x, source.y, target.x, target.y, size(target.incoming));
    return (
        <line
            strokeWidth={2}
            stroke={selected ? colors.primaryColor : linkColor}
            markerEnd={selected ? "url(#marker-highlight-arrow)" : "url(#marker-arrow)"}
            x1={source.x}
            y1={source.y}
            x2={x2}
            y2={y2}
        />
    );
}

interface ILegendProps {
    x: number;
    y: number;
}

function Legend({ x, y }: ILegendProps): JSX.Element {
    return (
        <g transform={`translate(${x} ${y})`}>
            <rect width="32" height="32" stroke="none" fill={colors.primaryColor} />
            <circle r="16" cx="16" cy="52" stroke="none" fill={colors.primaryColor} />
            <text x="36" y="16" dominantBaseline="central">
                {t("shared.team-scan-report.chart-network-label-team-leader")}
            </text>
            <text x="36" y="52" dominantBaseline="central">
                {t("shared.team-scan-report.chart-network-label-team-member")}
            </text>
        </g>
    );
}

interface IProps {
    width: number;
    height: number;
    links: ILink[];
    nodes: INode[];
}

interface IDataNode extends INode {
    x: number;
    y: number;
}

interface IDataLink {
    source: IDataNode;
    target: IDataNode;
}

interface IData {
    nodes: IDataNode[];
    links: IDataLink[];
}

function useSimulation(nodes: INode[], links: ILink[], x: number, y: number, width: number, height: number): IData {
    return useMemo(() => {
        const centerX = x + width / 2;
        const centerY = y + height / 2;
        const simulation = forceSimulation(nodes)
            .force(
                "link",
                forceLink()
                    .distance(100)
                    .id((d) => d.id)
                    .links(links),
            )
            .force("charge", forceManyBody().strength(-600))
            .force("center", forceCenter(centerX, centerY));
        for (let i = 0, iMax = 300; i < iMax; i++) {
            simulation.tick();
            for (const node of nodes as IDataNode[]) {
                const nodeSize = size(node.incoming);
                node.x = Math.max(x + nodeSize, Math.min(x + width - nodeSize, node.x));
                node.y = Math.max(y + nodeSize, Math.min(y + height - nodeSize, node.y));
            }
        }
        simulation.stop();
        return { links, nodes } as any;
    }, [nodes, links, x, y, width, height]);
}

function Graph({ width, height, links, nodes }: IProps): JSX.Element {
    const shift = width > 600 ? 0 : 80;
    const data = useSimulation(nodes, links, 0, shift, width, height - shift);
    const [selected, setSelected] = useState("");
    if (data == null) {
        return null;
    }
    const selLinks = data.links.filter((link) => link.target.id === selected);
    const nonSelLinks = data.links.filter((link) => link.target.id !== selected);
    return (
        <svg width={width} height={height} onClick={() => setSelected("")}>
            <title>Network graph</title>
            <defs>
                {[
                    ["marker-arrow", linkColor],
                    ["marker-highlight-arrow", colors.primaryColor],
                ].map(([id, color]) => (
                    <marker
                        key={id}
                        id={id}
                        markerWidth="10"
                        markerHeight="10"
                        refX="7"
                        refY="4"
                        orient="auto"
                        markerUnits="strokeWidth"
                        stroke="none"
                    >
                        <path d="M0,1.5 L0,6 L8,4 z" fill={color} />
                    </marker>
                ))}
            </defs>
            <Legend x={0} y={0} />
            {nonSelLinks.map((link) => (
                <Link key={`${link.source.id}|${link.target.id}`} link={link} selected={false} />
            ))}
            {selLinks.map((link) => (
                <Link key={`${link.source.id}|${link.target.id}`} link={link} selected={true} />
            ))}
            {data.nodes.map((node) => (
                <Node key={node.id} node={node} selected={selected} setSelected={setSelected} />
            ))}
        </svg>
    );
}

export default Graph;
