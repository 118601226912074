import { memo, type JSX } from "react";
import QuizResult, { type IQuzAnswer } from "./QuizResult";

interface IProps {
    results: IQuzAnswer[];
}

function QuizResults({ results }: IProps): JSX.Element {
    return (
        <>
            {results.map((result) => (
                <QuizResult key={result.id} result={result} />
            ))}
        </>
    );
}

export default memo(QuizResults);
