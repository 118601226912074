import { useState, type JSX } from "react";
import { useMutation } from "urql";
import t from "../../shared/translations";
import type { ITask, ITaskVariant } from "./query";
import { Button } from "./stylings";

interface Props {
    task: ITask;
    variant: ITaskVariant;
    invert: boolean;
}

interface IArgs {
    taskId: string;
    variantId: string;
}

const mutation = `
    mutation($taskId:String!,$variantId:String!) {
        start24HoursTask(taskId:$taskId,variantId:$variantId) {
            error
            submittedTask {
                id
                task {
                    id
                }
            }
        }
    }
`;

interface IData {
    start24HoursTask: {
        error: string | null;
        submittedTask: {
            id: string;
            task: {
                id: string;
            };
        };
    };
}

function Start24Hours({ task, variant, invert }: Props): JSX.Element {
    const [startResult, executeMutation] = useMutation<IData, IArgs>(mutation);
    const [error, setError] = useState("");

    const start = async () => {
        if (startResult.fetching) {
            return;
        }
        const result = await executeMutation({ taskId: task.id, variantId: variant.id });
        if (result.error) {
            console.error(result.error);
            setError("ERROR");
        } else if (result.data.start24HoursTask?.error) {
            console.error(result.data.start24HoursTask.error);
            setError(result.data.start24HoursTask.error);
        }
    };

    return (
        <section>
            <div className="text-center">
                <Button $invert={invert} type="button" onClick={start} disabled={startResult.fetching}>
                    {t("player.task.button-start-hours")}
                </Button>
            </div>
            {error && <p className="text-center">{error}</p>}
        </section>
    );
}

export default Start24Hours;
