import { useMatch } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import FeedbackTaskDescription from "../../shared/components/FeedbackTaskDescription";
import Loader from "../../shared/components/Loader";
import ShowError from "../components/ShowError";
import Feedback from "./Feedback";
import { type IArgs, type IData, query } from "./queries";
import RequestAnswer from "./RequestAnswer";
import type { JSX } from "react";

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1030px;
    width: 100%;
`;

function ProvideFeedback(): JSX.Element {
    const {
        params: { id },
    } = useMatch({ path: "/provide-feedback/:id/" });
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id },
    });

    if (result.fetching) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        );
    }
    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }

    const feedbackTask = result.data.feedbackTask;
    const submittedFeedbackTask = feedbackTask.submittedFeedbackTask;
    const task = submittedFeedbackTask || feedbackTask;
    const submittedTask = submittedFeedbackTask?.selectedSubmittedTaskVariant.submittedTask;
    const pathname = feedbackTask.learningPathItem.learningPath.pathname;
    return (
        <Wrapper>
            <FeedbackTaskDescription
                shortTitle={task.shortTitle}
                title={task.title}
                instructions={task.instructions}
                icon={task.icon}
                miles={task.miles}
                deadline={feedbackTask.deadline}
                finishDatetime={
                    submittedFeedbackTask?.status === "finished" ? submittedFeedbackTask.finishDatetime : null
                }
            />
            {submittedFeedbackTask === null && <RequestAnswer id={id} pathname={pathname} />}
            {!!submittedTask && (
                <Feedback
                    submittedTask={submittedTask}
                    pathname={pathname}
                    submittedFeedbackTaskId={submittedFeedbackTask?.id}
                />
            )}
        </Wrapper>
    );
}

export default ProvideFeedback;
