import type { ValidationErrors } from "final-form";
import { useCallback, useState, type JSX } from "react";
import { Form } from "react-final-form";
import { useMutation } from "urql";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import Input from "../components/FinalInput";
import { Button, StyledForm } from "./stylins";

const editPasswordMutation = `
    mutation($password:String!,$confirmPassword:String!) {
        currentPersonEditPassword(password:$password,confirmPassword:$confirmPassword) {
            error
        }
    }
`;

interface IEditArgs {
    password: string;
    confirmPassword: string;
}

interface IFormValues {
    password: string;
    confirmPassword: string;
}

const initialValues: IFormValues = {
    password: "",
    confirmPassword: "",
};

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.password) {
        errors.password = t("player.profile.form-password-error-new-password");
    } else if (values.password.length < loadJSData().min_password_length) {
        errors.password = t("player.profile.form-password-error-too-short");
    }
    if (values.password !== values.confirmPassword) {
        errors.confirmPassword = t("player.profile.form-password-error-repeat-password");
    }
    return errors;
}

function PasswordForm(): JSX.Element {
    const [apiError, setApiError] = useState("");
    const [_, executeMutation] = useMutation<any, IEditArgs>(editPasswordMutation);

    const onSubmit = useCallback(
        async (values: IFormValues) => {
            setApiError("");
            const result = await executeMutation(values);
            if (result.error) {
                setApiError(result.error.message);
            } else if (result.data.currentPersonEditPassword.error) {
                setApiError(result.data.currentPersonEditPassword.error);
            } else {
                window.location.href = "/profile";
            }
        },
        [executeMutation],
    );

    return (
        <div>
            <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
                {({ handleSubmit, submitting, pristine }) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <Input
                            name="password"
                            type="password"
                            label={t("player.profile.form-password-label-new-password")}
                        />
                        <Input
                            name="confirmPassword"
                            type="password"
                            label={t("player.profile.form-password-label-repeat-password")}
                        />
                        {apiError && <div className="error">{apiError}</div>}
                        <div style={{ textAlign: "right" }}>
                            <Button type="submit" disabled={submitting || pristine}>
                                {t("player.profile.form-password-submit")}
                            </Button>
                        </div>
                    </StyledForm>
                )}
            </Form>
        </div>
    );
}

export default PasswordForm;
