import styled from "styled-components";
import t from "../../../shared/translations";
import type { ITeamScan } from "../query";
import type { JSX } from "react";

interface IProps {
    teamScan: ITeamScan;
}

const Wrapper = styled.div`
    text-align: center;
    padding: 0.6rem;
    @media (min-width: 600px) {
        padding: 1rem;
    }
`;

function DoSurvey({ teamScan }: IProps): JSX.Element {
    return (
        <Wrapper>
            <a className="button" href={teamScan.myLink}>
                {t("player.task.link-team-scan")}
            </a>
        </Wrapper>
    );
}

export default DoSurvey;
