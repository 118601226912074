import styled from "styled-components";
import RenderMark from "../../../shared/components/RenderMark";

interface ICenter {
    $top?: string;
    $bottom?: string;
}

export const Center = styled.div<ICenter>`
    text-align: center;
    margin-top: ${({ $top }) => $top || "0"};
    margin-bottom: ${({ $bottom }) => $bottom || "0"};
`;

export const OList = styled.ol`
    li {
        margin-bottom: 1rem;
        &::marker {
            font-size: 1.7rem;
        }
    }
`;

export const PaddedLeft = styled.div`
    margin-left: 1rem;
    @media (min-width: 500px) {
        margin-left: 2rem;
    }
`;

export const PageBreak = styled.div`
    page-break-after: always;
`;

export const H3 = styled.h3`
    font-size: 1.8rem;
`;

export const StyledRenderMark = styled(RenderMark)`
    h3 {
        font-size: 1.8rem;
    }
`;
