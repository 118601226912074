import { type CSSProperties, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { BIG_MOBILE_CUTOFF, SMALL_MOBILE_CUTOFF } from "./stylings";

export const DesktopRow = styled.div`
    display: grid;
    margin: 0;
    align-items: flex-start;
    grid-template-columns: 270px 1fr;
    gap: 8px;
    min-height: 100%;
    @media screen and (min-width: 1000px) {
        gap: 16px;
        grid-template-columns: 290px 1fr;
    }
    @media screen and (min-width: 1100px) {
        grid-template-columns: 320px 1fr;
    }
    @media screen and (min-width: 1200px) {
        grid-template-columns: 350px 1fr;
    }
`;

export function useTwoColStyle(big: boolean): [React.MutableRefObject<HTMLDivElement>, React.CSSProperties] {
    const { width } = useWindowSize();
    const [mainColHeight, setMainColHeight] = useState<number>(null);
    const mainColRef = useRef<HTMLDivElement>(null);
    const cutoff = big ? BIG_MOBILE_CUTOFF : SMALL_MOBILE_CUTOFF;
    useLayoutEffect(() => {
        if (width >= cutoff && mainColRef.current) {
            setMainColHeight(mainColRef.current.offsetHeight);
        } else {
            setMainColHeight(null);
        }
    }, [width, cutoff]);
    // Don't allow All Tasks column to grow taller than main column in two column layout
    // Instead force overflow and scrolling
    const taskColStyle: CSSProperties = {
        height: width >= cutoff && mainColHeight > 0 ? mainColHeight : "auto",
        overflow: "hidden",
        paddingBottom: 30,
    };
    return [mainColRef, taskColStyle];
}
