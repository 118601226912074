import type { JSX } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import TaskDescription from "../../shared/components/TaskDescription";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import t from "../../shared/translations";
import Status from "./Status";
import { type ITask, type ITaskChoice, taskFinished } from "./query";

interface IProps {
    taskChoice: ITaskChoice;
}

const Wrapper = styled.div`
    margin: 0 auto;
    container-type: inline-size;
`;

const TaskBox = styled.a`
    color: ${colors.text};
    text-align: center;
    font-size: 0.85rem;
    &:hover, &:active, &:focus, &:visited {
        color: ${colors.text}
    }
    svg {
        width: 80px;
        height: 80px;
    }
`;

const TaskRow = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 80px);
    gap: 1.5rem;
`;

const BorderBox = styled.div`
    padding: 1rem;
    padding: 0.6rem;
    border-radius: 1rem;
    border: 1px solid ${colors.primaryColor};
    margin-bottom: 1rem;

    @container (min-width: 640px) {
        padding: 1rem;
    }

`;

function taskUrl(task: ITask): string {
    if (task?.submittedTask?.status === "accepted") {
        return task.submittedTask?.url;
    }
    return task.url;
}

function TaskChoiceDescription({ taskChoice }: IProps): JSX.Element {
    return (
        <Wrapper>
            <TaskDescription
                shortTitle={taskChoice.name}
                miles={null}
                objectives={null}
                inspiration={null}
                title={null}
                icon={taskChoice.icon}
                description={taskChoice.description}
                invert={false}
                media={[]}
                taskVariants={[]}
                deadline={taskChoice.deadline}
                status={taskChoice.complete ? "finished" : "deadline"}
            />
            <BorderBox>
                <h3>{t("player.task-choice.heading-choose-task")}</h3>
                <p>{t("player.task-choice.info-choose-task")}</p>
                <TaskRow>
                    {taskChoice.learningPathItem.tasks.map((task) => (
                        <TaskBox key={task.id} href={taskUrl(task)}>
                            <TaskIcon icon={task.icon} active={taskFinished(task)} />
                            <div>
                                <div>{task.shortTitle}</div>
                                <div>{task.miles}</div>
                                <Status task={task} />
                            </div>
                        </TaskBox>
                    ))}
                </TaskRow>
            </BorderBox>
        </Wrapper>
    );
}

export default TaskChoiceDescription;
