import type { Dispatch, JSX } from "react";
import { useMutation } from "urql";
import t from "../../../shared/translations";
import type { ITask, ITaskVariant } from "../query";
import { Button, Center } from "../stylings";
import { type IStartArgs, type IStartData, startMutation } from "./queries";
import type { IAction, IState } from "./state";

interface Props {
    state: IState;
    task: ITask;
    variant: ITaskVariant;
    invert: boolean;
    dispatch: Dispatch<IAction>;
}

function StartAiChatButton({ task, variant, invert, dispatch, state }: Props): JSX.Element {
    const [startResult, executeMutation] = useMutation<IStartData, IStartArgs>(startMutation);

    const start = async () => {
        if (startResult.fetching) {
            return;
        }
        dispatch({ type: "TOGGLE-AI-LOADING", to: true });
        const result = await executeMutation({ taskId: task.id, variantId: variant.id });
        if (result.error) {
            console.error(result.error);
            dispatch({ type: "RECORD-ERROR", error: t("player.error.error") });
        } else if (result.data.taskAiThreadCreate?.error) {
            console.error(result.data.taskAiThreadCreate.error);
            dispatch({ type: "RECORD-ERROR", error: result.data.taskAiThreadCreate.error });
        } else {
            const { aiThreadItems: messages, id: threadId } = result.data.taskAiThreadCreate.taskAiThread;
            dispatch({ type: "SET-CHAT", messages, threadId });
        }
    };

    return (
        <>
            <Center>
                <Button $invert={invert} type="button" onClick={start} disabled={startResult.fetching}>
                    {t("player.task.button-start-ai-chat")}
                </Button>
            </Center>
            {state.error && <Center>{state.error}</Center>}
        </>
    );
}

export default StartAiChatButton;
