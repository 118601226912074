import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function LadderIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m34.625 70.567 18.964 1.724-1.976 7.188-18.965-1.724zm5.489-19.96 18.964 1.724-1.931 7.021-18.964-1.724zm-2.739 9.96 18.964 1.724-1.941 7.061-18.965-1.724zm5.478-19.921 18.965 1.724-1.932 7.023-18.964-1.724zm2.761-10.038 18.964 1.724-1.952 7.099-18.965-1.724zm2.75-10.003 18.965 1.724-1.943 7.064-18.964-1.724z"
                fill="#fff"
            />
            <g fill={active ? "#0076c2" : "#fff"}>
                <path d="m48.364 20.605 18.965 1.724-1.943 7.064-18.964-1.724z" />
                <path d="m42.853 40.646 18.965 1.724-1.932 7.023-18.964-1.724z" />
                <path d="m37.375 60.567 18.964 1.724-1.941 7.061-18.965-1.724z" />
            </g>
            <g fill="none" stroke="#000" strokeWidth="3">
                <path d="m72 11c-7.333 26.667-14.667 53.333-22 80" />
                <path d="m50 9c-7.333 26.667-14.667 53.333-22 80" />
                <path d="m47.251 18.997c7.333.667 14.666 1.334 22 2" />
                <path d="m44.5 29c7.333.667 14.667 1.333 22 2" />
                <path d="m41.739 39.039c7.334.666 14.667 1.333 22 2" />
                <path d="m39 49c7.333.667 14.667 1.333 22 2" />
                <path d="m36.261 58.96c7.333.666 14.667 1.333 22 2" />
                <path d="m33.511 68.959c7.333.667 14.667 1.334 22 2" />
                <path d="m30.726 79.087c7.334.666 14.667 1.333 22 2" />
            </g>
        </svg>
    );
}

export default LadderIcon;
