import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { useQuery } from "urql";
import Loader from "../../../shared/components/Loader";
import ProgressBar from "../../../shared/components/ProgressBar";
import type { TeamScanStatusType } from "../../../shared/types";
import ShowError from "../../components/ShowError";
import { type Dispatch, initTeamScan, type State } from "../../state";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import type { JSX } from "react";

const dataQuery = `
    query($id:String!) {
        teamScan(id:$id) {
            id
            status
            myLink
            teamSizeLimit
            requiredFraction
        }
        currentPerson {
            id
            firstName
            lastName
            user {
                email
            }
        }
        systemMessage(message:"team_scan_survey", locale:"en") {
            id
            title
            body
        }
    }
`;

export interface ITeamScan {
    id: string;
    status: TeamScanStatusType;
    myLink: string | null;
    teamSizeLimit: number;
    requiredFraction: number;
}

export interface ICurrentPerson {
    id: string;
    firstName: string;
    lastName: string;
    user: {
        email: string;
    };
}

export interface ISystemMessage {
    id: string;
    title: string;
    body: string;
}

interface IData {
    teamScan: ITeamScan;
    currentPerson: ICurrentPerson;
    systemMessage: ISystemMessage;
}

interface IArgs {
    id: string;
}

type IParams = "id";

function Setup(): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const { id } = useParams<IParams>();
    const [result] = useQuery<IData, IArgs>({
        query: dataQuery,
        variables: { id },
    });
    const data = useSelector((state: State) => state.teamScan[id]);
    if (!data) {
        dispatch(initTeamScan(id));
    }
    const step = data ? data.step : "step1";
    const myLink = data ? data.myLink : null;

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }
    const { teamScan, currentPerson, systemMessage } = result.data;
    switch (teamScan.status) {
        case "reporting":
            return <Navigate to={`../../report/${teamScan.id}`} relative="path" />;
        case "waiting-for-responses":
            return <Navigate to={`../../progress/${teamScan.id}`} relative="path" />;
        case "do-survey":
            return (
                <>
                    <Step5 myLink={myLink || teamScan.myLink} />
                    <ProgressBar data={{ type: "percent", value: 100 }} />
                </>
            );
    }

    switch (step) {
        case "step5":
            return (
                <>
                    <Step5 myLink={myLink || teamScan.myLink} />
                    <ProgressBar data={{ type: "percent", value: 100 }} />
                </>
            );
        case "step4":
            return (
                <>
                    <Step4 teamScanId={id} />
                    <ProgressBar data={{ type: "percent", value: 75 }} />
                </>
            );
        case "step3":
            return (
                <>
                    <Step3 systemMessage={systemMessage} id={id} />
                    <ProgressBar data={{ type: "percent", value: 50 }} />
                </>
            );
        case "step2":
            return (
                <>
                    <Step2 id={id} />
                    <ProgressBar data={{ type: "percent", value: 25 }} />
                </>
            );
        default:
            return (
                <>
                    <Step1
                        current={currentPerson}
                        teamSizeLimit={teamScan.teamSizeLimit}
                        requiredFraction={teamScan.requiredFraction}
                        id={id}
                    />
                    <ProgressBar data={{ type: "percent", value: 0 }} />
                </>
            );
    }
}

export default Setup;
