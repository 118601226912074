import * as Sentry from "@sentry/browser";
import { printError } from "graphql";
import type { CombinedError, Exchange } from "urql";
import { pipe, tap } from "wonka";

function convertError(error: CombinedError) {
    const networkError = error.networkError
        ? {
              name: error.networkError.name,
              message: error.networkError.message,
          }
        : undefined;
    const graphQLErrors = error.graphQLErrors.map((e) => printError(e));
    return {
        name: error.name,
        message: error.message,
        graphQLErrors,
        networkError,
    };
}

const sentryExchange: Exchange =
    ({ forward }) =>
    (ops$) => {
        return pipe(
            forward(ops$),
            tap(({ error, data, operation }) => {
                if (error) {
                    console.error(error);
                    Sentry.captureException(new Error(`GraphQL: ${error.toString()}`), {
                        extra: {
                            operation,
                            error: convertError(error),
                            data,
                        },
                    });
                }
            }),
        );
    };

export default sentryExchange;
