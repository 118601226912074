import { endOfDay, format, startOfDay } from "./dateFns";

export default function eventDateStrs(
    start: Date,
    end: Date | null,
): {
    startStr: string;
    endStr: string | null;
} {
    const startDay = startOfDay(start);
    const endDay = end ? endOfDay(end) : null;
    const startStr = start !== startDay ? format(start, "yyyy-MM-dd HH:mm") : format(start, "yyyy-MM-dd");
    const endStr =
        end && end !== endDay
            ? startDay === endDay
                ? format(end, "HH:mm")
                : format(end, "yyyy-MM-dd HH:mm")
            : end
              ? format(end, "yyyy-MM-dd")
              : null;
    return { startStr, endStr };
}
