import getInt from "../../getInt";
import type { Gender } from "../../types";

export interface IValues {
    email: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    title: string | null;
    tel: string | null;
    password: string;
    confirmPassword: string;
    agreedToTerms: boolean;
    avatar: string;
    birthYear: number | null;
    emailNotifications: boolean;
    profilePicture: File | null;
}

export type IPage = "1-profile" | "2-password" | "3-terms" | "4-avatar" | "5-picture" | "6-review";

export interface IState {
    page: IPage;
    values: IValues;
}

const defaultValues: IValues = {
    email: "",
    firstName: "",
    lastName: "",
    gender: "unspecified",
    title: null,
    tel: null,
    password: "",
    confirmPassword: "",
    agreedToTerms: false,
    avatar: "male-1",
    birthYear: null,
    emailNotifications: true,
    profilePicture: null,
};

export function createInitialState(initialValues: Partial<IValues>): IState {
    return {
        page: "1-profile",
        values: {
            ...defaultValues,
            ...initialValues,
        },
    };
}

export type IAction =
    | {
          type: "NEXT-PROFILE";
          values: {
              email: string;
              firstName: string;
              lastName: string;
              gender: Gender;
              title: string;
              tel: string;
              birthYear: string;
              emailNotifications: boolean;
          };
      }
    | { type: "BACK-PASSWORD" }
    | { type: "NEXT-PASSWORD"; values: { password: string; confirmPassword: string } }
    | { type: "BACK-TERMS" }
    | { type: "NEXT-TERMS"; values: { agreedToTerms: boolean } }
    | { type: "BACK-AVATAR" }
    | { type: "NEXT-AVATAR"; values: { avatar: string } }
    | { type: "BACK-PICTURE" }
    | { type: "NEXT-PICTURE"; values: { profilePicture: File | null } }
    | { type: "BACK-REVIEW" }
    | { type: "GO-TO"; page: IPage };

export function reducer(draft: IState, action: IAction): void {
    switch (action.type) {
        case "NEXT-PROFILE":
            draft.values.email = action.values.email;
            draft.values.firstName = action.values.firstName;
            draft.values.lastName = action.values.lastName;
            draft.values.gender = action.values.gender;
            draft.values.title = action.values.title || null;
            draft.values.tel = action.values.tel || null;
            draft.values.birthYear = getInt(action.values.birthYear);
            draft.values.emailNotifications = action.values.emailNotifications;
            draft.page = "2-password";
            return;
        case "BACK-PASSWORD":
            draft.page = "1-profile";
            return;
        case "NEXT-PASSWORD":
            draft.values.password = action.values.password;
            draft.values.confirmPassword = action.values.confirmPassword;
            draft.page = "3-terms";
            return;
        case "BACK-TERMS":
            draft.page = "2-password";
            return;
        case "NEXT-TERMS":
            draft.values.agreedToTerms = action.values.agreedToTerms;
            draft.page = "4-avatar";
            return;
        case "BACK-AVATAR":
            draft.page = "3-terms";
            return;
        case "NEXT-AVATAR":
            draft.values.avatar = action.values.avatar;
            draft.page = "5-picture";
            return;
        case "BACK-PICTURE":
            draft.page = "4-avatar";
            return;
        case "NEXT-PICTURE":
            draft.values.profilePicture = action.values.profilePicture;
            draft.page = "6-review";
            return;
        case "BACK-REVIEW":
            draft.page = "5-picture";
            return;
        case "GO-TO":
            draft.page = action.page;
            return;
        default:
            throw new Error("Unknown action");
    }
}
