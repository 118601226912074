import { useDispatch } from "react-redux";
import AiThread from "../../shared/components/ai/AiThread";
import t from "../../shared/translations";
import Modal from "../components/Modal";
import { type Dispatch, openModal } from "../state";
import type { ITaskAiThread } from "./query";
import type { JSX } from "react";

interface IProps {
    thread: ITaskAiThread;
}

function ShowAiThread({ thread }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();

    const open = () => {
        dispatch(openModal("SubmittedTask/AiThread"));
    };
    return (
        <div>
            <button type="button" onClick={open}>
                {t("player.submitted-task.button-show-task-ai-thread")}
            </button>
            <Modal modal="SubmittedTask/AiThread">
                <h3>{t("player.submitted-task.heading-ai-thread")}</h3>
                <AiThread messages={thread.aiThreadItems} />
            </Modal>
        </div>
    );
}

export default ShowAiThread;
