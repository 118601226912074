import { type ComponentPropsWithoutRef, type JSX, memo } from "react";

type IProps = ComponentPropsWithoutRef<"svg"> & {
    dir: "left" | "right";
};

function FatArrow({ dir, ...props }: IProps): JSX.Element {
    const deg = dir === "left" ? 180 : 0;
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="square"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m33 15 35.037 35.037-35.132 35.132"
                fill="none"
                strokeWidth="17"
                transform={`rotate(${deg} 50 50)`}
            />
        </svg>
    );
}

export default memo(FatArrow);
