import type { JSX } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";

const Nav = styled.nav`
    display: grid;
    width: 100%;
    margin-bottom: 0.5rem;
    border: 1px solid ${colors.primaryColor};
    border-radius: 0.3rem;
    overflow: hiddern;
    grid-template-columns: 1fr 1fr;

    & a {
        flex-grow: 1;
        flex-shrink: 1;
        text-align: center;
        padding: 0.5rem;
        display: block;
    }

    a.active {
        background-color: ${colors.primaryColor};
        color: white;
    }

    a:nth-child(1) {
        border-right: 1px solid ${colors.primaryColor};
        border-bottom: 1px solid ${colors.primaryColor};
    }

    a:nth-child(2) {
        border-bottom: 1px solid ${colors.primaryColor};
    }

    a:nth-child(3) {
        border-right: 1px solid ${colors.primaryColor};
    }

    @container (min-width: 700px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        a:nth-child(n) {
            border-bottom: none;
        }
        a:nth-child(2) {
            border-right: 1px solid ${colors.primaryColor};
        }
    }
`;

function NavBar(): JSX.Element {
    return (
        <Nav>
            <NavLink to="../messages/inbox">{t("player.messages.link-inbox")}</NavLink>
            <NavLink to="../messages/notifications">{t("player.messages.link-notifications")}</NavLink>
            <NavLink to="../messages/archived">{t("player.messages.link-archived")}</NavLink>
            <NavLink to="../messages/sent">{t("player.messages.link-sent")}</NavLink>
        </Nav>
    );
}

export default NavBar;
