import { type JSX, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import styled from "styled-components";
import t from "../../../shared/translations";
import type { ITask } from "../query";
import { Box } from "../stylings";
import CopiedText from "./CopiedText";
import NotifyCheckbox from "./NotifyCheckbox";
import ResponseTable from "./ResponseTable";

interface IProps {
    task: ITask;
    invert: boolean;
}

const CopyRow = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-flow: column;
    grid-auto-columns: auto;;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    align-items: center;
    input {
        margin-bottom: 0;
    }
    button {
        padding: 0.5rem 0.9rem;
        margin-bottom: 0;
    }
`;

function Reporting({ task, invert }: IProps): JSX.Element {
    const [copied, setCopied] = useState(false);
    const answerSets = task.submittedTask?.collectFeedback?.collectFeedbackAnswerSets;

    const showCopied = () => {
        setCopied(true);
    };

    if (!answerSets) {
        return null;
    }

    const anonymous = task.submittedTask.submittedTaskVariant.anonymousCollectedFeedback;
    const link = task.submittedTask.collectFeedback.link;
    return (
        <>
            <Box>
                <h3>{t("player.task.heading-collect-feedback-copy-link")}</h3>
                <p>{t("player.task.info-collect-feedback-send-link")}</p>
                <CopyRow>
                    <input value={link} readOnly />
                    <CopyToClipboard text={link} onCopy={showCopied}>
                        <button type="button">{t("player.task.form-collect-feedback-copy-link")}</button>
                    </CopyToClipboard>
                    {copied && <CopiedText setCopied={setCopied} />}
                </CopyRow>
            </Box>
            {answerSets.length === 0 && (
                <Box>
                    <h3>{t("player.task.heading-collect-feedback-waiting")}</h3>
                    <NotifyCheckbox task={task} />
                    <p>{t("player.task.info-collect-feedback-responses-shown-here")}</p>
                    <p>{t("player.task.info-collect-feedback-at-least-one-response")}</p>
                </Box>
            )}
            {answerSets.length > 0 && (
                <Box>
                    <h3>{t("player.task.heading-collect-feedback-responses-received")}</h3>
                    <NotifyCheckbox task={task} />
                    <ResponseTable
                        collectFeedbackAnswerSets={answerSets}
                        anonymous={anonymous}
                        invert={invert}
                        submittedTask={task.submittedTask}
                    />
                </Box>
            )}
        </>
    );
}

export default Reporting;
