import type { ValidationErrors } from "final-form";
import { useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useMutation } from "urql";
import t from "../../../shared/translations";
import { type Dispatch, closeModal } from "../../state";
import { Center } from "../stylings";
import { Input } from "../stylings/form";
import type { ITeamScanSurvey } from "./Progress";

const mutation = `
    mutation($id:String!,$email:String!) {
        teamScanSurveyEditEmail(id:$id,email:$email) {
            error
            teamScanSurvey {
                id
            }
        }
    }
`;

interface IEditArgs {
    id: string;
    email: string;
}

interface IProps {
    teamScanSurvey?: ITeamScanSurvey;
}

interface IFormValues {
    email: string;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.email) {
        errors.email = t("player.team-scan-progress.modal-edit-email-error-email-required");
    } else if (!values.email.includes("@")) {
        errors.email = t("player.team-scan-progress.modal-edit-email-error-email-invalid");
    }
    return errors;
}

function EditEmail({ teamScanSurvey }: IProps): JSX.Element {
    const [_, executeMutation] = useMutation<any, IEditArgs>(mutation);
    const dispatch = useDispatch<Dispatch>();

    const onSubmit = useCallback(
        async (values: IFormValues) => {
            const data: IEditArgs = {
                email: values.email,
                id: teamScanSurvey.id,
            };
            const result = await executeMutation(data);
            if (result.error || result.data.teamScanSurveyEditEmail?.error) {
                console.error(result.error || result.data.teamScanSurveyEditEmail?.error);
            } else {
                dispatch(closeModal());
            }
        },
        [executeMutation, teamScanSurvey],
    );

    if (!teamScanSurvey) {
        return null;
    }

    const initialValues: IFormValues = {
        email: teamScanSurvey.email,
    };
    return (
        <>
            <h3>{t("player.team-scan-progress.modal-edit-email-heading")}</h3>
            <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Input name="email" label={t("player.team-scan-progress.modal-edit-email-label-email")} />
                        <Center>
                            <button type="submit" disabled={submitting}>
                                {t("player.team-scan-progress.modal-edit-email-button-submit")}
                            </button>
                        </Center>
                    </form>
                )}
            </Form>
        </>
    );
}

export default EditEmail;
