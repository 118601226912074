import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { IFormValues } from "../task/collectFeedback/Setup";
import type { ITaskThreadItem } from "../task/query";

interface IExtendedFormValues extends IFormValues {
    id: string;
}

interface TaskState {
    editAnswer: ITaskThreadItem | null;
    savedCollectFeedbackForm: IExtendedFormValues | null;
}

const initialState: TaskState = {
    editAnswer: null,
    savedCollectFeedbackForm: null,
};

const taskSlice = createSlice({
    name: "task",
    initialState,
    reducers: {
        editTaskAnswer(state, action: PayloadAction<ITaskThreadItem>) {
            state.editAnswer = action.payload;
        },
        cancelTaskEditAnswer(state) {
            state.editAnswer = null;
        },
        saveCollectFeedbackForm(state, action: PayloadAction<{ values: IFormValues; id: string }>) {
            state.savedCollectFeedbackForm = {
                id: action.payload.id,
                ...action.payload.values,
            };
        },
        clearCollectFeedbackForm(state) {
            state.savedCollectFeedbackForm = null;
        },
    },
});

export const { editTaskAnswer, cancelTaskEditAnswer, saveCollectFeedbackForm, clearCollectFeedbackForm } =
    taskSlice.actions;
export default taskSlice.reducer;
