import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type IStep = "step1" | "step2" | "step3" | "step4" | "step5";

export interface IRecipient {
    current: boolean;
    email: string;
    firstName: string;
    lastName: string;
    teamLeader: boolean | null;
}

interface ITeamScanData {
    emails: string;
    industry: string | null;
    myLink: string | null;
    organisationSize: string | null;
    recipients: IRecipient[];
    sendMessage: string | null;
    step: IStep;
    resendRecipients: {
        id: string;
        email: string;
    }[];
    msg: {
        title: string;
        body: string;
    };
}

type ITeamScanState = Record<string, ITeamScanData>;

function defaultData(): ITeamScanData {
    return {
        emails: "",
        industry: "",
        msg: {
            body: "",
            title: "",
        },
        myLink: null,
        organisationSize: "",
        recipients: [],
        resendRecipients: [],
        sendMessage: null,
        step: "step1",
    };
}

const initialState: ITeamScanState = {};

const teamScanSlice = createSlice({
    name: "teamScan",
    initialState,
    reducers: {
        initTeamScan(state, action: PayloadAction<string>) {
            state[action.payload] = {
                ...defaultData(),
                ...state[action.payload],
            };
        },
        toStep2(
            state,
            action: PayloadAction<{
                id: string;
                industry: string;
                organisationSize: string;
                recipients: IRecipient[];
                emails: string;
            }>,
        ) {
            const teamsScanData = state[action.payload.id];
            teamsScanData.industry = action.payload.industry;
            teamsScanData.organisationSize = action.payload.organisationSize;
            teamsScanData.recipients = action.payload.recipients;
            teamsScanData.emails = action.payload.emails;
            teamsScanData.step = "step2";
        },
        toStep3(
            state,
            action: PayloadAction<{
                id: string;
                recipients: IRecipient[];
            }>,
        ) {
            const teamScanData = state[action.payload.id];
            teamScanData.recipients = action.payload.recipients;
            teamScanData.step = "step3";
        },
        toStep4(
            state,
            action: PayloadAction<{
                id: string;
                title: string;
                body: string;
            }>,
        ) {
            const teamScanData = state[action.payload.id];
            teamScanData.msg.title = action.payload.title;
            teamScanData.msg.body = action.payload.body;
            teamScanData.step = "step4";
        },
        toStep5(
            state,
            action: PayloadAction<{
                id: string;
                myLink: string;
            }>,
        ) {
            const teamScanData = state[action.payload.id];
            teamScanData.myLink = action.payload.myLink;
            teamScanData.step = "step5";
            teamScanData.emails = "";
            teamScanData.industry = "";
            teamScanData.msg = {
                body: "",
                title: "",
            };
            teamScanData.organisationSize = "";
            teamScanData.recipients = [];
        },
        backToStep(
            state,
            action: PayloadAction<{
                step: "step3" | "step2" | "step1";
                id: string;
            }>,
        ) {
            state[action.payload.id].step = action.payload.step;
        },
        setResendRecipients(
            state,
            action: PayloadAction<{
                id: string;
                resendRecipients: {
                    id: string;
                    email: string;
                }[];
            }>,
        ) {
            if (!state[action.payload.id]) {
                state[action.payload.id] = defaultData();
            }
            state[action.payload.id].resendRecipients = action.payload.resendRecipients;
        },
    },
});

export const { initTeamScan, toStep2, toStep3, toStep4, toStep5, backToStep, setResendRecipients } =
    teamScanSlice.actions;
export default teamScanSlice.reducer;
