import { useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import * as API from "../../api/player";
import getInt from "../../shared/getInt";
import t from "../../shared/translations";
import { Button } from "../task/stylings";
import OrigTextarea from "./FinalTextarea";

interface IFormValues {
    content: string;
}

function validate(values: IFormValues): any {
    const errors: any = {};
    if (!values.content) {
        errors.content = t("player.task.response-form-error");
    }
    return errors;
}

const defaultValues: IFormValues = {
    content: "",
};

interface IProps {
    id: string;
    invert: boolean;
}

export const Center = styled.div`
    text-align: center;
`;

function ResponseForm({ id, invert }: IProps): JSX.Element {
    const onSubmit = useCallback(
        async (values: IFormValues) => {
            try {
                await API.Task.respondFeedback({
                    ...values,
                    submitted_task_id: getInt(id),
                });
            } catch {
                return;
            }
            window.location.reload();
        },
        [id],
    );

    return (
        <Form onSubmit={onSubmit} validate={validate} initialValues={defaultValues}>
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <OrigTextarea name="content" placeholder={t("player.task.response-form-placeholder")} />
                    <Center>
                        <Button type="submit" disabled={submitting} $invert={invert}>
                            {t("player.task.response-form-submit")}
                        </Button>
                    </Center>
                </form>
            )}
        </Form>
    );
}

export default ResponseForm;
