import { useState, type JSX } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import TaskDescription from "../../shared/components/TaskDescription";
import t from "../../shared/translations";
import LockedModal, { useLockedModal } from "../LockedModal";
import { useCurrentPerson } from "../components/CurrentPerson";
import ShowError from "../components/ShowError";
import WithTasksSidebar from "../tasksSidebar";
import TaskForm, { type IFormValues, defaultValues } from "./TaskForm";
import { type IPathArgs, type IPathData, type ISubmitArgs, type ISubmitData, pathQuery, submitMutation } from "./query";
import taskData from "./taskData";

const EMPTY_LIST = [];

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1440px;
    width: 100%;
`;

function CustomTask(): JSX.Element {
    const person = useCurrentPerson();
    const [result] = useQuery<IPathData, IPathArgs>({
        query: pathQuery,
        variables: { id: person.currentPath.id },
    });
    const { kind } = useParams();
    const data = taskData(kind);
    const [_, executeMutation] = useMutation<ISubmitData, ISubmitArgs>(submitMutation);
    const [apiError, setApiError] = useState("");
    const { openLockedModal, ...lockedProps } = useLockedModal();

    if (result.fetching) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        );
    }
    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }

    const onSubmit = async (values: IFormValues) => {
        const result = await executeMutation({
            ...values,
            kind,
        });
        if (result.error) {
            console.error(result.error.message);
            setApiError(t("player.error.error"));
        } else if (result.data?.submitCustomTask?.error) {
            setApiError(result.data.submitCustomTask.error);
        }
        if (result.data?.submitCustomTask?.redirect) {
            window.location.href = result.data.submitCustomTask.redirect;
        }
    };

    return (
        <Wrapper>
            <WithTasksSidebar
                openLockedModal={openLockedModal}
                path={result.data.learningPath}
                startTab="custom"
                activeItem={kind}
                mainLabel={t("player.custom-task.tab-task")}
                big={true}
            >
                <TaskDescription
                    icon={data.task.icon}
                    shortTitle={data.task.shortTitle}
                    miles={null}
                    title={null}
                    taskVariants={data.task.taskVariants}
                    objectives={null}
                    media={EMPTY_LIST}
                    inspiration={null}
                    invert={false}
                />
                <TaskForm
                    labels={data.placeholders}
                    errors={data.errors}
                    initialValues={defaultValues}
                    onSubmit={onSubmit}
                />
                {apiError && <div>{apiError}</div>}
            </WithTasksSidebar>
            <LockedModal {...lockedProps} />
        </Wrapper>
    );
}

export default CustomTask;
