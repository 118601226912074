import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { Provider } from "react-redux";
import { Provider as URQLProvider, cacheExchange, createClient, fetchExchange } from "urql";
import { UrqlGlobalNotice } from "../../shared/components/GlobalNotice";
import sentryExchange from "../../shared/sentryExchange";
import { store } from "../state";
import ReplacePassword from "./ReplacePassword";
import type { IFormData } from "./types";

const client = createClient({
    exchanges: [cacheExchange, sentryExchange, fetchExchange],
    fetchOptions: {
        credentials: "same-origin",
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
    },
    requestPolicy: "cache-and-network",
    url: "/graphql/player",
});

export default function init() {
    const elem = document.getElementById("form-data");
    const data = JSON.parse(elem.innerHTML) as IFormData;

    const container = document.getElementById("replace-password-page");
    Modal.setAppElement(container);
    const root = createRoot(container);
    root.render(
        <URQLProvider value={client}>
            <Provider store={store}>
                <UrqlGlobalNotice />
                <ReplacePassword data={data} />
            </Provider>
        </URQLProvider>,
    );
}
