import { type JSX, memo } from "react";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import type { ITaskChoice } from "../state";
import {
    ButtonAnchor,
    CallToActionBox,
    DeadlineBox,
    Label,
    LockIcon,
    MilesBox,
    TwoCol,
    UnlockBox,
    Wrapper,
} from "./TaskModal";

interface IProps {
    taskChoice: ITaskChoice;
}

function TaskChoiceModal({ taskChoice }: IProps): JSX.Element {
    return (
        <Wrapper>
            <h2>
                {t("player.calendar.modal-heading-task-choice")}: {taskChoice.name}
            </h2>
            <h4>{taskChoice.pathname}</h4>
            {taskChoice.complete && <p>{t("player.calendar.info-task-choice-complete")}</p>}
            <TwoCol>
                {taskChoice.unlockDate && (
                    <>
                        <Label>{t("player.calendar.modal-label-unlock-date")}:</Label>
                        <UnlockBox>
                            <LockIcon /> {formatYMD(parseDate(taskChoice.unlockDate))}
                        </UnlockBox>
                    </>
                )}
                <Label>{t("player.calendar.modal-label-call-to-action")}:</Label>
                <CallToActionBox $complete={taskChoice.complete}>
                    {formatYMD(parseDate(taskChoice.callToAction))}
                </CallToActionBox>
                {taskChoice.deadline && (
                    <>
                        <Label>{t("player.calendar.modal-label-deadline")}:</Label>
                        <DeadlineBox $complete={taskChoice.complete}>
                            {formatYMD(parseDate(taskChoice.deadline))}
                        </DeadlineBox>
                    </>
                )}
                <Label>{t("player.calendar.modal-label-miles")}:</Label>
                <MilesBox>
                    {taskChoice.miles[0]} - {taskChoice.miles[1]}
                </MilesBox>
                {taskChoice.url && (
                    <ButtonAnchor href={taskChoice.url}>{t("player.calendar.button-go-to-task-choice")}</ButtonAnchor>
                )}
            </TwoCol>
        </Wrapper>
    );
}

export default memo(TaskChoiceModal);
