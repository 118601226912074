import type { ComponentPropsWithoutRef } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function CheckmarkIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="20" width="60" height="60" rx="8" ry="8" fill="#fff" />
            <path
                d="m71.6 18.4h-43.2c-5.5 0-10 4.5-10 10v43.2c0 5.5 4.5 10 10 10h43.2c5.5 0 10-4.5 10-10v-43.2c0-5.5-4.5-10-10-10zm7 53.2c0 3.9-3.1 7-7 7h-43.2c-3.9 0-7-3.1-7-7v-43.2c0-3.9 3.1-7 7-7h43.2c3.9 0 7 3.1 7 7z"
                fill="#222"
            />
            <path
                d="m45.6 69.6c-.4 0-.8-.2-1.1-.5l-10.9-11.1c-.5-.5-.6-1.3-.2-1.9l3.3-5.2c.2-.4.7-.6 1.1-.7.5 0 .9.1 1.2.4l5 5 15.2-26.4c.2-.4.6-.7 1.1-.7.5-.1.9.1 1.2.4l4.8 4.3c.5.5.7 1.3.3 1.9l-19.7 33.8c-.2.4-.6.7-1.1.7-.1 0-.1 0-.2 0z"
                fill="#222"
            />
            <path
                d="m36.6 56.7 8.7 8.9 18.1-31.1-2.5-2.2-15.3 26.5c-.2.4-.6.7-1.1.7-.5.1-.9-.1-1.3-.4l-5-5.1z"
                fill={active ? "#ff4e57" : "#fff"}
            />
        </svg>
    );
}

export default CheckmarkIcon;
