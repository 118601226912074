import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import type { UseQueryState } from "urql";
import { formatYMD, parseDate } from "../../shared/dateFns";
import {
    type Dispatch,
    type ICalendarEvent,
    type IEvent,
    type IFeedbackTask,
    type ITask,
    type ITaskChoice,
    loadCalendarData,
} from "../state";
import type { IData } from "./query";

function toYMD(date: string): string {
    return formatYMD(parseDate(date));
}

function extractEvent(
    event: IData["currentPerson"]["learningPaths"][0]["learningPathItems"][0]["event"],
    path: IData["currentPerson"]["learningPaths"][0],
): IEvent {
    return {
        shortTitle: event.shortTitle,
        pathname: path.pathname,
        start: event.eventStart,
        end: event.eventEnd,
        url: event.url,
    };
}

function extractFeedbackTask(
    item: IData["currentPerson"]["learningPaths"][0]["learningPathItems"][0],
    path: IData["currentPerson"]["learningPaths"][0],
): IFeedbackTask {
    return {
        shortTitle: item.feedbackTask.shortTitle,
        pathname: path.pathname,
        callToAction: item.feedbackTask.callToAction,
        deadline: item.feedbackTask.deadline,
        miles: item.feedbackTask.miles,
        url: item.url,
        complete: item.feedbackTask.submittedFeedbackTask?.status === "finished",
    };
}

function extractTask(
    task: IData["currentPerson"]["learningPaths"][0]["learningPathItems"][0]["tasks"][0],
    path: IData["currentPerson"]["learningPaths"][0],
): ITask {
    return {
        shortTitle: task.shortTitle,
        pathname: path.pathname,
        unlockDate: task.unlockDate,
        callToAction: task.callToAction,
        deadline: task.deadline,
        miles: task.miles,
        timeEstimate: task.timeEstimate,
        url: task.url,
        complete: task.submittedTask?.status === "accepted",
    };
}

function extractTaskChoice(
    item: IData["currentPerson"]["learningPaths"][0]["learningPathItems"][0],
    path: IData["currentPerson"]["learningPaths"][0],
): ITaskChoice {
    const { taskChoice, tasks } = item;
    const minMiles = Math.min(...tasks.map((task) => task.miles));
    const maxMiles = Math.max(...tasks.map((task) => task.miles));
    return {
        name: taskChoice.name,
        pathname: path.pathname,
        unlockDate: taskChoice.unlockDate,
        callToAction: taskChoice.callToAction,
        deadline: taskChoice.deadline,
        url: taskChoice.url,
        complete: taskChoice.complete,
        miles: [minMiles, maxMiles],
    };
}

const EMPTY_RESULT = {
    events: [] as ICalendarEvent[],
    perfs: {
        calendarShowStartDate: true,
        calendarShowFinished: false,
    },
};

export function useCalendarData(result: UseQueryState<IData>) {
    const dispatch = useDispatch<Dispatch>();
    const { events, perfs } = useMemo(() => {
        if (result.fetching || result.error) {
            return EMPTY_RESULT;
        }
        let id = 1;
        const events: ICalendarEvent[] = [];
        for (const path of result.data.currentPerson.learningPaths) {
            for (const item of path.learningPathItems) {
                if (item.type === "event") {
                    events.push({
                        id: id++,
                        type: "event",
                        date: toYMD(item.event.eventStart),
                        event: extractEvent(item.event, path),
                        complete: false,
                    });
                }
                if (item.type === "feedback-task") {
                    events.push({
                        id: id++,
                        type: "feedback-task-call-to-action",
                        date: toYMD(item.feedbackTask.callToAction),
                        feedbackTask: extractFeedbackTask(item, path),
                        complete: item.feedbackTask.submittedFeedbackTask?.status === "finished",
                    });
                    if (item.feedbackTask.deadline) {
                        events.push({
                            id: id++,
                            type: "feedback-task-deadline",
                            date: toYMD(item.feedbackTask.deadline),
                            feedbackTask: extractFeedbackTask(item, path),
                            complete: item.feedbackTask.submittedFeedbackTask?.status === "finished",
                        });
                    }
                }
                if (item.type === "task" || item.type === "path-in-path") {
                    for (const task of item.tasks) {
                        if (task.unlockDate) {
                            events.push({
                                id: id++,
                                type: "task-unlock",
                                date: toYMD(task.unlockDate),
                                task: extractTask(task, path),
                                complete: task.submittedTask?.status === "accepted",
                            });
                        }
                        events.push({
                            id: id++,
                            type: "task-call-to-action",
                            date: toYMD(task.callToAction),
                            task: extractTask(task, path),
                            complete: task.submittedTask?.status === "accepted",
                        });
                        if (task.deadline) {
                            events.push({
                                id: id++,
                                type: "task-deadline",
                                date: toYMD(task.deadline),
                                task: extractTask(task, path),
                                complete: task.submittedTask?.status === "accepted",
                            });
                        }
                        for (const variant of task.taskVariants) {
                            if (variant.variantType === "event") {
                                events.push({
                                    id: id++,
                                    type: "task-event",
                                    date: toYMD(variant.eventStart),
                                    complete: false,
                                    event: {
                                        shortTitle: task.shortTitle,
                                        pathname: path.pathname,
                                        start: variant.eventStart,
                                        end: variant.eventEnd,
                                        url: task.url,
                                    },
                                });
                            }
                        }
                    }
                }
                if (item.type === "task-choice") {
                    if (item.taskChoice.unlockDate) {
                        events.push({
                            id: id++,
                            type: "task-choice-unlock",
                            date: toYMD(item.taskChoice.unlockDate),
                            taskChoice: extractTaskChoice(item, path),
                            complete: item.taskChoice.complete,
                        });
                    }
                    events.push({
                        id: id++,
                        type: "task-choice-call-to-action",
                        date: toYMD(item.taskChoice.callToAction),
                        taskChoice: extractTaskChoice(item, path),
                        complete: item.taskChoice.complete,
                    });
                    if (item.taskChoice.deadline) {
                        events.push({
                            id: id++,
                            type: "task-choice-deadline",
                            date: toYMD(item.taskChoice.deadline),
                            taskChoice: extractTaskChoice(item, path),
                            complete: item.taskChoice.complete,
                        });
                    }
                    for (const task of item.tasks) {
                        for (const variant of task.taskVariants) {
                            if (variant.variantType === "event") {
                                events.push({
                                    id: id++,
                                    type: "task-event",
                                    date: toYMD(variant.eventStart),
                                    complete: false,
                                    event: {
                                        shortTitle: task.shortTitle,
                                        pathname: path.pathname,
                                        start: variant.eventStart,
                                        end: variant.eventEnd,
                                        url: task.url,
                                    },
                                });
                            }
                        }
                    }
                }
            }
        }
        const perfs = {
            calendarShowStartDate: result.data.currentPerson.calendarShowStartDate,
            calendarShowFinished: result.data.currentPerson.calendarShowFinished,
        };
        return { events, perfs };
    }, [result]);
    useEffect(() => {
        if (events.length > 0) {
            dispatch(loadCalendarData({ events, perfs }));
        }
    }, [events, perfs]);
}
