import { type Dispatch, type SetStateAction, useState } from "react";
import type { JSX } from "react";
import { useResizeDetector } from "react-resize-detector";
import styled from "styled-components";
import Streetview, { ASPECT_RATIO } from "../../shared/components/Streetview";
import NavStreetArrow from "../../shared/components/icons/NavStreetArrow";
import type { OpenLockedModal } from "../LockedModal";
import CornerItem from "./CornerItem";
import type { PathItem } from "./StreetPath";

export const PAGE_SIZE = 6;
export const SMALL_CUTOFF = 650;

interface IProps {
    pathItems: PathItem[];
    openLockedModal: OpenLockedModal;
}

const AspectBox = styled.div`
    position: relative;
    margin-bottom: 1rem;
    overflow: hidden;
    background: white;
    height: 0;
    padding-top: ${ASPECT_RATIO}%;
    width: 100%;
`;

const Inner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Background = styled(Streetview)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

interface IArrow {
    $top: string;
    $left: string;
    $size: string;
}

const Arrow = styled(NavStreetArrow)<IArrow>`
    position: absolute;
    cursor: pointer;
    transform: translate(0%, -50%);
    top: ${({ $top }) => $top};
    left: ${({ $left }) => $left};
    width: ${({ $size }) => $size};
`;

function usePage(pathItems: PathItem[]): [number, Dispatch<SetStateAction<number>>] {
    const state = useState(() => Math.floor(pathItems.findIndex((item) => !!item.avatarSrc) / PAGE_SIZE));
    return state;
}

function useMaxPage(pathItems: PathItem[]): number {
    if (pathItems.length === 0) {
        return 0;
    }
    return Math.floor((pathItems.length - 1) / PAGE_SIZE);
}

function useCurrentItems(pathItems: PathItem[], page: number): PathItem[] {
    return pathItems.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);
}

function CornerPath({ pathItems, openLockedModal }: IProps): JSX.Element {
    const [page, setPage] = usePage(pathItems);
    const currentItems = useCurrentItems(pathItems, page);
    const maxPage = useMaxPage(pathItems);
    const { width, ref } = useResizeDetector();
    const prev = () => {
        setPage((p) => Math.max(p - 1, 0));
    };
    const next = () => {
        setPage((p) => Math.min(p + 1, maxPage));
    };
    const arrowTop = width >= SMALL_CUTOFF ? "28%" : "22%";
    return (
        <AspectBox>
            <Background />
            <Inner ref={ref}>
                {currentItems.map((item, index) => (
                    <CornerItem
                        key={item.id}
                        item={item}
                        index={index}
                        width={width}
                        openLockedModal={openLockedModal}
                    />
                ))}
                {page > 0 && <Arrow dir="left" $top={arrowTop} $left="0%" $size="60px" onClick={prev} />}
                {page < maxPage && (
                    <Arrow dir="right" $top={arrowTop} $left="calc(100% - 60px)" $size="60px" onClick={next} />
                )}
            </Inner>
        </AspectBox>
    );
}

export default CornerPath;
