import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { Provider } from "react-redux";
import styled from "styled-components";
import { Provider as URQLProvider, cacheExchange, createClient, fetchExchange } from "urql";
import { UrqlGlobalNotice } from "../shared/components/GlobalNotice";
import sentryExchange from "../shared/sentryExchange";
import Footer from "./Footer";
import Header from "./Header";
import { CurrentPersonProvider } from "./components/CurrentPerson";
import { store } from "./state";

const client = createClient({
    exchanges: [cacheExchange, sentryExchange, fetchExchange],
    fetchOptions: {
        credentials: "same-origin",
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
    },
    requestPolicy: "cache-and-network",
    url: "/graphql/player",
});

const Main = styled.main`
    flex-grow: 1;
`;

export default function init() {
    const container = document.getElementById("empty-page");
    Modal.setAppElement(container);
    const root = createRoot(container);
    root.render(
        <URQLProvider value={client}>
            <Provider store={store}>
                <CurrentPersonProvider>
                    <UrqlGlobalNotice />
                    <Header />
                    <Main />
                    <Footer />
                </CurrentPersonProvider>
            </Provider>
        </URQLProvider>,
    );
}
