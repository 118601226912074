import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function RoadIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m13.522 44.294c.925-3.469 1.85-6.938 2.775-10.408h67.406c.925 3.47 1.85 6.939 2.775 10.408-2.431 0-4.863 0-7.295 0 .211.99.422 1.98.633 2.97h7.455c1.059 3.972 2.118 7.944 3.177 11.916-7.753 0-73.143 0-80.896 0 1.059-3.972 2.118-7.944 3.177-11.916h7.455c.211-.99.422-1.98.633-2.97-2.432 0-4.864 0-7.295 0zm40.205 2.97c-.026-.99-.053-1.98-.079-2.97-2.432 0-4.864 0-7.296 0-.026.99-.053 1.98-.079 2.97zm11.181 0c-.105-.99-.211-1.98-.317-2.97-2.432 0-4.863 0-7.295 0 .052.99.105 1.98.158 2.97zm11.181 0c-.184-.99-.369-1.98-.554-2.97-2.432 0-4.864 0-7.296 0 .132.99.264 1.98.396 2.97zm-33.543 0c.053-.99.106-1.98.158-2.97-2.432 0-4.863 0-7.295 0-.106.99-.212 1.98-.317 2.97zm-11.181 0c.132-.99.264-1.98.396-2.97-2.432 0-4.864 0-7.296 0-.185.99-.37 1.98-.554 2.97z" />
            <path
                d="m20.184 47.264c-2.485 0-4.97 0-7.455 0 .265-.99.529-1.98.793-2.97h7.295c-.211.99-.422 1.98-.633 2.97zm11.181 0h-7.454c.184-.99.369-1.98.554-2.97h7.296c-.132.99-.264 1.98-.396 2.97zm11.181 0c-2.485 0-4.97 0-7.454 0 .105-.99.211-1.98.317-2.97h7.295c-.052.99-.105 1.98-.158 2.97zm11.181 0c-2.485 0-4.969 0-7.454 0 .026-.99.053-1.98.079-2.97h7.296c.026.99.053 1.98.079 2.97zm33.544 0c-2.485 0-4.97 0-7.455 0-.211-.99-.422-1.98-.633-2.97h7.295c.264.99.528 1.98.793 2.97zm-11.182 0c-2.484 0-4.969 0-7.454 0-.132-.99-.264-1.98-.396-2.97h7.296c.185.99.37 1.98.554 2.97zm-11.181 0c-2.484 0-4.969 0-7.454 0-.053-.99-.106-1.98-.158-2.97h7.295c.106.99.212 1.98.317 2.97z"
                fill={active ? "#ffc232" : "#fff"}
            />
        </svg>
    );
}

export default RoadIcon;
