import styled from "styled-components";
import t from "../../shared/translations";
import PasswordForm from "./PasswordForm";
import type { IFormData } from "./types";
import type { JSX } from "react";

interface IProps {
    data: IFormData;
}

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1030px;
    width: 100%;
`;

function ReplacePassword({ data }: IProps): JSX.Element {
    return (
        <Wrapper>
            <h1 className="small-only-text-center">{t("player.replace-password.heading")}</h1>
            <p>{t("player.replace-password.info-replace-password")}</p>
            <PasswordForm next={data.next.value} />
        </Wrapper>
    );
}

export default ReplacePassword;
