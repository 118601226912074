import {
    Children,
    type ComponentPropsWithoutRef,
    type ElementType,
    type ReactElement,
    type ReactNode,
    createElement,
} from "react";
import { useDispatch } from "react-redux";
import Doc from "../../shared/components/Doc";
import InfoIcon from "../../shared/components/icons/InfoIcon";
import { type Dispatch, type Modal as IModal, openModal } from "../state";
import Modal from "./Modal";

type IProps<C extends ElementType> = {
    component: C;
    modal: IModal;
    docPath: string;
    children?: ReactNode;
} & ComponentPropsWithoutRef<C>;

function WithDocs<C extends ElementType>({ component, modal, children, docPath, ...props }: IProps<C>): ReactElement {
    const dispatch = useDispatch<Dispatch>();

    const openInfo = () => {
        dispatch(openModal(modal));
    };

    const childrenArray = Children.toArray(children);
    childrenArray.push(<InfoIcon key="info-icon" onClick={openInfo} />);
    childrenArray.push(
        <Modal modal={modal} key="modal">
            <Doc path={docPath} />
        </Modal>,
    );

    const className = `with-docs ${props.className || ""}`.trim();

    return createElement(component, { ...props, className }, childrenArray);
}

export default WithDocs;
