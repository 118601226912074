import { Link } from "react-router-dom";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import FinalInput from "../components/FinalInput";
import FinalSelect from "../components/FinalSelect";
import FinalTextarea from "../components/FinalTextarea";

export const Input = styled(FinalInput)`
    input {
        border: 1px solid ${colors.text};
        color: ${colors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: 'white';
        font-size: 1rem;

        &:focus {
            border-color: ${colors.text};
        }
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border: 1px solid ${colors.text};
        border-top: none;
    }

    &.error input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const Textarea = styled(FinalTextarea)`
    textarea {
        border: 1px solid ${colors.text};
        color: ${colors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: 'white';
        font-size: 1rem;
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border: 1px solid ${colors.text};
        border-top: none;
    }

    &.error textarea {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const Select = styled(FinalSelect)`
    select {
        border: 1px solid ${colors.text};
        color: ${colors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: 'white';
        font-size: 1rem;

        &:focus {
            border-color: ${colors.text};
        }
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border: 1px solid ${colors.text};
        border-top: none;
    }

    &.error input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

type IBox = {
    $border?: string;
};

export const Box = styled.div<IBox>`
    border-radius: 1rem;
    padding: 0.6rem;
    border: 1px solid ${({ $border }) => $border ?? colors.primaryColor};
    margin-bottom: 0.5rem;

    @media (min-width: 600px) {
        padding: 1rem;
        margin-bottom: 1rem;
    }

    > *:last-child {
        margin-bottom: 0;
    }
`;

interface IQuestionBox {
    $bold?: boolean;
    $error?: boolean;
    $padRight?: boolean;
}

export const QuestionBox = styled(Box)<IQuestionBox>`
    page-break-inside: avoid;
    position: relative;
    border-width: ${({ $bold }) => ($bold ? 3 : 1)}px;
    border-color: ${({ $error }) => ($error ? colors.alert : colors.primaryColor)};
    padding-right: ${({ $padRight }) => ($padRight ? "calc(0.6rem + 1.2rem + 0.6rem)" : "0.6rem")};
`;

export const Center = styled.div`
    display: grid;
    place-items: center;
`;

export const QuestionHeading = styled.div`
    font-size: 1.15rem;
    display: grid;
    grid-template-columns: 35px 1fr;
    justify-content: start;
    align-items: start;
    margin-bottom: 1rem;
    gap: 0.5rem;
`;

interface IButtonLink {
    $invert?: boolean;
}

export const ButtonLink = styled(Link)<IButtonLink>`
    cursor: pointer;
    margin: 0 0 1.25rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 1rem 2rem 1.0625rem 2rem;
    font-size: 1rem;
    background-color: ${({ $invert }) => ($invert ? colors.altPrimaryColor : colors.primaryColor)};
    color: ${colors.altText};
    line-height: normal;

    &:hover, &:focus, &:active, &:visited {
        background-color: ${({ $invert }) => ($invert ? colors.altPrimaryColor : colors.primaryColor)};
        color: ${colors.altText};
    }
`;

export const Bold = styled.span`
    font-weight: bold;
`;

interface IButton {
    $invert?: boolean;
    $noMargin?: boolean;
}

export const Button = styled.button<IButton>`
    cursor: pointer;
    margin: ${({ $noMargin }) => ($noMargin ? "0" : "0 0 1.25rem")};
    position: relative;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 1rem 2rem 1.0625rem 2rem;
    font-size: 1rem;
    background-color: ${({ $invert }) => ($invert ? colors.altPrimaryColor : colors.primaryColor)};
    color: ${colors.altText};
    line-height: normal;

    &:hover, &:focus, &:active, &:visited {
        background-color: ${({ $invert }) => ($invert ? colors.altPrimaryColor : colors.primaryColor)};
        color: ${colors.altText};
    }
`;

interface IButtonAnchor {
    $invert?: boolean;
}

export const ButtonAnchor = styled.a<IButtonAnchor>`
    cursor: pointer;
    margin: 0 0 1.25rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 1rem 2rem 1.0625rem 2rem;
    font-size: 1rem;
    background-color: ${({ $invert }) => ($invert ? colors.altPrimaryColor : colors.primaryColor)};
    color: ${colors.altText};
    line-height: normal;

    &:hover, &:focus, &:active, &:visited {
        background-color: ${({ $invert }) => ($invert ? colors.altPrimaryColor : colors.primaryColor)};
        color: ${colors.altText};
    }
`;

export const FakeTextarea = styled.div`
    border: 1px solid ${colors.unlight};
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: 'white';
    font-size: 1rem;
    height: 3rem;
`;

export const QuestionRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 800px) {
        grid-template-columns: 250px 1fr;
        gap: 1rem;
        align-items: start;
    }
`;

export const FakeSelect = styled.div`
    border: 1px solid ${colors.text};
    color: ${colors.text};
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: 'white';
    font-size: 1rem;
    height: 2.62rem;
    overflow: hidden;
    margin-bottom: 1rem;
`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    @media (min-width: 500px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        > * {
            margin: 0 3px;
        }
    }
`;

interface IHider {
    $hide: boolean;
}

export const Hider = styled.div<IHider>`
    display: ${({ $hide }) => ($hide ? "none" : "block")};
`;

export const NavButtonRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    justify-content: center;
    margin-bottom: 1rem;
`;

interface INavButton {
    $prev: boolean;
}

export const NavButton = styled(Button)<INavButton>`
    margin-bottom: 0;
    svg {
        display: inline-block;
        fill: white;
        width: 20px;
        height: 20px;
        margin-top: -5px;
        margin-bottom: -5px;
        margin-left: ${({ $prev }) => ($prev ? "-15px" : "5px")};
        margin-right: ${({ $prev }) => ($prev ? "5px" : "-15px")};
    }
`;

export const PrevArrow = (
    <svg viewBox="0 0 100 100">
        <path d="M100 0 L0 45 L0 55 L100 100 L100 90 L10 50 L100 10Z" />
    </svg>
);

export const NextArrow = (
    <svg viewBox="0 0 100 100">
        <path d="M0 0 L100 45 L100 55 L0 100 L0 90 L90 50 L0 10Z" />
    </svg>
);
