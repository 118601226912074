import styled from "styled-components";
import * as colors from "../../shared/colors";

export const SMALL_MOBILE_CUTOFF = 768;
export const BIG_MOBILE_CUTOFF = 1000;

export const TaskRow = styled.div`
    display: grid;
    align-items: center;
    gap: 5px;
    grid-template-columns: 60px 1fr;
    grid-auto-flow: column;
`;

interface ITaskBox {
    $color: string;
    $borderColor: string;
    $borderRadius: number;
}

export const TaskBox = styled.a<ITaskBox>`
    display: block;
    color: ${({ $color }) => $color};
    border: 1px solid ${({ $borderColor }) => $borderColor};
    margin-top: 10px;
    padding: 5px;
    font-size: 0.9rem;
    border-radius: ${({ $borderRadius }) => $borderRadius}px;
    &:hover, &:active, &:focus, &:visited {
        color: ${({ $color }) => $color};
    }
`;

export const IconHolder = styled.div`
    width: 60px;
    height: 60px;
    position: relative;
`;

export const TabRow = styled.div`
    display: flex;
    justify-content: flex-start;
`;

interface ITab {
    $active: boolean;
}

export const Tab = styled.div<ITab>`
    border: 1px solid ${colors.primaryColor};
    border-radius: 10px 10px 0 0;
    color: ${({ $active }) => ($active ? colors.altText : colors.primaryColor)};
    background-color: ${({ $active }) => ($active ? colors.primaryColor : colors.white)};
    font-size: 1rem;
    padding: 4px 8px;
    @media (min-width: ${SMALL_MOBILE_CUTOFF}) {
        font-size: 1.1rem;
    }
`;

interface ICurrent {
    $borderRadius: number;
}

export const Current = styled.div<ICurrent>`
    border-radius: ${({ $borderRadius }) => $borderRadius}px;
    border: 6px solid ${colors.primaryColor};
    align-self: stretch;
`;
