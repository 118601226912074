import type { ComponentPropsWithoutRef } from "react";
import type { JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function ScissorsIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15.7" cy="28.9" r="12" fill="#fff" />
            <circle cx="31.5" cy="75.8" r="12" fill="#fff" />
            <path d="m44.7 37.3.7.8z" fill="#222" />
            <path
                d="m62.2 38.2 25.8-24.5-1.4-1.1c-.2-.2-2.5-1.8-7.5-2-4.8-.2-8.7 3-11.2 5.1l-.8.7c-1.5 1.2-13.2 11.8-22.8 20.5l.3.3 0 0-.3-.3c-.1.1-.2.2-.3.3l-16.6-.9h-.1c1.5-2.4 2.3-5.2 2.1-8.2-.2-3.7-1.9-7.1-4.6-9.5-2.8-2.5-6.3-3.7-10-3.5s-7.1 1.9-9.5 4.6-3.7 6.3-3.5 10c .4 7.3 6.5 13 13.8 13h .8c1.2-.1 2.3-.3 3.4-.7l0 0c8.1-.8 14.8 1.7 16.3 5.9 1.6 4.6-2.9 10.8-11.2 15.5l0 0c-4.8 2.5-7.8 7.6-7.4 13.1.2 3.7 1.9 7.1 4.6 9.5 2.6 2.3 5.8 3.5 9.2 3.5h.8c7.6-.5 13.4-7 13-14.6-.2-3.7-1.9-7.1-4.6-9.5-1.4-1.3-3.1-2.2-4.8-2.8l.7-.3 12.6-12v .2c12.6.4 27.9.9 31.1.9h.4.9c3.3-.1 8.3-.1 11.9-3.4 3.7-3.4 4.4-6.1 4.5-6.4l.4-1.7zm-45.8 1.5c-6 .4-11.1-4.2-11.5-10.2-.2-2.9.8-5.7 2.7-7.8 1.9-2.2 4.6-3.5 7.5-3.6h.7c2.7 0 5.2 1 7.2 2.7 2.2 1.9 3.5 4.6 3.6 7.5.3 6-4.3 11.1-10.2 11.4zm22.3 28.1c2.2 1.9 3.5 4.6 3.6 7.5.3 5.9-4.2 11-10.2 11.4-2.9.2-5.7-.8-7.8-2.7-2.2-1.9-3.5-4.6-3.6-7.5-.3-4.8 2.7-9.3 7.2-10.9.9-.3 1.9-.5 2.9-.6h.7c2.6 0 5.2 1 7.2 2.8z"
                fill="#222"
            />
            <g fill={active ? "#0076c2" : "#fff"}>
                <path d="m27.8 39.4.2-.1 13 .7c-1.7 1.6-3.1 2.8-4 3.7-2.1-2.1-5.3-3.6-9.2-4.3z" />
                <path d="m35 59.9-.2.1c4.1-4.3 5.7-8.9 4.3-12.9-.1-.3-.2-.6-.4-.9 1.4-1.2 4.2-3.8 7.7-7l0 0c13.5-12.3 21.5-19.5 22.7-20.4l.7-.6c2.3-1.9 5.6-4.6 9.2-4.5 1.8 0 3.1.3 4 .6z" />
                <path d="m91.3 46c-2.7 2.5-6.9 2.5-9.9 2.6h-.9c-1.4 0-11.5-.3-28.5-.8l7.1-6.7 34.9 1.9c-.5.7-1.3 1.8-2.7 3z" />
            </g>
        </svg>
    );
}

export default ScissorsIcon;
