import { type Dispatch, type JSX, useState } from "react";
import { loadJSData } from "../../jsData";
import t from "../../translations";
import Modal from "../StyledModal";
import type { IAction, IState } from "./state";
import { AspectBox, AvatarList, Background, ButtonRow, SelectedAvatar } from "./stylings";

interface IProps {
    state: IState;
    dispatch: Dispatch<IAction>;
    avatarOptions: string[];
}

export function avatarSrc(avatar: string): string {
    const path = loadJSData().avatar_path;
    if (avatar) {
        return `${path}${avatar}-welcome.png`;
    }
    return "/static/images/avatar-shilouette.png";
}

function AvatarForm({ state, dispatch, avatarOptions }: IProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const [avatar, setAvatar] = useState(state.values.avatar);
    const onNext = () => {
        dispatch({ type: "NEXT-AVATAR", values: { avatar } });
    };
    const onBack = () => {
        dispatch({ type: "BACK-AVATAR" });
    };
    const selectAvatar = (avt: string) => {
        setAvatar(avt);
        setOpen(false);
    };
    return (
        <div>
            <p>{t("shared.account-wizard.form-avatar-info")}</p>
            <div style={{ maxWidth: 800, margin: "0 auto" }}>
                <AspectBox>
                    <Background />
                    <SelectedAvatar src={avatarSrc(avatar)} />
                </AspectBox>
            </div>
            <ButtonRow>
                <button onClick={() => setOpen(true)} type="button">
                    {t("shared.account-wizard.form-avatar-button-avatar-modal")}
                </button>
                <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
                    <h3>{t("shared.account-wizard.form-avatar-heading-avatar-modal")}</h3>
                    <AvatarList>
                        {avatarOptions.map((avt) => (
                            <img key={avt} src={avatarSrc(avt)} onClick={() => selectAvatar(avt)} alt={avt} />
                        ))}
                    </AvatarList>
                </Modal>
            </ButtonRow>
            <ButtonRow>
                <button type="button" onClick={onBack}>
                    {t("shared.account-wizard.form-avatar-button-back")}
                </button>
                <button type="button" onClick={onNext}>
                    {t("shared.account-wizard.form-avatar-button-next")}
                </button>
            </ButtonRow>
        </div>
    );
}

export default AvatarForm;
