import { type JSX, useState } from "react";
import { useMutation } from "urql";
import RenderMark from "../../shared/components/RenderMark";
import AiInput from "../../shared/components/ai/AiInput";
import AiThread from "../../shared/components/ai/AiThread";
import type { AiMessageRoleType } from "../../shared/types";
import { type IContinueArgs, type IContinueData, continueMutation } from "./queries";

interface IProps {
    id: string;
    threadItems: {
        id: string;
        role: AiMessageRoleType;
        content: string;
    }[];
    chatbot: {
        id: string;
        name: string;
        description: string;
    };
}

function Thread({ id, threadItems, chatbot }: IProps): JSX.Element {
    const [contninueResult, executeContinue] = useMutation<IContinueData, IContinueArgs>(continueMutation);
    const [items, setItems] = useState(threadItems);
    const [qItem, setQItem] = useState("");

    const continueThread = async (content: string) => {
        if (contninueResult.fetching) {
            return;
        }
        if (!content) {
            return;
        }
        setQItem(content);
        const result = await executeContinue({ id, content });
        if (result.error || result.data.standaloneAiThreadContinue?.error) {
            console.error(result.error || result.data.standaloneAiThreadContinue?.error);
        } else {
            setItems(result.data.standaloneAiThreadContinue.standaloneAiThread.aiThreadItems);
            setQItem("");
        }
    };

    return (
        <>
            <h2>{chatbot.name}</h2>
            <RenderMark content={chatbot.description} />
            <AiThread messages={items} fmtUser={false} />
            {qItem && <AiThread messages={[{ id: "q", role: "user", content: qItem }]} fmtUser={false} />}
            <AiInput onSubmit={continueThread} loading={contninueResult.fetching} />
        </>
    );
}

export default Thread;
