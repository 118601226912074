import { useState, type JSX } from "react";
import { useMutation } from "urql";
import t from "../../shared/translations";
import Info from "./Info";
import { type IAssignArgs, type IAssignData, assignMutation } from "./queries";
import { ErrorBox } from "./stylings";

interface IProps {
    id: string;
    pathname: string;
}

function RequestAnswer({ id, pathname }: IProps): JSX.Element {
    const [executeResult, executeAssign] = useMutation<IAssignData, IAssignArgs>(assignMutation);
    const [error, setError] = useState("");
    const onClick = async () => {
        const args: IAssignArgs = { id };
        const result = await executeAssign(args);
        if (result.error) {
            console.error(result.error);
            setError(t("player.error.error"));
        } else if (result.data.feedbackTaskAssign?.error) {
            console.error(result.data.feedbackTaskAssign.error);
            setError(result.data.feedbackTaskAssign.error);
        } else {
            setError("");
        }
    };
    return (
        <>
            <Info info="request" pathname={pathname} />
            <div style={{ textAlign: "center" }}>
                <button type="button" disabled={executeResult.fetching} onClick={onClick}>
                    {t("player.provide-feedback.button-request-answer")}
                </button>
            </div>
            {error && <ErrorBox>{error}</ErrorBox>}
        </>
    );
}

export default RequestAnswer;
