import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import * as colors from "../colors";
import type { JSX } from "react";

interface IProps {
    leftLabel: string;
    rightLabel: string;
    answers: [number, number, number, number, number];
}

const Wrapper = styled.div`
    display: flex;
    margin: 0 -1px;
    justify-content: center;
    flex-wrap: wrap;

    *:nth-child(2) {
        order: 0;
        @media only screen and (min-width: 800px) {
            order: 2;
            max-width: 400px;
            margin: 0 0.5rem;
            flex-grow: 1;
        }
        @media only print {
            order: 2;
            max-width: 400px;
            margin: 0 0.5rem;
            flex-grow: 1;
        }
    }

    @media only screen and (min-width: 800px) {
        flex-wrap: nowrap;
        align-items: flex-end;
    }
    @media only print {
        flex-wrap: nowrap;
        align-items: flex-end;
    }
`;

interface ILabel {
    $order: number;
    $right?: boolean;
}

export const Label = styled.div<ILabel>`
    flex-grow: 1;
    flex-basis: 0;
    order: ${({ $order }) => $order};
    text-align: ${({ $right }) => ($right ? "right" : "left")};

    @media only screen and (min-width: 800px) {
        text-align: ${({ $right }) => ($right ? "left" : "right")};
        flex-basis: auto;
    }
    @media only print {
        text-align: ${({ $right }) => ($right ? "left" : "right")};
        flex-basis: auto;
    }
`;

const margins = { top: 0, right: 0, bottom: -20, left: -10 };

function LikertChart({ leftLabel, rightLabel, answers }: IProps): JSX.Element {
    const data = answers.map((ans) => ({ value: ans, label: "" }));
    return (
        <Wrapper>
            <Label $order={1}>{leftLabel}</Label>
            <div>
                <ResponsiveContainer width="100%" aspect={2.2}>
                    <BarChart data={data} margin={margins}>
                        <YAxis />
                        <XAxis dataKey="label" />
                        <Bar dataKey="value" fill={colors.primaryColor} isAnimationActive={false} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <Label $order={3} $right>
                {rightLabel}
            </Label>
        </Wrapper>
    );
}

export default LikertChart;
