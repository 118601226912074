import { range } from "lodash-es";
import styled from "styled-components";
import FlatStreetview from "../../shared/components/FlatStreetview";
import type { OpenLockedModal } from "../LockedModal";
import FlatItem from "./FlatItem";
import type { PathItem } from "./StreetPath";
import type { JSX } from "react";

export const BLOCK_SIZE = 6;
const HEIGHT = 280;

interface IProps {
    pathItems: PathItem[];
    openLockedModal: OpenLockedModal;
}

const ScrollTainer = styled.div`
    position: relative;
    padding-bottom: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
`;

interface IBackground {
    $idx: number;
}

const Background = styled(FlatStreetview)<IBackground>`
    width: ${70 * BLOCK_SIZE}px;
    position: absolute;
    top: 30px;
    left: ${({ $idx }) => $idx * 70 * BLOCK_SIZE}px;
`;

function FlatPath({ pathItems, openLockedModal }: IProps): JSX.Element {
    const numBackgrounds = Math.ceil(pathItems.length / BLOCK_SIZE);
    return (
        <ScrollTainer>
            <div style={{ width: numBackgrounds * 70 * BLOCK_SIZE, height: HEIGHT }}>
                {range(numBackgrounds).map((idx) => (
                    <Background key={idx} $idx={idx} />
                ))}
                {pathItems.map((item, index) => (
                    <FlatItem key={item.id} item={item} index={index} openLockedModal={openLockedModal} />
                ))}
            </div>
        </ScrollTainer>
    );
}

export default FlatPath;
