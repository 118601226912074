import { memo, type JSX } from "react";
import { v4 as uuid4 } from "uuid";
import * as colors from "../../../shared/colors";
import { loadJSData } from "../../../shared/jsData";
import t from "../../../shared/translations";
import type { ITask, ITaskQuestion, ITaskVariant } from "../query";

interface Props {
    task: ITask;
    variant: ITaskVariant;
    invert: boolean;
}

function makeUrl(question: ITaskQuestion, task: ITask, resp_id: string, variant: ITaskVariant) {
    const surveyGizmoUrl = question.surveyGizmoUrl;
    const url = new URL(surveyGizmoUrl);
    url.searchParams.append("userid", loadJSData().current_person.id);
    url.searchParams.append("taskid", task.id);
    url.searchParams.append("respid", resp_id);
    url.searchParams.append("varid", variant.id);
    const urlString = url.toString();
    return urlString;
}

function StartSurveyGizmo({ task, variant, invert }: Props): JSX.Element {
    const resp_id = uuid4();
    const question = variant.taskQuestions.find((q) => q.surveyGizmoUrl);
    if (!question) {
        return null;
    }
    const urlString = makeUrl(question, task, resp_id, variant);
    const style = { backgroundColor: invert ? colors.altPrimaryColor : colors.primaryColor };
    return (
        <div className="text-center">
            {question.questionContent && <h4>{question.questionContent}</h4>}
            <a href={urlString} className="button" style={style}>
                {t("player.task.button-survey")}
            </a>
        </div>
    );
}

export default memo(StartSurveyGizmo);
