import { memo } from "react";
import type { JSX } from "react";

function MyPathsIcon(): JSX.Element {
    return (
        <svg className="nav-icon" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path d="m69.3 9.8h-38.2c-5.5 0-10 4.5-10 10v48.4c0 5.5 4.5 10 10 10h20.3v11c0 .6.4 1.2 1 1.4s1.3 0 1.7-.5l4.3-5.1 4.3 5.1c.3.4.7.5 1.2.5.2 0 .3 0 .5-.1.6-.2 1-.8 1-1.4v-11h4c5.5 0 10-4.5 10-10v-48.3c-.1-5.5-4.6-10-10.1-10zm-7 75.2-2.8-3.3c-.3-.3-.7-.5-1.2-.5s-.9.2-1.2.5l-2.6 3.3v-10.7c1.2.4 2.6.7 3.9.7s2.7-.2 3.9-.7zm-3.9-13.1c-5.1 0-9.3-4.2-9.3-9.3s4.2-9.3 9.3-9.3 9.3 4.2 9.3 9.3-4.2 9.3-9.3 9.3zm17.9-3.7c0 3.9-3.1 7-7 7h-4v-2.4c3.2-2.2 5.3-5.9 5.3-10.1 0-6.8-5.5-12.3-12.3-12.3s-12.3 5.5-12.3 12.3c0 4.2 2.1 7.9 5.3 10.1v2.4h-20.2c-3.9 0-7-3.1-7-7v-48.4c0-3.9 3.1-7 7-7h38.2c3.9 0 7 3.1 7 7z" />
            <path d="m67.7 23.3h-34.3c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h34.3c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5z" />
            <path d="m67.7 30.1h-34.3c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h34.3c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5z" />
            <path d="m67.7 36.8h-34.3c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h34.3c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5z" />
        </svg>
    );
}

export default memo(MyPathsIcon);
