import styled from "styled-components";
import RenderMark from "../../shared/components/RenderMark";
import type { IData } from "./index";
import type { JSX } from "react";

interface IProps {
    data: IData;
}

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1030px;
    width: 100%;
`;

function Terms({ data }: IProps): JSX.Element {
    return (
        <Wrapper>
            <section>
                <RenderMark content={data.settingsTerms} />
            </section>
            <section>
                <RenderMark content={data.terms} />
            </section>
        </Wrapper>
    );
}

export default Terms;
