import { ARRAY_ERROR, type ValidationErrors } from "final-form";
import arrayMutators from "final-form-arrays";
import { Form } from "react-final-form";
import styled from "styled-components";
import { loadJSData } from "../../../shared/jsData";
import t from "../../../shared/translations";
import type { AnswerType } from "../../../shared/types";
import FileInput from "../../components/FileInput";
import type { ITaskThreadItem } from "../query";
import { Box, Button, NavButton, NavButtonRow, NextArrow, PrevArrow } from "../stylings";
import Checkboxes, { type ICheckboxAnswer } from "./Checkboxes";
import ImpactAnswers, { type IImpactAnswer } from "./ImpactAnswers";
import LikertAnswers, { type ILikertAnswer } from "./LikertAnswers";
import Textfield from "./Textfield";
import TextfieldSave from "./TextfieldSave";
import type { JSX } from "react";

export interface IFormValues {
    answerType: AnswerType;
    questionContent: string;
    answerContent: string;
    file: File | null;
    checkboxAnswers: ICheckboxAnswer[];
    taskLikertAnswers: ILikertAnswer[];
    requireAllCheckmarks: boolean;
    impactAnswers: IImpactAnswer[];
}

interface IProps {
    index: number;
    invert: boolean;
    editAnswer: ITaskThreadItem | null;
    initialValues: IFormValues;
    onSubmit: (values: IFormValues, index: number) => Promise<void>;
    goBack?: (values: IFormValues, index: number) => void;
    cancel: VoidFunction;
    saveIncomplete: (values: IFormValues[]) => Promise<void>;
    final: boolean;
    allValues: IFormValues[];
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    const formats = loadJSData().formats;
    if (values?.answerType === "file") {
        if (values.file == null) {
            errors.file = t("player.task.error-file-required");
        } else if (!formats.answer_formats.includes(values.file.name.split(".").pop().toLowerCase())) {
            errors.file = t("player.task.error-file-unsupported-format");
        } else if (values.file.size > formats.max_upload_size) {
            errors.file = t("player.task.error-file-too-big");
        }
    } else if (values.answerType === "textbox") {
        if (!values.answerContent) {
            errors.answerContent = t("player.task.error");
        }
    } else if (values.answerType === "checkbox") {
        if (!values.checkboxAnswers.some((opt) => opt.checked)) {
            errors.checkboxAnswers = [];
            errors.checkboxAnswers[ARRAY_ERROR] = values.requireAllCheckmarks
                ? t("player.task.error-must-check-all")
                : t("player.task.error-must-check-one");
        } else {
            if (values.requireAllCheckmarks && values.checkboxAnswers.some((a) => !a.checked)) {
                errors.checkboxAnswers = [];
                errors.checkboxAnswers[ARRAY_ERROR] = t("player.task.error-must-check-all");
            } else if (!values.requireAllCheckmarks && !values.checkboxAnswers.some((a) => a.checked)) {
                errors.checkboxAnswers = [];
                errors.checkboxAnswers[ARRAY_ERROR] = t("player.task.error-must-check-one");
            }
        }
    } else if (values.answerType === "likert") {
        errors.taskLikertAnswers = new Array(values.taskLikertAnswers.length);
        values.taskLikertAnswers.forEach((ans, idx) => {
            if (!ans.value) {
                errors.taskLikertAnswers[idx] = {
                    value: t("player.task.error-likert-required"),
                };
            }
        });
    }
    return errors;
}

const Question = styled.h4`
    margin-bottom: 0.8rem;
    padding: 0 0.5rem;
    @media (min-width: 640px){
        margin-bottom: 1rem;
        padding: 0 1rem;
    }
`;

function AnswerForm({
    invert,
    initialValues,
    onSubmit,
    index,
    editAnswer,
    goBack,
    final,
    cancel,
    allValues,
    saveIncomplete,
}: IProps): JSX.Element {
    const taskAnswerFile = editAnswer ? editAnswer.taskAnswers[index]?.taskAnswerFile : null;
    const formats = loadJSData().formats;
    const fileFormatStr = formats.answer_formats.map((f) => `.${f}`).join(", ");
    return (
        <Form<IFormValues>
            onSubmit={(values) => onSubmit(values, index)}
            validate={validate}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
        >
            {({ handleSubmit, submitting, values, invalid }) => {
                const question = values.questionContent;
                return (
                    <form onSubmit={handleSubmit}>
                        {!!question && <Question>{question}</Question>}
                        {values.answerType === "textbox" && <Textfield name="answerContent" />}
                        {values.answerType === "checkbox" && <Checkboxes name="checkboxAnswers" />}
                        {values.answerType === "likert" && <LikertAnswers name="taskLikertAnswers" />}
                        {values.answerType === "impact-tracker" && <ImpactAnswers name="impactAnswers" />}
                        {values.answerType === "file" && (
                            <Box>
                                {t("player.task.info-file-formats", {
                                    formats: formats.answer_formats.join(", "),
                                })}
                                <br />
                                {t("player.task.info-max-upload", {
                                    size: `${formats.max_upload_size / 1024 / 1024}MB`,
                                })}
                                <br />
                                {taskAnswerFile != null && (
                                    <span>
                                        {t("player.task.info-previous-file")}{" "}
                                        <a href={taskAnswerFile.src} target="_blank" rel="noreferrer">
                                            <strong>{taskAnswerFile.name}</strong>
                                        </a>
                                        <br />
                                    </span>
                                )}
                                {t("player.task.info-selected-file")} <strong>{values.file?.name ?? ""}</strong>
                                <NavButtonRow>
                                    <FileInput
                                        name="file"
                                        type="file"
                                        invert={invert}
                                        accept={fileFormatStr}
                                        label={t("player.task.button-select-file")}
                                    />
                                </NavButtonRow>
                            </Box>
                        )}
                        <NavButtonRow>
                            {!!editAnswer && (
                                <Button onClick={cancel} type="button" $invert={invert} $noMargin>
                                    {t("player.task.button-cancel")}
                                </Button>
                            )}
                            {goBack !== null && (
                                <NavButton
                                    $prev={true}
                                    onClick={() => goBack(values, index)}
                                    type="button"
                                    $invert={invert}
                                >
                                    {PrevArrow} {t("player.task.button-prev")}
                                </NavButton>
                            )}
                            <NavButton $prev={false} type="submit" disabled={submitting || invalid} $invert={invert}>
                                {final ? t("player.task.button-submit") : t("player.task.button-next")}{" "}
                                {final ? null : NextArrow}
                            </NavButton>
                        </NavButtonRow>
                        <div>
                            {values.answerType === "textbox" && (
                                <TextfieldSave
                                    allValues={allValues}
                                    currValue={values}
                                    invert={invert}
                                    saveIncomplete={saveIncomplete}
                                    index={index}
                                />
                            )}
                        </div>
                    </form>
                );
            }}
        </Form>
    );
}

export default AnswerForm;
