import { memo } from "react";
import type { JSX } from "react";
import styled from "styled-components";

const FinishFlag = styled.svg`
    width: 145px;
    height: 145px;
    margin: 0.2rem 0;
`;

function Flag(): JSX.Element {
    return (
        <FinishFlag xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.12 149.73">
            <path d="M14.86 9.5c.71 6.58 3.37 12.85 4.09 19.42.61 5.57.93 4.22 6.65 29.65 1.25 5.55 4 18.52 9.46 44.47 4.26 20.19 6.68 31.84 7.92 34.76a9.61 9.61 0 0 1 0 7.67 6.91 6.91 0 0 1-2.56 3.32 6.44 6.44 0 0 1-4.86.77 6.64 6.64 0 0 1-3.07-1.79 10.06 10.06 0 0 1-2.56-4.86c-1.22-4.21-1.83-6.31-2.3-8.43-.85-3.87-1.19-4.34-2.56-9.46-2.26-8.47-2.77-12.68-5.11-23.51-1.52-7-1.73-7.25-3.83-16.36-3.07-13.31-3-14.34-6.39-30.16-5.07-23.43-7.61-35.15-7.66-35.27C.52 16 .36 13.47 0 8.47a7.93 7.93 0 0 1 .8-4.34A7.81 7.81 0 0 1 4.38.81 6.25 6.25 0 0 1 8 0c2.42.31 3.89 2.34 4.6 3.32a13 13 0 0 1 2.26 6.18zm11.24 4.83c.36-1.43 1.6-2.3 3.83-3.58 3.21-1.84 4.68-2.33 7.16-3.58C42 4.7 43 3.26 44.25 3.85c.9.42.93 1.37 2 6.39 1.2 5.4 1.27 4.84 1.79 7.67.85 4.62.46 4.72 1.23 6.92.84 2.41 1.71 3.43 1.18 4.29s-1.76.51-5 .8a38.13 38.13 0 0 0-7.67 1.53A27.63 27.63 0 0 0 33.52 33c-2.8 1.31-3.76 2.44-4.6 2s-.68-1.94-.77-4.86c-.15-6.08-.28-9.34-1.54-12.74a4.93 4.93 0 0 1-.51-3.07zM70.58.81a2.69 2.69 0 0 0 .26.26 3.79 3.79 0 0 0 2.56.77s.92 0 23.77 7.92c5.38 19.54 8.08 27.3 9.32 29a.54.54 0 0 1 .14.44s-.38 1.23-26.07-3.83m40.88-24.6c.61-1.24 2.46-.3 6.65-1.53 1.68-.5 6.18-2.07 7.44-3.44a.41.41 0 0 1 .23-.14c.95-.16 2.31 4.18 3.32 6.9 0 0 .5 1.35 8.95 16.61a32.87 32.87 0 0 1-3.07 2c-1.57.86-2.55.85-4.35 1.28-5.15 1.24-6.41 3.62-8.18 2.81-1-.44-1-1.33-2-4.09-.08-.2-1.41-3-4.09-8.69-1.24-2.62-2.23-5.36-3.58-7.92-1.11-1.96-1.76-2.93-1.32-3.79zM50.79 29.08a43.93 43.93 0 0 1 6 .35 50.14 50.14 0 0 1 7.9 1.76c6.06 1.71 15.87 4.48 15.8 4.74a.19.19 0 0 1-.17 0c.18.48.42 1.19.67 2.06.34 1.2.51 2.1.56 2.32a127.43 127.43 0 0 0 4 14c2.62 7.17 5.07 10.45 3.86 11.59-.33.31-.83.36-3.16-.18-4.37-1-6.57-2.12-9.83-3.16-3-1-2.38-.42-7-1.76-5.35-1.54-4.88-1.88-7-2.11s-3.73 0-4.74-1.23a3.62 3.62 0 0 1-.7-2.11c-.29-2.65-1.47-5.13-2.11-7.72-1.26-5.15-1.24-4.6-1.93-7.55-.67-2.61-1.46-6.37-2.15-11z" />
            <path d="M106.08 38.89a1.48 1.48 0 0 1 .06-.19s3.48-.06 6.42-.33c4.73-.43 7.09-.55 10-1 0 0 1.54-.17 9.48-1.53.3.58.73 1.45 1.23 2.48.8 1.65 1.63 3.47 3.34 7.56 1.05 2.51.83 2.06 2.46 6 1.5 3.61 2.25 5.43 2.46 5.8 1.3 2.32 2.38 3.43 1.93 4.21-.31.54-1.09.48-2.81.7s-3.16.72-4.74 1.05c-4.47.93-5.09 1-7.72 1.58s-5.31.87-7.9 1.58-3.72 1.21-4.74.53c-.82-.56-.83-1.45-1.58-4-.44-1.51-.08 0-1.58-4.56-1.38-4.21-1.67-5.47-2.46-7.9-.61-1.89-1.57-4.71-3-8M35.46 59.74a27.58 27.58 0 0 1 5.09-1.93 32.39 32.39 0 0 1 9.45-.88c6.84 0 8 1.06 8.43 1.58s.63 1 1.23 3.16c4.18 14.74 1 3.07 2.11 7.37.14.56 0 0 3.16 10.88 0 0 1.4 4.83 1.93 8.07.22 1.37.11 1.64.43 3.2a1.42 1.42 0 0 1 .1 1c-.52.53-2.95-2.11-6.67-3.51a18.54 18.54 0 0 0-9.83-.88 26.66 26.66 0 0 1-3.86.53 5.18 5.18 0 0 1-2.63-.18 4.72 4.72 0 0 1-1.93-1.76c-1.2-1.87-3.61-11.06-6.14-23.7" />
            <path d="M95.2 65.82a55 55 0 0 0 7.37.88c2.14.11 3.79 0 6.14 0 0 0 1.43 0 6.85-.35.22 1.12.61 2.84 1.23 4.91.54 1.81.92 2.75 2.46 6.85 1.66 4.43 1.36 3.73 2.28 6.14 1.2 3.15 2.22 5.69 2.87 7.27a.47.47 0 0 1 .11.28c-.08.66-2.93.81-4 .88-3.23.2-6.43.77-9.65 1.05-6.56.57-12.64 1.4-12.64 1.4s-1.38-3.72-1.76-4.74c-1.19-3.27-1.11-3.87-2.81-9.83s-2.62-9.15-3-10c-.93-2.15-2.13-4.43-1.4-5.09.36-.32.95 0 2.63.18a21.65 21.65 0 0 0 3.32.17zm48.27-4.39l5.09-2.43c7.32-3.51 6.83-3.16 7.37-3.51 2-1.34 3.24-2.57 4.39-2.11a2.77 2.77 0 0 1 1.23 1.58c.89 1.76 2.07 3.35 3 5.09a59 59 0 0 0 3 5.27c2.32 3.55 4 4.89 3.51 6.49a4.09 4.09 0 0 1-1.05 1.4 11.61 11.61 0 0 1-3.51 2.46c-2.68 1.4-2 1.85-4.91 3.51-2 1.17-2.51 1-5.62 2.63a51.69 51.69 0 0 0-3.16 1.76" />
        </FinishFlag>
    );
}

export default memo(Flag);
