import { type JSX, memo } from "react";
import styled from "styled-components";
import GroupTaskIcon from "../../../shared/components/icons/GroupTaskIcon";
import t from "../../../shared/translations";
import type { IPathGroup, IPerson } from "../query";
import { Box } from "../stylings";

interface IProps {
    pathGroup: IPathGroup | null;
    finished: IPerson[];
    requireApproval: boolean;
}

const DEFAULT_SRC = "/static/images/profile-silouette.png";

export const PathGroupPreviewBox = styled(Box)`
    display: grid;
    grid-template-columns: repeat(auto-fill, 90px);
    justify-content: center;
    justify-items: center;
`;

export const PathGroupMemberBox = styled.div`
    display: grid;
    grid-template-rows: 60px auto;
    justify-items: center;
    text-align: center;
    font-size: 0.85rem;
`;

const H3 = styled.h3`
    display: grid;
    grid-template-columns: 26px 1fr;
    align-items: center;
    gap: 0.5rem;
    svg {
        width: 26px;
        height: 26px;
    }
`;

const H4 = styled.h4`
    grid-column: 1/-1;
    place-self: start;
`;

export const ProfilePic = styled.img`
    width: 60px;
    height: 60px;
    object-fit: cover;
`;

function PathGroupPreview({ pathGroup, finished, requireApproval }: IProps): JSX.Element {
    if (pathGroup == null) {
        return <PathGroupPreviewBox />;
    }
    const members = pathGroup.members.filter((pm) => !finished.some((p) => p.id === pm.person.id));
    return (
        <>
            <Box>
                <H3>
                    <GroupTaskIcon />
                    {t("player.task.heading-path-group-task")}
                </H3>
                <ul>
                    <li>{t("player.task.info-submit-path-group")}</li>
                    {requireApproval && <li>{t("player.task.info-submit-path-group-task-with-approval")}</li>}
                </ul>
            </Box>
            <PathGroupPreviewBox>
                <H4>{t("player.task.heading-path-group-members")}</H4>
                {members.map((pm) => (
                    <PathGroupMemberBox key={pm.id}>
                        <ProfilePic src={pm.person.profilePicSrc || DEFAULT_SRC} />
                        <span>
                            {pm.person.firstName} {pm.person.lastName}
                        </span>
                    </PathGroupMemberBox>
                ))}
            </PathGroupPreviewBox>
        </>
    );
}

export default memo(PathGroupPreview);
