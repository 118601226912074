import { memo, type JSX } from "react";
import type { ComponentPropsWithoutRef } from "react";

type IProps = ComponentPropsWithoutRef<"svg">;

function APIErrorIcon(props: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m89.8 87.1h-36.9c-.5 0-1-.3-1.3-.8s-.3-1 0-1.5l18.5-32c.3-.5.8-.8 1.3-.8s1 .3 1.3.8l18.5 32c.3.5.3 1 0 1.5s-.8.8-1.4.8z"
                fill="#181815"
            />
            <path d="m55.5 84.1h31.7l-15.8-27.5z" fill="#fff" />
            <path
                d="m71.4 77.1c-.8 0-1.5-.7-1.5-1.5v-11.9c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v11.9c0 .8-.7 1.5-1.5 1.5z"
                fill="#181815"
            />
            <path d="m71.4 81c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" fill="#181815" />
            <path
                d="m45.9 42.5c-17.8 0-31.8-6.4-31.8-14.5s14-14.5 31.8-14.5 31.8 6.4 31.8 14.5-14 14.5-31.8 14.5z"
                fill="#181815"
            />
            <path
                d="m45.9 16.6c-17.2 0-28.8 5.9-28.8 11.5 0 5.5 11.6 11.5 28.8 11.5s28.8-5.9 28.8-11.5-11.6-11.5-28.8-11.5z"
                fill="#fff"
            />
            <g fill="#181815">
                <path d="m45.9 55.2c-17.8 0-31.8-6.4-31.8-14.5 0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5c0 5.5 11.6 11.5 28.8 11.5s28.8-5.9 28.8-11.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5c0 8.2-14 14.5-31.8 14.5z" />
                <path d="m45.9 67.9c-17.8 0-31.8-6.4-31.8-14.5 0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5c0 5.5 11.6 11.5 28.8 11.5 7.2 0 14.1-1.1 19.4-3.1.8-.3 1.6.1 1.9.9s-.1 1.6-.9 1.9c-5.6 2.2-12.9 3.3-20.4 3.3z" />
                <path d="m45.9 80.7c-17.8 0-31.8-6.4-31.8-14.5 0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5c0 5.5 11.6 11.5 28.8 11.5 3.8 0 7.5-.3 11-.9.8-.1 1.6.4 1.7 1.2s-.4 1.6-1.2 1.7c-3.6.6-7.5 1-11.5 1z" />
                <path d="m15.5 67.7c-.8 0-1.5-.7-1.5-1.5v-38.3c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v38.3c0 .8-.6 1.5-1.5 1.5z" />
                <path d="m76.2 62.5c-.8 0-1.5-.7-1.5-1.5v-32.6c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v32.6c0 .9-.7 1.5-1.5 1.5z" />
            </g>
        </svg>
    );
}

export default memo(APIErrorIcon);
