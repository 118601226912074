import styled from "styled-components";
import * as colors from "../../shared/colors";

export const SectionHeading = styled.h2`
    font-size: 1.2rem;
`;

export const AccordionContainer = styled.div`
    border: 1px solid ${colors.primaryColor};
    border-radius: 5px;
    margin-bottom: 1px;
    padding: 3px 3px;
    @media (min-width: 650px) {
        padding: 5px 30px;
    }
`;

interface IAccordionHeader {
    $pointer: boolean;
}

export const AccordionHeader = styled.div<IAccordionHeader>`
    cursor: ${({ $pointer }) => ($pointer ? "pointer" : "auto")};
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    gap: 6px;
    padding: 4px 0;
    @media (min-width: 400px) {
        gap: 10px;

    }
    @media (min-width: 650px) {
        gap: 20px;
    }
`;

export const AccordionHeading = styled.h3`
    font-size: 1.1rem;
    margin: 0;
`;

export const AccordionBody = styled.div`
    margin-top: 1rem;
`;

export const Grid = styled.div`
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
    @container (min-width: 840px) {
        grid-template-columns: 1fr 1fr;
        gap: 2rem 20px;
    }
`;
