import { Form } from "react-final-form";
import styled from "styled-components";
import type { ValidationErrors } from "final-form";
import t from "../../shared/translations";
import Input from "../components/FinalInput";
import Textarea from "../components/FinalTextarea";
import type { JSX } from "react";

export interface IFormValues {
    title: string;
    answer: string;
}

export const defaultValues: IFormValues = {
    title: "",
    answer: "",
};

interface IProps {
    labels: {
        title: string;
        answer: string;
    };
    errors: {
        title: string;
        answer: string;
    };
    initialValues: IFormValues;
    onSubmit: (values: IFormValues) => Promise<void>;
}

const Center = styled.div`
    text-align: center;
`;

function validate(values: IFormValues, errMsg: { title: string; answer: string }): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.title) {
        errors.title = errMsg.title;
    }
    if (!values.answer) {
        errors.answer = errMsg.answer;
    }
    return errors;
}

function TaskForm({ initialValues, onSubmit, labels, errors }: IProps): JSX.Element {
    return (
        <Form onSubmit={onSubmit} validate={(values) => validate(values, errors)} initialValues={initialValues}>
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Input type="text" name="title" placeholder={labels.title} />
                    <Textarea name="answer" placeholder={labels.answer} />
                    <Center>
                        <button type="submit" disabled={submitting}>
                            {t("player.custom-task.form-submit")}
                        </button>
                    </Center>
                </form>
            )}
        </Form>
    );
}

export default TaskForm;
