import { type JSX, memo } from "react";
import styled from "styled-components";
import Modals from "./Modals";
import Month from "./Month";
import MonthHeader from "./MonthHeader";

const Wrapper = styled.div`
    margin-bottom: 1rem;
    container-type: inline-size;
`;

function MonthContainer(): JSX.Element {
    return (
        <Wrapper>
            <MonthHeader />
            <Month />
            <Modals />
        </Wrapper>
    );
}

export default memo(MonthContainer);
