import type { Dispatch, JSX } from "react";
import styled from "styled-components";
import { useMutation } from "urql";
import SharedAiInput from "../../../shared/components/ai/AiInput";
import t from "../../../shared/translations";
import { Button, Center } from "../stylings";
import { type IContinueArgs, type IContinueData, continueMutation } from "./queries";
import type { IAction, IState } from "./state";

interface IProps {
    state: IState;
    dispatch: Dispatch<IAction>;
    setHasTalkedAi: (to: boolean) => void;
}

const Col = styled.div`
    display: grid;
    gap: 1rem;
`;

function AiInput({ state, dispatch, setHasTalkedAi }: IProps): JSX.Element {
    const [startResult, executeMutation] = useMutation<IContinueData, IContinueArgs>(continueMutation);

    const continueChat = async (input: string) => {
        if (startResult.fetching) {
            return;
        }
        dispatch({ type: "TOGGLE-AI-LOADING", to: true });
        const result = await executeMutation({ threadId: state.threadId, input });
        if (result.error) {
            console.error(result.error);
            dispatch({ type: "RECORD-ERROR", error: t("player.error.error") });
        } else if (result.data.taskAiThreadContinue?.error) {
            console.error(result.data.taskAiThreadContinue.error);
            dispatch({ type: "RECORD-ERROR", error: result.data.taskAiThreadContinue.error });
        } else {
            const { aiThreadItems: messages, id: threadId } = result.data.taskAiThreadContinue.taskAiThread;
            dispatch({ type: "SET-CHAT", messages, threadId });
        }
    };

    const finishTalking = () => {
        setHasTalkedAi(true);
        dispatch({ type: "FINISH" });
    };

    const showFinish = state.messages.filter((msg) => msg.role === "assistant").length > 1;

    return (
        <Col>
            <SharedAiInput onSubmit={continueChat} loading={false} />
            <Center>
                {showFinish && <Button onClick={finishTalking}>{t("player.task.button-finish-ai")}</Button>}
            </Center>
            {state.error && <Center>{state.error}</Center>}
        </Col>
    );
}

export default AiInput;
