import { Transition, animated, to } from "@react-spring/web";
import maxBy from "lodash-es/maxBy";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client/dist/socket.io.js";
import { useImmer } from "use-immer";
import { type IPathGroup, type Message, getData } from "../../api/player/Chat";
import SpeechBubbleIcon from "../../shared/components/icons/taskIcons/SpeechBubbleIcon";
import { diffSeconds } from "../../shared/dateFns";
import { type Dispatch, type State, closeChat, openChat } from "../state";
import ChatWindow from "./ChatWindow";

interface IProps {
    pathGroupId: number;
}

const LAST_MESSAGE_KEY = "LAST_MESSAGE_KEY";

function calcOnline(datetime: Date): boolean {
    return diffSeconds(new Date(), datetime) < 15;
}

interface ChatState {
    typing: Record<number, boolean>;
    lastSeen: Record<number, Date>;
    history: Message[];
    pathGroup: IPathGroup | null;
    currentId: number | null;
    loading: boolean;
    newMessages: boolean;
}

function useSocketIoChat(pathGroupId: number, chatOpen: boolean) {
    const [state, setState] = useImmer<ChatState>({
        typing: {},
        lastSeen: {},
        history: [],
        pathGroup: null,
        currentId: null,
        loading: true,
        newMessages: false,
    });
    const chatOpenRef = useRef(chatOpen);
    chatOpenRef.current = chatOpen;
    const socketRef = useRef<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getData(pathGroupId);
            let newMessages = false;
            if (data.history.length > 0) {
                const lastSeen = localStorage.getItem(LAST_MESSAGE_KEY);
                const lastMessage = maxBy(data.history, (item) => item.datetime);
                newMessages = lastSeen != null && lastSeen < lastMessage.datetime;
            }
            setState((draft) => {
                draft.loading = false;
                draft.pathGroup = data.path_group;
                draft.currentId = data.current_id;
                draft.history = data.history;
                draft.newMessages = newMessages;
            });
        };

        fetchData();

        const socket = io("/path-group-chat");
        socketRef.current = socket;

        const sendJoinRoom = () => {
            socket.emit("join room", {
                path_group_id: pathGroupId,
            });
        };
        const receiveIsOnline = (data: { id: number }) => {
            setState((draft) => {
                draft.lastSeen[data.id] = new Date();
            });
        };
        const receiveGoOffline = (data: { id: number }) => {
            setState((draft) => {
                draft.lastSeen[data.id] = undefined;
            });
        };
        const receiveNewChatMessage = (data: Message) => {
            setState((draft) => {
                draft.typing[data.person] = false;
                draft.history.push(data);
                draft.history.sort((a, b) => a.datetime.localeCompare(b.datetime));
                draft.newMessages = !chatOpenRef.current;
                localStorage.setItem(LAST_MESSAGE_KEY, data.datetime);
            });
            receiveIsOnline({ id: data.person });
        };
        const receiveIsTyping = (data: { id: number; is_typing: boolean }) => {
            setState((draft) => {
                draft.typing[data.id] = data.is_typing;
            });
            receiveIsOnline({ id: data.id });
        };
        const sendIsOnline = () => {
            if (!socket.connected || !chatOpenRef.current) {
                return;
            }
            setState((draft) => {
                if (draft.history.length > 0) {
                    const lastMessage = maxBy(draft.history, (item) => item.datetime);
                    localStorage.setItem(LAST_MESSAGE_KEY, lastMessage.datetime);
                }
                draft.newMessages = false;
                socket.emit("is online", {
                    path_group_id: draft.pathGroup!.id,
                });
            });
        };

        socket.on("connect", sendJoinRoom);
        socket.on("reconnect", sendJoinRoom);
        socket.on("is online", receiveIsOnline);
        socket.on("go offline", receiveGoOffline);
        socket.on("new chat message", receiveNewChatMessage);
        socket.on("is typing", receiveIsTyping);
        const onlineIntervalTimer = window.setInterval(sendIsOnline, 10000);
        return () => {
            socket.disconnect();
            window.clearInterval(onlineIntervalTimer);
        };
    }, [pathGroupId]);

    const online = useMemo(() => {
        return Object.fromEntries(Object.entries(state.lastSeen).map(([key, value]) => [key, calcOnline(value)]));
    }, [state.lastSeen]);

    const sendChatMessage = (message: string) => {
        if (!socketRef.current?.connected) {
            return;
        }
        const { pathGroup } = state;
        const data = {
            message,
            path_group_id: pathGroup!.id,
        };
        socketRef.current.emit("send chat message", data);
    };
    const sendIsTyping = (isTyping: boolean) => {
        if (!socketRef.current?.connected) {
            return;
        }
        const { currentId, pathGroup } = state;
        const data = {
            id: currentId!,
            is_typing: isTyping,
            path_group_id: pathGroup!.id,
        };
        socketRef.current.emit("change typing", data);
    };
    const sendIsOnline = () => {
        if (!socketRef.current?.connected || !chatOpen) {
            return;
        }
        setState((draft) => {
            draft.newMessages = false;
        });
        socketRef.current.emit("is online", {
            path_group_id: state.pathGroup!.id,
        });
    };
    const sendGoOffline = () => {
        if (!socketRef.current?.connected) {
            return;
        }
        socketRef.current.emit("go offline", {
            path_group_id: state.pathGroup!.id,
        });
    };

    return {
        loading: state.loading,
        currentId: state.currentId,
        pathGroup: state.pathGroup,
        online,
        typing: state.typing,
        history: state.history,
        newMessages: state.newMessages,
        sendChatMessage,
        sendIsTyping,
        sendIsOnline,
        sendGoOffline,
    };
}

function Chat({ pathGroupId }: IProps) {
    const chatOpen = useSelector((state: State) => state.chat.open);
    const dispatch = useDispatch<Dispatch>();
    const {
        loading,
        currentId,
        pathGroup,
        online,
        typing,
        history,
        newMessages,
        sendChatMessage,
        sendIsTyping,
        sendIsOnline,
        sendGoOffline,
    } = useSocketIoChat(pathGroupId, chatOpen);
    return (
        <div>
            <Transition
                native
                items={chatOpen}
                from={{ scaleX: 0.25, scaleY: 0.12 }}
                enter={{ scaleX: 1.0, scaleY: 1.0 }}
                leave={{ scaleX: 0.25, scaleY: 0.12 }}
                config={{ tension: 1000, friction: 60 }}
            >
                {(style, open) =>
                    open ? (
                        <animated.div
                            id="chat"
                            style={{
                                transform: to([style.scaleX, style.scaleY], (sX, sY) => `scale(${sX}, ${sY})`),
                            }}
                        >
                            <ChatWindow
                                closeChat={() => dispatch(closeChat())}
                                loading={loading}
                                currentId={currentId}
                                pathGroup={pathGroup}
                                online={online}
                                typing={typing}
                                messages={history}
                                sendChatMessage={sendChatMessage}
                                sendIsTyping={sendIsTyping}
                                sendIsOnline={sendIsOnline}
                                sendGoOffline={sendGoOffline}
                            />
                        </animated.div>
                    ) : (
                        <button
                            className="chat-button"
                            onClick={() => dispatch(openChat())}
                            style={{ opacity: chatOpen ? 0 : 1 }}
                            type="button"
                        >
                            <SpeechBubbleIcon active invert alert={newMessages} />
                        </button>
                    )
                }
            </Transition>
        </div>
    );
}

export default Chat;
