import { memo, useCallback, useState, type JSX } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useMatch } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import TaskDescription from "../../shared/components/TaskDescription";
import t from "../../shared/translations";
import LockedModal, { useLockedModal } from "../LockedModal";
import ShowError from "../components/ShowError";
import PipNav from "../pip/PipNav";
import YourGroup from "../pip/YourGroup";
import type { State } from "../state";
import TaskChoiceNav from "../taskChoice/TaskChoiceNav";
import WithTasksSidebar from "../tasksSidebar";
import Start24Hours from "./Start24Hours";
import Thread from "./Thread";
import Undecided from "./Undecided";
import AiChat from "./ai/AiChat";
import AnswerSection from "./answerSection/AnswerSection";
import ApprovalSection from "./approval/ApprovalSection";
import CreateCollectFeedbackButton from "./collectFeedback/CreateButton";
import CollectFeedbackReporting from "./collectFeedback/Reporting";
import SetupCollectFeedback from "./collectFeedback/Setup";
import Pulse from "./pulse/Pulse";
import type { IArgs, IData } from "./query";
import { query } from "./query";
import TeamScan from "./teamScan/TeamScan";

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1440px;
    width: 100%;
`;

function Task(): JSX.Element {
    const {
        params: { id },
    } = useMatch({ path: "/task/:id/*" })!;
    const [result, refetch] = useQuery<IData, IArgs>({
        query,
        requestPolicy: "network-only",
        variables: { id },
    });
    if (result.fetching) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        );
    }
    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }
    return (
        <Wrapper>
            <Routes>
                <Route
                    path="/task/:id/:variantId/collect-feedback-setup"
                    element={<SetupCollectFeedback task={result.data.task} />}
                />
                <Route path="/task/:id/*" element={<TaskInner data={result.data} refetch={refetch} />} />
            </Routes>
        </Wrapper>
    );
}

function TaskInner({ data, refetch }: { data: IData; refetch: VoidFunction }): JSX.Element {
    const [hasTalkedAi, setHasTalkedAi] = useState(false);
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const editAnswer = useSelector((state: State) => state.task.editAnswer);
    const task = data.task;
    const submittedTask = task.submittedTask;
    const pathGroup = data.pathGroupForTask;
    const [selectedVariant, setSelectedVariant] = useState(task.taskVariants[0]);
    const shownTask = submittedTask ?? task;
    const shownVariant = submittedTask?.submittedTaskVariant ?? selectedVariant;
    const path = task.learningPathItem.learningPath;
    const taskGroup = task.taskGroup;

    // Only show task thread if there is a submitted task
    const showThread = !!submittedTask;

    // Only show the approve answer interface if:
    const showApproval =
        submittedTask?.status === "awaiting-approval" && // There is an answer awaiting approval
        pathGroup != null && // and the participant is in a group
        editAnswer === null; // and the participant is not editing the answer

    // Only show the undecided info if submitted task is undecided:
    const showUndecided = submittedTask?.status === "undecided"; // There is an answer awaiting learning coach decision

    // Only show TeamScan interface for team-scan tasks
    const showTeamScan = shownVariant.variantType === "team-scan";

    // Only show Pulse interface for pulse tasks
    const showPulse = shownVariant.variantType === "pulse";

    // Only show the CollectFeedback interface for collect-feedback tasks
    const showCollectFeedback = shownVariant.variantType === "collect-feedback";

    // Only show the CollectFeedbackReporting if there already is a created survey
    const showCollectFeedbackReporting = showCollectFeedback && !!submittedTask?.collectFeedback;

    // Show task choice nav if the task is part of Path in path
    const showTaskChoiceNav = task.learningPathItem.taskChoice !== null;

    // Only show AI for ai-chat types
    const showAiChat = shownVariant.variantType === "ai-chat";

    // Only show the AnswerSection if:
    const showAnswer =
        !showApproval && // Not currently showing the approve group task interface
        !showUndecided && // Not currently waiting for leaning coach to approve/reject answer
        !(
            showTeamScan && // and only if the TeamScan is reporting
            (submittedTask == null ||
                submittedTask.submittedTaskVariant.teamScan == null ||
                submittedTask.submittedTaskVariant.teamScan.status !== "reporting")
        ) &&
        !(
            showCollectFeedback && // and only is there is more than one collected insight
            (submittedTask?.collectFeedback == null ||
                submittedTask.collectFeedback.collectFeedbackAnswerSets.length === 0)
        ) &&
        !(
            showPulse && // and only there is more than one Pulse response
            (submittedTask == null ||
                submittedTask.submittedTaskVariant.pulse == null ||
                submittedTask.submittedTaskVariant.pulse.pulseAnswers.length === 0)
        ) &&
        !(
            showTaskChoiceNav && // and not if other task in same task choice has already been completed
            task.learningPathItem.tasks.some(
                (task) => task.submittedTask?.status === "accepted" || task.submittedTask?.status === "undecided",
            )
        ) &&
        (!showAiChat || hasTalkedAi); // Don't show answer if player should talk to AI and hasn't.

    // The participant is redoing a rejected task
    const redo = submittedTask != null && submittedTask.status === "rejected";

    // Show pip nav if the task is part of Path in path
    const showPipNav = task.learningPathItem.pathInPath != null;

    // Show pip group box if the pip has show group enabled and there is a pathGroup to show
    const showGroup = showPipNav && task.learningPathItem.pathInPath.showGroup && !!pathGroup;

    const status = redo ? "redo" : "deadline";

    let main = <div />;
    if (shownVariant.variantType === "unsubmittable") {
        main = null;
    } else if (shownVariant.variantType === "hours" && !submittedTask) {
        main = <Start24Hours task={task} variant={shownVariant} invert={showPipNav} />;
    } else if (task.doInGroup === "path_group" && !pathGroup) {
        main = <div>{t("player.task.error-path-group-required")}</div>;
    } else if (task.doInGroup === "task_group" && !taskGroup) {
        main = <div>{t("player.task.error-task-group-required")}</div>;
    } else {
        main = (
            <>
                {showPulse && <Pulse task={task} variant={shownVariant} refetch={refetch} />}
                {showTeamScan && <TeamScan task={task} variant={shownVariant} />}
                {showCollectFeedback &&
                    (showCollectFeedbackReporting ? (
                        <CollectFeedbackReporting task={task} invert={showPipNav} />
                    ) : (
                        <CreateCollectFeedbackButton task={task} invert={showPipNav} variant={shownVariant} />
                    ))}
                {showAiChat && (
                    <AiChat task={task} invert={showPipNav} variant={shownVariant} setHasTalkedAi={setHasTalkedAi} />
                )}
                {showThread && <Thread thread={task.submittedTask.thread} />}
                {showApproval && <ApprovalSection task={task.submittedTask} pathGroup={pathGroup} />}
                {showUndecided && <Undecided />}
                {showAnswer && (
                    <AnswerSection
                        task={task}
                        shownTask={shownTask}
                        redo={redo}
                        pathGroup={pathGroup}
                        taskGroup={taskGroup}
                        variant={shownVariant}
                        invert={showPipNav}
                    />
                )}
            </>
        );
    }

    const onVariantSelect = useCallback((variant) => {
        setSelectedVariant(variant);
    }, []);

    return (
        <div>
            <WithTasksSidebar
                openLockedModal={openLockedModal}
                path={path}
                activeItem={task.learningPathItem.id}
                mainLabel={t("player.task.tab-task")}
                big
            >
                <TaskDescription
                    shortTitle={shownTask.shortTitle}
                    objectives={shownTask.objectives}
                    inspiration={shownTask.inspiration}
                    title={shownTask.title}
                    timeEstimate={shownTask.timeEstimate}
                    icon={shownTask.icon}
                    miles={shownTask.miles}
                    status={status}
                    deadline={task.deadline}
                    media={shownTask.media}
                    taskVariants={submittedTask ? [] : task.taskVariants}
                    onVariantSelect={onVariantSelect}
                    submittedVariant={submittedTask?.submittedTaskVariant}
                    invert={showPipNav}
                >
                    {showPipNav && (
                        <PipNav
                            miles={task.learningPathItem.miles}
                            earnedMiles={task.learningPathItem.earnedMiles}
                            url={task.learningPathItem.pathInPath.url}
                            tasks={task.learningPathItem.tasks}
                            icon={task.learningPathItem.pathInPath.icon}
                            current={task.id}
                        />
                    )}
                    {showTaskChoiceNav && (
                        <TaskChoiceNav
                            taskChoice={task.learningPathItem.taskChoice}
                            tasks={task.learningPathItem.tasks}
                            current={task.id}
                        />
                    )}
                    {showGroup && <YourGroup members={pathGroup.members} />}
                </TaskDescription>
                <div key={selectedVariant.id}>{main}</div>
            </WithTasksSidebar>
            <LockedModal {...lockedProps} />
        </div>
    );
}

export default memo(Task);
