import { type JSX, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useMutation } from "urql";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import { type Dispatch, closeModal } from "../state";

const editAvatarMutation = `
    mutation($avatar:String!) {
        currentPersonEditAvatar(avatar:$avatar) {
            error
            currentPerson {
                __typename
            }
        }
    }
`;

interface IEditArgs {
    avatar: string;
}

const avatars = ["male-1", "female-1", "male-2", "female-2", "male-3", "female-3"];

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 90px);
    justify-content: space-around;
    gap: 10px 20px;
`;

const AvatarImg = styled.img`
    width: 90px;
    height: 200px;
    cursor: pointer; 
`;

function Avatar(): JSX.Element {
    const path = loadJSData().avatar_path;
    const dispatch = useDispatch<Dispatch>();
    const [apiError, setApiError] = useState("");
    const [result, executeMutation] = useMutation<any, IEditArgs>(editAvatarMutation);

    const select = async (avatar: string) => {
        if (result.fetching) {
            return;
        }
        setApiError("");
        const execResult = await executeMutation({ avatar });
        if (execResult.error) {
            setApiError(execResult.error.message);
        } else if (execResult.data.currentPersonEditAvatar.error) {
            setApiError(execResult.data.currentPersonEditAvatar.error);
        } else {
            dispatch(closeModal());
        }
    };

    return (
        <div>
            <h3>{t("player.profile.heading-change-avatar")}</h3>
            <Row>
                {avatars.map((avatar) => (
                    <div key={avatar}>
                        <AvatarImg
                            src={`${path}${avatar}-welcome.png`}
                            alt={`${avatar}-avatar`}
                            onClick={() => select(avatar)}
                        />
                    </div>
                ))}
            </Row>
            {!!apiError && <div className="error">{apiError}</div>}
        </div>
    );
}

export default Avatar;
