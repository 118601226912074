import { Route, Routes } from "react-router-dom";
import CustomTask from "./CustomTask";
import RejectedTask from "./RejectedTask";
import type { JSX } from "react";

function CustomTaskRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/custom-task/rejected/:taskId" element={<RejectedTask />} />
            <Route path="/custom-task/:kind" element={<CustomTask />} />
        </Routes>
    );
}

export default CustomTaskRoutes;
