import { useState } from "react";
import type { JSX } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "urql";
import Loader from "../../../shared/components/Loader";
import t from "../../../shared/translations";
import ShowError from "../../components/ShowError";
import SL from "./SL";
import TLB from "./TLB";
import TPS from "./TPS";

const query = `
    query {
        docList(ids: ["player.team-scan.theory-tps", "player.team-scan.theory-tlb", "player.team-scan.theory-sl"]) {
            id
            content
        }
    }
`;

interface IDoc {
    id: string;
    content: string;
}

interface IData {
    docList: IDoc[];
}

export type IAccordion = "TPS" | "TLB" | "SL" | "";

function Theory(): JSX.Element {
    const location = useLocation();
    const [accordion, setAccordion] = useState<IAccordion>(() =>
        location.hash === "#tps" ? "TPS" : location.hash === "#tlb" ? "TLB" : location.hash === "#sl" ? "SL" : "",
    );
    const [result] = useQuery<IData>({ query });

    const toggle = (acc: IAccordion) => {
        setAccordion((currAcc) => (currAcc === acc ? "" : acc));
    };

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    return (
        <>
            <h1>{t("player.team-scan-theory.heading")}</h1>
            <TPS
                open={accordion === "TPS"}
                toggle={toggle}
                content={result.data.docList.find((doc) => doc.id === "player.team-scan.theory-tps")!.content}
            />
            <TLB
                open={accordion === "TLB"}
                toggle={toggle}
                content={result.data.docList.find((doc) => doc.id === "player.team-scan.theory-tlb")!.content}
            />
            <SL
                open={accordion === "SL"}
                toggle={toggle}
                content={result.data.docList.find((doc) => doc.id === "player.team-scan.theory-sl")!.content}
            />
        </>
    );
}

export default Theory;
