import { useState, type JSX } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import t from "../../../shared/translations";
import Modal from "../../components/Modal";
import { type Dispatch, openModal } from "../../state";
import type { ICollectFeedbackAnswerSet } from "../query";
import { Button, ButtonAnchor, ButtonRow } from "../stylings";
import AllResponsesModal from "./AllResponsesModal";
import ResponseModal from "./ResponseModal";
import ResponseRow from "./ResponseRow";

interface IProps {
    collectFeedbackAnswerSets: ICollectFeedbackAnswerSet[];
    anonymous: boolean;
    invert: boolean;
    submittedTask: {
        collectFeedback: {
            id: string;
        };
    };
}

const Table = styled.table`
    border: none;

    thead {
        background-color: white;

        tr th {
            padding-bottom: 0;
        }
    }
`;

function ResponseTable({ collectFeedbackAnswerSets, anonymous, invert, submittedTask }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const [selectedResponse, selectResponse] = useState<ICollectFeedbackAnswerSet | null>(null);
    const downloadLink = `/pdf/download/collect-feedback-responses/${submittedTask.collectFeedback.id}/all-responses.pdf`;

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        {!anonymous && <th>{t("player.task.table-collect-feedback-heading-from")}</th>}
                        <th>{t("player.task.table-collect-feedback-heading-date")}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {collectFeedbackAnswerSets.map((tf) => (
                        <ResponseRow key={tf.id} answerSet={tf} selectResponse={selectResponse} anonymous={anonymous} />
                    ))}
                </tbody>
            </Table>
            <ButtonRow>
                <Button $invert={invert} onClick={() => dispatch(openModal("Task/CollectFeedback/AllResponses"))}>
                    {t("player.task.button-collect-feedback-all-responses")}
                </Button>
                <ButtonAnchor $invert={invert} target="_blank" href={downloadLink}>
                    {t("player.task.button-collect-feedback-download-responses")}
                </ButtonAnchor>
            </ButtonRow>
            <Modal modal={"Task/CollectFeedback/IndividualResponse"}>
                <ResponseModal answerSet={selectedResponse} anonymous={anonymous} />
            </Modal>
            <Modal modal={"Task/CollectFeedback/AllResponses"}>
                <h3>{t("player.task.heading-collect-feedback-all-responses-received")}</h3>
                <AllResponsesModal answerSets={collectFeedbackAnswerSets} />
            </Modal>
        </>
    );
}

export default ResponseTable;
