import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import ProfileNav from "../ProfileNav";
import Terms from "../teamScans/Terms";
import Landing from "./Landing";
import Results from "./Results";
import Setup from "./Setup";
import type { JSX } from "react";

const Main = styled.div`
    flex-grow: 1;
    padding: 0 10px;
    width: 100%;
    max-width: 1440px;
`;

function PulseRoutes(): JSX.Element {
    return (
        <Main>
            <Routes>
                <Route path="/pulse/profile/*" element={<ProfileNav />} />
                <Route path="/pulse/setup" element={<Setup />} />
                <Route path="/pulse/:id/results" element={<Results />} />
                <Route path="/pulse/terms" element={<Terms />} />
                <Route path="/pulse" element={<Landing />} />
            </Routes>
        </Main>
    );
}

export default PulseRoutes;
