import { useCallback, useState, type JSX } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../../shared/components/Loader";
import { Toast, useToast } from "../../../shared/components/Toast";
import t from "../../../shared/translations";
import type { TeamScanStatusType } from "../../../shared/types";
import Modal from "../../components/Modal";
import ShowError from "../../components/ShowError";
import { type Dispatch, openModal, setResendRecipients } from "../../state";
import { Center, PaddedLeft } from "../stylings";
import EditEmail from "./EditEmail";
import ProgressRow from "./ProgressRow";

const teamScanQuery = `
    query($id:String!) {
        teamScan(id:$id) {
            id
            status
            progress
            requiredFraction
            teamScanSurveys {
                id
                firstName
                lastName
                email
                datetime
                link
            }
        }
    }
`;

export interface ITeamScanSurvey {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    datetime: string;
    link: string;
}

export interface ITeamScan {
    id: string;
    status: TeamScanStatusType;
    progress: [number, number];
    requiredFraction: number;
    teamScanSurveys: ITeamScanSurvey[];
}

interface IData {
    teamScan: ITeamScan;
}

interface IArgs {
    id: string;
}

type IParams = "id";

const Table = styled.div`
    margin: 1rem auto;
`;

function Progress(): JSX.Element {
    const navigate = useNavigate();
    const { id } = useParams<IParams>();
    const [result] = useQuery<IData, IArgs>({
        query: teamScanQuery,
        variables: { id },
    });
    const dispatch = useDispatch<Dispatch>();
    const [showToast, showCopied] = useToast();
    const [editId, setEditId] = useState("");

    const onSubmit = useCallback(
        (values) => {
            if (!result.data) {
                return;
            }
            const ids = Object.entries(values)
                .filter(([_, sel]) => sel)
                .map(([key, _]) => key.slice(3));
            const resendRecipients = result.data.teamScan.teamScanSurveys.filter((tqs) => ids.includes(tqs.id));
            dispatch(
                setResendRecipients({
                    id,
                    resendRecipients,
                }),
            );
            navigate("resend");
        },
        [result, id, navigate],
    );

    const edit = useCallback((id: string) => {
        setEditId(id);
        dispatch(openModal("TeamScan/ProgressEditEmail"));
    }, []);

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const { teamScan } = result.data;
    switch (teamScan.status) {
        case "do-survey":
            return <Navigate to=".." />;
        case "setup-survey":
            return <Navigate to=".." />;
    }

    const [complete, total] = teamScan.progress;
    const requiredPercent = (teamScan.requiredFraction * 100).toFixed(0);
    const completePercent = ((complete / total) * 100).toFixed(0);
    return (
        <>
            <h1>{t("player.team-scan-progress.heading")}</h1>
            <PaddedLeft>
                <p>{t("player.team-scan-progress.info-this-page")}</p>
                <p>
                    {t("player.team-scan-progress.info-num-complete", { complete, total })}
                    <br />
                    {t("player.team-scan-progress.info-send-reminder")}
                    <br />
                    {t("player.team-scan-progress.info-copy-link")}
                </p>
                <p>
                    {t("player.team-scan-progress.info-required-percent", { requiredPercent })} <br />
                    {t("player.team-scan-progress.info-complete-percent", { completePercent })}
                    {teamScan.status === "reporting" && (
                        <>
                            <br />
                            <Link to={`../../report/${teamScan.id}`} relative="path">
                                {t("player.team-scan-progress.link-report")}
                            </Link>
                        </>
                    )}
                </p>
            </PaddedLeft>
            <Form onSubmit={onSubmit}>
                {({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <Table>
                            {teamScan.teamScanSurveys.map((tqs) => (
                                <ProgressRow key={tqs.id} teamScanSurvey={tqs} showCopied={showCopied} edit={edit} />
                            ))}
                        </Table>
                        <Center>
                            <button type="submit" disabled={pristine || submitting}>
                                {t("player.team-scan-progress.button-send-reminder")}
                            </button>
                        </Center>
                    </form>
                )}
            </Form>
            {showToast && <Toast>{t("player.team-scan-progress.info-link-copied")}</Toast>}
            <Modal modal="TeamScan/ProgressEditEmail">
                <EditEmail teamScanSurvey={teamScan.teamScanSurveys.find((tsq) => tsq.id === editId)} />
            </Modal>
        </>
    );
}

export default Progress;
