import { useImmer } from "use-immer";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import InfoButton from "../components/InfoButton";
import { BIG_MOBILE_CUTOFF, SMALL_MOBILE_CUTOFF, Tab, TabRow } from "./stylings";
import type { JSX } from "react";

export type ITab = "main" | "tasks" | "custom";

interface ITabState {
    mobileTab: ITab;
    desktopTab: ITab;
}

type SetTab = (tab: ITab) => void;

type IUseType = {
    state: ITabState;
    setTab: SetTab;
    showMain: boolean;
    showSidebar: boolean;
    sidebarTab: ITab;
};

export function useTabState(big: boolean, startTab?: ITab): IUseType {
    const { width } = useWindowSize();
    const [state, setState] = useImmer<ITabState>({ mobileTab: "main", desktopTab: startTab ?? "tasks" });
    const cutoff = big ? BIG_MOBILE_CUTOFF : SMALL_MOBILE_CUTOFF;

    const setTab = (tab: ITab) => {
        setState((draft) => {
            if (width < cutoff) {
                draft.mobileTab = tab;
            } else {
                draft.desktopTab = tab;
            }
        });
    };

    const showMain = state.mobileTab === "main" || width >= cutoff;
    const showSidebar = state.mobileTab === "tasks" || state.mobileTab === "custom" || width >= cutoff;
    const sidebarTab = width < cutoff ? state.mobileTab : state.desktopTab;

    return { state, setTab, showMain, showSidebar, sidebarTab };
}

interface IProps {
    state: ITabState;
    setTab: SetTab;
    mainLabel: string;
    big: boolean;
}

function Tabs({ state, setTab, mainLabel, big }: IProps): JSX.Element {
    const { width } = useWindowSize();
    const docPath = ["player/landing/tasks"];
    if (loadJSData().features.custom_tasks) {
        docPath.push("player/landing/tasks-custom");
    }
    const cutoff = big ? BIG_MOBILE_CUTOFF : SMALL_MOBILE_CUTOFF;
    const activeTab = width < cutoff ? state.mobileTab : state.desktopTab;
    return (
        <TabRow>
            {width < cutoff && (
                <Tab $active={activeTab === "main"} onClick={() => setTab("main")}>
                    {mainLabel}
                </Tab>
            )}
            <Tab $active={activeTab === "tasks"} onClick={() => setTab("tasks")}>
                {t("player.tasks-sidebar.tab-tasks")}
            </Tab>
            {loadJSData().features.custom_tasks && (
                <Tab $active={activeTab === "custom"} onClick={() => setTab("custom")}>
                    {t("player.tasks-sidebar.tab-custom")}
                </Tab>
            )}
            <InfoButton modal="TasksSidebar/Info" docPath={docPath} />
        </TabRow>
    );
}

export default Tabs;
