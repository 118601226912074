import type { ChangeEvent, JSX } from "react";
import { type UseFieldConfig, useField } from "react-final-form";
import styled from "styled-components";
import * as colors from "../../shared/colors";

interface IOwnProps {
    accept: string;
    label?: string;
    name: string;
    warning?: string;
    invert?: boolean;
}

type IProps = IOwnProps & UseFieldConfig<File>;

interface IFileButton {
    $invert: boolean;
}

const FileButton = styled.label<IFileButton>`
    border: 0;
    border-style: solid;
    cursor: pointer;
    font-family: "Proxima Nova",Verdana,sans-serif;
    position: relative;
    text-align: center;
    display: inline-block;
    line-height: normal;
    padding: 1rem 2rem 1.0625rem 2rem;
    font-size: 1rem;
    background-color: ${({ $invert }) => ($invert ? colors.altPrimaryColor : colors.primaryColor)};
    color: ${colors.altText};
    transition: background-color 300ms ease-out;
        &:hover, &:focus, &:active, &:visited {
        background-color: ${({ $invert }) => ($invert ? colors.altPrimaryColor : colors.primaryColor)};
        color: ${colors.altText};
    }
`;

const StyledInput = styled.input`
    position: absolute;
    display: none;
    visibility: hidden;
`;

const ErrorLabel = styled.small`
    display: block;
    font-size: .75rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 1rem;
    margin-top: -1px;
    padding: .375rem .5625rem .5625rem;
    background: #f04124;
    color: #fff;
`;

function FileInput({ name, label, accept, invert, ...props }: IProps): JSX.Element {
    const {
        input: { onChange, ...input },
        meta: { touched, error },
    } = useField(name, props);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.files[0]);
    };
    return (
        <div>
            <FileButton $invert={invert ?? false}>
                {label}
                <StyledInput
                    name={input.name}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                    type="file"
                    accept={accept}
                    onChange={handleChange}
                    value={undefined}
                />
            </FileButton>
            {touched && error && <ErrorLabel>{error}</ErrorLabel>}
        </div>
    );
}

export default FileInput;
