import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { Provider as ReduxProvider } from "react-redux";
import { openChat, store } from "../state";
import Chat from "./Chat";

export default function init() {
    const elem = document.getElementById("path-group-data");
    const id = JSON.parse(elem.innerHTML).path_group_id;

    document.querySelector("body").addEventListener("tm-open-chat", () => store.dispatch(openChat()));

    const container = document.getElementById("chat-holder");
    Modal.setAppElement(container);
    const root = createRoot(container);
    root.render(
        <ReduxProvider store={store}>
            <Chat pathGroupId={id} />
        </ReduxProvider>,
    );
}
