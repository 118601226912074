import type { ValidationErrors } from "final-form";
import type { Dispatch, JSX } from "react";
import { Form } from "react-final-form";
import t from "../../translations";
import RenderMark from "../RenderMark";
import Checkbox from "./Checkbox";
import type { IAction, IState } from "./state";
import { ButtonRow, TermsContainer } from "./stylings";

interface IFormValues {
    agreedToTerms: boolean;
}

interface IProps {
    state: IState;
    dispatch: Dispatch<IAction>;
    terms: string | null;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.agreedToTerms) {
        errors.agreedToTerms = t("shared.account-wizard.form-terms-error-confirm-terms-required");
    }
    return errors;
}

function TermsForm({ state, dispatch, terms }: IProps): JSX.Element {
    const initialValues: IFormValues = {
        agreedToTerms: state.values.agreedToTerms,
    };
    const onSubmit = (values: IFormValues) => {
        dispatch({ type: "NEXT-TERMS", values });
    };
    const onBack = () => {
        dispatch({ type: "BACK-TERMS" });
    };

    return (
        <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <p>{t("shared.account-wizard.form-terms-info")}</p>
                    <TermsContainer>
                        <RenderMark content={terms} />
                    </TermsContainer>
                    <Checkbox name="agreedToTerms" label={t("shared.account-wizard.form-terms-label-agree")} required />
                    <ButtonRow>
                        <button type="button" onClick={onBack}>
                            {t("shared.account-wizard.form-terms-button-back")}
                        </button>
                        <button type="submit">{t("shared.account-wizard.form-terms-button-next")}</button>
                    </ButtonRow>
                </form>
            )}
        </Form>
    );
}

export default TermsForm;
