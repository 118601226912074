import { type JSX, memo } from "react";
import styled from "styled-components";
import RenderMark from "../../../shared/components/RenderMark";
import RocketIcon from "../../../shared/components/icons/taskIcons/RocketIcon";
import t from "../../../shared/translations";
import type { IDoc } from "./Report";

interface IProps {
    ids: string[];
    allIds: string[];
    docList: IDoc[];
}

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-bottom: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
    > * > ul {
        margin-bottom: 0;
    }
    @media (min-width: 660px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const Ol = styled.ol`
    margin-bottom: 0.5rem;
    li {
        font-weight: bold;
    }
`;

const Doctainer = styled.div`
    > div > ul > li {
        margin-bottom: 0.5rem;
    }
    > div > ul > li:last-child {
        margin-bottom: 0;
    }
`;

const H5 = styled.h5`
    display: grid;
    place-items: center;
    svg {
        width: 90px;
        height: 90px;
    }
`;

const SHARED_POINTS = ["tlb1", "tlb4", "tlb5", "tlb6"];

function ActionPoints({ ids, allIds, docList }: IProps): JSX.Element {
    const sharedPointsToShow = ids.filter((id) => SHARED_POINTS.includes(id));
    const actionMap = {
        tlb1: (
            <Row key="tlb1">
                <div>
                    <Ol type="A">
                        {sharedPointsToShow.map((id) => (
                            <li value={allIds.indexOf(id) + 1} key={id}>
                                {t(`shared.team-scan-report-question.${id}`)}
                            </li>
                        ))}
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tlb1").content} />
                </Doctainer>
            </Row>
        ),
        tlb2: (
            <Row key="tlb2">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tlb2") + 1}>{t("shared.team-scan-report-question.tlb2")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tlb2").content} />
                </Doctainer>
            </Row>
        ),
        tlb3: (
            <Row key="tlb3">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tlb3") + 1}>{t("shared.team-scan-report-question.tlb3")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tlb3").content} />
                </Doctainer>
            </Row>
        ),
        tlb7: (
            <Row key="tlb7">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tlb7") + 1}>{t("shared.team-scan-report-question.tlb7")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tlb7").content} />
                </Doctainer>
            </Row>
        ),
        tlb8: (
            <Row key="tlb8">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tlb8") + 1}>{t("shared.team-scan-report-question.tlb8")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tlb8").content} />
                </Doctainer>
            </Row>
        ),
        tlb9: (
            <Row key="tlb9">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tlb9") + 1}>{t("shared.team-scan-report-question.tlb9")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tlb9").content} />
                </Doctainer>
            </Row>
        ),
        tps1: (
            <Row key="tps1">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tps1") + 1}>{t("shared.team-scan-report-question.tps1")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tps1").content} />
                </Doctainer>
            </Row>
        ),
        tps2: (
            <Row key="tps2">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tps2") + 1}>{t("shared.team-scan-report-question.tps2")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tps2").content} />
                </Doctainer>
            </Row>
        ),
        tps3: (
            <Row key="tps3">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tps3") + 1}>{t("shared.team-scan-report-question.tps3")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tps3").content} />
                </Doctainer>
            </Row>
        ),
        tps4: (
            <Row key="tps4">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tps4") + 1}>{t("shared.team-scan-report-question.tps4")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tps4").content} />
                </Doctainer>
            </Row>
        ),
        tps5: (
            <Row key="tps5">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tps5") + 1}>{t("shared.team-scan-report-question.tps5")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tps5").content} />
                </Doctainer>
            </Row>
        ),
        tps6: (
            <Row key="tps6">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tps6") + 1}>{t("shared.team-scan-report-question.tps6")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tps6").content} />
                </Doctainer>
            </Row>
        ),
        tps7: (
            <Row key="tps7">
                <div>
                    <Ol type="A">
                        <li value={allIds.indexOf("tps7") + 1}>{t("shared.team-scan-report-question.tps7")}</li>
                    </Ol>
                </div>
                <Doctainer>
                    <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-ap-tps7").content} />
                </Doctainer>
            </Row>
        ),
        tlb4: undefined,
        tlb5: undefined,
        tlb6: undefined,
    };
    actionMap.tlb4 = actionMap.tlb1;
    actionMap.tlb5 = actionMap.tlb1;
    actionMap.tlb6 = actionMap.tlb1;

    let shownSharedPoints = false;
    const points = [];
    for (const id of ids) {
        const elem = actionMap[id];
        if (!SHARED_POINTS.includes(id)) {
            points.push(elem);
            continue;
        }
        if (!shownSharedPoints) {
            points.push(elem);
            shownSharedPoints = true;
        }
    }
    return (
        <>
            <H5>
                <RocketIcon active />
                <span>{t("shared.team-scan-report.heading-action-points")}</span>
            </H5>
            {points}
        </>
    );
}

export default memo(ActionPoints);
