import { memo } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";

interface IProps {
    miles: number;
    wide?: boolean;
}

const Svg = styled.svg`
    max-width: 100%;
    width: 200px;
    height: auto;
    stroke: ${colors.text};
    text-anchor: middle;
    font-size: 0.5rem;
    font-variant: small-caps;

    circle {
        fill: transparent;
        stroke-width: 2.5px;
    }

    text {
        stroke: none;
    }
`;

const MilesBox = styled.rect`
    fill: ${colors.text};
`;

const MilesCounter = styled.text`
    fill: white;
    font-family: "Courier New", Courier, monospace;
    font-size: 1.1rem;
`;

function Odometer({ miles, wide }: IProps): JSX.Element {
    const milesStr = Math.floor(miles)
        .toString()
        .padStart(wide ? 6 : 5, "0");
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 102 102">
            <circle cx="50" cy="50" r="49" />
            <circle cx="50" cy="50" r="43" />
            {wide ? (
                <MilesBox x="15" y="38" width="70" height="25" rx="5" />
            ) : (
                <MilesBox x="18" y="38" width="64" height="25" rx="5" />
            )}
            <MilesCounter x="50" y="56">
                {milesStr}
            </MilesCounter>
            <text x="50" y="75">
                {t("player.performance.label-collected-miles")}
            </text>
        </Svg>
    );
}

export default memo(Odometer);
