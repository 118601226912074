import Linkify from "linkify-react";
import { type JSX, useState } from "react";
import styled from "styled-components";
import * as colors from "../../../shared/colors";
import TaskDescription from "../../../shared/components/TaskDescription";
import t from "../../../shared/translations";
import { useCurrentPerson } from "../../components/CurrentPerson";
import ProfileImg from "../../components/ProfileImg";
import TaskThreadItemAnswer from "../../components/TaskThreadItemAnswer";
import type { ISubmittedTask, ITask, ITaskVariant } from "../query";
import { Box, Button, NavButton, NavButtonRow, PrevArrow } from "../stylings";
import type { IFormValues as ITaskFormValues } from "./AnswerForm";
import type { IFormValues as IFeedbackFormValues } from "./OutsideFeedbackForm";
import OutsideFeedbackInfo from "./OutsideFeedbackInfo";

interface IProps {
    taskValues: ITaskFormValues[];
    feedbackValue: IFeedbackFormValues;
    shownTask: ISubmittedTask | ITask;
    variant: ITaskVariant;
    back: VoidFunction;
    submit: () => Promise<void>;
    invert: boolean;
}

const ProfileBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 45px;
    gap: 0.5rem;
    border-radius: 1rem;
    padding: 0.6rem;
    border: 1px solid ${colors.primaryColor};
    margin-bottom: 0.5rem;
    align-items: center;

    @media (min-width: 600px) {
        grid-template-columns: 1fr 60px;
        padding: 1rem;
        margin-bottom: 1rem;
        gap: 1rem;
    }

    > *:first-child {
        font-size: 1.25rem;
        @media (min-width: 600px) {
            font-size: 1.5rem;
        }
    }
`;

function OutsideFeedbackPreview({
    taskValues,
    feedbackValue,
    shownTask,
    variant,
    back,
    invert,
    submit,
}: IProps): JSX.Element {
    const [submitting, setSubmitting] = useState(false);
    const current = useCurrentPerson();
    const name = `${current.firstName} ${current.lastName}`;
    const taskAnswers = taskValues.map((answer, index) => ({
        id: index.toString(),
        answerType: answer.answerType,
        questionContent: answer.questionContent,
        answerContent: answer.answerContent,
        checkboxAnswers: answer.checkboxAnswers.map((ans, idx) => ({
            id: idx.toString(),
            content: ans.content,
            checked: ans.checked,
        })),
        taskLikertAnswers: answer.taskLikertAnswers.map((ans, idx) => ({
            id: idx.toString(),
            statement: ans.statement,
            leftLabel: ans.leftLabel,
            rightLabel: ans.rightLabel,
            value: ans.value,
        })),
        quizAnswerSet: null,
        pollAnswerSet: null,
        showPollResults: true,
        taskAnswerFile:
            answer.answerType === "file"
                ? {
                      id: index.toString(),
                      src: URL.createObjectURL(answer.file),
                      logoSrc: null,
                      name: answer.file.name,
                  }
                : null,
        impactTrackerAnswerSet:
            answer.answerType === "file"
                ? {
                      id: index.toString(),
                      impactLikertAnswers: answer.taskLikertAnswers.map((ans, idx) => ({
                          id: idx.toString(),
                          statement: ans.statement,
                          leftLabel: ans.leftLabel,
                          rightLabel: ans.rightLabel,
                          value: ans.value,
                      })),
                  }
                : null,
    }));

    const onSubmit = async () => {
        setSubmitting(true);
        await submit();
        setSubmitting(false);
    };

    return (
        <>
            <OutsideFeedbackInfo redo={false} />
            <Box>
                <h3>{t("player.task.heading-outside-feedback-preview")}</h3>
                <ProfileBox>
                    <div>{t("player.task.info-outside-feedback-preview-sender", { name })}</div>
                    <div>
                        <ProfileImg src={current.profilePicSrc} />
                    </div>
                </ProfileBox>
                <Box>
                    <Linkify options={{ nl2br: true }} tagName="p">
                        {feedbackValue.outsideFeedbackDescription}
                    </Linkify>
                </Box>
                <TaskDescription
                    shortTitle={shownTask.shortTitle}
                    title={shownTask.title}
                    description={variant.description}
                    icon={shownTask.icon}
                    media={shownTask.media}
                    objectives={null}
                    inspiration={null}
                    miles={null}
                    invert={false}
                    taskVariants={[]}
                />
                <TaskThreadItemAnswer
                    tti={{
                        id: "",
                        type: "answer",
                        person: current,
                        taskAnswers,
                    }}
                />
            </Box>
            <NavButtonRow>
                <NavButton $prev={true} type="button" $invert={invert} onClick={back}>
                    {PrevArrow} {t("player.task.button-back")}
                </NavButton>
                <Button type="submit" disabled={submitting} $invert={invert} onClick={onSubmit} $noMargin>
                    {t("player.task.button-submit")}
                </Button>
            </NavButtonRow>
        </>
    );
}

export default OutsideFeedbackPreview;
