import { animated, useSpring } from "@react-spring/web";
import { produce } from "immer";
import { type JSX, memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Doc from "../../../shared/components/Doc";
import InfoIcon from "../../../shared/components/icons/InfoIcon";
import t from "../../../shared/translations";
import Modal from "../../components/Modal";
import { type Dispatch, openModal } from "../../state";
import { Button } from "../stylings";
import type { IFormValues } from "./AnswerForm";

interface IProps {
    allValues: IFormValues[];
    currValue: IFormValues;
    index: number;
    invert: boolean;
    saveIncomplete: (values: IFormValues[]) => Promise<void>;
}

function TextfieldSave({ allValues, currValue, index, invert, saveIncomplete }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const [savingAnswers, setSavingAnswers] = useState(false);
    const [saved, setSaved] = useState(false);

    const props = useSpring({ opacity: saved ? 1 : 0, config: { friction: 160, tension: 120 }, reset: saved });

    const showDocs = () => {
        dispatch(openModal("Task/Info/MultiQuestion"));
    };
    const save = async (): Promise<void> => {
        setSavingAnswers(true);
        setSaved(false);
        const values = produce(allValues, (draft) => {
            draft[index] = currValue;
        });
        try {
            await saveIncomplete(values);
        } catch (err) {
            console.error(err);
            return;
        } finally {
            setSavingAnswers(false);
        }
        setSaved(true);
    };
    const handleClick = (event: React.FormEvent<HTMLButtonElement>): boolean => {
        event.preventDefault();
        void save();
        return false;
    };

    useEffect(() => {
        if (!saved) {
            return;
        }
        const timer = setTimeout(() => {
            setSaved(false);
        }, 4000);
        return () => clearTimeout(timer);
    }, [saved]);

    return (
        <div className="save-task-container">
            <InfoIcon className="info-icon" onClick={showDocs} />
            <Button
                className="save-answer"
                type="button"
                onClick={handleClick}
                disabled={savingAnswers}
                $invert={invert}
            >
                {t("player.task.button-save")}
            </Button>
            <animated.span className="fader" style={props}>
                {t("player.task.info-saved")}
            </animated.span>
            <Modal modal={"Task/Info/MultiQuestion"}>
                <Doc path={"player/task/multi-question"} />
            </Modal>
        </div>
    );
}

export default memo(TextfieldSave);
