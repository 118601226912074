import Slider from "rc-slider";
import type { JSX } from "react";
import { type UseFieldConfig, useField } from "react-final-form";
import styled from "styled-components";
import * as colors from "../colors";
import FatArrow from "./icons/FatArrow";

interface IOwnProps {
    name: string;
    statement?: string;
    leftLabel: string;
    rightLabel: string;
    showValue?: boolean;
    min?: number;
    max?: number;
}

type IProps = IOwnProps & UseFieldConfig<number>;

export const Container = styled.div`
    container-type: inline-size;
`;

const ErrorLabel = styled.small`
    color: ${colors.white};
    background-color: ${colors.alert};
    padding: 0.5rem 1rem;
    border-radius: 8px;
    display: block;
    font-size: .75rem;
    font-style: italic;
`;

interface ISliderArrow {
    dir: "left" | "right";
    $pointer?: boolean;
}

export const SliderArrow = styled(FatArrow)<ISliderArrow>`
    cursor: ${({ $pointer }) => ($pointer ? "pointer" : "auto")};
    position: absolute;
    top: -3px;
    left: ${({ dir }) => (dir === "left" ? "-13px" : "auto")};
    right: ${({ dir }) => (dir === "left" ? "auto" : "-13px")};
    width: 30px;
    height: 30px;
    path {
        stroke: ${colors.primaryColor};
    }
`;

interface ISliderLabel {
    $right?: boolean;
}

export const SliderLabel = styled.label<ISliderLabel>`
    font-size: 1rem;
    user-select: none;
    grid-area: ${({ $right }) => ($right ? "rightLabel" : "leftLabel")};
    text-align: ${({ $right }) => ($right ? "right" : "left")};
    cursor: auto;
    @container (min-width: 560px) {
        text-align: ${({ $right }) => ($right ? "left" : "right")};
    }
`;

export const Statement = styled.div`
    font-size: 1.05rem;
    text-align: center;
    grid-area: statement;
    @container (min-width: 560px) {
        grid-template-columns: 1fr 400px 1fr;
        grid-template-areas: 
            "statement statement statement"
            "leftLabel slider rightLabel";
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.6rem 1rem;
    margin: 1rem auto 0 auto;
    max-width: 460px;
    grid-template-areas: 
        "statement statement"
        "slider slider"
        "leftLabel rightLabel";

    @container (min-width: 560px) {
        max-width: 100%;
        grid-template-columns: 1fr 300px 1fr;
        grid-template-areas: 
            "statement statement statement"
            "leftLabel slider rightLabel";
    }
    @container (min-width: 640px) {
        grid-template-columns: 1fr 400px 1fr;
    }

    .rc-slider-track {
        background-color: ${colors.primaryColor};
    }
    .rc-slider-handle {
        border-color: ${colors.primaryColor};
        background-color: ${colors.primaryColor};
        opacity: 1;
        width: 28px;
        height: 28px;
        margin-top: -12px;
        color: white;
        text-align: center;
        user-select: none;
    }
    .rc-slider {
        padding: 10px 0;
    }
`;

const SliderBox = styled.div`
    position: relative;
    grid-area: slider;
    height: 30px;
`;

function Handle({ showValue, value, ...props }): JSX.Element {
    return <div {...props}>{showValue && value}</div>;
}

function SliderInput({
    name,
    statement,
    leftLabel,
    rightLabel,
    showValue,
    min = 0,
    max = 10,
    ...props
}: IProps): JSX.Element {
    const { input, meta } = useField(name, props);
    const decrease = () => {
        if (input.value === 0) {
            return;
        }
        input.onChange(input.value - 1);
    };
    const increase = () => {
        if (input.value === 10) {
            return;
        }
        input.onChange(input.value + 1);
    };
    return (
        <Container>
            <Grid>
                {statement && <Statement>{statement}</Statement>}
                <SliderBox>
                    <Slider
                        min={min}
                        max={max}
                        value={input.value}
                        onChange={input.onChange}
                        handleRender={({ props }) => <Handle value={input.value} showValue={showValue} {...props} />}
                    />
                    <SliderArrow dir="left" onClick={decrease} $pointer />
                    <SliderArrow dir="right" onClick={increase} $pointer />
                </SliderBox>
                <SliderLabel>{leftLabel}</SliderLabel>
                <SliderLabel $right>{rightLabel}</SliderLabel>
            </Grid>
            {meta?.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
        </Container>
    );
}

export default SliderInput;
