import { type ComponentPropsWithoutRef, memo, type JSX } from "react";

type IProps = ComponentPropsWithoutRef<"svg">;

const Y_SCALE = 1.0;

export const ASPECT_RATIO = ((Y_SCALE * 707) / 1144) * 100;

function FlatStreetview(props: IProps): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox={`0 0 1144 ${Y_SCALE * 707}`}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>Learning path flat street view</title>
            <linearGradient
                id="flat-street-gradient-a"
                gradientTransform="matrix(0 226.437 -411.174 0 600.428 435.566)"
                gradientUnits="userSpaceOnUse"
                x1="0"
                x2="1"
                y1="0"
                y2="0"
            >
                <stop offset="0" stopColor="#fff" stopOpacity=".5" />
                <stop offset="1" stopColor="#614a2f" stopOpacity=".5" />
            </linearGradient>
            <path d="m0 0h1143.69v706.861h-1143.69z" fill="none" />
            <path
                d="m-61.326 437.37c-.149 40.543-.76 201.215.084 225.355l1320.252-.007c.07-42.29.25-185.385.02-227.152z"
                fill="url(#flat-street-gradient-a)"
            />
            <path d="m-55.748 639.814h1312.64v28.008h-1312.64z" fill="#d6d0c8" />
            <path
                d="m.738 516.523c-1.782.014.024 6.746 2.451 6.864 1.753.085 12.976.161 15.594.23 35.637.933 144.669.383 144.669.383.358.099 10.318.088 10.318.088v3.433c0 .453.304.849.741.967l175.539 5.825c2.068.555 4.212.774 6.35.65l12.538.46 33.323 1.412c0 1.385 33.511 1.678 34.857 2.005l60.972 1.177c7.663 8.896 5.472 8.868 28.323 10.78 3.851.932 7.799 1.403 11.761 1.403l46.587 1.419c6.58 0 14.004-.878 20.352-2.609l36.184-8.635c1.305-.356 1.555-3.07 1.555-4.424l26.472 2.016 12.405 5.499 43.243-6.17c4.054.372 3.171-10.762 7.135-11.687l21.591 1.351 73.108-.632 317.194-6.218v-15.753s-954.938 8.724-1143.262 10.166z"
                fill="#666"
            />
            <path
                d="m1.504 518.218c-2.989-9.527-.477-313.511.772-330.711l108.418-4.584 2.372-56.47c0-.966.35-1.9.985-2.628l12.719-14.59c.691-.792 1.747-1.166 2.782-.983l16.784 2.952-.373 68.912 36.917-.962c-5.094 55.043-4.145 295.356-1.372 338.523.071 1.102-.765 2.053-1.868 2.124-.2.013-.402-.004-.598-.052l-176.124-.262c-.654-.158-1.212-.626-1.414-1.269z"
                fill="#e06f6f"
            />
            <path
                d="m126.755 110.585c-.017.164-1.669 47.124-1.669 47.124l-85.792 6.513c-2.236 1.987-31.201 15.865-38.302 24.154-2.471 2.886.614 6.903.614 6.903s100.813.155 164.26-.7c-1.451 25.38-1.538 310.926-2.214 323.832-.057 1.103.791 2.045 1.894 2.103h.014l61.442 1.596-2.498-346.699-23.736-13.45c-1.099-1.285-3.689-.804-4.325-.718-.087.011-50.09-1.839-50.09-1.839l.774-46.482c0-2.057-1.882-3.748-3.928-3.951l-11.561-.707c-2.749-.274-4.61-.428-4.883 2.321z"
                fill="#956c6c"
            />
            <path
                d="m82.334 248.103c.748 10.203.697 19.299-.155 27.286v19.343c0 1.204-1.449 2.231-3.426 2.427l-40.411-.098c.344-8.486-.633-42.356.09-48.835l40.845-1.136c.981-.338 2.229-.134 2.789.456.162.17.254.361.268.557z"
                fill="#956c6c"
            />
            <path
                d="m138.922 347.33c1.019 5.972.95 11.295-.211 15.97v11.321c0 .704-1.975 1.306-4.667 1.42l-55.059-.057c.468-4.967-.863-24.79.122-28.582l55.649-.665c1.337-.198 3.038-.078 3.801.267.22.1.346.211.365.326z"
                fill="#956c6c"
            />
            <path
                d="m465.355 530.009-112.24-.638-174.533-4.364c-1.217-37.958-2.172-117.234-1.564-141.507.609-24.274-1.744-168.637 2.054-203.135l300.274-3.305z"
                fill="#7dc6e8"
            />
            <path
                d="m351.997 174.775c2.67 61.092 1.158 113.535 1.158 162.212s1.308 119.505 3.23 192.439l104.535 1.018-17.569-366.358z"
                fill="#649ec1"
            />
            <path
                d="m356.619 172.419-3.609-1.882c-1.118 94.313-1.809 133.349-1.809 157.628s1.539 87.983 5.951 187.912c-1.298-99.675-2.746-164.139-2.746-194.456 0-30.318-.151-69.389 2.213-149.202z"
                fill="#fff"
            />
            <path
                d="m362.01 290.615c-.245 4.598-.368 10.082-.368 16.452 0 6.814.289 15.838.867 27.073.082 1.596 1.4 2.847 2.997 2.847l22.292-.791c1.105 0 2.001-.896 2.001-2.001 0-6.855.512-20.876.512-27.732v-20.789c0-1.017-.764-1.872-1.776-1.987l-24.302 5.046c-1.098-.124-2.089.665-2.213 1.763-.005.04-.008.079-.01.119z"
                fill="#6690aa"
            />
            <path
                d="m317.032 249.788c-1.91 14.133-2.181 44.067-2.181 44.067-.101 1.533-1.159 2.832-2.639 3.241l-33.99-1.912c.353-7.458 1.461-37.538 2.474-46.081l34.33-.363c.489-.448 1.251-.417 1.699.073.242.263.355.62.307.975z"
                fill="#6c9cb2"
            />
            <path
                d="m226.265 328.795c.504 11.436.469 21.63-.105 30.582v21.679c0 1.349-.977 2.501-2.31 2.72l-27.246-.11c.232-9.51-.427-47.471.061-54.733l27.538-1.273c.662-.379 1.503-.151 1.881.511.109.19.171.404.181.624z"
                fill="#6c9cb2"
            />
            <path
                d="m294.509 437.484c.916 15.495 1.917 46.059 2.576 55.093.696 9.528.614 22.03 2.316 35.498l-41.985-1.15c-.734-10.489-.796-28.332-1.252-38.601-.422-9.52-.627-36.276-.615-50.072.001-1.656 1.346-2.998 3.003-2.997.095 0 .19.005.285.014l32.963-.595c1.472.141 2.622 1.334 2.709 2.81z"
                fill="#6c9cb2"
            />
            <path
                d="m353.33 185.372-172.894-.863c-.893.651-2.145.455-2.796-.438-.249-.342-.384-.754-.384-1.178v-2.327c0-1.583.75-3.073 2.022-4.016l53.865-26.013c1.132-.839 206.561.967 206.561.967 1.084-.187 69.904 10.498 69.904 10.498z"
                fill="#6690aa"
            />
            <path
                d="m761.259 524.58-49.54.467.134-375.373 63.421-99.526 27.509 84.358 108.284 2.547 44.156-67.006 29.593 66.489 85.554 7.047 29.56-49.684-.07 209.5c-177.616-51.649-328.916-4.076-328.638-2.427 6.678 39.602 3.394 114.211-9.963 223.608z"
                fill="#d8a840"
            />
            <path
                d="m734.838 523.348 26.391 1.296 382.771-7.538c-.05-20.199-4.05-357.277-4.05-357.277-.32-1.058-34.28-65.754-39.65-66.321-1.12-.119-4.67 7.516-4.88 8.114l-23.37 52.426-88.877-11.331-23.863-67.24c-.488-1.583-2.517-5.849-4.129-5.839-1.704.011-4.309 4.395-4.586 5.374l-37.794 68.998-112.503-2.547-17.402-72.027c-.528-2.71-4.196-20.099-6.907-19.571-1.729.337-3.153 1.56-3.75 3.217l-39.831 75.565c5.488 62.487 2.43 356.543 2.43 394.701z"
                fill="#f4c359"
            />
            <path
                d="m921.09 365.529c0 25.236-2.123 98.423-1.017 155.256l-8.892.294c2.203-62.116 2.195-131.609 2.195-155.161 0-23.55-4.752-167.443-6.146-216.387l-102.751-2.963c6.214 65.754 1.734 275.618-1.617 368.055 1.983-95.563 3.895-294.892-2.535-373.202l112.46 2.446c2.166 49.442 8.303 196.426 8.303 221.662z"
                fill="#ca9d3b"
            />
            <path
                d="m983.208 142.579 88.942 11.16c.81 13.02 5.71 317.869 2.67 356.663 1.63-50.963-4.76-341.155-6.12-350.179l-83.031-9.982c1.327 30.293.159 263.875.159 276.497 0 12.62-.631 42.296-3.598 83.056 1.641-36.208 1.609-65.884 1.609-83.056 0-17.174 1.01-253.311-.631-284.159z"
                fill="#ca9d3b"
            />
            <path
                d="m731.943 130.582 2.422.659c5.791 77.16 5.242 166.816 5.242 191.095 0 24.28-.955 57.652-.955 179.321-2.852-112.564-2.29-297.247-6.709-371.075z"
                fill="#fff"
            />
            <g fill="#ca9d3b">
                <path d="m860.93 431.238c-.261 15.705-1.799 46.796-1.783 55.172.017 9.009-1.26 20.756-1.942 35.928l42.063-1.071c.656-10.57.421-27.108 1.261-38.308.795-10.603.267-42.304.465-53.504.018-1.064-.799-1.956-1.86-2.031l-34.993.87c-1.652-.116-3.088 1.129-3.204 2.783-.004.053-.006.107-.007.161z" />
                <path d="m900.64 260.021.143-31.247c0-3.74-2.604-4.487-6.168-5.619-6.816-2.164-22.96 1.3-22.96 4.441v33.194c0 1.027.612 1.954 1.555 2.359l23.852-.769c1.302.558 2.811-.045 3.37-1.348.137-.319.208-.663.208-1.011z" />
                <path d="m1021.62 459.452.11-23.849c0-2.854-1.99-3.424-4.71-4.288-5.2-1.652-17.527.992-17.527 3.389v25.335c0 .784.467 1.492 1.187 1.8l18.2-.586c1 .426 2.15-.035 2.58-1.029.1-.244.16-.506.16-.772z" />
                <path d="m1059.95 330.342.1-27.9c-3.11-1.155-20.34-4.332-20.62-1.052-.85 9.822 0 19.76 0 29.639 0 .916.43 1.745 1.1 2.106l16.89-.687c.92.499 1.99-.04 2.38-1.203.1-.285.15-.592.15-.903z" />
                <path d="m860.285 307.717c-1.132 0-18.069-1.659-31.205-.969-1.446.076-2.858.292-3.312 1.666-.088.267-.132.545-.132.824v36.946c0 1.365 1.046 2.502 2.406 2.615l29.686-1.283c1.444.12 2.71-.953 2.831-2.396.001-.029.004-.059.005-.088 1.058-21.537.87-37.315-.279-37.315z" />
            </g>
            <path
                d="m499.087 78.54 38.303-31.111c1.804-1.524 4.09-2.36 6.451-2.36h57.305c2.481 0 4.872.921 6.712 2.586l33.057 29.702c3.211 2.904 4.358 9.25 2.591 13.203l-8.012 30.816 25.398 7.619 24.827-3.517c2.257 0 6.632-.657 8.399.746l14.84 12.899c1.675 1.329 2.24 2.733 2.24 4.871l5.033 1.101c1.122.74 6.157 5.076 6.157 6.421l4.163 12.778-276.12 32.232-30.685-4.352-19.443-4.132.293-29.27c0-2.743.967-7.697 3.283-9.163l16.784-10.976c1.744-1.104 3.848-1.492 5.873-1.081l10.011 6.168 67.342-11.294-.119-36.361-7.701-5.51c-1.04-3.764.034-9.495 3.018-12.015z"
                fill="#8aa090"
            />
            <path
                d="m412.4 156.767 7.602-4.805c2.637-1.667 5.899-2.009 8.823-.924l5.186 1.923 70.672-11.265.258-48.041c0-3.182 1.377-6.208 3.777-8.297l28.744-25.03c2.185-1.903 4.985-2.951 7.883-2.951h50.836c3.021 0 5.931 1.14 8.15 3.19l28.931 26.754c2.287 2.116 3.571 5.102 3.532 8.216l-.538 37.682 25.133 6.131 26.765-5.296c1.721 0 3.396.555 4.777 1.583l11.261 8.382c2.028 1.51 3.224 3.89 3.224 6.418l3.668.42 5.3 3.371c1.619 1.03 2.647 2.77 2.769 4.684 2.158 33.899-1.088 94.961.67 163.055 1.812 70.167 4.809 207.834 3.003 209.176-1.996 1.481-40.506 4.706-40.506 4.706l-11.969-4.862-30.917-1.849-.66 5.21-38.323 7.11c-1.401.305-2.83.459-4.264.459h-57.054c-.545 0-1.089-.044-1.626-.133l-29.394-4.845c-1.45-.238-2.513-1.491-2.513-2.96v-2.038l-57.8-.222h-4.871c-.24 0-.478-.029-.711-.086l-36.164-1.133c-1.354-.33-2.304-1.551-2.29-2.944.752-77.808 1.661-142.305 2.726-193.49 1.041-50.024.492-129.156 2.19-170.86.107-2.627 1.497-5.034 3.72-6.439z"
                fill="#c6dac3"
            />
            <path d="m637.046 144.948 2.418 384.205 30.923 1.838-10.195-380.673z" fill="#adc2aa" />
            <path d="m505.2 155.045.416 377.368-68.485-1.257 2.269-364.474z" fill="#adc2aa" />
            <path
                d="m603.915 66.267 5.624 5.451c2.255 139.045 2.027 192.15 2.027 224.718s.456 107.276-1.132 231.851c-2.411-147.152-3.616-232.902-3.616-264.144s-.898-75.122-2.903-197.876z"
                fill="#fff"
            />
            <path
                d="m689.127 143.888 3.522 2.579c1.793 103.675.931 148.128.931 172.171v170.942c-2.001-106.375-3.001-171.094-3.001-194.158s.689-60.637-1.452-151.534z"
                fill="#fff"
            />
            <path
                d="m427.51 158.895-2.352-1.707c-2.001 97.608-1.651 189.336-1.651 211.482v157.459c2.001-97.984 3.001-157.599 3.001-178.843s-.907-104.138 1.002-188.391z"
                fill="#fff"
            />
            <path
                d="m546.098 62.935h-2.865c-2 97.607-1.694 130.548-1.694 152.694s.977 83.301 2.933 183.466c.045-115.323.068-183.606.068-204.85 0-21.245-.351-47.057 1.558-131.31z"
                fill="#fff"
            />
        </svg>
    );
}

export default memo(FlatStreetview);
