import { memo, useState, type JSX } from "react";
import RenderMark from "../../shared/components/RenderMark";
import PlusMinus from "../../shared/components/icons/PlusMinus";
import t from "../../shared/translations";
import type { IOtherLearningMiles } from "./MyHistory";
import { AccordionBody, AccordionContainer, AccordionHeader, AccordionHeading, SectionHeading } from "./stylings";

interface IProps {
    otherMiles: IOtherLearningMiles[];
}

function OtherLearningMiles({ otherMiles }: IProps): JSX.Element {
    const [open, setOpen] = useState("");
    const toggle = (id: string) => {
        setOpen((currId) => (currId === id ? "" : id));
    };

    if (otherMiles.length === 0) {
        return null;
    }

    return (
        <section>
            <SectionHeading>{t("player.my-history.heading-other-awarded-miles")}</SectionHeading>
            <div>
                {otherMiles.map((om) => (
                    <AccordionContainer key={om.id}>
                        <AccordionHeader onClick={() => toggle(om.id)} $pointer={true}>
                            <AccordionHeading>{om.title}</AccordionHeading>
                            <div>{om.miles}</div>
                            <PlusMinus open={open === om.id} />
                        </AccordionHeader>
                        {open === om.id && (
                            <AccordionBody>
                                <div>{t("player.my-history.label-date", { date: om.date })}</div>
                                <RenderMark content={om.description} />
                            </AccordionBody>
                        )}
                    </AccordionContainer>
                ))}
            </div>
        </section>
    );
}

export default memo(OtherLearningMiles);
