import type { ReactNode, JSX } from "react";
import { type UseFieldConfig, useField } from "react-final-form";

interface IOwnProps {
    children?: ReactNode;
    label?: string;
    name: string;
    className?: string;
}

type IProps<T> = IOwnProps & UseFieldConfig<T>;

function Select<T = string>({ name, label, children, className, ...props }: IProps<T>): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField<any, HTMLSelectElement>(name, props);
    return (
        <div className={touched && error ? `error ${className}` : className || ""}>
            <label>
                {label && `${label}: `}
                <select {...input}>{children}</select>
                {touched && error && <small className="error">{error}</small>}
            </label>
        </div>
    );
}

export default Select;
