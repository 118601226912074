import type { JSX } from "react";
import { type UseFieldArrayConfig, useFieldArray } from "react-final-form-arrays";
import LikertInput from "../../../shared/components/LikertInput";

export interface ILikertAnswer {
    statement: string;
    leftLabel: string;
    rightLabel: string;
    value: number | null;
}

interface IOwnProps {
    name: string;
}

type IProps = IOwnProps & UseFieldArrayConfig<ILikertAnswer>;

function LikertAnswers({ name, ...props }: IProps): JSX.Element {
    const {
        fields,
        meta: { error, invalid, touched },
    } = useFieldArray(name, props);
    return (
        <div>
            {fields.map((answer, index) => (
                <div key={index}>
                    <LikertInput
                        name={`${answer}.value`}
                        statement={fields.value[index].statement}
                        leftLabel={fields.value[index].leftLabel}
                        rightLabel={fields.value[index].rightLabel}
                    />
                </div>
            ))}
            {touched && invalid && typeof error === "string" && <div className="error">{error}</div>}
        </div>
    );
}

export default LikertAnswers;
