import type { CSSProperties, JSX } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";

interface IProps {
    miles: number;
    milesGoal: number;
}

const Text = styled.div`
    text-align: center;
    margin-top: 1rem;
`;

const Outer = styled.div`
    position: relative;
    background-color: ${colors.secondaryColor};
    margin: 0 auto 1.5rem auto;
    max-width: 800px;
    height: 27px;
    z-index: 0;
    border: 1px solid ${colors.primaryColor};
`;

const Inner = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: ${colors.primaryColor};
    height: 25px;
    transition: width 1s;
    z-index: 2;
    overflow: hidden;
`;

function PathProgress({ miles, milesGoal }: IProps): JSX.Element {
    miles = Math.min(miles, milesGoal);
    const style: CSSProperties = {
        width: `${(miles / milesGoal) * 100}%`,
    };
    return (
        <div>
            <Text>{t("player.landing.miles-progress", { miles, milesGoal })}</Text>
            <Outer>
                <Inner style={style} />
            </Outer>
        </div>
    );
}

export default PathProgress;
