import { type JSX, memo, useState } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import PlusMinus from "../../shared/components/icons/PlusMinus";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import DownloadAnswers from "../myAnswers/DownloadAnswers";
import type { ICurrentPath } from "./MyHistory";
import { AccordionBody, AccordionContainer, AccordionHeader, AccordionHeading, SectionHeading } from "./stylings";

interface IProps {
    currentPaths: ICurrentPath[];
}

const Button = styled.button`
    padding: 0.5rem 1rem 0.5325rem;
    margin: 0.3rem 0;
`;

const DatesHolder = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
`;

const TaskTable = styled.table`
    border: solid 1px ${colors.secondaryColor};
    thead {
        background-color: ${colors.secondaryColor};
    }
`;

const TaskA = styled.a`
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    gap: 0.3rem;
    
    svg {
        width: 30px;
        height: 30px;
    }
`;

function CurrentPaths({ currentPaths }: IProps): JSX.Element {
    const [open, setOpen] = useState("");
    const toggle = (id: string) => {
        setOpen((currId) => (currId === id ? "" : id));
    };

    if (currentPaths.length === 0) {
        return null;
    }

    return (
        <section>
            <SectionHeading>{t("player.my-history.heading-current")}</SectionHeading>
            <div>
                {currentPaths.map((path) => (
                    <AccordionContainer key={path.id}>
                        <AccordionHeader onClick={() => toggle(path.id)} $pointer={true}>
                            <AccordionHeading>{path.pathname}</AccordionHeading>
                            <div>
                                {path.acceptedItems.reduce((acc, item) => acc + item.earnedMiles, 0)}/{path.goal}
                            </div>
                            <PlusMinus open={open === path.id} />
                        </AccordionHeader>
                        {open === path.id && (
                            <AccordionBody>
                                <DatesHolder>
                                    <div>{t("player.my-history.label-start-date", { date: path.startDate })}</div>
                                    <div>{t("player.my-history.label-end-date", { date: path.endDate })}</div>
                                </DatesHolder>
                                {path.acceptedItems.length > 0 && (
                                    <>
                                        <AccordionHeading>{t("player.my-history.heading-done-tasks")}</AccordionHeading>
                                        <TaskTable>
                                            <thead>
                                                <tr>
                                                    <th>{t("player.my-history.table-tasks-heading-task")}</th>
                                                    <th>{t("player.my-history.table-tasks-heading-finish-date")}</th>
                                                    <th>{t("player.my-history.table-tasks-heading-miles")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {path.acceptedItems
                                                    .filter((i) => i.type !== "event")
                                                    .map((item) => (
                                                        <tr key={item.id}>
                                                            <td>
                                                                <TaskA href={item.url}>
                                                                    <TaskIcon icon={item.icon} active={true} />
                                                                    {item.title}
                                                                </TaskA>
                                                            </td>
                                                            <td>{formatYMD(parseDate(item.finishDatetime))}</td>
                                                            <td>{item.earnedMiles}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </TaskTable>
                                    </>
                                )}
                                <form action="/select-path" method="POST">
                                    <input name="_csrf_token" type="hidden" value={__CSRF_TOKEN__} />
                                    <Button name="path" value={path.id}>
                                        {t("player.my-history.button-view")}
                                    </Button>
                                </form>
                                <DownloadAnswers pathId={path.id} />
                            </AccordionBody>
                        )}
                    </AccordionContainer>
                ))}
            </div>
        </section>
    );
}

export default memo(CurrentPaths);
