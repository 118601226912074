import type { JSX } from "react";
import { useImmerReducer } from "use-immer";
import AiLoader from "../../../shared/components/ai/AiLoader";
import AiThread from "../../../shared/components/ai/AiThread";
import type { ISubmittedTaskVariant, ITask, ITaskVariant } from "../query";
import AiInput from "./AiInput";
import Info from "./Info";
import StartAiChatButton from "./StartAiChatButton";
import { createInitialState, reducer } from "./state";

interface IProps {
    task: ITask;
    variant: ITaskVariant | ISubmittedTaskVariant;
    invert: boolean;
    setHasTalkedAi: (to: boolean) => void;
}

function AiChat({ task, variant, invert, setHasTalkedAi }: IProps): JSX.Element {
    const [state, dispatch] = useImmerReducer(reducer, variant, createInitialState);

    let main = null;
    if (state.aiLoading) {
        main = <AiLoader />;
    } else if (state.status === "notStarted") {
        main = <StartAiChatButton task={task} variant={variant} invert={invert} dispatch={dispatch} state={state} />;
    } else if (state.status === "chatting") {
        main = <AiInput state={state} dispatch={dispatch} setHasTalkedAi={setHasTalkedAi} />;
    }

    return (
        <section>
            <Info variant={variant} />
            <AiThread messages={state.messages} />
            {main}
        </section>
    );
}

export default AiChat;
