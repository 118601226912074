import { memo, useEffect, type JSX } from "react";
import type { IPathGroup, Message } from "../../api/player/Chat";
import t from "../../shared/translations";
import History from "./History";
import Input from "./Input";
import Members from "./Members";

interface IProps {
    closeChat: VoidFunction;
    loading: boolean;
    currentId: number;
    pathGroup: IPathGroup;
    online: { [index: number]: boolean };
    typing: { [index: number]: boolean };
    messages: Message[] | null;
    sendChatMessage: (message: string) => void;
    sendIsTyping: (isTyping: boolean) => void;
    sendIsOnline: VoidFunction;
    sendGoOffline: VoidFunction;
}

function ChatWindow({
    closeChat,
    online,
    messages,
    typing,
    pathGroup,
    currentId,
    loading,
    sendChatMessage,
    sendIsTyping,
    sendGoOffline,
    sendIsOnline,
    ...props
}: IProps): JSX.Element {
    // biome-ignore lint/correctness/useExhaustiveDependencies: Only run when chat window is opened
    useEffect(() => {
        sendIsOnline();
        return () => {
            sendGoOffline();
        };
    }, []);

    if (loading) {
        return (
            <div className="inner-chat" {...props}>
                <span onClick={closeChat} className="close-cross">
                    ×
                </span>
            </div>
        );
    }

    return (
        <div className="inner-chat" {...props}>
            <header className="header">
                <span onClick={closeChat} className="close-cross">
                    ×
                </span>
                <h2>{t("player.chat.heading")}</h2>
                <Members members={pathGroup.members} online={online} currentId={currentId} />
            </header>
            <History messages={messages} members={pathGroup.members} currentId={currentId} typing={typing} />
            <Input sendChatMessage={sendChatMessage} sendIsTyping={sendIsTyping} />
        </div>
    );
}

export default memo(ChatWindow);
