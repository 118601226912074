import { type JSX, memo, useEffect, useState } from "react";
import styled from "styled-components";
import * as API from "../../api/admin";
import RenderMark from "./RenderMark";

interface IProps {
    path: string;
}

const StyledMark = styled(RenderMark)`
    margin: 0 auto;
    max-width: 80ch;
`;

function Doc({ path }: IProps): JSX.Element {
    const [content, setContent] = useState("");

    useEffect(() => {
        void (async () => {
            const cont = await API.Docs.get(path);
            setContent(cont);
        })();
    }, [path]);

    return <StyledMark content={content} />;
}

export default memo(Doc);
