import { useQuery } from "urql";
import styled from "styled-components";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import ShowError from "../components/ShowError";
import ChatbotList from "./ChatbotList";
import PrevThreads from "./PrevThreads";
import { type IListData, listQuery } from "./queries";
import type { JSX } from "react";

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    align-items: flex-start;

    @media (min-width: 800px) {
        grid-template-columns: 1fr 300px;
    }
    @media (min-width: 900px) {
        grid-template-columns: 1fr 400px;
    }
`;

function Landing(): JSX.Element {
    const [result] = useQuery<IListData>({
        query: listQuery,
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    return (
        <>
            <h1>{t("player.ai-chatbots-landing.heading")}</h1>
            <Grid>
                <ChatbotList chatbots={result.data.currentPerson.standaloneAiChatbots} />
                <PrevThreads />
            </Grid>
        </>
    );
}

export default Landing;
