import { useCallback, useState, type JSX } from "react";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import ShowError from "../components/ShowError";
import SentMessage from "./SentMessage";
import { type ISentData, sentQuery } from "./query";

function Sent(): JSX.Element {
    const [open, setOpen] = useState<string>("");

    const [result] = useQuery<ISentData>({
        query: sentQuery,
    });
    const toggleOpen = useCallback((id: string) => {
        setOpen((open) => (open === id ? "" : id));
    }, []);

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    return (
        <section>
            {result.data.learningCoachMessageList.map((message) => (
                <SentMessage key={message.id} message={message} open={open === message.id} toggleOpen={toggleOpen} />
            ))}
        </section>
    );
}

export default Sent;
