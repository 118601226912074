import { type JSX, useState } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import RenderMark from "../../shared/components/RenderMark";
import fmtGroupName from "../../shared/fmtGroupName";
import t from "../../shared/translations";
import { Tab, TabRow } from "../tasksSidebar/stylings";
import type { IData } from "./Landing";
import Person from "./Person";

interface IProps {
    path: IData["learningPath"] | null;
}

const Box = styled.div`
    border: 1px solid ${colors.unlight};
    padding: 5px;
    margin-bottom: 1rem;
`;

const Section = styled.section`
    margin-top: 1rem;
`;

type TabType = "path" | "my-group" | "learning-coaches" | "participants";

function initialTab(path: IData["learningPath"]): TabType {
    if (path.info) {
        return "path";
    }
    return "learning-coaches";
}

function Info({ path }: IProps): JSX.Element {
    const [selectedTab, switchTab] = useState<TabType>(initialTab(path));

    return (
        <Section>
            <TabRow>
                {!!path?.info && (
                    <Tab $active={selectedTab === "path"} onClick={() => switchTab("path")}>
                        {t("player.landing.tab-path-info")}
                    </Tab>
                )}
                <Tab $active={selectedTab === "learning-coaches"} onClick={() => switchTab("learning-coaches")}>
                    {t("player.landing.tab-learning-coaches", {
                        count: path?.learningCoaches.length ?? 0,
                    })}
                </Tab>
                {!!path?.pathGroup && (
                    <Tab $active={selectedTab === "my-group"} onClick={() => switchTab("my-group")}>
                        {t("player.landing.tab-my-group")}
                    </Tab>
                )}
                {path?.showParticipants && (
                    <Tab $active={selectedTab === "participants"} onClick={() => switchTab("participants")}>
                        {t("player.landing.tab-participants")}
                    </Tab>
                )}
            </TabRow>
            {selectedTab === "learning-coaches" && (
                <Box>
                    {path?.learningCoaches.map((person) => (
                        <Person key={person.id} person={person} />
                    ))}
                </Box>
            )}
            {path?.info && selectedTab === "path" && (
                <Box>
                    <h3>{path.pathname}</h3>
                    <RenderMark content={path.info} />
                </Box>
            )}
            {path?.showParticipants && selectedTab === "participants" && (
                <Box>
                    <h3>{t("player.landing.heading-participants")}</h3>
                    {path.participants.map((person) => (
                        <Person key={person.id} person={person} />
                    ))}
                </Box>
            )}
            {path?.pathGroup && selectedTab === "my-group" && (
                <Box>
                    <h3>{fmtGroupName(path.pathGroup)}</h3>
                    {path.pathGroup.members.map((m) => (
                        <Person key={m.id} person={m.person} />
                    ))}
                </Box>
            )}
        </Section>
    );
}

export default Info;
