import { memo, type JSX } from "react";
import type { Person } from "../../api/player/Chat";
import { formatLong, parseDate } from "../../shared/dateFns";

interface IProps {
    person: Person | null;
    datetime: string;
}

const DEFAULT_IMG = "/static/images/profile-silouette.png";

function HistoryProfile({ person, datetime }: IProps): JSX.Element {
    return (
        <div className="clearfix">
            {person && <img className="history-pic" src={person.profile_pic_src || DEFAULT_IMG} alt="Profile" />}
            {person && (
                <div className="history-name">
                    {person.first_name} {person.last_name}{" "}
                </div>
            )}
            <div className="history-datetime">{formatLong(parseDate(datetime))}</div>
        </div>
    );
}

export default memo(HistoryProfile);
