import { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import * as colors from "../colors";

const inAndOut = keyframes`
    0% {
        opacity: 0;
        transform: scale(2.0);
    }
    12.5% {
        opacity: 1;
        transform: scale(1.0);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const Toast = styled.span`
    position: fixed;
    background-color: white;
    border: 1px solid ${colors.primaryColor};
    border-radius: 3px;
    padding: 0.1rem 0.5rem;
    bottom: 10px;
    right: 10px;
    animation-name: ${inAndOut};
    animation-duration: 4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    transform-origin: bottom right;
    z-index: 5;
`;

export function useToast(): [boolean, VoidFunction] {
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            if (showToast) {
                setShowToast(false);
            }
        }, 4000);
        return () => {
            window.clearTimeout(timer);
        };
    }, [showToast]);

    const showCopied = useCallback(() => {
        if (showToast) {
            setShowToast(false);
            setShowToast(true);
        } else {
            setShowToast(true);
        }
    }, [showToast]);

    return [showToast, showCopied];
}
