import TaskDescription from "../../shared/components/TaskDescription";
import ResponseForm from "../components/ResponseForm";
import PipNav from "../pip/PipNav";
import YourGroup from "../pip/YourGroup";
import Thread from "../task/Thread";
import Undecided from "../task/Undecided";
import ResponseTable from "../task/collectFeedback/ResponseTable";
import { Box } from "../task/stylings";
import TaskChoiceNav from "../taskChoice/TaskChoiceNav";
import FeedbackLink from "./FeedbackLink";
import FeedbackerInfo from "./FeedbackerInfo";
import NavTabs from "./NavTabs";
import Pulse from "./Pulse";
import ShowAiThread from "./ShowAiThread";
import TeamScan from "./TeamScan";
import type { ISubmittedTask } from "./query";
import type { JSX } from "react";

interface IProps {
    task: ISubmittedTask;
}

const EMPTY_LIST = [];

function MyAnswer({ task }: IProps): JSX.Element {
    const showCollectFeedback =
        task.submittedTaskVariant.variantType === "collect-feedback" && task.collectFeedback !== null;
    const showPeerInfo = task.submittedTaskVariant.variantType === "peer-feedback";
    const showTeamScan =
        task.submittedTaskVariant.variantType === "team-scan" && task.submittedTaskVariant.teamScan !== null;
    const showPulse = task.submittedTaskVariant.variantType === "pulse" && task.submittedTaskVariant.pulse !== null;
    const showPipNav = !!task.learningPathItem?.learningPath?.open && task.learningPathItem?.pathInPath != null;
    const showGroup =
        showPipNav &&
        task.learningPathItem.pathInPath.showGroup &&
        task.learningPathItem.learningPath.pathGroup != null;
    const showFeedbackLink = task.submittedTaskVariant.feedbackRequest != null;
    const showUndecided = task.status === "undecided";
    const showResponse = !showUndecided;
    const showTaskChoiceNav = task.learningPathItem?.taskChoice !== null;
    const showAiThread = !!task.submittedTaskVariant.taskAiThread?.id;

    const unread = task.sharedAnswers.filter((answer) => !answer.read).length;
    return (
        <>
            <h1>{task.shortTitle}</h1>
            <NavTabs task={task} unread={unread} />
            <TaskDescription
                objectives={task.objectives}
                inspiration={task.inspiration}
                title={task.title}
                taskVariants={EMPTY_LIST}
                submittedVariant={task.submittedTaskVariant}
                icon={task.icon}
                miles={task.miles}
                timeEstimate={task.timeEstimate}
                status={"finished"}
                finishDatetime={task.finishDatetime}
                media={task.media}
                invert={showPipNav}
            >
                {showPipNav && (
                    <PipNav
                        miles={task.learningPathItem.miles}
                        earnedMiles={task.learningPathItem.earnedMiles}
                        url={task.learningPathItem.pathInPath.url}
                        tasks={task.learningPathItem.tasks}
                        icon={task.learningPathItem.pathInPath.icon}
                        current={task.id}
                    />
                )}
                {showTaskChoiceNav && (
                    <TaskChoiceNav
                        taskChoice={task.learningPathItem.taskChoice}
                        tasks={task.learningPathItem.tasks}
                        current={task.task.id}
                    />
                )}
                {showGroup && <YourGroup members={task.learningPathItem.learningPath.pathGroup.members} />}
            </TaskDescription>
            {showPeerInfo && <FeedbackerInfo feedbacker={task.feedbacker} />}
            {showFeedbackLink && <FeedbackLink feedbackRequest={task.submittedTaskVariant.feedbackRequest} />}
            {showCollectFeedback && (
                <Box>
                    <ResponseTable
                        collectFeedbackAnswerSets={task.collectFeedback.collectFeedbackAnswerSets}
                        anonymous={task.collectFeedback.anonymous}
                        invert={showPipNav}
                        submittedTask={task}
                    />
                </Box>
            )}
            {showTeamScan && <TeamScan task={task} />}
            {showPulse && <Pulse task={task} />}
            {showAiThread && <ShowAiThread thread={task.submittedTaskVariant.taskAiThread} />}
            <Thread thread={task.thread} />
            {showUndecided && <Undecided />}
            {showResponse && <ResponseForm id={task.id} invert={showPipNav} />}
        </>
    );
}

export default MyAnswer;
