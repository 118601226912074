import type { JSX } from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import t from "../../shared/translations";
import PrevMessage from "./PrevMessage";
import { type IThreadArgs, type IThreadData, replyQuery } from "./query";

interface IProps {
    id: string;
}

const Col = styled.div`
    border-top: 1px solid DarkGray;
    border-bottom: 1px solid DarkGray;
    margin-bottom: 1rem;
`;

function PrevThread({ id }: IProps): JSX.Element {
    const [result] = useQuery<IThreadData, IThreadArgs>({
        query: replyQuery,
        variables: {
            id,
        },
    });

    if (result.fetching) {
        return null;
    }
    if (result.error) {
        return null;
    }
    if (!result.data.message) {
        return null;
    }
    if (result.data.message.replyThread.length === 0) {
        return null;
    }
    const thread = result.data.message.replyThread;
    return (
        <Col>
            <strong>{t("player.messages.label-prev-message")}</strong>
            {thread.map((msg) => (
                <PrevMessage key={msg.id} message={msg} />
            ))}
        </Col>
    );
}

export default PrevThread;
