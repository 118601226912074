import { type UseFieldConfig, useField } from "react-final-form";
import styled from "styled-components";
import * as colors from "../shared/colors";
import type { JSX } from "react";

interface IOwnProps {
    name: string;
    className?: string;
    placeholder?: string;
}

type IProps = IOwnProps & UseFieldConfig<string>;

const Wrapper = styled.div`
    input {
        border: 1px solid ${colors.text};
        color: ${colors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: 'white';
        font-size: 1rem;
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border: 1px solid ${colors.text};
        border-top: none;
    }

    &.error input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

function Input({ name, className, placeholder, ...props }: IProps): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField<any, HTMLInputElement>(name, props);

    return (
        <Wrapper className={touched && error ? `error ${className}` : className || ""}>
            <input {...input} type="text" placeholder={placeholder} />
            {touched && error && <small className="error">{error}</small>}
        </Wrapper>
    );
}

export default Input;
