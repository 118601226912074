import { memo } from "react";
import type { ComponentPropsWithoutRef, JSX } from "react";
import * as colors from "../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function GroupTaskIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="50" cy="50" r="50" fill={active ? colors.primaryColor : colors.altPrimaryColor} />
            <ellipse cx="44" cy="38" rx="15" ry="18" fill="white" />
            <path fill="#fff" d="M16 72 L70 72 A20 15 1 1 0 16 72" />
            <g transform="scale(0.75) translate(18 10)">
                <path
                    d="m74.2 54.1c4.1-4.6 6.5-11.1 6.5-17.9 0-13.6-9.4-24.7-21-24.7-5 0-9.8 2.1-13.6 5.9l0 0c-1.9-.6-3.9-1-5.9-1-11.6 0-21 11.1-21 24.7 0 7 2.5 13.4 6.5 17.9-13.9 4.4-23 14.7-23 26.3 0 .6 0 1.1.1 1.8.1.8.7 1.4 1.5 1.4h71.7c.8 0 1.4-.6 1.5-1.4.1-.7.1-1.2.1-1.8s0-1.2-.1-1.7h18c .8 0 1.4-.6 1.5-1.4.1-.7.1-1.2.1-1.8 0-11.5-9.1-21.9-22.9-26.3zm-52.1-13c0-12 8.1-21.7 18-21.7s18 9.7 18 21.7-8.1 21.7-18 21.7-18-9.7-18-21.7zm52.5 44.4h-68.9c0-.1 0-.1 0-.2 0-10.7 9-20.2 22.4-23.9 3.4 2.8 7.5 4.4 12 4.4s8.7-1.7 12.1-4.5c.1.1.2.1.4.2 13.1 3.8 22 13.4 22 23.8z"
                    fill="black"
                />
                <path
                    d="m94.1 80.6h-17.1c-2.1-9.6-10.5-17.8-22.3-21.6 4-4.5 6.5-10.8 6.5-17.9 0-9.9-5-18.4-12.2-22.4 3.1-2.7 6.8-4.2 10.7-4.2 9.9 0 18 9.7 18 21.7 0 7.1-2.9 13.7-7.7 17.8-.5.4-.7 1.1-.4 1.7s2.8 1 2.8 1c13 3.8 21.8 13.4 21.7 23.9 0-.1 0-.1 0 0z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}

export default memo(GroupTaskIcon);
