import { type ComponentPropsWithoutRef, memo, type JSX } from "react";
import * as colors from "../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    dir: "left" | "right";
}

function NavStreetArrow({ dir, ...props }: IProps): JSX.Element {
    const deg = dir === "left" ? "180" : "0";
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g transform={`rotate(${deg} 50 50)`}>
                <polygon
                    points="16,10 90,50 16,90"
                    fill={colors.secondaryColor}
                    stroke={colors.primaryColor}
                    strokeWidth="4"
                />
            </g>
        </svg>
    );
}

export default memo(NavStreetArrow);
