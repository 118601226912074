import RenderMark from "../../../shared/components/RenderMark";
import type { IDoc } from "./Report";
import type { JSX } from "react";

interface IProps {
    printing?: boolean;
    docList: IDoc[];
}

function NowWhat({ printing, docList }: IProps): JSX.Element {
    return printing ? (
        <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-now-what-print").content} />
    ) : (
        <RenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-now-what").content} />
    );
}

export default NowWhat;
