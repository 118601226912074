import type { JSX } from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import type { Gender } from "../../shared/types";
import Modal from "../components/Modal";
import ShowError from "../components/ShowError";
import Avatar from "./Avatar";
import InfoSection, { COL_CUTOFF } from "./InfoSection";
import PasswordForm from "./PasswordForm";
import PictureForm from "./PictureForm";
import PictureSection from "./PictureSection";
import ProfileForm from "./ProfileForm";

const query = `
    query {
        currentPerson {
            id
            firstName
            lastName
            gender
            title
            tel
            birthYear
            profilePicSrc
            noteForPlayer
            avatar
            avatarUrl(pose:"welcome")
            eMailNotifications
            user {
                email
            }
            organisation {
                id
                name
            }
            team {
                id
                name
            }
        }
    }
`;

export interface ICurrentPerson {
    id: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    title: string;
    tel: string;
    birthYear: number | null;
    profilePicSrc: string | null;
    noteForPlayer: string | null;
    avatar: string;
    avatarUrl: string;
    eMailNotifications: boolean;
    user: {
        email: string;
    };
    organisation: {
        id: string;
        name: string;
    };
    team: {
        id: string;
        name: string;
    } | null;
}

interface IData {
    currentPerson: ICurrentPerson;
}

const Wrapper = styled.div`
    border: 1px solid lightgray;
    padding: 5px;
    min-height: 300px;
    container-type: inline-size;
    @media (min-width: 400px) {
        padding: 10px;
    }
    @media (min-width: 600px) {
        padding: 15px;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 1rem;
    @container (min-width: ${COL_CUTOFF}px) {
        grid-template-columns: 1fr 1fr;
    }
`;

function Profile(): JSX.Element {
    const [result] = useQuery<IData>({
        query,
        requestPolicy: "network-only",
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const person = result.data.currentPerson;
    return (
        <Wrapper>
            <Grid>
                <InfoSection person={person} />
                <PictureSection person={person} />
                <ProfileForm person={person} />
            </Grid>
            <Modal modal="Profile/ChangeAvatar">
                <Avatar />
            </Modal>
            <Modal modal="Profile/ChangePassword">
                <PasswordForm />
            </Modal>
            <Modal modal="Profile/ChangeProfilePicture">
                <PictureForm />
            </Modal>
        </Wrapper>
    );
}

export default Profile;
