import type { JSX } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Doc from "../../shared/components/Doc";
import InfoIcon from "../../shared/components/icons/InfoIcon";
import { type Dispatch, type Modal as IModal, openModal } from "../state";
import Modal from "./Modal";

interface IProps {
    modal: IModal;
    docPath: string | string[];
    margin?: boolean;
}

interface IInfo {
    $margin?: boolean;
}

const Info = styled(InfoIcon)<IInfo>`
    margin-left: ${({ $margin }: IInfo) => ($margin ? "0.3rem" : "0")};
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex-shrink: 0;
`;

function InfoButton({ docPath, modal, margin }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();

    const open = () => {
        dispatch(openModal(modal));
    };

    return (
        <>
            <Info onClick={open} $margin={margin} />
            <Modal modal={modal}>
                {Array.isArray(docPath) ? docPath.map((dp) => <Doc key={dp} path={dp} />) : <Doc path={docPath} />}
            </Modal>
        </>
    );
}

export default InfoButton;
