import { memo, useState, type JSX } from "react";
import RenderMark from "../../shared/components/RenderMark";
import PlusMinus from "../../shared/components/icons/PlusMinus";
import t from "../../shared/translations";
import type { ISpentLearningMiles } from "./MyHistory";
import { AccordionBody, AccordionContainer, AccordionHeader, AccordionHeading, SectionHeading } from "./stylings";

interface IProps {
    spentMiles: ISpentLearningMiles[];
}

function SpentLearningMiles({ spentMiles }: IProps): JSX.Element {
    const [open, setOpen] = useState<string>("");
    const toggle = (id: string) => {
        setOpen((currId) => (currId === id ? "" : id));
    };

    if (spentMiles.length === 0) {
        return null;
    }

    return (
        <section>
            <SectionHeading>{t("player.my-history.heading-spent-learning-miles")}</SectionHeading>
            <div>
                {spentMiles.map((sm) => (
                    <AccordionContainer key={sm.id}>
                        <AccordionHeader onClick={() => toggle(sm.id)} $pointer={true}>
                            <AccordionHeading>{sm.title}</AccordionHeading>
                            <div>{sm.miles}</div>
                            <PlusMinus open={open === sm.id} />
                        </AccordionHeader>
                        {open === sm.id && (
                            <AccordionBody>
                                <div>{t("player.my-history.label-date", { date: sm.date })}</div>
                                <RenderMark content={sm.description} />
                            </AccordionBody>
                        )}
                    </AccordionContainer>
                ))}
            </div>
        </section>
    );
}

export default memo(SpentLearningMiles);
