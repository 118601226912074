import styled from "styled-components";
import * as colors from "../shared/colors";

const Box = styled.div`
    border-radius: 1rem;
    padding: 0.6rem;
    border: 1px solid ${colors.primaryColor};
    margin-bottom: 0.5rem;

    @media (min-width: 600px) {
        padding: 1rem;
        margin-bottom: 1rem;
    }

    > *:last-child {
        margin-bottom: 0;
    }
`;

export default Box;
