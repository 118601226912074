import type { ValidationErrors } from "final-form";
import { type MouseEvent, useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import t from "../../../shared/translations";
import { type Dispatch, type State, backToStep, toStep4 } from "../../state";
import { Center } from "../stylings";
import { Input, Textarea } from "../stylings/form";
import type { ISystemMessage } from "./Setup";

interface IProps {
    systemMessage: ISystemMessage;
    id: string;
}

interface IFormValues {
    title: string;
    body: string;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.title) {
        errors.title = t("player.team-scan-setup-3.form-error-title-required");
    }
    if (!values.body) {
        errors.body = t("player.team-scan-setup-3.form-error-body-required");
    } else if (!values.body.includes("{{link}}")) {
        errors.body = t("player.team-scan-setup-3.form-error-body-include-link");
    }
    return errors;
}

function Step3({ systemMessage, id }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const msg = useSelector((state: State) => state.teamScan[id].msg);

    const initialValues: IFormValues = {
        body: msg.body || systemMessage.body,
        title: msg.title || systemMessage.title,
    };

    const onSubmit = useCallback(
        (values: IFormValues) => {
            dispatch(toStep4({ id, ...values }));
        },
        [id],
    );

    const back = (event: MouseEvent<HTMLButtonElement>): boolean => {
        event.preventDefault();
        dispatch(backToStep({ id, step: "step2" }));
        return false;
    };

    return (
        <>
            <h1>{t("player.team-scan-setup-3.heading")}</h1>
            <p>{t("player.team-scan-setup-3.info-participants")}</p>
            <p>{t("player.team-scan-setup-3.info-edit")}</p>
            <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Input name="title" label={t("player.team-scan-setup-3.form-label-subject")} />
                        <Textarea name="body" label={t("player.team-scan-setup-3.form-label-message")} />
                        <Center>
                            <button type="button" onClick={back}>
                                {t("player.team-scan-setup-3.form-button-back")}
                            </button>{" "}
                            <button type="submit" disabled={submitting}>
                                {t("player.team-scan-setup-3.form-button-submit")}
                            </button>
                        </Center>
                    </form>
                )}
            </Form>
        </>
    );
}

export default Step3;
