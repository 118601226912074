import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function NetworkOfPeopleIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="29" r="17" fill="#fff" />
            <circle cx="23.3" cy="71" r="17" fill="#fff" />
            <circle cx="77" cy="71" r="17" fill="#fff" />
            <path
                d="m77.1 53c-7.4 0-13.8 4.5-16.6 10.9l-8.8-7.6v-9.3c9.3-.8 16.6-8.5 16.6-18 0-10-8.1-18.1-18.1-18.1s-18.1 8.1-18.1 18.1c0 9.4 7.3 17.2 16.6 18v9.3l-8.8 7.6c-2.8-6.5-9.1-10.9-16.5-10.9-10 0-18.1 8.1-18.1 18 0 10 8.1 18.1 18.1 18.1s18.1-8.1 18.1-18.1c0-1.4-.2-2.8-.5-4.1l9.3-8 9.3 8c-.4 1.3-.6 2.7-.6 4.1 0 10 8.1 18.1 18.1 18.1s18.1-8.1 18.1-18.1c-.1-9.9-8.2-18-18.1-18zm-41.5 26.8c-.9-2.6-3.1-4.8-6.1-6.1.9-1.4 1.4-3.2 1.4-5.1 0-4.8-3.4-8.7-7.6-8.7s-7.6 3.9-7.6 8.7c0 1.9.5 3.6 1.4 5.1-3 1.3-5.2 3.5-6.1 6-1.8-2.5-2.9-5.5-2.9-8.8 0-8.3 6.8-15.1 15.1-15.1s15.1 6.8 15.1 15.1c.1 3.4-1 6.4-2.7 8.9zm7-53.1c0 1.9.5 3.6 1.4 5.1-3 1.3-5.2 3.5-6.1 6-1.8-2.5-2.9-5.5-2.9-8.8 0-8.3 6.8-15.1 15.1-15.1s15.1 6.8 15.1 15.1c0 3.3-1.1 6.3-2.9 8.8-.9-2.6-3.1-4.8-6.1-6.1.9-1.4 1.4-3.2 1.4-5.1 0-4.8-3.4-8.7-7.6-8.7s-7.4 4-7.4 8.8zm46.6 53.1c-.9-2.6-3.1-4.8-6.1-6.1.9-1.4 1.4-3.2 1.4-5.1 0-4.8-3.4-8.7-7.6-8.7s-7.6 3.9-7.6 8.7c0 1.9.5 3.6 1.4 5.1-3 1.3-5.2 3.5-6.1 6-1.5-2.4-2.6-5.4-2.6-8.7 0-8.3 6.8-15 15.1-15s15.1 6.8 15.1 15.1c-.1 3.2-1.2 6.2-3 8.7z"
                fill="#222"
            />
            <g fill={active ? "#ff855f" : "#fff"}>
                <path d="m13.7 82.6c0-.1 0-.1 0-.2 0-2.7 2.3-5.2 5.7-6.3 1.1.8 2.5 1.3 3.9 1.3s2.8-.5 3.9-1.3c3.4 1.1 5.7 3.6 5.7 6.3v.2c-2.6 2.2-6 3.5-9.6 3.5s-7-1.4-9.6-3.5z" />
                <ellipse cx="23.4" cy="68.7" rx="4.6" ry="5.7" />
                <path d="m40.6 40.4c0-2.7 2.3-5.2 5.7-6.3 1.1.8 2.5 1.3 3.9 1.3s2.8-.5 3.9-1.3c3.4 1.1 5.7 3.6 5.7 6.3v.2c-2.6 2.2-6 3.5-9.6 3.5-3.7 0-7-1.3-9.6-3.5 0-.1 0-.2 0-.2z" />
                <ellipse cx="50.2" cy="26.7" rx="4.6" ry="5.7" />
                <path d="m67.4 82.6c0-.1 0-.1 0-.2 0-2.7 2.3-5.2 5.7-6.3 1.1.8 2.5 1.3 3.9 1.3s2.8-.5 3.9-1.3c3.4 1.1 5.7 3.6 5.7 6.3v.2c-2.6 2.2-6 3.5-9.6 3.5s-7-1.4-9.6-3.5z" />
                <ellipse cx="77.1" cy="68.7" rx="4.6" ry="5.7" />
            </g>
        </svg>
    );
}

export default NetworkOfPeopleIcon;
