import Linkify from "linkify-react";
import type { ComponentType, JSX } from "react";
import styled from "styled-components";
import * as colors from "../../colors";
import type { AiMessageRoleType, AiThreadRatingType } from "../../types";
import RenderMark from "../RenderMark";
import AiIcon from "../icons/AiIcon";
import ProfileIcon from "../icons/navIcons/ProfileIcon";
import MagnifyingGlassIcon from "../icons/taskIcons/MagnifyingGlassIcon";

interface IMessage {
    id: string;
    role: AiMessageRoleType;
    content: string;
    toolCallId?: string | null;
    toolName?: string | null;
    toolArguments?: string | null;
    rating?: AiThreadRatingType;
    hide?: boolean;
    contextData?: {
        avgRelevance: number;
        query: string;
        documentNames: string[];
    } | null;
}

interface IProps {
    messages: IMessage[];
    fmtUser?: boolean;
    actions?: ComponentType<{ message: IMessage }>;
}

type IBox = {
    $border?: string;
};

const AiBox = styled.div<IBox>`
    display: grid;
    grid-template-columns: 35px 1fr;
    gap: 0.5rem;
    border-radius: 1rem;
    padding: 0.6rem;
    border: 1px solid ${({ $border }) => $border ?? colors.primaryColor};
    margin-bottom: 0.5rem;

    svg {
        width: 35px;
        height: 35px;
    }
    
    > *:last-child > *:last-child {
        margin-bottom: 0;
    }
        
    @media (min-width: 600px) {
        padding: 1rem;
        margin-bottom: 1rem;
        grid-template-columns: 45px 1fr;
    
        svg {
            width: 45px;
            height: 45px;
        }
    }
        
    @media (min-width: 800px) {
        grid-template-columns: 60px 1fr;
    
        svg {
            width: 60px;
            height: 60px;
        }
    }
`;

const ContextBox = styled.div`
    padding: 0.6rem;
    border: 1px solid ${colors.black};
    margin-bottom: 0.5rem;
`;

function DisplayContext({ contextData }: { contextData: IMessage["contextData"] }): JSX.Element {
    if (!contextData) {
        return null;
    }
    return (
        <ContextBox>
            {contextData.query} <br />
            {contextData.avgRelevance} <br />
            {contextData.documentNames?.map((name, index) => (
                <span key={index}>
                    {name}
                    <br />
                </span>
            ))}
        </ContextBox>
    );
}

function AiThread({ messages, actions: Actions, fmtUser = false }: IProps): JSX.Element {
    if (messages.length === 0) {
        return null;
    }
    return (
        <div>
            {messages.map((message) => (
                <AiBox key={message.id} $border={message.role === "user" ? colors.answer : colors.text}>
                    <div>
                        {message.role === "assistant" ? (
                            <AiIcon />
                        ) : message.role === "tool" ? (
                            <MagnifyingGlassIcon />
                        ) : (
                            <ProfileIcon />
                        )}
                        {!!Actions && <Actions message={message} />}
                    </div>
                    {message.role === "assistant" || fmtUser ? (
                        <RenderMark content={message.content || message.toolName} />
                    ) : (
                        <div>
                            {message.contextData && <DisplayContext contextData={message.contextData} />}
                            <Linkify options={{ nl2br: true }} tagName="div">
                                {message.content}
                            </Linkify>
                        </div>
                    )}
                </AiBox>
            ))}
        </div>
    );
}

export default AiThread;
