import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Provider as URQLProvider, cacheExchange, createClient, fetchExchange } from "urql";
import { UrqlGlobalNotice } from "../../shared/components/GlobalNotice";
import sentryExchange from "../../shared/sentryExchange";
import type { TaskIcon } from "../../shared/types";
import Footer from "../Footer";
import Header from "../Header";
import { CurrentPersonProvider } from "../components/CurrentPerson";
import { store } from "../state";
import Flipper from "./Flipper";

const client = createClient({
    exchanges: [cacheExchange, sentryExchange, fetchExchange],
    fetchOptions: {
        credentials: "same-origin",
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
    },
    requestPolicy: "cache-and-network",
    url: "/graphql/player",
});

export type AnimationType = "task" | "unlock" | "milestone" | "redirect";

export interface IData {
    finishMessage: string | null;
    redirectUrl: string;
    icon: TaskIcon;
    milesEarned: string;
    milesCheckpoint: string;
    milesGoal: string;
    pipId: number | null;
    showMilesText: boolean;
    passedCheckpoint: boolean;
    passedGoal: boolean;
    animations: AnimationType[];
    lockedTasks: {
        id: string;
        icon: TaskIcon;
        shortTitle: string;
    }[];
}

export default function init() {
    const elem = document.getElementById("flipper-data");
    const data = JSON.parse(elem.innerHTML) as IData;

    const container = document.getElementById("flipper-page");
    Modal.setAppElement(container);
    const root = createRoot(container);
    root.render(
        <URQLProvider value={client}>
            <ReduxProvider store={store}>
                <BrowserRouter>
                    <CurrentPersonProvider>
                        <UrqlGlobalNotice />
                        <Header />
                        <Flipper data={data} />
                        <Footer />
                    </CurrentPersonProvider>
                </BrowserRouter>
            </ReduxProvider>
        </URQLProvider>,
    );
}
