// Query path inspirations

export interface IArgs {
    id: string;
}

export const query = `
    query($id:String!) {
        learningPath(id:$id) {
            id
            inspirations {
                id
                created
                content
                read
                liked
                likeCount
            }
        }
    }
`;

export interface IData {
    learningPath: {
        id: string;
        inspirations: {
            id: string;
            created: string | null;
            content: string;
            read: boolean;
            liked: boolean;
            likeCount: number;
        }[];
    };
}

// Toggle like mutation

export interface ILikeArgs {
    id: string;
    to: "like" | "unlike";
}

export const likeMutation = `
    mutation($id:String!,$to:String!) {
        inspirationChangeLike(id:$id,to:$to) {
            error
            inspiration {
                id
            }
        }
    }
`;

export interface ILikeData {
    inspirationChangeLike: {
        error: string | null;
        inspiration: {
            id: string;
        } | null;
    };
}

// Mark read mutaion

export interface IMarkReadArgs {
    ids: string[];
}

export const markReadMutation = `
    mutation($ids:[String!]!) {
        inspirationMarkReads(ids:$ids) {
            error        }
    }
`;

export interface IMarkReadData {
    inspirationMarkReads: {
        error: string | null;
    };
}

// Mark unread mutaion

export interface IMarkUnreadArgs {
    id: string;
}

export const markUnreadMutation = `
    mutation($id:String!) {
        inspirationMarkUnread(id:$id) {
            error
            inspiration {
                id
            }
        }
    }
`;

export interface IMarkUnreadData {
    inspirationMarkUnread: {
        error: string | null;
        inspiration: {
            id: string;
        } | null;
    };
}
