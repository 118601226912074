import {
    type FormatOptions,
    addDays,
    addMonths,
    compareAsc,
    differenceInDays,
    differenceInMinutes,
    differenceInSeconds,
    endOfDay,
    endOfMonth,
    endOfWeek as endOfWeekOrig,
    formatISO,
    format as formatOrig,
    getMonth,
    getTime,
    getWeek as getWeekOrig,
    getYear,
    intervalToDuration,
    isAfter,
    isBefore,
    isDate,
    isSameDay,
    isValid,
    isWithinInterval,
    parseISO,
    parseJSON,
    startOfDay,
    startOfMonth,
    startOfWeek as startOfWeekOrig,
    subMonths,
    subYears,
} from "date-fns";
import { de, enGB, es, fi, sv } from "date-fns/locale";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import getLocale from "./getLocale";

export {
    addDays,
    addMonths,
    compareAsc,
    endOfDay,
    endOfMonth,
    getMonth,
    getTime,
    getYear,
    intervalToDuration,
    isAfter,
    isBefore,
    isSameDay,
    parseJSON,
    startOfDay,
    startOfMonth,
    subMonths,
    subYears,
};

let locale = enGB;
let intlDateFormat = new Intl.DateTimeFormat("en-GB", { month: "short", day: "numeric" });

export function initDateLocale() {
    registerLocale("en", enGB);
    registerLocale("fi", fi);
    registerLocale("sv", sv);
    registerLocale("de", de);
    registerLocale("es", es);
    const language = getLocale();
    setDefaultLocale(language);
    switch (language) {
        case "en":
            locale = enGB;
            intlDateFormat = new Intl.DateTimeFormat("en-GB", { month: "short", day: "numeric" });
            break;
        case "sv":
            locale = sv;
            intlDateFormat = new Intl.DateTimeFormat("sv-SE", { month: "short", day: "numeric" });
            break;
        case "fi":
            locale = fi;
            intlDateFormat = new Intl.DateTimeFormat("fi-FI", { month: "short", day: "numeric" });
            break;
        case "de":
            locale = de;
            intlDateFormat = new Intl.DateTimeFormat("de-DE", { month: "short", day: "numeric" });
            break;
        case "es":
            locale = es;
            intlDateFormat = new Intl.DateTimeFormat("es-ES", { month: "short", day: "numeric" });
            break;
    }
}

export function parseDate(string: string): Date {
    if (!string) {
        return null;
    }
    return parseISO(string);
}

export function format(date: Date, formatting: string, options: FormatOptions = {}): string {
    return formatOrig(date, formatting, { locale, ...options });
}

export function formatShort(date: Date | null): string {
    if (date == null) {
        return "";
    }
    return formatOrig(date, "PP", { locale });
}

export function formatLong(date: Date | null): string {
    if (date == null) {
        return "";
    }
    return formatOrig(date, "PPp", { locale });
}

export function formatVeryShort(date: Date | null): string {
    if (date == null) {
        return "";
    }
    return intlDateFormat.format(date);
}

export function formatYMD(date: Date | null): string {
    if (date == null) {
        return "";
    }
    return formatOrig(date, "yyyy-MM-dd", { locale });
}

export function validDate(date: Date | null): boolean {
    if (date == null) {
        return false;
    }
    return isDate(date) && isValid(date);
}

export const diffDays = differenceInDays;
export const diffSeconds = differenceInSeconds;
export const diffMinutes = differenceInMinutes;

export function startOfWeek(date: Date): Date {
    return startOfWeekOrig(date, { locale, weekStartsOn: 1 });
}

export function endOfWeek(date: Date): Date {
    return endOfWeekOrig(date, { locale, weekStartsOn: 1 });
}

export function isBetweenDays(date: Date, start: Date, end: Date): boolean {
    // Check is inclusive with day granularity
    if (isSameDay(date, start)) {
        return true;
    }
    if (isSameDay(date, end)) {
        return true;
    }
    return isWithinInterval(date, { start, end });
}

export function isSameOrBeforeDay(date: Date, dateToCompare: Date): boolean {
    if (isSameDay(date, dateToCompare)) {
        return true;
    }
    return isBefore(date, dateToCompare);
}

export function isAfterDay(date: Date, dateToCompare: Date): boolean {
    return !isSameDay(date, dateToCompare) && isAfter(date, dateToCompare);
}

export function isBeforeDay(date: Date, dateToCompare: Date): boolean {
    return !isSameDay(date, dateToCompare) && isBefore(date, dateToCompare);
}

export function getWeek(date: Date): number {
    return getWeekOrig(date, { locale });
}

export function formatDate(date: Date): string {
    if (!date) {
        return null;
    }
    return formatISO(date, { representation: "date" });
}

export function formatDatetime(date: Date): string {
    if (!date) {
        return null;
    }
    return formatISO(date, { representation: "complete" });
}
