import { type JSX, memo } from "react";
import styled from "styled-components";
import { Bikers, Runners } from "../../shared/components/Runners";
import t from "../../shared/translations";
import WithDocs from "../components/WithDocs";
import Odometer from "./Odometer";
import Speedometer from "./Speedometer";
import type { IData } from "./query";
import { Box, Frame } from "./stylings";

interface IProps {
    data: IData;
}

const BikersHolder = styled.div`
    max-width: 800px;
`;

function PathGroupPerf({ data }: IProps): JSX.Element {
    const pm = data.currentPathMembership;
    const path = pm.learningPath;
    return (
        <Frame>
            {path.fixed && (
                <>
                    <Box $align="left">
                        <WithDocs
                            modal="Performance/Info/Running"
                            docPath="player/performance/running"
                            component="h3"
                            style={{ gridColumn: "span 12", justifyContent: "flex-start" }}
                        >
                            {t("player.performance.heading-path-group-running")}
                        </WithDocs>
                    </Box>
                    <Box $align="left">
                        <BikersHolder>
                            <Bikers
                                goal={path.goal}
                                checkpoint={path.checkpoint}
                                miles={pm.pathGroupMilesAvg}
                                pathGroupMiles={pm.allPathGroupMiles}
                            />
                        </BikersHolder>
                    </Box>
                    <Box $align="left">
                        <div style={{ maxWidth: 650 }}>
                            <Runners
                                goal={path.goal}
                                checkpoint={path.checkpoint}
                                miles={pm.miles}
                                memberMiles={pm.pathGroupMemberMiles}
                            />
                        </div>
                    </Box>
                </>
            )}
            <Box $span={[12, 4]}>
                <WithDocs
                    modal="Performance/Info/PathGroupMilesTotal"
                    docPath="player/performance/path-group-miles-total"
                    component="h3"
                >
                    {t("player.performance.heading-path-group-miles-total")}
                </WithDocs>
                <Odometer miles={pm.pathGroupMiles} wide />
            </Box>
            <Box $span={[12, 4]}>
                <WithDocs
                    modal="Performance/Info/PathGroupSpeedometer"
                    docPath="player/performance/path-group-speed"
                    component="h3"
                >
                    {t("player.performance.heading-path-group-speedometer")}
                </WithDocs>
                <Speedometer miles={pm.pathGroupSpeed} />
            </Box>
            <Box $span={[12, 4]}>
                <WithDocs
                    modal="Performance/Info/PathGroupMilesAvg"
                    docPath="player/performance/path-group-miles-avg"
                    component="h3"
                >
                    {t("player.performance.heading-path-group-miles-avg")}
                </WithDocs>
                <Odometer miles={pm.pathGroupMilesAvg} />
            </Box>
        </Frame>
    );
}

export default memo(PathGroupPerf);
