import t from "../../shared/translations";
import { Box } from "./FeedbackLink";
import type { IPerson } from "./query";
import type { JSX } from "react";

interface IProps {
    feedbacker: IPerson | null;
}

function FeedbackerInfo({ feedbacker }: IProps): JSX.Element {
    const name = feedbacker != null ? `${feedbacker.firstName} ${feedbacker.lastName}` : "";
    return (
        <Box>
            <h3>{t("player.submitted-task.heading-peer-feedback")}</h3>
            <p>
                {feedbacker != null
                    ? t("player.submitted-task.info-peer-feedbacker", { name })
                    : t("player.submitted-task.info-no-peer-feedbacker")}
            </p>
        </Box>
    );
}

export default FeedbackerInfo;
