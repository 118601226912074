import type { ValidationErrors } from "final-form";
import arrayMutators from "final-form-arrays";
import { type FormEvent, type JSX, useCallback, useMemo } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as colors from "../../../shared/colors";
import t from "../../../shared/translations";
import { type Dispatch, type IRecipient, type State, backToStep, toStep3 } from "../../state";
import { Center } from "../stylings";
import { Input } from "../stylings/form";

interface IProps {
    id: string;
}

interface IFormValues {
    recipients: IRecipient[];
    teamLeader: string;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (values.recipients) {
        const recipientErrors = new Array(values.recipients.length);
        values.recipients.forEach((value, index) => {
            recipientErrors[index] = {};
            if (!value.email) {
                recipientErrors[index].email = t("player.team-scan-setup-2.form-error-email-required");
            } else if (!value.email.includes("@")) {
                recipientErrors[index].email = t("player.team-scan-setup-2.form-error-email-invalid");
            }
            if (!value.firstName) {
                recipientErrors[index].firstName = t("player.team-scan-setup-2.form-error-first-name-required");
            }
            if (!value.lastName) {
                recipientErrors[index].lastName = t("player.team-scan-setup-2.form-error-last-name-required");
            }
        });
        errors.recipients = recipientErrors;
    }
    return errors;
}

const OuterRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem 15px;
    margin: 0 -15px;
    gap: 1rem;
    &:nth-child(even) {
        background-color: ${colors.secondaryColor};
    }

    @media (min-width: 600px) {
        grid-template-columns: 1fr auto;
    }

    @media (min-width: 1000px) {
        margin: 0;
    }
`;

const InnerRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    input {
        margin-bottom: 0;
    }
    @media (min-width: 480px) {
        grid-template-columns: 1fr 1fr;
        > *:first-child {
            grid-column: 1 / 3;
        }
    }
`;

function Step2({ id }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const recipients = useSelector((state: State) => state.teamScan[id].recipients);
    const initialValues = useMemo((): IFormValues => {
        const leader = recipients.find((rep) => rep.teamLeader);
        const teamLeader = leader.email;
        return {
            recipients,
            teamLeader,
        };
    }, [recipients]);

    const onSubmit = useCallback(
        (values: IFormValues) => {
            dispatch(
                toStep3({
                    id,
                    recipients: values.recipients.map((resp) => ({
                        ...resp,
                        teamLeader: resp.email === values.teamLeader,
                    })),
                }),
            );
        },
        [id],
    );

    const back = (event: FormEvent<HTMLButtonElement>): boolean => {
        event.preventDefault();
        dispatch(backToStep({ id, step: "step1" }));
        return false;
    };

    return (
        <>
            <h1>{t("player.team-scan-setup-2.heading")}</h1>
            <ul>
                <li>{t("player.team-scan-setup-2.info-all-correct")}</li>
                <li>{t("player.team-scan-setup-2.info-team-leader")}</li>
            </ul>
            <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues} validate={validate}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <FieldArray<IRecipient> name="recipients">
                            {({ fields }) =>
                                fields.map((name, index) => {
                                    const recipient = fields.value[index];
                                    if (recipient.current) {
                                        return (
                                            <OuterRow key={index}>
                                                <InnerRow>
                                                    <div>{recipient.email}</div>
                                                    <div>{recipient.firstName}</div>
                                                    <div>{recipient.lastName}</div>
                                                </InnerRow>
                                                <div>
                                                    <Field
                                                        name={"teamLeader"}
                                                        component="input"
                                                        type="radio"
                                                        value={recipient.email}
                                                    />{" "}
                                                    {t("player.team-scan-setup-2.form-label-team-leader")}
                                                </div>
                                            </OuterRow>
                                        );
                                    }
                                    return (
                                        <OuterRow key={index}>
                                            <InnerRow>
                                                <Input name={`${name}.email`} type="text" />
                                                <Input name={`${name}.firstName`} type="text" />
                                                <Input name={`${name}.lastName`} type="text" />
                                            </InnerRow>
                                            <div>
                                                <Field
                                                    name={"teamLeader"}
                                                    component="input"
                                                    type="radio"
                                                    value={recipient.email}
                                                />{" "}
                                                {t("player.team-scan-setup-2.form-label-team-leader")}
                                            </div>
                                        </OuterRow>
                                    );
                                })
                            }
                        </FieldArray>
                        <Center $top="1rem">
                            <button type="button" onClick={back}>
                                {t("player.team-scan-setup-2.form-button-back")}
                            </button>{" "}
                            <button type="submit" disabled={submitting}>
                                {t("player.team-scan-setup-2.form-button-submit")}
                            </button>
                        </Center>
                    </form>
                )}
            </Form>
        </>
    );
}

export default Step2;
