import { type JSX, memo } from "react";
import styled from "styled-components";
import GroupTaskIcon from "../../../shared/components/icons/GroupTaskIcon";
import fmtGroupName from "../../../shared/fmtGroupName";
import t from "../../../shared/translations";
import { Box } from "../stylings";
import { PathGroupMemberBox, PathGroupPreviewBox, ProfilePic } from "./PathGroupPreview";

interface IProps {
    taskGroup: {
        id: string;
        number: number;
        name: string | null;
        members: {
            id: string;
            firstName: string;
            lastName: string;
            profilePicSrc: string | null;
        }[];
    } | null;
}

const DEFAULT_SRC = "/static/images/profile-silouette.png";

const H3 = styled.h3`
    display: grid;
    grid-template-columns: 26px 1fr;
    align-items: center;
    gap: 0.5rem;
    svg {
        width: 26px;
        height: 26px;
    }
`;

const H4 = styled.h4`
    grid-column: 1/-1;
    place-self: start;
`;

function TaskGroupPreview({ taskGroup }: IProps): JSX.Element {
    if (taskGroup == null) {
        return <PathGroupPreviewBox />;
    }
    return (
        <>
            <Box>
                <H3>
                    <GroupTaskIcon />
                    {t("player.task.heading-task-group-task")}
                </H3>
                <ul>
                    <li>{t("player.task.info-submit-task-group")}</li>
                </ul>
            </Box>
            <PathGroupPreviewBox>
                <H4>{fmtGroupName(taskGroup)}</H4>
                {taskGroup.members.map((person) => (
                    <PathGroupMemberBox key={person.id}>
                        <ProfilePic src={person.profilePicSrc || DEFAULT_SRC} />
                        <span>
                            {person.firstName} {person.lastName}
                        </span>
                    </PathGroupMemberBox>
                ))}
            </PathGroupPreviewBox>
        </>
    );
}

export default memo(TaskGroupPreview);
