import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import ShowError from "../components/ShowError";
import InspList from "./InspList";
import { type IArgs, type IData, query } from "./query";
import type { JSX } from "react";

interface IProps {
    id: string;
}

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1030px;
    width: 100%;
`;

function Inspirations({ id }: IProps): JSX.Element {
    const [result] = useQuery<IData, IArgs>({
        query,
        requestPolicy: "network-only",
        variables: { id },
    });
    if (result.fetching) {
        return (
            <Wrapper style={{ height: 200 }}>
                <Loader />
            </Wrapper>
        );
    }
    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }
    return (
        <Wrapper>
            <InspList inspirations={result.data.learningPath.inspirations} />
        </Wrapper>
    );
}

export default Inspirations;
