import type { ValidationErrors } from "final-form";
import { range } from "lodash-es";
import type { Dispatch, JSX } from "react";
import { Form } from "react-final-form";
import t from "../../translations";
import type { Gender } from "../../types";
import Input from "./Input";
import Select from "./Select";
import type { IAction, IState } from "./state";
import { ButtonRow, FormGrid } from "./stylings";

interface IFormValues {
    email: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    title: string;
    tel: string;
    birthYear: string;
    emailNotifications: boolean;
}

interface IProps {
    state: IState;
    dispatch: Dispatch<IAction>;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.email) {
        errors.email = t("shared.account-wizard.form-profile-error-email-required");
    } else if (!values.email.includes("@")) {
        errors.email = t("shared.account-wizard.form-profile-error-email-must-be-email");
    }
    if (!values.firstName) {
        errors.firstName = t("shared.account-wizard.form-profile-error-first-name-required");
    }
    if (!values.lastName) {
        errors.lastName = t("shared.account-wizard.form-profile-error-last-name-required");
    }
    return errors;
}

const YEARS = range(99, 0, -1)
    .map((i) => (new Date().getFullYear() - i).toString())
    .map((year) => ({ value: year, label: year }));
YEARS.unshift({ value: "", label: "" });

const GENDERS = [
    { label: t("shared.gender.unspecified"), value: "unspecified" },
    { label: t("shared.gender.female"), value: "female" },
    { label: t("shared.gender.male"), value: "male" },
];

function ProfileForm({ state, dispatch }: IProps): JSX.Element {
    const initialValues: IFormValues = {
        email: state.values.email,
        firstName: state.values.firstName,
        lastName: state.values.lastName,
        gender: state.values.gender,
        title: state.values.title || "",
        tel: state.values.tel || "",
        birthYear: (state.values.birthYear || "").toString(),
        emailNotifications: state.values.emailNotifications,
    };
    const onSubmit = (values: IFormValues) => {
        dispatch({ type: "NEXT-PROFILE", values });
    };

    return (
        <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <p>{t("shared.account-wizard.form-profile-info")}</p>
                    <FormGrid>
                        <Input name="email" label={t("shared.account-wizard.form-profile-label-email")} required />
                        <Input
                            name="firstName"
                            label={t("shared.account-wizard.form-profile-label-first-name")}
                            required
                        />
                        <Input
                            name="lastName"
                            label={t("shared.account-wizard.form-profile-label-last-name")}
                            required
                        />
                        <Select
                            name="gender"
                            label={t("shared.account-wizard.form-profile-label-gender")}
                            options={GENDERS}
                        />
                        <Input name="title" label={t("shared.account-wizard.form-profile-label-title")} />
                        <Input name="tel" label={t("shared.account-wizard.form-profile-label-tel")} />
                        <Select
                            name="birthYear"
                            label={t("shared.account-wizard.form-profile-label-birth-year")}
                            options={YEARS}
                        />
                    </FormGrid>
                    <ButtonRow>
                        <button type="submit">{t("shared.account-wizard.form-profile-button-next")}</button>
                    </ButtonRow>
                </form>
            )}
        </Form>
    );
}

export default ProfileForm;
