import type { ComponentPropsWithoutRef } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function ComputerIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path d="m10 71 l23 0 l1 3 l32 0 l1 -3 l23 0 q4 5, 0 10 l-79.5 0 q-5 -5, 0 -10z" fill="#fff" />
            <path
                d="m81.1 63.8h-62.1c-5.5 0-10-4.5-10-10v-26.2c0-5.5 4.5-10 10-10h62c5.5 0 10 4.5 10 10v26.2c.1 5.5-4.4 10-9.9 10z"
                fill="#222"
            />
            <path
                d="m19 20.6c-3.9 0-7 3.1-7 7v26.2c0 3.9 3.1 7 7 7h62c3.9 0 7-3.1 7-7v-26.2c0-3.9-3.1-7-7-7z"
                fill={active ? "#0076c2" : "#fff"}
            />
            <path
                d="m88.7 69.3h-21.9c-.5 0-1 .3-1.3.7s-.3 1-.1 1.4c0 .1.1.2.1.4s-.1.4-.1.5h-30.8c0-.1-.1-.2-.1-.5 0-.2 0-.3.1-.4.2-.5.2-1-.1-1.4s-.8-.7-1.3-.7h-21.8c-2.7 0-5 3.1-5 7 0 2.8 2.2 6 5 6h77.1c2.8 0 5-3.2 5-6 .1-3.4-1.9-7-4.8-7zm0 9.9h-77.3c-.9 0-2-1.7-2-3 0-2.3 1.2-4 2-4h20.1c.2 1.7 1.4 3 2.9 3h31.1c1.5 0 2.7-1.3 2.9-3h20.1c.8 0 2 1.8 2 4 .1 1.4-1 3-1.8 3z"
                fill="#222"
            />
        </svg>
    );
}

export default ComputerIcon;
