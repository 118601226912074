import type { JSX } from "react";
import type { IPathGroup, ISubmittedTask, ITask, ITaskVariant } from "../query";
import AnswerWizard from "./AnswerWizard";
import PathGroupPreview from "./PathGroupPreview";
import PeerFeedbackInfo from "./PeerFeedbackInfo";
import ShareAnswersInfo from "./ShareAnswersInfo";
import StartPoll from "./StartPoll";
import StartQuiz from "./StartQuiz";
import StartSurveyGizmo from "./StartSurveyGizmo";
import TaskGroupPreview from "./TaskGroupPreview";

interface Props {
    task: ITask;
    shownTask: ISubmittedTask | ITask;
    pathGroup: IPathGroup;
    taskGroup: {
        id: string;
        number: number;
        name: string | null;
        members: {
            id: string;
            firstName: string;
            lastName: string;
            profilePicSrc: string | null;
        }[];
    } | null;
    redo: boolean;
    variant: ITaskVariant;
    invert: boolean;
}

function AnswerSection({ task, shownTask, redo, pathGroup, taskGroup, variant, invert }: Props): JSX.Element {
    let answerField = <div />;
    if (variant.taskQuestions[0].answerType === "quiz") {
        answerField = <StartQuiz task={task} variant={variant} invert={invert} />;
    } else if (variant.taskQuestions[0].answerType === "poll") {
        answerField = <StartPoll task={task} variant={variant} invert={invert} />;
    } else if (variant.taskQuestions[0].answerType === "survey-gizmo") {
        answerField = <StartSurveyGizmo task={task} variant={variant} invert={invert} />;
    } else {
        answerField = <AnswerWizard task={task} shownTask={shownTask} redo={redo} variant={variant} invert={invert} />;
    }
    return (
        <section>
            {task.doInGroup === "path_group" && (
                <PathGroupPreview
                    pathGroup={pathGroup}
                    finished={task.finished}
                    requireApproval={shownTask.requireApproval}
                />
            )}
            {task.doInGroup === "task_group" && <TaskGroupPreview taskGroup={taskGroup} />}
            {shownTask.shareAnswers !== "no_one" && (
                <ShareAnswersInfo shareType={shownTask.shareAnswers} anonymous={shownTask.shareAnswersAnonymously} />
            )}
            {variant.variantType === "peer-feedback" && <PeerFeedbackInfo task={task} />}
            {answerField}
        </section>
    );
}

export default AnswerSection;
