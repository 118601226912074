import { memo, type JSX } from "react";
import TaskThreadItemAnswer from "../components/TaskThreadItemAnswer";
import ThreadItem from "../components/ThreadItem";
import type { ITaskThreadItem } from "./query";

interface IProps {
    thread: ITaskThreadItem[];
}

function Thread({ thread }: IProps): JSX.Element {
    thread = thread.filter((item) => item.type !== "unapproved-answer");
    return (
        <section className="feedback-conversation">
            {thread.map((tti) => {
                if (tti.type === "answer") {
                    return <TaskThreadItemAnswer key={tti.id} tti={tti} />;
                }
                return <ThreadItem tti={tti} key={tti.id} />;
            })}
        </section>
    );
}

export default memo(Thread);
