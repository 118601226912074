import type { JSX } from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../../shared/components/Loader";
import t from "../../../shared/translations";
import type { TeamScanStatusType } from "../../../shared/types";
import ShowError from "../../components/ShowError";
import { OList, PaddedLeft } from "../stylings";
import TeamScanBox from "./TeamScanBox";

const teamScansQuery = `
    query {
        teamScanList {
            id
            status
            reportDatetime
            progress
            myLink
            submittedTaskVariant {
                id
                submittedTask {
                    id
                    learningPathItem {
                        id
                        learningPath {
                            id
                            pathname
                        }
                    }
                }
            }
        }
    }
`;

export interface ITeamScan {
    id: string;
    status: TeamScanStatusType;
    reportDatetime: string | null;
    progress: [number, number];
    myLink: string;
    submittedTaskVariant: {
        id: string;
        submittedTask: {
            id: string;
            learningPathItem: {
                id: string;
                learningPath: {
                    id: string;
                    pathname: string;
                };
            } | null;
        };
    } | null;
}

interface IData {
    teamScanList: ITeamScan[];
}

const Row = styled.div`
    display: grid;
    gap: 1rem;
    align-items: start;
    grid-tempalte-columns: 1fr;
    @media (min-width: 700px) {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
    }
`;

const TeamScansCol = styled.div`
    display: grid;
    justify-items: center;
    gap: 1rem;
`;

function Landing(): JSX.Element {
    const [result] = useQuery<IData>({
        query: teamScansQuery,
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const showIntro = result.data.teamScanList.length === 1 && result.data.teamScanList[0].status === "setup-survey";

    return (
        <>
            <h1>{t("player.team-scan-landing.heading")}</h1>
            <Row>
                {showIntro && (
                    <PaddedLeft>
                        <p>{t("player.team-scan-landing.info-process-steps")}</p>
                        <OList>
                            <li>{t("player.team-scan-landing.info-process-step-1")}</li>
                            <li>{t("player.team-scan-landing.info-process-step-2")}</li>
                            <li>{t("player.team-scan-landing.info-process-step-3")}</li>
                        </OList>
                    </PaddedLeft>
                )}
                <TeamScansCol>
                    {result.data.teamScanList.map((tq) => (
                        <TeamScanBox key={tq.id} teamScan={tq} />
                    ))}
                </TeamScansCol>
            </Row>
        </>
    );
}

export default Landing;
