import { type UseFieldConfig, useField } from "react-final-form";
import type { JSX } from "react";

interface IOwnProps {
    name: string;
}

type IProps = IOwnProps & UseFieldConfig<string>;

function Textfield({ name, ...props }: IProps): JSX.Element {
    const {
        input,
        meta: { error, touched },
    } = useField(name, props);
    return (
        <div className={touched && error ? "error" : ""}>
            <textarea {...input} className="answer" />
            {touched && error && <small className="error">{error}</small>}
        </div>
    );
}

export default Textfield;
