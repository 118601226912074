import * as colors from "../../shared/colors";
import t from "../../shared/translations";
import { type ITask, taskFinished, taskRedo, taskUndecied } from "./query";
import type { JSX } from "react";

interface IProps {
    task: ITask;
}

function Status({ task }: IProps): JSX.Element | null {
    if (taskFinished(task)) {
        return <span style={{ color: colors.primaryColor }}>{t("player.task-choice.status-accepted")}</span>;
    }
    if (taskUndecied(task)) {
        return <span style={{ color: colors.primaryColor }}>{t("player.task-choice.status-undecided")}</span>;
    }
    if (taskRedo(task)) {
        return <span style={{ color: colors.deadline }}>{t("player.task-choice.status-redo")}</span>;
    }
    return null;
}

export default Status;
